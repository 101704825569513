import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../../@components/UI";
import Paper from "@mui/material/Paper";
import useBlockchainState from "../useBlockchainState";
import useVote from "../useVote";
import useQueue from "../useQueue";
import useExecute from "../useExecute";
import { useSnackbar } from "notistack";
import useService from "../../../proposal/useService";
import { useMenu, useUserRole } from "../../../context";
import { ethers } from "ethers";
import eventDeployerArtifact from "../../../../../abis/contracts/modules/event/Deployer.sol/EventDeployer.json";
import collectionDeployerArtifact from "../../../../../abis/contracts/modules/collection/Deployer.sol/CollectionDeployer.json";
import { useAccount } from "wagmi";
import { useUserContext } from "../../../../../@components/userContext";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import customHooks from "../../../../../@components/hooks";

const VotingPanel = ({ DAO, proposal, onChange }) => {
  const { user: loggedInUser } = useUserContext();
  const { isConnected } = useAccount();
  const { isBrandMember } = useUserRole();
  const proposalService = useService(DAO?.id);
  const { updateMenu } = useMenu();
  const signer = customHooks.useEthersSigner();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [eventListeners, setEventListeners] = React.useState([]);

  const { proposalState, accountHasVoted, votingPower } = useBlockchainState(
    DAO,
    proposal
  );
  const { vote } = useVote(DAO, proposal);
  const { queue } = useQueue(DAO, proposal);
  const { execute } = useExecute(DAO, proposal);

  const saveProposalToExecuted = async (eventAddress) => {
    const data = {
      deployed_address: eventAddress,
    };

    await proposalService
      .setProposalExecuted(proposal?.id, data)
      .then(() => {
        enqueueSnackbar("Collection was created successfully", {
          variant: "success",
        });
        updateMenu();
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(error?.message, { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addEventListeners = () => {
    if (eventListeners.length === 0) {
      if (proposal?.data?.payment_token.type === "NATIVE") {
        const eventDeployer = new ethers.Contract(
          DAO?.event_deployer_contract?.address,
          eventDeployerArtifact.abi,
          signer
        );
        eventDeployer.on(
          "EventCreatedSucessfully",
          async (
            address,
            owner,
            treasuryAddress,
            maxMintLimit,
            price,
            name,
            symbol,
            uri
          ) => {
            if (name === proposal.data.name) {
              await saveProposalToExecuted(address);
              removeEventListeners();
            }
          }
        );
        setEventListeners((prevValue) => [
          ...prevValue,
          "EventCreatedSucessfully",
        ]);
      } else if (proposal?.data?.payment_token.type === "ERC20") {
        const collectionDeployer = new ethers.Contract(
          DAO?.collection_deployer_contract?.address,
          collectionDeployerArtifact.abi,
          signer
        );
        collectionDeployer.on(
          "CollectionCreatedSucessfully",
          async (
            address,
            owner,
            treasuryAddress,
            payTokenAddress,
            maxMintLimit,
            price,
            name,
            symbol,
            uri
          ) => {
            if (name === proposal.data.name) {
              await saveProposalToExecuted(address);
              removeEventListeners();
            }
            // onSuccess(eventAddress);
          }
        );
        setEventListeners((prevValue) => [
          ...prevValue,
          "CollectionCreatedSucessfully",
        ]);
      }
    }
  };

  const removeEventListeners = () => {
    const eventDeployer = new ethers.Contract(
      DAO?.event_deployer_contract?.address,
      eventDeployerArtifact.abi,
      signer
    );
    eventDeployer.removeAllListeners();

    const collectionDeployer = new ethers.Contract(
      DAO?.collection_deployer_contract?.address,
      collectionDeployerArtifact.abi,
      signer
    );
    collectionDeployer.removeAllListeners();
  };

  React.useEffect(() => {
    return () => {
      removeEventListeners();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <UI.Busy.FullscreenIndicator show={isLoading} />
      {proposalState !== "Executed" && (
        <Paper
          sx={{
            mt: "45px",
            p: { md: "40px", mobile: "20px" },
            backgroundColor: "rgba(27, 30, 47, 0.5)",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant={"h2"}>CAST YOUR VOTE</Typography>
            </Box>
            {!accountHasVoted && isBrandMember && (
              <Typography variant={"subtitle1"}>
                {votingPower.toString() !== "0.0"
                  ? `You have ${votingPower.toString()} votes`
                  : `Only members of this DAO can vote`}
              </Typography>
            )}
            {accountHasVoted && (
              <Typography variant={"subtitle1"}>
                You have already voted
              </Typography>
            )}
            {proposalState === "Queued" && (
              <Box
                sx={{
                  mt: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant={"h3"} sx={{ mb: "20px" }}>
                    Proposal in Timelock
                  </Typography>
                  <UI.Button
                    onClick={async () => {
                      addEventListeners();
                      setIsLoading(true);
                      execute(proposal)
                        .then((response) => {
                          onChange(response);
                        })
                        .catch(() => {
                          removeEventListeners();
                          setIsLoading(false);
                        });
                    }}
                    title={"Create Collection"}
                    type={"primary"}
                  />
                </Box>
              </Box>
            )}
            {proposalState === "Executed" && !isLoading && (
              <Box>
                <Box
                  sx={{
                    mt: "20px",
                    display: { md: "flex", mobile: "none" },
                    justifyContent: "center",
                  }}
                >
                  <Typography variant={"h3"} sx={{ mb: "20px" }}>
                    Collection was sucessfully created
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: "20px",
                    display: { md: "none", mobile: "flex" },
                    justifyContent: "center",
                  }}
                >
                  <Typography variant={"h4"} sx={{ mb: "20px" }}>
                    Collection was sucessfully created
                  </Typography>
                </Box>
              </Box>
            )}
            {proposalState === "Succeeded" && (
              <Box
                sx={{
                  mt: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <UI.Button
                  onClick={async () => {
                    setIsLoading(true);
                    queue(proposal)
                      .then((response) => {
                        onChange(response);
                        setIsLoading(false);
                      })
                      .catch(() => {
                        setIsLoading(false);
                      });
                  }}
                  title={"Add to Queue"}
                  type={"primary"}
                />
              </Box>
            )}
            {proposalState !== "Succeeded" &&
              proposalState !== "Queued" &&
              proposalState !== "Executed" && (
                <Box
                  sx={{
                    mt: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <UI.Button
                    disabled={
                      accountHasVoted ||
                      !isConnected ||
                      !loggedInUser?.email_verified ||
                      !isBrandMember
                    }
                    onClick={async () => {
                      setIsLoading(true);
                      vote(proposal, 1, votingPower)
                        .then((response) => {
                          onChange(response);
                          setIsLoading(false);
                        })
                        .catch(() => {
                          setIsLoading(false);
                        });
                    }}
                    title={"YES"}
                    type={"primary"}
                    startIcon={<AccountBalanceWalletIcon />}
                  />
                  <UI.Button
                    disabled={
                      accountHasVoted ||
                      !isConnected ||
                      !loggedInUser?.email_verified ||
                      !isBrandMember
                    }
                    onClick={async () => {
                      setIsLoading(true);
                      vote(proposal, 0, votingPower)
                        .then((response) => {
                          onChange(response);
                          setIsLoading(false);
                        })
                        .catch(() => {
                          setIsLoading(false);
                        });
                    }}
                    sx={{ ml: "20px" }}
                    title={"NO"}
                    type={"primary"}
                    startIcon={<AccountBalanceWalletIcon />}
                  />
                  <UI.Button
                    disabled={
                      accountHasVoted ||
                      !isConnected ||
                      !loggedInUser?.email_verified ||
                      !isBrandMember
                    }
                    onClick={async () => {
                      setIsLoading(true);
                      vote(proposal, 2, votingPower)
                        .then((response) => {
                          onChange(response);
                          setIsLoading(false);
                        })
                        .catch(() => {
                          setIsLoading(false);
                        });
                    }}
                    sx={{ ml: "20px" }}
                    title={"ABSTAIN"}
                    type={"secondary"}
                    startIcon={<AccountBalanceWalletIcon />}
                  />
                </Box>
              )}
          </Box>
        </Paper>
      )}
    </React.Fragment>
  );
};

export default VotingPanel;
