import { ethers } from "ethers";
import tokenArtifact from "../../../../abis/contracts/modules/token/ERC20Token.sol/ERC20Token.json";
import customHooks from "../../../../@components/hooks";

const mintTokensEncodedFunction = (
  tokenInstance,
  walletAddress,
  amountTokens
) => {
  return tokenInstance.interface.encodeFunctionData(
    "mintTokens(address _receiver, uint256 _amount)",
    [walletAddress, ethers.parseEther(amountTokens.toString())]
  );
};

export const useProposal = (DAO) => {
  const provider = customHooks.useEthersProvider();

  const getProposalData = (walletAddress, amountTokens) => {
    const tokenInstance = new ethers.Contract(
      DAO?.dao_token?.token_address,
      tokenArtifact.abi,
      provider
    );

    let calldatas = [];
    let targets = [];
    let values = [];

    targets.push(DAO?.token_contract?.address);
    calldatas.push(
      mintTokensEncodedFunction(tokenInstance, walletAddress, amountTokens)
    );
    values.push(0);

    return {
      targets,
      calldatas,
      values,
    };
  };

  return {
    getProposalData,
  };
};
