import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useUserContext } from "../../@components/userContext";
import Link from "@mui/material/Link";
import { useDisconnect } from "wagmi";
import truncateEthAddress from "truncate-eth-address";
import UI from "../../@components/UI";

const WrongAccountDialog = ({ toggleOpen }) => {
  const { disconnect } = useDisconnect();
  const { user: loggedInUser } = useUserContext();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);

  const handleClose = () => {
    setOpen(false);
    disconnect();
  };
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          backgroundColor: "#1B1E2F !important",
          padding: { md: "40px 80px", mobile: "0px 0px" },
          width: "700px",
          textAlign: "center",
          margin: { mobile: "15px" },
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <CloseIcon
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 30,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <Grid container>
          <Grid item xs={12} md={12}>
            <AccountBalanceWalletIcon
              sx={{
                width: "80px",
                height: "80px",
                display: "flex",
                margin: "0 auto",
              }}
            />
            <Typography
              variant={"h6"}
              sx={{ fontSize: "30px", textAlign: "center", mt: "50px" }}
            >
              Wrong Wallet
            </Typography>
            <Typography variant={"subtitle1"} sx={{ mt: "20px" }}>
              The account associated with the email address{" "}
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                {loggedInUser?.email}
              </Box>{" "}
              has already been linked to the following wallet address{" "}
              <Box
                sx={{
                  fontWeight: "bold",
                  width: "190px",
                  margin: "0 auto",
                  mt: "10px",
                }}
              >
                <UI.CopyToClipboard
                  label={truncateEthAddress(loggedInUser?.address || "")}
                  value={loggedInUser?.address}
                  showBorder
                />
              </Box>{" "}
            </Typography>
            <Box sx={{ mt: "70px", borderBottom: "1px solid #303346" }}></Box>
            <Box sx={{ mt: "45px", px: "10px" }}>
              <Typography
                variant={"subtitle1"}
                sx={{ fontSize: "15px !important", textAlign: "center" }}
              >
                Contact support{" "}
                <Link
                  sx={{ textDecoration: "none" }}
                  target="_blank"
                  href={"mailto: mainUtility@qvrse.io"}
                >
                  info@qvrse.io
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </MaterialDialog>
  );
};

export default WrongAccountDialog;
