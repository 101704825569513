import * as React from "react";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Drawer from "@mui/material/Drawer";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "100%",
  },
}));

const MobileSidebar = ({ toggleMenuOpen, children }) => {
  const classes = useStyles();
  const location = useLocation();
  const [state, setState] = React.useState(false);

  React.useEffect(() => {
    setState(false);
  }, [location]);

  React.useEffect(() => {
    toggleMenuOpen && setState(!state);
    // eslint-disable-next-line
  }, [toggleMenuOpen]);

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor={"right"}
      open={state}
      onClose={() => setState(false)}
      transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
    >
      <DialogTitle
        className="drawerTitle"
        sx={{ display: "flex", mt: "15px", justifyContent: "flex-end" }}
      >
        <IconButton onClick={() => setState(false)}>
          <HighlightOffIcon sx={{ color: "white" }} />
        </IconButton>
      </DialogTitle>
      <Box sx={{ width: "auto", zIndex: 0 }} role="presentation">
        {children}
      </Box>
    </Drawer>
  );
};

export default MobileSidebar;
