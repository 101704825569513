import React from "react";
import useService from "./useService";
import Tooltip from "@mui/material/Tooltip";
import SkeletonList from "./SkeletonList";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import UI from "../../../@components/UI";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import publicIcon from "../../../static/public_icon.png";
import collectionIcon from "../../../static/collection_icon.png";
import PreviewSwiper from "./PreviewSwiper";
import { useSnackbar } from "notistack";
import DeleteDialog from "./DeleteDialog";
import CustomHooks from "../../../@components/hooks";
import { useUserRole } from "../context";
import PushPinIcon from "@mui/icons-material/PushPin";
import Poll from "./poll";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";

const List = ({ DAO }) => {
  const { enqueueSnackbar } = useSnackbar();

  const service = useService(DAO?.id);

  const {
    data: items,
    isLoading,
    refetch,
  } = CustomHooks.useFetch("posts", service.list, {
    select: (response) => {
      // Find the pinned post
      const pinnedPost = response.find((item) => item.pinned_post === true);
      if (pinnedPost) {
        // Remove the pinned post from the original array
        const filteredItems = response.filter(
          (item) => item.pinned_post !== true
        );

        // Create a new array with the pinned post as the first item
        const reorganizedItems = [pinnedPost, ...filteredItems];
        return reorganizedItems;
      } else {
        return response;
      }
    },
  });

  const { mutate: removePost, isLoading: isRemovingPost } = CustomHooks.usePost(
    service.remove,
    {
      onSuccess: () => {
        enqueueSnackbar("Post was succesfully removed", {
          variant: "success",
        });
        refetch();
      },
    }
  );

  const { mutate: pinPost, isLoading: isPinningPost } = CustomHooks.usePost(
    service.pinPost,
    {
      onSuccess: () => {
        enqueueSnackbar("Post was succesfully pinned", {
          variant: "success",
        });
        refetch();
      },
    }
  );

  React.useEffect(() => {
    DAO && refetch();
    // eslint-disable-next-line
  }, [DAO, refetch]);

  return (
    <Box>
      <UI.Busy.FullscreenIndicator show={isRemovingPost}>
        <Typography variant={"subtitle2"}>Removing post ...</Typography>
        <Typography variant={"subtitle2"}>
          Please do not close this window.
        </Typography>
      </UI.Busy.FullscreenIndicator>
      <UI.Busy.FullscreenIndicator show={isPinningPost}>
        <Typography variant={"subtitle2"}>Pinning post ...</Typography>
        <Typography variant={"subtitle2"}>
          Please do not close this window.
        </Typography>
      </UI.Busy.FullscreenIndicator>
      {isLoading && <SkeletonList />}
      {!isLoading &&
        items &&
        items.length > 0 &&
        items.map((item) => (
          <Item
            key={item.id}
            DAO={DAO}
            data={item}
            refetch={refetch}
            onPinPost={(id) => pinPost(id)}
            onRemove={(id) => removePost(id)}
          />
        ))}
      {!isLoading && items && items.length === 0 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <UI.NoResults text={"No posts found :("} />
        </Box>
      )}
    </Box>
  );
};

const Item = ({ DAO, data, onRemove, onPinPost, refetch }) => {
  const { isBrandMember } = useUserRole();

  const getMaxTextLength = () => {
    if (data?.files?.length > 0) return 200;
    if (data?.content?.type === "poll") return 400;
    return 800;
  };

  return (
    <Paper
      sx={{
        backgroundColor: "transparent",
        mb: "25px",
        width: "100%",
        position: "relative",
        "&:hover .removeIcon": {
          display: "block",
          transition: "all 0.1s ease 0s",
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            backgroundColor: "#1B1E2F",
            display: "flex",
            p: { md: "20px 20px", mobile: "20px 10px" },
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                mr: { md: "10px", mobile: "10px" },
              }}
            >
              <Avatar src={DAO?.avatar_url} alt={"avatar"} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography 
                variant={"h4"}
                sx={{
                  lineHeight: { md: "34px", mobile: "22px" },
                }}
              >
                {DAO?.name}
              </Typography>
              <Typography
                variant={"subtitle1"}
                sx={{
                  fontSize: "13px !important",
                  color: "#7A7E92 !important",
                  opacity: "1 !important",
                  display: { md: "none", mobile: "flex" },
                }}
              >
                {moment(data.datetime_created).format("DD MMM YYYY HH:mm")}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { md: "column", mobile: "flex" },
                justifyContent: "center",
                mr: "15px",
              }}
            >
              <Typography
                variant={"subtitle1"}
                sx={{
                  fontSize: "13px !important",
                  color: "#7A7E92 !important",
                  opacity: "1 !important",
                  display: { md: "flex", mobile: "none" },
                }}
              >
                {moment(data.datetime_created).format("DD MMM YYYY HH:mm")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {data.is_public ? (
                <Tooltip
                  sx={{
                    width: "18px",
                    cursor: "pointer",
                    ml: "5px",
                    color: (theme) => theme.palette.colors.primary,
                  }}
                  title={"Public"}
                  placement="top"
                  arrow
                >
                  <Box
                    component={"img"}
                    sx={{
                      width: "18px",
                      height: "18px",
                      marginRight: "7px",
                    }}
                    src={publicIcon}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  sx={{
                    width: "18px",
                    cursor: "pointer",
                    ml: "5px",
                    color: (theme) => theme.palette.colors.primary,
                  }}
                  title={`Visible only to collections: ${data.collections.map(
                    (collection) => " " + collection.name
                  )}`}
                  placement="top"
                  arrow
                >
                  <Box
                    component={"img"}
                    sx={{
                      width: "23.3px",
                      height: "17px",
                      marginRight: "7px",
                    }}
                    src={collectionIcon}
                  />
                </Tooltip>
              )}
            </Box>
            {isBrandMember && (
              <Box
                onClick={() => onPinPost(data.id)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Tooltip
                  sx={{
                    width: "20px",
                    cursor: "pointer",
                    ml: "4px",
                    color: data?.pinned_post
                      ? (theme) => "#ffffff"
                      : "#7A7E92",
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                  title={data?.pinned_post ? "Pinned post" : "Pin Post"}
                  placement="top"
                  arrow
                >
                  <PushPinIcon />
                </Tooltip>
              </Box>
            )}
            {isBrandMember && (
              <Box
                sx={{
                  display: "flex",
                  ml: "2px",
                }}
              >
                <UI.VertMenu color={"#7A7E92"} fontSize={"1.5rem"}>
                  <DeleteDialog.Action
                    element={
                      <MenuItem disabled={!isBrandMember}
                        sx={{ 
                          mr: "10px", 
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          fontSize: "14px",
                          background: "#0E111F",
                         }}
                        >
                          <ListItemIcon sx={{ mr: "9px" }}>
                            <DeleteOutlineIcon sx={{ color: "#944DFF", fontSize:"26px !important" }} />
                          </ListItemIcon>
                          Remove post
                      </MenuItem>
                    }
                    onConfirm={() => onRemove(data.id)}
                  />
                </UI.VertMenu>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            pt: "6px",
            px: { md: "20px", mobile: "10px" },
            pb: "23px",
            backgroundColor: "#1B1E2F",
          }}
        >
          <UI.ReadMore
            maxTextLength={getMaxTextLength()}
            sx={{
              overflowWrap: "break-word",
            }}
          >
            {data.description}
          </UI.ReadMore>
        </Box>
        {data.files !== null && data.files.length > 0 && (
          <PreviewSwiper items={data.files} />
        )}
        {data.content?.type === "poll" && (
          <Box>
            <Poll
              daoId={DAO?.id}
              postId={data?.id}
              data={data.content}
              refetch={refetch}
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default List;
