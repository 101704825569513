import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import UI from "../../../../../../@components/UI";
import Grid from "@mui/material/Grid";
import Ticket from "./Ticket";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CustomHooks from "../../../../../../@components/hooks";
import useCollectionService from "../../../useService";
import Typography from "@mui/material/Typography";
import PerkItem from "./PerkItem";
import NFTVaultPasswordDialog from "./NFTVaultPasswordDialog";

const Success = ({ DAO }) => {
  const { id } = useParams();
  const { state } = useLocation();
  const collectionService = useCollectionService(DAO?.id);
  const [mainUtility, setMainUtility] = React.useState(null);
  const [groupedUtilities, setGroupedUtilities] = React.useState(null);
  const history = useHistory();
  const [showNFTVaultPasswordDialog, setShowNFTVaultPasswordDialog] =
    React.useState(false);

  const { mutate: requestNFTVaultPassword } = CustomHooks.usePost(
    collectionService.getNFTVaultPassword,
    {
      onSuccess: (response) => {
        const { password } = response.data;
        setShowNFTVaultPasswordDialog(password);
        localStorage.setItem("is_password_generated", true);
      },
    }
  );

  // When user buys free nft, password is already generated. So just show the dialog.
  React.useEffect(() => {
    if (state?.password) {
      setShowNFTVaultPasswordDialog(state.password);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // When user buys with fiat.
  React.useEffect(() => {
    const is_password_generated = localStorage.getItem("is_password_generated");

    if (state.orderId && !is_password_generated) {
      requestNFTVaultPassword(state.orderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  React.useEffect(() => {
    return () => {
      localStorage.removeItem("is_password_generated");
    };
  }, []);

  const { data: collection } = CustomHooks.useFetch(
    "collection",
    () => collectionService.collection(id),
    {
      onSuccess: async (collection) => {
        setMainUtility(
          collection?.utilities.find((utility) => utility.is_main_utility)
        );

        const groupedUtilities = collection?.utilities.reduce(
          (acc, utility) => {
            const type = utility.type;
            if (!acc[type]) {
              acc[type] = [];
            }
            acc[type].push(utility);
            return acc;
          },
          {}
        );
        setGroupedUtilities(groupedUtilities);
      },
    }
  );

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Paper elevation={0} sx={{ width: "100%", background: "transparent" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { md: "0px", mobile: "10px" },
            pb: "60px",
            pt: { md: "20px", mobile: "20px" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <UI.BackButton
              fontSize={"14px !important"}
              title={"BACK TO COLLECTION"}
              onClick={() => history.push(`/brand/${DAO?.id}/collection/${id}`)}
              address={collection?.nft_address}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            paddingLeft: { mobile: "10px", md: "0px" },
            paddingRight: { mobile: "10px", md: "0px" },
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={4} mobile={12}>
              <Box
                sx={{
                  display: "flex",
                  mb: "60px",
                  justifyContent: { mobile: "center", md: "unset" },
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Ticket
                    brandName={DAO?.name}
                    brandAvatar={DAO?.avatar_url}
                    name={collection?.name}
                    fileType={collection?.nft_file_type}
                    data={collection?.nft_image_url}
                    mainUtility={mainUtility}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item md={8} mobile={12}>
              <Box sx={{ ml: { md: "40px", mobile: "0px" } }}>
                {collection?.total_price_with_tax === "0.00" ? (
                  <Typography variant={"h2"}>
                    CONGRATS ON YOUR NEW NFT
                  </Typography>
                ) : (
                  <Typography variant={"h2"}>
                    YOUR NFT IS ON ITS WAY TO YOUR DIGITAL WALLET
                  </Typography>
                )}

                {collection?.total_price_with_tax === "0.00" ? (
                  <Typography
                    variant={"subtitle1"}
                    sx={{
                      mt: { md: "30px", mobile: "24px" },
                      fontSize: "16px !important",
                    }}
                  >
                    You can check it out in your profile in just a few seconds.
                    From there, you can also manage your newly acquired NFT.
                  </Typography>
                ) : (
                  <Typography
                    variant={"subtitle1"}
                    sx={{
                      mt: { md: "30px", mobile: "24px" },
                      fontSize: "16px !important",
                    }}
                  >
                    Great news! Your payment is currently being processed, and
                    your NFT will be transferred to your digital wallet shortly.
                    As soon as the transfer is complete, you'll be able to view
                    it in your profile.
                  </Typography>
                )}

                <Typography
                  variant={"subtitle1"}
                  sx={{
                    mt: { md: "10px", mobile: "14px" },
                    fontSize: "16px !important",
                  }}
                >
                  The ways you can make use of your NFT cards depend on the
                  perks the brand has prepared for you. Access your QR code for
                  event tickets, services and product info in the confirmation
                  email. Meanwhile, explore the brand's feed to find your
                  digital content and the latest news.
                </Typography>
                <Typography
                  variant={"h2"}
                  sx={{
                    mt: { md: "40px", mobile: "50px" },
                    mb: "20px",
                    fontSize: "18px !important",
                  }}
                >
                  DISCOVER YOUR PERKS
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "rgba(27, 30, 47, 0.5);",
                    padding: { md: "14px 30px 15px", mobile: "20px 15px 10px" },
                    mb: { md: "0px", mobile: "30px" },
                  }}
                >
                  {groupedUtilities &&
                    Object.keys(groupedUtilities).map((key) => {
                      return (
                        <Box
                          sx={{
                            m: { md: "10px 0px 10px", mobile: "0px 0px 12px" },
                            fontSize: "16px !important",
                          }}
                        >
                          <PerkItem
                            collection={collection}
                            perkName={key}
                            numOfPerks={groupedUtilities[key].length}
                          />
                        </Box>
                      );
                    })}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      {showNFTVaultPasswordDialog && (
        <NFTVaultPasswordDialog
          DAO={DAO}
          collection={collection}
          password={showNFTVaultPasswordDialog}
          onClose={() => setShowNFTVaultPasswordDialog(false)}
        />
      )}
    </Box>
  );
};

export default Success;
