import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import UI from "../@components/UI";
import useService from "./useService";
import RedeemDialog from "./RedeemDialog";
import { useParams } from "react-router-dom";
import CustomHooks from "../@components/hooks";
import CheckIcon from "@mui/icons-material/Check";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";

const Item = ({
  name,
  onConfirm,
  availablePoints,
  spentPoints,
  hasPermissionToRedeem,
  utilityType,
  isMainUtility,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Paper
      sx={{
        backgroundColor: "rgba(27, 30, 47, 0.5)",
        height: "80px",
        padding: { md: "0px 35px", mobile: "0px 15px" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            variant={"h4"}
            sx={{
              fontSize: { mobile: "18px !important", md: "18px !important" },
              mr: "15px",
              maxWidth: { mobile: "200px", md: "350px", lg: "500px" },
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <UI.OverflowTip>{name}</UI.OverflowTip>
          </Typography>
          <Box
            sx={{
              display: { md: "flex", mobile: "none" },
              alignItems: "center",
            }}
          >
            <UI.UtilityTag
              utilityType={utilityType}
              isMainUtility={isMainUtility}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant={"h3"}
            sx={{
              mr: "24px",
              fontSize: { mobile: "18px !important", md: "18px !important" },
            }}
          >{`${spentPoints}/${availablePoints}`}</Typography>
          {hasPermissionToRedeem && (
            <RedeemDialog.Action
              element={
                <UI.Button
                  disabled={spentPoints === availablePoints}
                  type={"primary"}
                  title={isMobile ? <CheckIcon /> : "redeem"}
                  sx={{ height: "40px", width: isMobile ? "20px" : "unset" }}
                />
              }
              disabled={spentPoints === availablePoints}
              onConfirm={onConfirm}
            />
          )}
        </Box>
      </Box>
    </Paper>
  );
};

const Utilities = ({ utilities, onRedeem, hasPermissionToRedeem }) => {
  const service = useService();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: redeem, isLoading } = CustomHooks.usePost(service.redeem, {
    onSuccess: () => {
      enqueueSnackbar("Utility successfully redeemed", {
        variant: "success",
      });
      onRedeem();
    },
  });

  return (
    <>
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle2"}>Loading in progress ...</Typography>
        <Typography variant={"subtitle2"}>
          Please do not close this window.
        </Typography>
      </UI.Busy.FullscreenIndicator>
      <Box>
        {utilities.length &&
          utilities.map((item) => {
            return (
              <Box sx={{ mb: "15px" }} key={3}>
                <Item
                  utilityType={item?.utility.type}
                  isMainUtility={item?.utility.is_main_utility}
                  hasPermissionToRedeem={hasPermissionToRedeem}
                  name={item.utility.name}
                  availablePoints={item.available_redeems}
                  spentPoints={item.redeems_spent}
                  onConfirm={() =>
                    redeem({ qrTicketId: id, utilityId: item.id })
                  }
                />
              </Box>
            );
          })}
      </Box>
    </>
  );
};

export default Utilities;
