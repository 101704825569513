import React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UI from "../../../../../../@components/UI";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import Alert from "@mui/material/Alert";
import useService from "../../../useService";
import CustomHooks from "../../../../../../@components/hooks";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const RequestInfoDialog = ({
  collection,
  onConfirm,
  onClose,
  isFreeMintNoWallet,
}) => {
  const classes = useStyles();
  const service = useService(collection?.dao_id);

  const [requestInfoFields, setRequestInfoFields] = React.useState([]);

  React.useEffect(() => {
    if (collection?.utilities.length === 0) return;

    const fields = collection?.utilities
      .flatMap((utility) => utility.request_info_fields)
      .filter((field) => field !== undefined);
    setRequestInfoFields(fields);
  }, [collection]);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { mutate: newOrder } = CustomHooks.usePost(service.createOrder, {
    onSuccess: (response) => {
      const { id: orderId } = response;
      onConfirm(orderId);
    },
  });

  const onSubmit = async (form) => {
    const data = {
      collection_id: collection.id,
      additional_info: { ...form },
      payment_type: isFreeMintNoWallet ? "FREE_MINT" : "PAYPAL",
    };

    await newOrder({ collection_id: collection.id, data: data });
  };
  return (
    <Dialog
      fullWidth
      PaperProps={{
        sx: {
          position: "relative",
          width: "700px",
          p: { md: "50px", mobile: "30px 20px" },
          margin: { md: "32px", mobile: "10px" },
          backgroundColor: (theme) => theme.palette.colors.darkGray,
        },
      }}
      onClose={onClose}
      open={true}
    >
      <CloseIcon
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 30,
          top: 30,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant={"h2"} sx={{ maxWidth: {mobile:"300px", md:"700px"} }}>
          Requested Information
        </Typography>
        <Box sx={{ mt: { md: "35px", mobile: "20px" } }}>
          <Typography
            variant={"subtitle1"}
            sx={{ fontSize: "16px !important" }}
          >
            Please provide the following information so the brand can create
            personalized NFT experiences for you.
          </Typography>
        </Box>
        {requestInfoFields.length > 0 && (
          <Box sx={{ mt: { md: "40px", mobile: "20px" } }}>
            {requestInfoFields?.map((data) => {
              return (
                <Box className={classes.inputOuter} key={data?.id}>
                  <Box sx={{ display: "flex", mb: "10px" }}>
                    <Typography variant={"h4"}>{data?.title}</Typography>
                    <UI.Tooltip>{data?.tooltip}</UI.Tooltip>
                  </Box>
                  <Controller
                    name={data?.title}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <UI.TextField
                        {...field}
                        backgroundcolor={"#303346"}
                        placeholder={data?.placeholder}
                      />
                    )}
                  />
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors[data?.title]?.type === "required" && (
                      <Alert
                        sx={{ color: (theme) => theme.palette.colors.darkRed }}
                        variant="outlined"
                        severity="error"
                      >
                        {`${data?.title} is required`}
                      </Alert>
                    )}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        )}
        {requestInfoFields.length === 0 && (
          <Box sx={{ mt: { md: "30px", mobile: "20px" } }}>
            <Typography
              variant={"subtitle1"}
              sx={{ fontSize: "16px !important" }}
            >
              * No additional information is required at this time. Click the button below to continue. 
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: { md: "50px", mobile: "35px" },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <UI.Button
              type={"primary"}
              title={"Continue"}
              sx={{
                width: "250px",
                height: "50px",
                filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
              }}
            />
          </Box>
        </Box>
      </form>
    </Dialog>
  );
};

export default RequestInfoDialog;
