import React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UI from "../../../../../../@components/UI";
import CloseIcon from "@mui/icons-material/Close";
import DownloadPDFButton from "./DownloadPDFButton";

const NFTVaultPasswordDialog = ({ DAO, collection, password, onClose }) => {
  return (
    <Dialog
      fullWidth
      PaperProps={{
        sx: {
          position: "relative",
          width: "800px",
          p: { md: "50px", mobile: "45px 20px" },
          margin: { md: "32px", mobile: "10px" },
          backgroundColor: (theme) => theme.palette.colors.darkGray,
        },
      }}
      onClose={onClose}
      open={true}
    >
      <CloseIcon
        onClick={() => {
          onClose();
        }}
        sx={{
          position: "absolute",
          right: 20,
          top: 20,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <Typography variant={"h2"}>
        YOU'VE SECURED YOUR NFT - DON'T MISS THIS ESSENTIAL STEP!
      </Typography>
      <Box sx={{ mt: { md: "35px", mobile: "20px" } }}>
        <Typography variant={"subtitle1"} sx={{ fontSize: "16px !important" }}>
          Congratulations on acquiring your NFT! It's now safely stored in our vault. If you wish to transfer the NFT to your blockchain wallet in the future, you'll need the One-Time Password (OTP) provided below.
        </Typography>
        <Typography variant={"subtitle1"} sx={{ mt: "20px", fontSize: "16px !important" }}>
          <UI.CopyToClipboardPassword
            label={"One-Time Password"}
            value={password || ""}
            showBorder
          />
        </Typography>
        <Typography variant={"subtitle1"} sx={{ mt: "20px", fontSize: "16px !important" }}>
          Please ensure you store this password in a safe place. We've also sent the OTP to your registered email address for your convenience.
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: "50px" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <DownloadPDFButton
            password={password}
            DAO={DAO}
            collection={collection}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default NFTVaultPasswordDialog;
