import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import moment from "moment";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const AccordionItem = (props) => {
  const type = props?.type;
  let displayValue = props?.value;

  if (type === "date") {
    displayValue = moment(props?.value).format("DD MMM YYYY");
  } else if (type === "time") {
    displayValue = moment(props?.value).format("HH:mm");
  }
  return (
    <Box sx={{ display: "flex", mb: "10px" }}>
      <Box
        sx={{
          display: "flex",
          mr: { md: "10px", mobile: "5px" },
        }}
      >
        <ChevronRightIcon
          sx={{
            color: props.customStyles
              ? props.customStyles.card_text_color
              : "rgba(27, 30, 47, 1)",
          }}>
        </ChevronRightIcon>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <Typography 
            variant={"subtitle1"} 
            sx={{
              color: props.customStyles
                ? props.customStyles.card_text_color
                : "rgba(27, 30, 47, 1)",
              opacity:"0.8",
            }}
          >
            {`${props?.title}${ displayValue !== "" ? ":" : ""} `}
          </Typography>
        </Box>
        {
          <Box>
            <Typography
              variant={"h4"}
              sx={{
                fontSize: "16px",
                overflowWrap: "anywhere",
                textTransform: "unset",
                lineHeight: "20px",
                fontWeight: "bold",
                color: props.customStyles
                  ? props.customStyles.card_text_color
                  : "rgba(27, 30, 47, 1)",
              }}
            >
              {displayValue}
            </Typography>
          </Box>
        }
      </Box>
    </Box>
  );
};

export default AccordionItem;
