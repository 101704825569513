import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useFormData } from "../context";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../../@components/UI";
import Alert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useAccount } from "wagmi";
import { useUserContext } from "../../../../../@components/userContext";
import backArrow from "../../../../../static/back-arrow.png";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import photoIcon from "../../../../../static/photo_icon.png";
import videoIcon from "../../../../../static/video_icon.png";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import MultiUpload from "./MultiUpload";

const useStyles = makeStyles((theme) => ({
  inputOuter: {
    marginBottom: "15px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  backButton: {
    textAlign: "left",
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },
}));

const Index = ({ DAO, draft, onSave, nextStep }) => {
  const history = useHistory();
  const classes = useStyles();
  const { user: loggedInUser } = useUserContext();
  const { isConnected } = useAccount();
  const { data, setFormValues } = useFormData();
  const [totalMintLimit, setTotalMintLimit] = React.useState(0);

  const {
    handleSubmit,
    control,
    register,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      basic_info: {
        picture_url: data?.basic_info?.picture_url,
        nft_animation_url: data?.basic_info?.nft_animation_url,
        random_images: data?.basic_info?.random_images || false,
        collection_images: data?.basic_info?.collection_images,
        type_of_media: data?.basic_info?.type_of_media || "photo",
        name: data?.basic_info?.name,
        symbol: data?.basic_info?.symbol,
        supply: data?.basic_info?.supply,
        description: data?.basic_info?.description,
        scheduled_date_time: null,
        visible: data?.basic_info?.visible,
        is_scheduled_later: false,
      },
    },
    mode: "onSubmit",
  });

  const formValues = watch();

  React.useEffect(() => {
    console.log(formValues);
  }, [formValues]);

  const selectedScheduleDisplay = watch("basic_info.is_scheduled_later");
  const selectedRandomMint = watch("basic_info.random_images");
  const selectedVisible = watch("basic_info.visible");
  const selectedMedia = watch("basic_info.type_of_media");

  const handleMedia = (event, newSelectedMedia) => {
    reset(); //Reset form when media type is changed.
    setValue("basic_info.type_of_media", newSelectedMedia);
    if (newSelectedMedia === "multi-upload") {
      setValue("basic_info.has_multiple_images", true);
    } else {
      setValue("basic_info.has_multiple_images", false);
    }
  };

  React.useEffect(() => {
    if (selectedMedia === "multi-upload" && totalMintLimit > 0) {
      setValue("basic_info.supply", totalMintLimit);
    }
  }, [totalMintLimit, selectedMedia, setValue]);

  // Reset form with draft data.
  React.useEffect(() => {
    if (draft) {
      setFormValues(draft.data);
      reset({
        basic_info: draft.data.basic_info,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft]);

  const onSubmit = async (values, action) => {
    switch (action) {
      case "save_and_continue": {
        await onSave(values, false);
        nextStep();

        break;
      }
      case "save": {
        await onSave(values, true);
        break;
      }
      default: {
        await onSave(values, true);
        break;
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit((values) => onSubmit(values, "save_and_continue"))}
    >
      <Box
        sx={{
          mt: { md: "60px", mobile: "20px" },
          p: { md: "0px 65px", mobile: "20px 10px" },
        }}
      >
        <Typography variant={"subtitle1"} sx={{ opacity: 1 }}>
          Create your NFT collection in 5 simple steps. First, create the NFT
          card, assign it the main feature and add extra perks for your buyers
          in the next step. Please, keep in mind you can’t edit the provided
          information after the NFT card is created.
        </Typography>
      </Box>
      <Box
        sx={{
          mt: { md: "15px", mobile: "20px" },
          p: { md: "40px 65px", mobile: "20px 10px" },
        }}
      >
        <Box
          onClick={() => history.push(`/brand/${DAO?.id}/collection`)}
          className={classes.backButtonOuter}
          sx={{
            display: "flex",
            cursor: "pointer",
            mb: { md: "50px", mobile: "30px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mr: "8px",
            }}
          >
            <img
              className={"backArrow"}
              style={{ transition: "0.4s ease-in" }}
              src={backArrow}
              alt={"back-arrow"}
              width={"15px"}
              height={"15px"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box className={classes.backButton}>back to collections</Box>
          </Box>
        </Box>
        <Box
          sx={{
            mb: "50px",
            display: "flex",
            textAlign: "left",
          }}
        >
          <Typography
            sx={{
              fontSize: { md: "30px !important", mobile: "24px !important" },
            }}
            variant={"h1"}
          >
            step 1: basic collection information
          </Typography>
        </Box>
        <Paper
          elevation={0}
          sx={{
            width: "100%",
            backgroundColor: "rgba(27, 30, 47, 0.5)",
            px: { md: "50px", mobile: "10px" },
            py: { md: "50px", mobile: "50px" },
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px", mt: "0" }}>
                  <Typography
                    variant={"h2"}
                    sx={{ fontSize: "18px !important" }}
                  >
                    Add to your collection
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: "10px",
                    mb: "0px",
                    display: "flex",
                    width: { mobile: "100%", md: "unset" },
                  }}
                >
                  <ToggleButtonGroup
                    sx={{ flexGrow: { md: "unset", mobile: 2 } }}
                    value={selectedMedia}
                    exclusive
                    onChange={handleMedia}
                    aria-label="select media"
                  >
                    <ToggleButton
                      sx={{
                        padding: "0px",
                        height: "72px",
                        border: "0px",
                        flexGrow: { mobile: 1, md: "unset" },
                        justifyContent: "center",
                      }}
                      value="photo"
                      aria-label="bold"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { md: "row", mobile: "column" },
                          alignItems: "center",
                          width: { md: "160px", mobile: "auto" },
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          component={"img"}
                          sx={{
                            width: "30px",
                            height: "25px",
                            mr: { md: "8px", mobile: "0px" },
                            lineHeight: 1.5,
                          }}
                          alt={"media upload"}
                          src={photoIcon}
                        />
                        <Typography
                          variant={"h1"}
                          sx={{
                            fontSize: {
                              md: "16px !important",
                              mobile: "12px !important",
                            },
                            lineHeight: {
                              md: "unset",
                              mobile: "24px !important",
                            },
                            display: "block",
                          }}
                        >
                          Photo
                        </Typography>
                      </Box>
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        padding: "0px",
                        height: "72px",
                        border: "0px",
                        flexGrow: { mobile: 1, md: "unset" },
                        justifyContent: "center",
                      }}
                      value="video"
                      aria-label="italic"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { md: "row", mobile: "column" },
                          alignItems: "center",
                          width: { md: "160px", mobile: "auto" },
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          component={"img"}
                          sx={{
                            width: "30px",
                            height: "25px",
                            mr: { md: "8px", mobile: "0px" },
                          }}
                          alt={"media upload"}
                          src={videoIcon}
                        />
                        <Typography
                          variant={"h1"}
                          sx={{
                            fontSize: {
                              md: "16px !important",
                              mobile: "12px !important",
                            },
                            lineHeight: {
                              md: "unset",
                              mobile: "24px !important",
                            },
                            display: "block",
                          }}
                        >
                          Animation
                        </Typography>
                      </Box>
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        padding: "0px",
                        height: "72px",
                        border: "0px",
                        flexGrow: { mobile: 1, md: "unset" },
                        justifyContent: "center",
                      }}
                      value="multi-upload"
                      aria-label="italic"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { md: "row", mobile: "column" },
                          alignItems: "center",
                          width: { md: "190px", mobile: "auto" },
                          justifyContent: "center",
                        }}
                      >
                        <BurstModeIcon
                          sx={{
                            width: "40px",
                            height: "45px",
                            color: "#944dff",
                            mr: { md: "8px", mobile: "0px" },
                          }}
                        />
                        <Typography
                          variant={"h1"}
                          sx={{
                            fontSize: {
                              md: "16px !important",
                              mobile: "12px !important",
                            },
                            lineHeight: {
                              md: "unset",
                              mobile: "24px !important",
                            },
                            display: "block",
                          }}
                        >
                          Multi Upload
                        </Typography>
                      </Box>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                {selectedMedia && (
                  <Box sx={{ mt: { mb: "40px", mobile: "30px" } }}>
                    {selectedMedia === "photo" && (
                      <Grid item md={12} mobile={12}>
                        <Box>
                          <Controller
                            name={"basic_info.picture_url"}
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <UI.Upload
                                noHeight
                                defaultValue={data?.basic_info?.picture_url}
                                {...field}
                                id={"profile"}
                                title={"Upload NFT picture"}
                                subtitle={
                                  "* Recommended size is 300x300 pixels (PNG, JPG, JPEG, GIF up to 10 MB)"
                                }
                                acceptFiles={
                                  "image/jpg, image/jpeg,image/gif,image/png"
                                }
                              />
                            )}
                          />
                          {errors.basic_info?.picture_url?.type ===
                            "required" && (
                            <Typography
                              variant={"subtitle1"}
                              sx={{ mt: "15px" }}
                            >
                              <Alert
                                sx={{
                                  color: (theme) =>
                                    theme.palette.colors.darkRed,
                                }}
                                variant="outlined"
                                severity="error"
                              >
                                Picture is required
                              </Alert>
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    )}
                    {selectedMedia === "video" && (
                      <>
                        <Grid item md={12} mobile={12}>
                          <Box>
                            <Controller
                              name={"basic_info.picture_url"}
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <UI.Upload
                                  noHeight
                                  defaultValue={data?.basic_info?.picture_url}
                                  {...field}
                                  id={"profile"}
                                  title={"Upload NFT animation"}
                                  subtitle={
                                    "* Recommended size is 300x300 pixels (MP4 up to 10 MB)"
                                  }
                                  acceptFiles={"video/mp4"}
                                />
                              )}
                            />
                            {errors.basic_info?.picture_url?.type ===
                              "required" && (
                              <Typography
                                variant={"subtitle1"}
                                sx={{ mt: "15px" }}
                              >
                                <Alert
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.colors.darkRed,
                                  }}
                                  variant="outlined"
                                  severity="error"
                                >
                                  Picture is required
                                </Alert>
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                        <Box
                          className={classes.inputOuter}
                          sx={{ mb: "0px !important" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              mb: "10px",
                              mt: "23px !important",
                            }}
                          >
                            <Typography variant={"h4"}>Animation</Typography>
                            <Typography
                              variant={"h4"}
                              sx={{
                                fontFamily: "Open Sans",
                                fontSize: "14px",
                                fontWeight: "regular",
                                ml: "6px",
                                textTransform: "lowercase",
                              }}
                            >
                              (optional)
                            </Typography>
                            <UI.Tooltip>
                              {`A URL to a multi-media attachment for the item. This supports HTML pages, allowing you to build rich experiences and interactive NFTs using JavaScript canvas, WebGL and more.`}
                            </UI.Tooltip>
                          </Box>
                          <Controller
                            name={"basic_info.nft_animation_url"}
                            control={control}
                            rules={{
                              required: false,
                              pattern: {
                                value:
                                  /^(http(s?):\/\/.)[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/g,
                                message: "Invalid URL format",
                              },
                            }}
                            render={({ field }) => (
                              <UI.TextField
                                {...field}
                                placeholder={"e.g. Https://..."}
                              />
                            )}
                          />
                          {errors.basic_info?.nft_animation_url?.type ===
                            "pattern" && (
                            <Typography
                              variant={"subtitle1"}
                              sx={{ mt: "15px" }}
                            >
                              <Alert
                                sx={{
                                  color: (theme) =>
                                    theme.palette.colors.darkRed,
                                }}
                                variant="outlined"
                                severity="error"
                              >
                                {errors.basic_info?.nft_animation_url?.message}
                              </Alert>
                            </Typography>
                          )}
                        </Box>
                      </>
                    )}
                    {selectedMedia === "multi-upload" && (
                      <MultiUpload
                        getValues={getValues}
                        setTotalMintLimit={setTotalMintLimit}
                        data={data}
                        register={register}
                        control={control}
                        selectedRandomMint={selectedRandomMint}
                        errors={errors}
                        setValue={setValue}
                        watch={watch}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h4"}>Collection Name</Typography>
                  <UI.Tooltip>
                    Name the NFT collection. The name will appear on the NFT
                    itself.
                  </UI.Tooltip>
                </Box>
                <Controller
                  name={"basic_info.name"}
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <UI.TextField
                      {...field}
                      placeholder={"e.g. THE QVRSE CONCERT"}
                    />
                  )}
                />
                {errors?.basic_info?.name?.type === "required" && (
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    <Alert
                      sx={{ color: (theme) => theme.palette.colors.darkRed }}
                      variant="outlined"
                      severity="error"
                    >
                      Name is required
                    </Alert>
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h4"}>Symbol</Typography>
                  <UI.Tooltip>
                    Provide the symbol for the NFT collection, usually a
                    shortened version of collection's name. The symbol plays a
                    significant role in identification if the NFT is traded on
                    secondary markets.
                  </UI.Tooltip>
                </Box>

                <Controller
                  name={"basic_info.symbol"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.TextField {...field} placeholder={"e.g. QC"} />
                  )}
                />
                {errors.basic_info?.symbol?.type === "required" && (
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    <Alert
                      sx={{ color: (theme) => theme.palette.colors.darkRed }}
                      variant="outlined"
                      severity="error"
                    >
                      Symbol is required
                    </Alert>
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h4"}>Description</Typography>
                  <UI.Tooltip>
                    Describe your NFT collection in a few short sentences.
                  </UI.Tooltip>
                </Box>

                <Controller
                  name={"basic_info.description"}
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <UI.TextField
                      multiline
                      rows={3}
                      {...field}
                      placeholder={
                        "e.g. Qvrse invites its community members to an enchanting evening filled with music and a good vibe featuring a spectacular performance by one of Slovenia's premier bands."
                      }
                    />
                  )}
                />
                {errors.basic_info?.description?.type === "required" && (
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    <Alert
                      sx={{ color: (theme) => theme.palette.colors.darkRed }}
                      variant="outlined"
                      severity="error"
                    >
                      Description is required
                    </Alert>
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h4"}>NUMBER OF CREATED NFTs</Typography>
                  <UI.Tooltip>
                    Enter the number of NFTs you want to create in this
                    collection.
                  </UI.Tooltip>
                </Box>

                <Controller
                  name={"basic_info.supply"}
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^1*[1-9][0-9]*$/,
                      message: "Number of created NFTs cannot be 0",
                    },
                  }}
                  render={({ field }) => (
                    <UI.NumberField
                      {...field}
                      disabled={selectedMedia === "multi-upload"}
                      placeholder={"25"}
                    />
                  )}
                />
                {errors.basic_info?.supply?.type === "required" && (
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    <Alert
                      sx={{ color: (theme) => theme.palette.colors.darkRed }}
                      variant="outlined"
                      severity="error"
                    >
                      Number of created NFTs is required
                    </Alert>

                    {errors.basic_info?.supply?.type === "pattern" && (
                      <Alert
                        sx={{ color: (theme) => theme.palette.colors.darkRed }}
                        variant="outlined"
                        severity="error"
                      >
                        {errors.supply.message}
                      </Alert>
                    )}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "5px", mt: "15px" }}>
                  <Typography variant={"h4"}>
                    Enable the purchase: now or later?
                  </Typography>
                  <UI.Tooltip>
                    Choose "Enable Now" to instantly make your NFT collection
                    accessible for purchase, or select "Enable Later" if you
                    wish to postpone the availability for buying until later.
                  </UI.Tooltip>
                </Box>

                <UI.Checkbox
                  onClick={() => {
                    setValue("basic_info.is_scheduled_later", false, {
                      shouldDirty: true,
                    });
                    setValue("basic_info.scheduled_date_time", null, {
                      shouldDirty: true,
                    });
                  }}
                  defaultChecked={true}
                  value={selectedScheduleDisplay === false ? true : false}
                  nomargin={"true"}
                  label={
                    <Box
                      component={"span"}
                      sx={{
                        fontWeight: "regular",
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                      }}
                    >
                      Enable now
                    </Box>
                  }
                />
                <UI.Checkbox
                  onClick={() => {
                    setValue("basic_info.is_scheduled_later", true, {
                      shouldDirty: true,
                    });
                  }}
                  value={selectedScheduleDisplay === true}
                  nomargin={"true"}
                  label={
                    <Box
                      component={"span"}
                      sx={{
                        fontWeight: "regular",
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                      }}
                    >
                      Enable later
                    </Box>
                  }
                />
                {selectedScheduleDisplay && (
                  <Box sx={{ mt: "40px" }}>
                    <Controller
                      name={"basic_info.scheduled_date_time"}
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => <UI.DateTime {...field} />}
                    />
                    {errors.basic_info?.supply?.type === "required" && (
                      <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                        <Alert
                          sx={{
                            color: (theme) => theme.palette.colors.darkRed,
                          }}
                          variant="outlined"
                          severity="error"
                        >
                          Number of created NFTs is required
                        </Alert>

                        {errors.basic_info?.supply?.type === "pattern" && (
                          <Alert
                            sx={{
                              color: (theme) => theme.palette.colors.darkRed,
                            }}
                            variant="outlined"
                            severity="error"
                          >
                            {errors.supply.message}
                          </Alert>
                        )}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid item md={12} mobile={12}>
            <Box className={classes.inputOuter}>
              <Box sx={{ display: "flex", mb: "10px" }}>
                <Typography variant={"h4"}>
                  Visibility of the collection
                </Typography>
                <UI.Tooltip>
                  Choose the visibility of your NFT collection. Select 'Public'
                  to make your collection accessible to everyone for purchase
                  and viewing. Select 'Private' to restrict access to your
                  collection, allowing only users with the URL to access or view
                  the NFTs.
                </UI.Tooltip>
              </Box>
              <UI.Checkbox
                onClick={() => {
                  setValue("basic_info.visible", true, {
                    shouldDirty: true,
                  });
                }}
                defaultChecked={true}
                value={selectedVisible === true}
                nomargin={"true"}
                label={
                  <Box
                    component={"span"}
                    sx={{
                      fontWeight: "regular",
                      fontFamily: "Open Sans",
                      fontSize: "16px",
                    }}
                  >
                    Public
                  </Box>
                }
              />
              <UI.Checkbox
                onClick={() => {
                  setValue("basic_info.visible", false, {
                    shouldDirty: true,
                  });
                }}
                value={selectedVisible === false}
                nomargin={"true"}
                label={
                  <Box
                    component={"span"}
                    sx={{
                      fontWeight: "regular",
                      fontFamily: "Open Sans",
                      fontSize: "16px",
                    }}
                  >
                    Private
                  </Box>
                }
              />
            </Box>
          </Grid>
        </Paper>
      </Box>
      <Box
        sx={{
          width: "100%",
          mt: "40px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "210px" }}>
            <UI.Button
              disabled={
                !!Object.keys(errors).length ||
                !isConnected ||
                !loggedInUser?.email_verified
              }
              sx={{
                filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
              }}
              title={`Save & Continue`}
              type={"primary"}
            />
            <UI.Button
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                const allValues = getValues();
                await onSubmit(allValues, "save");
              }}
              disabled={!isConnected || !loggedInUser?.email_verified}
              sx={{
                mt: "10px",
                filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
              }}
              title={"Save for later"}
              type={"terciary"}
            />
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default Index;
