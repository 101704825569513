export const getProposalState = async (proposalState) => {
  switch (proposalState) {
    case 0:
      return "Pending";
    case 1:
      return "Active";
    case 2:
      return "Canceled";
    case 3:
      return "Defeated";
    case 4:
      return "Succeeded";
    case 5:
      return "Queued";
    case 6:
      return "Expired";
    case 7:
      return "Executed";
    default:
      return "State id not recognized";
  }
};

export const handleVote = (option, voteContext) => {
  return new Promise((resolve, reject) => {
    if (option === "yes") {
      voteContext
        .voteFunc(voteContext.proposal, 1, voteContext.votingPower)
        .then((response) => {
          resolve(response);
        })
        .catch((reason) => {
          reject(reason);
        });
    } else if (option === "no") {
      voteContext
        .voteFunc(voteContext.proposal, 0, voteContext.votingPower)
        .then((response) => {
          resolve(response);
        })
        .catch((reason) => {
          reject(reason);
        });
    } else if (option === "abstain") {
      voteContext
        .voteFunc(voteContext.proposal, 2, voteContext.votingPower)
        .then((response) => {
          resolve(response);
        })
        .catch((reason) => {
          reject(reason);
        });
    }
  });
};

export const prepareDaoData = (id, proposal, contractData) => {
  let dao;

  if (proposal.data.token_type === "ERC20") {
    dao = {
      proposal_id: id,
      name: proposal.data.name,
      dao_address: contractData.deployedAddress,
      staking_address: contractData.stakingAddress,
      governor_address: contractData.governorAddress,
      treasury_address: contractData.treasuryAddress,
      timelock_address: contractData.timelockAddress,
      token_address: contractData.tokenAddress,
      payment: proposal.data.payment,
      avatar_url: proposal.data.avatar_url,
      maintainer_address: contractData.maintainer,
      short_description: contractData.description,
      enable_staking_rewards: proposal.data.enable_staking_rewards || false,
      whitepaper_url: proposal.data.whitepaper_url,
      quorum: proposal.data.quorum,
      voting_delay: proposal.data.voting_delay,
      voting_period: proposal.data.voting_period,
      timelock_delay: proposal.data.timelock_delay,
      token_type: proposal.data.token_type,
      token_icon_url: proposal.data.token_icon_url,
      token_symbol: proposal.data.token_symbol,
      token_name: proposal.data.token_name,
    };
  } else if (proposal.data.token_type === "QVRS") {
    dao = {
      proposal_id: id,
      name: proposal.data.name,
      dao_address: contractData.deployedAddress,
      staking_address: contractData.stakingAddress,
      governor_address: contractData.governorAddress,
      treasury_address: contractData.treasuryAddress,
      crowdsale_address: "0x123",
      token_address: contractData.tokenAddress,
      payment: proposal.data.payment,
      avatar_url: proposal.data.avatar_url,
      maintainer_address: contractData.maintainer,
      development_days: proposal.data.development_time,
      founders_tokens: proposal.data.founders_tokens,
      short_description: contractData.description,
      enable_staking_rewards: proposal.data.enable_staking_rewards || false,
      whitepaper_url: proposal.data.whitepaper_url,
      support: proposal.data.support,
      quorum: proposal.data.quorum,
      voting_delay: proposal.data.voting_delay,
      voting_period: proposal.data.voting_period,
      timelock_delay: proposal.data.timelock_delay,
      token_type: proposal.data.token_type,
    };
  }

  return dao;
};

export const prepareTransactionData = (id, proposal, contractData) => {
  return {
    transaction_trx: "",
    proposal_id: proposal.id,
    from_address: contractData.from,
    to_address: contractData.to,
    token_symbol: proposal.data.token.token_symbol,
    amount: proposal.data.amount,
    receipt: "",
  };
};
