import useFetch from "./useFetch";
import usePost from "./usePost";
import useQuery from "./useQuery";
import useProtectedRoute from "./useProtectedRoute";
import { useEthersSigner } from "./ethers/useEthersSigner";
import { useEthersProvider } from "./ethers/useEthersProvider";
import useBlockchainState from "./useBlockchainState";
const CustomHooks = {
  useFetch,
  usePost,
  useQuery,
  useProtectedRoute,
  useEthersSigner,
  useEthersProvider,
  useBlockchainState,
};

export default CustomHooks;
