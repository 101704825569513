import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import UI from "../../../../../@components/UI";
import { useUserContext } from "../../../../../@components/userContext";
import { useAccount } from "wagmi";
import { useFormData } from "../context";
import { makeStyles } from "@mui/styles";
import backArrow from "../../../../../static/back-arrow.png";
import Ticket from "./Ticket";
import { ethers } from "ethers";
import collectionNativeDeployerArtifact from "../../../../../abis/contracts/modules/collectionNative/Deployer.sol/CollectionNativeDeployer.json";
import collectionNativeMultiUploadDeployerArtifact from "../../../../../abis/contracts/modules/collectionNativeMultipleImages/Deployer.sol/CollectionNativeMultipleImagesDeployer.json";
import useService from "../../../collection/useService";
import useQvrseService from "../../../../../qvrse/useService";
import useHandleErrors from "../../../../../@components/UI/useHandleErrors";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Utilities from "../../../collection/@id/Utilities";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import customHooks from "../../../../../@components/hooks";
import MaterialAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";

const useStyles = makeStyles((theme) => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  backButton: {
    textAlign: "left",
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },
}));

const Create = ({ previouseStep, DAO, onRemoveDraft }) => {
  const classes = useStyles();
  const history = useHistory();
  const signer = customHooks.useEthersSigner();
  const { enqueueSnackbar } = useSnackbar();
  const { user: loggedInUser } = useUserContext();
  const collectionService = useService(DAO?.id);
  const { isConnected, address } = useAccount();
  const { data } = useFormData();
  const qvrseService = useQvrseService();
  const [fee_address, setFeeAddress] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const { prepareCollectionNFTUri, prepareNFTUri } = UI.useIPFS();
  const { handleApiError, handleMetamaskError } = useHandleErrors();

  React.useEffect(() => {
    qvrseService
      .fee_address()
      .then((response) => {
        const {
          data: { fee_address },
        } = response;
        setFeeAddress(fee_address);
      })
      .catch((error) => {
        handleApiError(error);
      });
    // eslint-disable-next-line
  }, []);

  const deployNativeCollection = async (data) => {
    const collectionDeployer = new ethers.Contract(
      DAO?.event_deployer_contract?.address,
      collectionNativeDeployerArtifact.abi,
      signer
    );

    const deployCollectionTx = await collectionDeployer.deploy(
      DAO.maintainer_address || address,
      DAO.system_minter_address,
      data.basic_info.name,
      data.basic_info.symbol,
      data.nft_ipfs_uri,
      ethers.parseUnits(
        String(data.totalPrice),
        data?.payment?.crypto?.token?.decimals || 18
      ),
      data.basic_info.supply,
      DAO.treasury_contract.address,
      fee_address,
      DAO.collection_buy_fee
    );

    const collectionReceipt = await deployCollectionTx.wait(1);

    const collectionCreatedEvent = collectionReceipt.logs.find(
      (event) => event.eventName === "CollectionNativeCreatedSucessfully"
    );

    return collectionCreatedEvent;
  };

  const deployNativeMultiUploadCollection = async (data) => {
    const collectionDeployer = new ethers.Contract(
      DAO?.event_multiple_images_deployer_contract?.address,
      collectionNativeMultiUploadDeployerArtifact.abi,
      signer
    );

    const deployCollectionTx = await collectionDeployer.deploy(
      DAO.maintainer_address || address,
      DAO.system_minter_address,
      data.basic_info.name,
      data.basic_info.symbol,
      data.nft_ipfs_uri,
      ethers.parseUnits(
        String(data.totalPrice),
        data?.payment?.crypto?.token?.decimals || 18
      ),
      data.basic_info.supply,
      DAO.treasury_contract.address,
      fee_address,
      DAO.collection_buy_fee
    );

    const collectionReceipt = await deployCollectionTx.wait(1);

    const collectionCreatedEvent = collectionReceipt.logs.find(
      (event) => event.eventName === "CollectionNativeCreatedSucessfully"
    );

    return collectionCreatedEvent;
  };

  const saveCollectionToDatabase = async (collectionData) => {
    const data = {
      name: collectionData.basic_info.name,
      description: collectionData?.basic_info?.description,
      scheduled_date_time: collectionData?.basic_info?.scheduled_date_time,
      nft_address: collectionData.address,
      picture_url: collectionData.nft_url,
      ticket_supply: collectionData.basic_info.supply,
      ticket_name: collectionData.basic_info.name,
      ticket_symbol: collectionData.basic_info.symbol,
      nft_file_type: collectionData.basic_info.picture_url.file?.type,
      nft_animation_url: collectionData.basic_info.nft_animation_url,
      custom_actions: collectionData?.custom_actions,
      payment_token:
        collectionData?.payment?.crypto?.token != null
          ? collectionData?.payment?.crypto?.token
          : null,
      currency: collectionData?.payment?.crypto?.token
        ? collectionData?.payment?.crypto?.currency
        : collectionData?.payment?.fiat.currency,
      stripe: collectionData?.payment.fiat.stripe,
      paypal: collectionData?.payment.fiat.paypal,
      isFreeMintEnabled: collectionData?.isFreeMintEnabled || false,
      visible: collectionData?.basic_info?.visible,
      total_price_no_vat: collectionData?.total_price,
      total_price_with_tax: collectionData?.total_price,
      total_vat_amount: collectionData?.total_price,
      utilities: [
        collectionData?.main_utility,
        ...collectionData?.extra_utilities,
      ],
      request_info_fields: [],
      start_block: collectionData?.blockNumber,
      //Multi upload
      ...(collectionData.basic_info.collection_images
        ? { collection_images: collectionData.basic_info.collection_images }
        : {}),
      ...(collectionData.basic_info.has_multiple_images
        ? { has_multiple_images: collectionData.basic_info.has_multiple_images }
        : {}),
      ...(collectionData.basic_info.random_images
        ? { random_images: collectionData.basic_info.random_images }
        : {}),
    };

    await collectionService
      .save(data)
      .then(() => {
        enqueueSnackbar("Collection was created successfully", {
          variant: "success",
        });
        setIsLoading(false);
        onRemoveDraft();
        history.push({
          pathname: `/brand/${DAO?.id}/collection`,
          state: { scrollToTop: true },
        });
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkAndPrepareDiscordAccessPerks = async (form) => {
    form.extra_utilities.forEach((item, index) => {
      if (item.type === "discord_access") {
        const channelNameItem = item.custom_info_fields.find(
          (field) => field.name === "Channel Name"
        );
        const inviteLinkItem = item.custom_info_fields.find(
          (field) => field.name === "Invite Link"
        );

        form.extra_utilities[index].discord_fields = {
          channel_name: channelNameItem ? channelNameItem.value : "",
          invite_link: inviteLinkItem ? inviteLinkItem.value : "",
        };
      }
    });
    return form;
  };

  const getCustomActions = (form) => {
    let customActions = [];
    // Custom actions in main utility
    form.main_utility.custom_info_fields =
      form.main_utility.custom_info_fields.filter((field) => {
        // if (field.type === "button") {
        //   customActions.push(field);
        //   return false;
        // }
        return true;
      });

    // Check if extra_utilities array exists and has length > 0
    if (form.extra_utilities && form.extra_utilities.length > 0) {
      // Loop over each item in the array
      form.extra_utilities.forEach((extra_utility, index) => {
        // In each item, filter the custom_info_fields for objects where type !== 'button'
        extra_utility.custom_info_fields =
          extra_utility.custom_info_fields.filter((field) => {
            // if (field.type === "button") {
            //   customActions.push(field);
            //   return false;
            // }
            return true;
          });
      });

      return customActions;
    }
  };

  const onSubmit = async () => {
    let form = { ...data };
    form = await checkAndPrepareDiscordAccessPerks(form);
    const nftUri = await prepareCollectionNFTUri(form, DAO);
    form.custom_actions = getCustomActions(form);

    const allData = {
      ...form,
      nft_ipfs_uri: nftUri,
      nft_url: form.basic_info.picture_url.ipfs,
      avatar_url: form.basic_info.picture_url.ipfs,
      total_price: form?.totalPrice,
      total_price_with_tax: form?.totalPrice,
      total_tax_amount: form?.totalPrice,
    };

    setIsLoading(true);
    try {
      //Deploy collection on chain.
      let deployEvent;

      if (form.basic_info.type_of_media === "multi-upload") {
        //Create IPFS URI for each image
        const collectionImages = await Promise.all(
          allData.basic_info.collection_images.map(async (imageData) => {
            const imageUri = await prepareNFTUri(allData, imageData, DAO);
            return {
              ...imageData,
              ipfs_data_url: imageUri,
            };
          })
        );

        allData.basic_info.collection_images = collectionImages;

        deployEvent = await deployNativeMultiUploadCollection(allData);
      } else {
        deployEvent = await deployNativeCollection(allData);
      }

      //Save collection to BE
      await saveCollectionToDatabase({
        ...allData,
        address: deployEvent.args[0],
        blockNumber: deployEvent.blockNumber,
      });
    } catch (error) {
      handleMetamaskError(error);
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        mt: { md: "15px", mobile: "20px" },
        p: { md: "40px 65px", mobile: "20px 10px" },
        overflowWrap: "break-word",
      }}
    >
      <UI.Busy.FullscreenIndicator show={isLoading} />

      <Box
        onClick={() => previouseStep()}
        className={classes.backButtonOuter}
        sx={{
          display: "flex",
          cursor: "pointer",
          mb: { md: "50px", mobile: "30px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mr: "8px",
          }}
        >
          <img
            className={"backArrow"}
            style={{ transition: "0.4s ease-in" }}
            src={backArrow}
            alt={"back-arrow"}
            width={"15px"}
            height={"15px"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box className={classes.backButton}>Back to payment</Box>
        </Box>
      </Box>
      <Box
        sx={{
          mb: "50px",
          display: "flex",
          flexDirection: { md: "row", mobile: "column" },
          textAlign: "left",
        }}
      >
        <Box>
          <Typography
            variant={"h1"}
            sx={{
              fontSize: { md: "30px !important", mobile: "24px !important" },
            }}
          >
            step 5: Preview & Publish
          </Typography>
        </Box>
      </Box>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={4} mobile={12}>
          <Box
            sx={{
              display: "flex",
              mb: "60px",
              justifyContent: { mobile: "center", md: "unset" },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Ticket
                brandName={DAO?.name}
                brandAvatar={DAO?.avatar_url}
                name={data?.basic_info?.name}
                fileType={data?.basic_info?.picture_url?.file?.type}
                data={data?.basic_info?.picture_url?.ipfs}
                mainUtility={data?.main_utility}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={8} mobile={12}>
          <Box sx={{ ml: { md: "60px", mobile: "0px" } }}>
            <Box sx={{ mb: "14px" }}>
              {DAO?.name ? (
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    fontSize: "16px !important",
                    color: "#7A7E92",
                    lineHeight: "13px !important",
                  }}
                >
                  {DAO?.name}
                </Typography>
              ) : (
                <Skeleton
                  sx={{ mb: "10px" }}
                  height={"15px"}
                  width={"300px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            <Box sx={{ mb: "30px" }}>
              {data?.basic_info?.name ? (
                <Typography
                  variant={"h2"}
                  sx={{ lineHeight: "18px !important" }}
                >
                  {data?.basic_info?.name}
                </Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"300px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {data ? (
              <Box sx={{ mb: "30px", display: "flex" }}>
                <UI.UtilityTag utilityType={data?.main_utility?.type} />
                <Box
                  sx={{
                    ml: "10px",
                    borderRadius: "100px",
                    backgroundColor: "#50AF95",
                    width: "fit-content",
                    display: "flex",
                    alignItems: "center",
                    p: "4px 14px",
                    height: "23px",
                  }}
                >
                  <Typography
                    variant={"h2"}
                    sx={{
                      fontSize: "10px !important",
                      lineHeight: "unset",
                    }}
                  >
                    LIMITED
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Skeleton
                sx={{ mb: "30px", display: "flex" }}
                height={"30px"}
                width={"300px"}
                variant={"rectangular"}
              />
            )}
            <Box sx={{ mb: "30px" }}>
              {data?.basic_info?.description ? (
                <UI.ReadMore fontSize={"18px"} maxTextLength={360}>
                  {data?.basic_info?.description}
                </UI.ReadMore>
              ) : (
                <Skeleton
                  height={"150px"}
                  width={"100%"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {data ? (
              <Box sx={{ mb: "15px", display: "flex", alignItems: "center" }}>
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    fontSize: "16px !important",
                    color: "#7A7E92",
                  }}
                >
                  Price
                </Typography>
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    ml: "4.5px",
                    fontSize: "10px !important",
                    color: "#7A7E92",
                    mt: "5px",
                  }}
                >
                  with tax
                </Typography>

                {!data?.isFreeMintEnabled && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ width: "20px", height: "20px", ml: "10px" }}>
                      <UI.PaymentIcon
                        currency={
                          data?.payment?.crypto?.token
                            ? data?.payment?.crypto?.currency
                            : data?.payment?.fiat?.currency
                        }
                      />
                    </Box>

                    <Typography
                      variant={"h1"}
                      sx={{
                        fontSize: "16px !important",
                        ml: "10px",
                        lineHeight: "unset !important",
                      }}
                    >
                      {`${data?.totalPrice} ${
                        data?.payment?.crypto?.token
                          ? data?.payment?.crypto?.currency
                          : data?.payment?.fiat?.currency
                      }`}
                    </Typography>
                  </Box>
                )}
                {data?.isFreeMintEnabled && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant={"h1"}
                      sx={{
                        fontSize: "16px !important",
                        ml: "10px",
                        lineHeight: "unset !important",
                      }}
                    >
                      Free
                    </Typography>
                  </Box>
                )}
              </Box>
            ) : (
              <Skeleton
                sx={{ mb: "10px" }}
                height={"15px"}
                width={"300px"}
                variant={"rectangular"}
              />
            )}
            {data ? (
              <Box sx={{ mb: "30px", display: "flex", alignItems: "center" }}>
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    fontSize: "16px !important",
                    color: "#7A7E92",
                  }}
                >
                  Availability
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant={"h1"}
                    sx={{
                      fontSize: "16px !important",
                      ml: "10px",
                      lineHeight: "unset !important",
                    }}
                  >
                    {`0/${data?.basic_info?.supply}`}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Skeleton
                sx={{ mb: "10px" }}
                height={"15px"}
                width={"300px"}
                variant={"rectangular"}
              />
            )}
          </Box>
        </Grid>
        {data?.basic_info?.has_multiple_images && (
          <Grid
            item
            md={12}
            mobile={12}
            sx={{ mt: { mobile: "60px", md: "50px" } }}
          >
            <MaterialAccordion
              TransitionProps={{ unmountOnExit: true }}
              sx={{
                backgroundColor: "rgba(27, 30, 47, 0.5)",
              }}
            >
              <AccordionSummary
                sx={{
                  height: "80px",
                  padding: { md: "18px 30px", mobile: "15px" },
                }}
                expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
              >
                {" "}
                <Typography variant={"h2"}>
                  Sneak Peek: NFTs That Could Be Yours
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: { md: "15px  35px", mobile: "15px  15px" },
                }}
              >
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {data?.basic_info.collection_images.map((item, index) => (
                    <CardItem item={item} />
                  ))}
                </Box>
              </AccordionDetails>
            </MaterialAccordion>
          </Grid>
        )}
        <Grid
          item
          md={12}
          mobile={12}
          sx={{ mt: { mobile: "60px", md: "50px" } }}
        >
          <Box sx={{ mb: "30px" }}>
            <Typography variant={"h2"}>Perks</Typography>
          </Box>
          <Utilities
            collection={{
              utilities: [
                {
                  ...data?.main_utility,
                  custom_info_fields: data?.main_utility.custom_info_fields.map(
                    (field) => ({
                      ...field,
                      title: field.name,
                    })
                  ),
                },
                ...data?.extra_utilities.map((utility) => ({
                  ...utility,
                  custom_info_fields: utility.custom_info_fields.map(
                    (field) => ({
                      ...field,
                      title: field.name,
                    })
                  ),
                })),
              ],
              currency: data?.payment?.crypto?.token
                ? data?.payment?.crypto?.currency
                : data?.payment?.fiat?.currency,
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          mt: "40px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "250px" }}>
            <UI.Button
              onClick={() => onSubmit()}
              disabled={!isConnected || !loggedInUser?.email_verified}
              sx={{
                filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
              }}
              title={"Create Collection"}
              type={"primary"}
              startIcon={<AccountBalanceWalletIcon />}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const CardItem = ({ item }) => {
  return (
    <Box
      sx={{
        width: "150px",
        mr: { md: "30px", mobile: "20px" },
        mt: { md: "12px", mobile: "10px" },
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        transition: "all  0.3s ease-in-out",
        "&:hover": {
          transform: "scale(1.1)",
        },
        "&:hover .removeIcon": {
          display: "block",
          transition: "all 0.1s ease 0s",
        },
      }}
    >
      <Box
        sx={{
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "140px",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            height: "140px",
            overflow: "hidden",
            border: "2px solid transparent",
            transition: "all 0.2s ease-in-out",
            zIndex: 1,
            "&:hover": {
              boxShadow: "#944dff 0px 0px 15px 0px",
              border: "2px solid #944dff",
              transform: "scale(1.1)",
              transition: "all 0.2s ease-in-out",
            },
          }}
        >
          {item ? (
            <UI.ImageWithSkeleton
              sx={{
                position: "absolute",
                inset: 0,
                boxSizing: "border-box",
                padding: 0,
                border: "none",
                margin: "auto",
                display: "block",
                width: 0,
                height: 0,
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: "100%",
                maxHeight: "100%",
                objectFit: "cover",
              }}
              src={item.image_url}
              width={"100%"}
              alt={"slider-item"}
            />
          ) : (
            <Skeleton
              height={"300px"}
              width={"300px"}
              variant={"rectangular"}
            />
          )}
        </Box>
        <Box
          sx={{
            mt: { md: "14px", mobile: "8px" },
            justifyContent: "center",
            textAlign: "center",
            height: "50px",
            overflow: "hidden",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              variant={"h3"}
              sx={{
                margin: "0 auto",
                maxWidth: "300px",
                fontSize: { md: "14px !important", mobile: "14px !important" },
                whiteSpace: "wrap",
                lineHeight: "26px !important",
              }}
            >
              {item?.name.length > 15
                ? item?.name.slice(0, 15) + "... "
                : item?.name + " "}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Create;
