import React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import useProposalService from "../../../proposal/useService";
import UI from "../../../../../@components/UI";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import QPEOPLE from "../../../qpeople";
import Link from "@mui/material/Link";
import VotingPanel from "./VotingPanel";
import useHandleErrors from "../../../../../@components/UI/useHandleErrors";
import truncateEthAddress from "truncate-eth-address";
import { useUserRole } from "../../../context";
import CustomHooks from "../../../../../@components/hooks";
import Stepper from "../../../../../qvrse/vote/@id/Stepper";
import useBlockchainState from "../../../../../qvrse/vote/@id/useBlockchainState";

const steps = ["Pre-vote", "Active", "Pending", "Executed"];

const Item = (props) => {
  const { children } = props;
  return (
    <Box {...props}>
      <Box
        sx={{
          background: "rgba(27, 30, 47, 0.5)",
          display: "flex",
          alignItems: "center",
          py: { md: "18px", mobile: "15px" },
          pl: { md: "30px", mobile: "15px" },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const Index = ({ DAO }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const history = useHistory();
  const { id } = useParams();
  const { handleApiError } = useHandleErrors();
  const proposalService = useProposalService(DAO?.id);
  const [proposal, setProposal] = React.useState(null);
  const { isBrandMember } = useUserRole();
  CustomHooks.useProtectedRoute(DAO, isBrandMember);

  const { proposalState } = useBlockchainState(DAO, proposal);

  React.useEffect(() => {
    if (proposalState === "Pending") {
      setActiveStep(0);
    } else if (proposalState === "Active") {
      setActiveStep(1);
    } else if (proposalState === "Queued" || proposalState === "Succeeded") {
      setActiveStep(2);
    } else if (proposalState === "Executed") {
      setActiveStep(3);
    } else if (proposalState === "Defeated") {
      setActiveStep(3);
    }
  }, [proposalState]);

  const fetchDatabaseData = async () => {
    proposalService
      .proposal(id)
      .then((response) => {
        setProposal(response.data);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  React.useEffect(() => {
    id && fetchDatabaseData();
    // eslint-disable-next-line
  }, [id]);
  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Paper
        elevation={0}
        sx={{ width: "100%", backgroundColor: "transparent" }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              mt: { md: "50px", mobile: "0px" },
              display: { md: "block", mobile: "none" },
            }}
          >
            <Stepper
              steps={[
                "Pre-vote",
                "Active",
                "Pending",
                proposalState === "Defeated" ? "Defeated" : "Executed",
              ]}
              proposalState={proposalState}
              activeStep={activeStep}
            />
          </Box>
          <Box
            sx={{
              mt: { md: "50px", mobile: "0px" },
              display: { md: "none", mobile: "block" },
            }}
          >
            <Stepper steps={steps} activeStep={activeStep} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { md: "70px", mobile: "0px" },
            pt: { md: "40px", mobile: "40px" },
          }}
        >
          <UI.BackButton
            fontSize={"14px !important"}
            title={"Back to votes"}
            onClick={() => history.push(`/brand/${DAO?.id}/vote`)}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            px: { md: "80px", mobile: "10px" },
            pb: "75px",
          }}
        >
          <Typography
            variant={"h1"}
            sx={{
              fontSize: "30px !important",
              lineHeight: "20px !important",
              mb: { mobile: "55px", md: "70px" },
              mt: "20px",
            }}
          >
            WITHDRAW FUNDS
          </Typography>
          <Grid container>
            <Grid item mobile={12} md={12}>
              <Typography
                variant={"h2"}
                sx={{
                  fontSize: "24px !important",
                  mb: { mobile: "12px", md: "20px" },
                }}
              >
                WITHDRAW INFO
              </Typography>
              <>
                <Item>
                  <Typography
                    variant={"h2"}
                    sx={{ 
                      fontSize: { md: "18px !important", mobile: "16px !important" }, 
                      lineHeight: "1.5" 
                    }}
                  >
                    Amount: {proposal?.data?.amount}{" "}
                    {proposal?.data?.token?.token_symbol}
                  </Typography>
                </Item>

                <Item sx={{ mt: { mobile: "12px", md: "18px" } }}>
                  <Typography
                    variant={"h2"}
                    sx={{
                      fontSize: { md: "18px !important", mobile: "16px !important" },
                      mr: "16px",
                      lineHeight: "1.5",
                    }}
                  >
                    Recipient:
                  </Typography>

                  <UI.CopyToClipboard
                    label={truncateEthAddress(proposal?.data?.recipient || "")}
                    value={proposal?.data?.recipient}
                    showBorder
                  />
                </Item>
                <Item sx={{ mt: { mobile: "12px", md: "18px" } }}>
                  <Box sx={{ flexDirection: "column", display: "flex" }}>
                    <Typography
                      variant={"h2"}
                      sx={{
                        fontSize: { md: "18px !important", mobile: "16px !important" },
                        mr: "10px",
                        lineHeight: "1.5",
                      }}
                    >
                      Description:
                    </Typography>
                    <Box>
                      <Typography
                        variant={"subtitle1"}
                        sx={{ pr: "30px", mt: "2px" }}
                      >
                        {proposal?.data?.description}
                      </Typography>
                    </Box>
                    {proposal?.data?.document_url && (
                      <Typography
                        variant={"subtitle1"}
                        sx={{ mt: { mobile: "12px", md: "18px" } }}
                      >
                        More information
                      </Typography>
                    )}
                    {proposal?.data?.document_url && (
                      <Typography variant={"h4"} sx={{ mt: "10px" }}>
                        <Link
                          target="_blank"
                          href={proposal?.data?.document_url}
                          underline="hover"
                        >
                          Document
                        </Link>
                      </Typography>
                    )}
                  </Box>
                </Item>
              </>
            </Grid>
            <Grid
              sx={{ mt: { mobile: "0px", md: "0px" } }}
              item
              mobile={12}
              md={12}
            >
              <Box
                sx={{
                  mt: "0px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ mb: { mobile: "12px", md: "20px" }, mt: "50px" }}>
                  <Typography variant={"h2"}>Voting</Typography>
                </Box>
                <VotingPanel
                  DAO={DAO}
                  proposal={proposal}
                  onChange={() => fetchDatabaseData()}
                />
              </Box>
            </Grid>
            <Grid
              sx={{ mt: { mobile: "50px", md: "50px" } }}
              item
              mobile={12}
              md={12}
            >
              <Box>
                <Typography variant={"h2"}>PARTICIPANTS</Typography>
                <Box sx={{ mt: { mobile: "12px", md: "20px" } }}>
                  {proposal?.votes?.length > 0 && (
                    <QPEOPLE.List items={proposal.votes} />
                  )}
                  {proposal?.votes?.length === 0 && (
                    <UI.NoResults text={"No Participants found :("} />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default Index;
