import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import UI from "../../../../@components/UI";
import applyLaterIcon from "../../../../static/apply-later-icon.png";

const ApplyLaterDialog = ({ toggleOpen, onConfirm }) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          backgroundColor: (theme) => theme.palette.colors.darkGray,
          padding: { md: "60px 60px", mobile: "40px 0px" },
          width: "800px",
          textAlign: "center",
          margin: { mobile: "15px" },
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ p: "0px" }}>
        <CloseIcon
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 30,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <Grid container>
          <Grid item mobile={12} md={12}>
            <Box
              component={"img"}
              src={applyLaterIcon}
              sx={{
                width: { md: "84px", mobile: "60px" },
                height: { md: "100px", mobile: "68px" },
                display: "flex",
                margin: "0 auto",
              }}
            />
            <Typography
              variant={"h6"}
              sx={{ fontSize: "30px", textAlign: "center", mt: { md: "35px", mobile: "30px" } }}
            >
              Apply Later
            </Typography>
            <Typography variant={"subtitle1"} sx={{ mt: "20px" }}>
              You can find the application in your dashboard.
            </Typography>

            <Box sx={{ mt: "45px", px: "10px" }}>
              <UI.Button
                onClick={() => onConfirm()}
                type={"primary"}
                title={"OK"}
                sx={{ width: "170px" }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </MaterialDialog>
  );
};

export default ApplyLaterDialog;
