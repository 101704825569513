import React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stripe from "./stripe";
import FreeMint from "./freeMint";
import Paypal from "./paypal";
import CloseIcon from "@mui/icons-material/Close";

const PaymentDialog = ({
  orderId,
  collection,
  onSuccess,
  onError,
  onClose,
  onRequestPassword,
  isFreeMintNoWallet,
}) => {
  return (
    <Dialog
      fullWidth
      PaperProps={{
        sx: {
          position: "relative",
          width: "700px",
          p: { md: "50px", mobile: "30px 20px" },
          margin: { md: "32px", mobile: "10px" },
          backgroundColor: (theme) => theme.palette.colors.darkGray,
        },
      }}
      onClose={onClose}
      open={true}
    >
      <CloseIcon
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 30,
          top: 30,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />

      {isFreeMintNoWallet ? (
        <Typography variant={"h2"} sx={{ maxWidth: {mobile:"300px", md:"700px"} }} >BRAND’S FREE NFT AWAITS!</Typography>
      ) : (
        <Typography variant={"h2"} sx={{ maxWidth: {mobile:"300px", md:"700px"} }}>Payment for NFT purchase</Typography>
      )}

      {isFreeMintNoWallet ? (
        <Box sx={{ mt: { md: "35px", mobile: "20px" } }}>
          <Typography variant={"subtitle1"} sx={{ fontSize: "16px !important" }}>
            Please confirm you wish to recieve your free NFT now! 
          </Typography>
        </Box>
      ) : (
        <Box sx={{ mt: { md: "35px", mobile: "20px" } }}>
          <Typography variant={"subtitle1"} sx={{ fontSize: "16px !important" }}>
            By clicking on either the PayPal or Stripe button, you will be
            directed to their respective payment gateway to complete the
            transaction. Once the payment is confirmed, you will receive your NFT
            within minutes. <br />
            <br />
            Please select your preferred payment method:
          </Typography>
        </Box>
      )}

      <Box></Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: { md: "50px", mobile: "35px" } }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {isFreeMintNoWallet && (
            <FreeMint.Button
              orderId={orderId}
              title={"Get Free NFT"}
              collection={collection}
              onRequestPassword={onRequestPassword}
              onSuccess={onSuccess}
            />
          )}
          {collection.stripe && (
            <Stripe.Button
              orderId={orderId}
              title={"Pay with Credit Card"}
              collection={collection}
              onSuccess={onSuccess}
            />
          )}

          <Box
            sx={{
              width: "220px",
              margin: "0 auto",
              mt: collection.stripe ? "20px" : "0px",
            }}
          >
            {collection.paypal && (
              <Paypal.Button
                orderId={orderId}
                collection={collection}
                onSuccess={onSuccess}
                onRequestPassword={onRequestPassword}
                onError={onError}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default PaymentDialog;
