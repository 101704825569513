import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import UI from "../../@components/UI";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import Auth from "../../@components/auth";
import { useUserContext } from "../../@components/userContext";
import Alert from "@mui/material/Alert";
import { useGoogleLogin } from "@react-oauth/google";
import useMediaQuery from "@mui/material/useMediaQuery";
import googleLoginIcon from "../../static/google-login.png";
import LoginWithWallet from "../../@components/UI/loginWithWallet";

const LoginDialog = ({
  toggleOpen,
  showCreateAccountDialog,
  showNoExistingAccountError,
  showPasswordResetDialog,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { login, loginWithGoogle } = Auth.useAuth();
  const { setUser: setUserContext } = useUserContext();
  const [isLoading, setIsLoading] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onSubmit",
  });

  const googleAuth = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await googleLogIn(tokenResponse.access_token);
    },
  });

  const googleLogIn = async (access_token) => {
    setError(null);
    setIsLoading(true);
    await loginWithGoogle(access_token)
      .then((response) => {
        const { user } = response;
        setUserContext(user);

        enqueueSnackbar("User was Logged In successfully", {
          variant: "success",
        });
        setIsLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setError("Account does not exists");
        } else {
          setError(error?.response?.data.message);
        }
        setIsLoading(false);
      });
  };

  const onSubmit = async (formData) => {
    setError(null);
    setIsLoading(true);
    await login(formData)
      .then((response) => {
        const { user } = response;
        setUserContext(user);

        enqueueSnackbar("User was Logged In successfully", {
          variant: "success",
        });

        setIsLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setError("Account does not exists");
        } else {
          setError(error?.response?.data.message);
        }
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);
  const handleClose = () => {
    setError(null);
    reset();
    setOpen(false);
  };
  return (
    <MaterialDialog
      fullWidth={true}
      PaperProps={{
        sx: {
          position: "relative",
          backgroundColor: "#1B1E2F !important",
          padding: { md: "40px 100px 50px", mobile: "30px 10px" },
          width: "730px",
          margin: { mobile: "0px" },
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ p: { mobile: "10px", md: "0px" } }}>
        <CloseIcon
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 30,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />

        <Typography
          variant={"h6"}
          sx={{ fontSize: "30px", textAlign: "center" }}
        >
          Log In
        </Typography>
        <Typography
          variant={"subtitle1"}
          sx={{ fontSize: "15px !important", textAlign: "center" }}
        >
          Don’t have an account yet?{" "}
          <Box
            onClick={() => {
              setError(null);
              reset();
              showCreateAccountDialog();
            }}
            component={"span"}
            sx={{
              cursor: "pointer",
              color: (theme) => theme.palette.colors.primary,
            }}
          >
            Create account
          </Box>
        </Typography>
        <Grid container>
          <Grid item mobile={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { md: "row", mobile: "column" },
                justifyContent: { md: "space-between", mobile: "center" },
                mt: "40px",
              }}
            >
              <LoginWithWallet
                title={isMobile ? "Wallet" : "Login with Wallet"}
                onNoExistingAccountError={() => showNoExistingAccountError()}
              />
              <Box sx={{ mt: { mobile: "20px", md: "0px" } }}>
                <Box
                  onClick={() => googleAuth()}
                  sx={{
                    margin: { mobile: "0 auto", md: "unset" },
                    cursor: "pointer",
                    display: "flex",
                    width: "251px",
                    height: "44px",
                    p: "10px 26px",
                    alignItems: "center",
                    backgroundColor: "rgba(51, 54, 69, 1);",
                    border: "1px solid transparent",
                    "&:hover": {
                      border: "1px solid transparent",
                      filter:
                        "drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.10))",
                      transition: "all 0.2s ease",
                      backgroundColor: "#616479",
                    },
                  }}
                >
                  <Box
                    component={"img"}
                    src={googleLoginIcon}
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <Typography
                    variant={"h2"}
                    sx={{
                      ml: "15px",
                      fontSize: "16px !important",
                      textTransform: "uppercase",
                    }}
                  >
                    Log in with google
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                my: "30px",
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Box
                component={"span"}
                sx={{
                  position: "absolute",
                  top: "50%",
                  borderBottom: "1px solid #303346",
                  width: "130px",
                  left: { md: "90px", mobile: "20px" },
                }}
              />
              <Typography variant={"subtitle2"} sx={{ mx: "20px" }}>
                or
              </Typography>
              <Box
                component={"span"}
                sx={{
                  position: "absolute",
                  top: "50%",
                  borderBottom: "1px solid #303346",
                  width: "130px",
                  right: { md: "90px", mobile: "20px" },
                }}
              />
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <Controller
                  name={"email"}
                  control={control}
                  render={({ field }) => (
                    <UI.TextField
                      {...field}
                      backgroundcolor={"#303346"}
                      fullWidth
                      placeholder={"Email Address"}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mt: "16px" }}>
                <Controller
                  name={"password"}
                  control={control}
                  render={({ field }) => (
                    <UI.PasswordField
                      {...field}
                      backgroundcolor={"#303346"}
                      fullWidth
                      placeholder={"Password"}
                    />
                  )}
                />
                <Typography
                  variant={"subtitle1"}
                  sx={{ fontSize: "16px !important", mt: "10px" }}
                >
                  Forgot you password?{" "}
                  <Box
                    onClick={() => showPasswordResetDialog()}
                    component={"span"}
                    sx={{
                      cursor: "pointer",
                      color: (theme) => theme.palette.colors.primary,
                    }}
                  >
                    Reset password
                  </Box>
                </Typography>
              </Box>
              <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                {error && (
                  <Alert
                    sx={{
                      color: (theme) => theme.palette.colors.darkRed,
                    }}
                    variant="outlined"
                    severity="error"
                  >
                    {error}
                  </Alert>
                )}
              </Typography>

              <Box
                sx={{
                  mt: { md: "40px", mobile: "40px" },
                  px: "15px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <UI.Button
                  disabled={Object.keys(errors).length}
                  sx={{ width: "210px" }}
                  title={"Log In"}
                  type={"primary"}
                />
              </Box>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle2"}>Login in progress ...</Typography>
        <Typography variant={"subtitle2"}>
          Please do not close this window.
        </Typography>
      </UI.Busy.FullscreenIndicator>
    </MaterialDialog>
  );
};

export default LoginDialog;
