import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import { useAccount, useBalance } from "wagmi";
import truncateEthAddress from "truncate-eth-address";
import UI from "../../@components/UI";
import qvrseLogo from "../../static/qvrse-logo.png";
import maticLogo from "../../static/matic-logo.png";
import tokenArtifact from "../../abis/contracts/token/QvrseToken.sol/QvrseToken.json";
import { ethers } from "ethers";
import Grid from "@mui/material/Grid";
import useService from "../../daos/@id/collection/useService";
import Alert from "@mui/material/Alert";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import QvrsButton from "../../@components/UI/Button";
import CustomHooks from "../../@components/hooks";

const Index = ({ user }) => {
  const service = useService();
  const provider = CustomHooks.useEthersProvider();
  const { address, isConnected } = useAccount();
  const [qvrseBalance, setQvrseBalance] = React.useState(0);
  const [otherTokens, setOtherTokens] = React.useState([]);
  const [nativeTokenBalance, setNativeTokenBalance] = React.useState(0);
  const { data } = useBalance({
    address: address,
  });
  const { openConnectModal } = useConnectModal();

  const { data: paymentOptions } = CustomHooks.useFetch(
    "payment_options",
    service.paymentOptions,
    {
      select: (response) => {
        return response.filter((item) => item.type !== "NATIVE");
      },
    }
  );

  const fetchTokenBalance = (item) => {
    return new Promise((resolve, reject) => {
      const token = new ethers.Contract(
        item?.address,
        tokenArtifact.abi,
        provider
      );
      token
        .balanceOf(address)
        .then((tokenBalance) => {
          if (!tokenBalance) {
            resolve("0");
          }
          resolve(ethers.formatUnits(tokenBalance, item?.decimals));
        })
        .catch((error) => {
          console.error("Error fetching balance: " + error);
          resolve("0");
        });
    });
  };

  const fetchBalances = async () => {
    const tokens = await Promise.all(
      paymentOptions.map((item) => {
        return fetchTokenBalance(item)
          .then((balance) => {
            return { ...item, balance };
          })
          .catch((error) => {
            console.error("Error in fetchTokenBalance: " + error);
          });
      })
    );
    setOtherTokens(tokens);
  };

  React.useEffect(() => {
    if (paymentOptions) {
      fetchBalances();
    }
    //eslint-disable-next-line
  }, [paymentOptions]);

  React.useEffect(() => {
    if (data && data?.value) {
      setNativeTokenBalance(ethers.formatEther(data?.value));
    }
  }, [data]);

  React.useEffect(() => {
    const fetchData = async () => {
      const token = new ethers.Contract(
        user?.qvrs_token_address,
        tokenArtifact.abi,
        provider
      );

      const tokenBalance = await token.balanceOf(address);

      setQvrseBalance(ethers.formatEther(tokenBalance));
    };
    provider && address && user && fetchData();
    //eslint-disable-next-line
  }, [provider, address, user]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "column", mobile: "column" },
          justifyContent: "center",
          pb: "20px",
          pt: { md: "70px", mobile: "30px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { mobile: "column", md: "row" },
          }}
        >
          <Typography variant={"h1"} sx={{ fontSize: "30px !important" }}>
            FINANCE
          </Typography>
        </Box>
        <Box sx={{ padding: { md: "50px 0px", mobile: "30px 0px" } }}>
          <Box>
            <Grid container>
              {!user?.email_verified && (
                <Grid
                  item
                  mobile={12}
                  md={12}
                  sx={{
                    mt: { mobile: "15px", md: "15px" },
                  }}
                >
                  <Typography
                    variant={"subtitle1"}
                    sx={{
                      position: "relative",
                      display: { md: "none", mobile: "block" },
                    }}
                  >
                    <Alert
                      variant="outlined"
                      severity="info"
                      sx={{
                        color: "#4DB8FF",
                        fontWeight: "bold",
                        padding: "20px",
                        height: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        {"Please verify your email in settings."}
                      </Box>
                    </Alert>
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                mobile={12}
                md={12}
                sx={{
                  mt: { mobile: "50px", md: "0px" },
                }}
              >
                <Typography
                  sx={{ mb: "20px", fontSize: "18px !important" }}
                  variant={"h2"}
                >
                  Wallet Address
                </Typography>
                <Paper
                  sx={{
                    cursor: "default",
                    border: "1px solid transparent",
                    borderRadius: "0px",
                    "&:hover": {
                      boxShadow: "#944dff 0px 0px 10px 0px",
                      border: "1px solid #944DFF",
                      transition: "all 0.1s ease 0s",
                    },
                    backgroundColor: "rgba(27, 30, 47, 0.5)",
                  }}
                >
                  <Box
                    sx={{
                      height: "76px",
                      padding: { md: "20px 35px", mobile: "20px 15px" },
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          mr: "20px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar src={user?.avatar_url} alt={"avatar"} />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant={"subtitle1"}>
                          {user?.address ? (
                            <UI.CopyToClipboard
                              label={truncateEthAddress(user?.address || "")}
                              value={user?.address}
                              showBorder
                            />
                          ) : (
                            <QvrsButton
                              sx={{
                                height: "42px",
                              }}
                              onClick={() => openConnectModal()}
                              title={"Connect Wallet"}
                              type={"secondary"}
                            />
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
                {!isConnected && (
                  <Typography
                    variant={"subtitle1"}
                    sx={{
                      position: "relative",
                      display: { md: "block", mobile: "block" },
                    }}
                  >
                    <Alert
                      variant="outlined"
                      severity="info"
                      sx={{
                        color: "#4DB8FF",
                        height: "50px",
                        width: "100%",
                        fontWeight: "bold",
                        mt: "15px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        Connect your wallet to unlock all features and enhance
                        your Qvrse experience.{"  "}
                        <Box
                          sx={{
                            ml: "5px",
                            cursor: "pointer",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                            color: "#944DFF",
                            fontWeight: "bold",
                          }}
                          onClick={() => openConnectModal()}
                          component={"span"}
                        >
                          Connect Wallet
                        </Box>
                      </Box>
                    </Alert>
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                mobile={12}
                md={12}
                sx={{
                  mt: { mobile: "50px", md: "80px" },
                }}
              >
                <Typography
                  sx={{ mb: "20px", fontSize: "18px !important" }}
                  variant={"h2"}
                >
                  CRYPTO CURRENCIES
                </Typography>
                {qvrseBalance && (
                  <TokenItem
                    sx={{ mb: "15px" }}
                    name={"QVRS"}
                    icon={qvrseLogo}
                    amount={qvrseBalance}
                  />
                )}

                {nativeTokenBalance && (
                  <TokenItem
                    sx={{ mb: "15px" }}
                    name={"MATIC"}
                    icon={maticLogo}
                    amount={nativeTokenBalance || 0}
                  />
                )}

                {otherTokens.map((item) => (
                  <TokenItem
                    key={item.address}
                    item={item}
                    icon={item.icon}
                    name={item.name}
                    sx={{ mb: "15px" }}
                    amount={item.balance || 0}
                  />
                ))}
              </Grid>
              <Grid item mobile={12} md={12}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant={"subtitle1"}
                    sx={{
                      position: "relative",
                      display: { md: "block", mobile: "block" },
                    }}
                  >
                    <Alert
                      variant="outlined"
                      severity="info"
                      sx={{
                        color: "#4DB8FF",
                        height: "50px",
                        width: "330px",
                        fontWeight: "bold",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <>
                          Get USDT/USDC tokens on
                          <Link
                            sx={{ textDecoration: "none", whiteSpace: "pre" }}
                            href={"https://app.uniswap.org/"}
                            target="_blank"
                          >
                            {" "}
                            Uniswap
                          </Link>
                        </>
                      </Box>
                    </Alert>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const TokenItem = (props) => {
  const { name, icon, amount } = props;
  return (
    <Box {...props}>
      <Paper
        sx={{
          cursor: "default",
          border: "1px solid transparent",
          "&:hover": {
            boxShadow: "#944dff 0px 0px 10px 0px",
            border: "1px solid #944DFF",
            transition: "all 0.1s ease 0s",
          },
          backgroundColor: "rgba(27, 30, 47, 0.5)",
        }}
      >
        <Box
          sx={{
            height: "76px",
            padding: { md: "20px 35px", mobile: "20px 15px" },
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                display: { md: "flex", mobile: "none" },
                fontSize: "18px !important",
              }}
              variant={"h2"}
            >
              {name}
            </Typography>
            <Typography
              sx={{ display: { md: "none", mobile: "flex" } }}
              variant={"h4"}
            >
              {name}
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                width: "25px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <img src={icon} alt={"icon"} />
            </Box>
            <Box
              sx={{
                ml: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant={"h4"}>
                {parseFloat(amount).toFixed(2)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default Index;
