import React from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useFormData } from "../context";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../../@components/UI";
import Alert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useAccount } from "wagmi";
import { useUserContext } from "../../../../../@components/userContext";
import backArrow from "../../../../../static/back-arrow.png";
import { ethers } from "ethers";
import erc721Artifact from "../../../../../abis/contracts/modules/collection/Collection.sol/Collection.json";
import { useSnackbar } from "notistack";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import { getNFTData, getContentTypeFromUrl, getMetadata } from "./utils";
import customHooks from "../../../../../@components/hooks";

const useStyles = makeStyles((theme) => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "5px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  backButton: {
    textAlign: "left",
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },
}));

const Index = ({ DAO, draft, onSave, nextStep }) => {
  const history = useHistory();
  const classes = useStyles();
  const { user: loggedInUser } = useUserContext();
  const { isConnected } = useAccount();
  const { data, setFormValues } = useFormData();
  const provider = customHooks.useEthersProvider();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      basic_info: {
        picture_url: data?.basic_info?.picture_url,
        nft_collection_address: data?.basic_info?.nft_collection_address,
        name: data?.basic_info?.name,
        symbol: data?.basic_info?.symbol,
        supply: data?.basic_info?.supply,
        description: data?.basic_info?.description,
      },
    },
    mode: "onSubmit",
  });

  const watched_picture_url = useWatch({
    control,
    name: "basic_info.picture_url",
  });

  const setValuesFromMetadata = async (token, tokenId) => {
    try {
      const name = await token.name();
      const symbol = await token.symbol();
      const tokenURI = await token.tokenURI(tokenId);
      const metadata = await getMetadata(tokenURI);
      const contentType = await getContentTypeFromUrl(metadata.image);

      setValue("basic_info.name", name);
      setValue("basic_info.symbol", symbol);
      setValue("basic_info.picture_url", {
        ipfs: metadata.image,
        file: { type: contentType },
      });
      setValue("basic_info.description", metadata.description);
      clearErrors();
    } catch (e) {
      enqueueSnackbar("Something went wrong when reading metadata", {
        variant: "error",
      });
    }
  };

  const triggerSearch = async (address) => {
    setIsLoading(true);
    const nftContractInstance = new ethers.Contract(
      address,
      erc721Artifact.abi,
      provider
    );
    await getNFTData(provider, nftContractInstance)
      .then(async (tokenId) => {
        await setValuesFromMetadata(nftContractInstance, tokenId);
      })
      .catch((error) => {
        enqueueSnackbar(error || "Something went wrong when reading metadata", {
          variant: "error",
        });
      });
    setIsLoading(false);
  };

  // Reset form with draft data.
  React.useEffect(() => {
    if (draft) {
      setFormValues(draft.data);
      reset({
        basic_info: draft.data.basic_info,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft]);

  const onSubmit = async (values, action) => {
    switch (action) {
      case "save_and_continue": {
        await onSave(values, false);
        nextStep();

        break;
      }
      case "save": {
        await onSave(values, true);
        break;
      }
      default: {
        await onSave(values, true);
        break;
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit((values) => onSubmit(values, "save_and_continue"))}
    >
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle2"}>
          Searching for collection data...
        </Typography>
        <Typography variant={"subtitle2"}>
          Please do not close this window.
        </Typography>
      </UI.Busy.FullscreenIndicator>
      <Box
        sx={{
          mt: { md: "60px", mobile: "20px" },
          p: { md: "0px 65px", mobile: "20px 10px" },
        }}
      >
        <Typography variant={"subtitle1"} sx={{ opacity: 1 }}>
          To import your NFT collection, enter the address of your NFT contract
          deployed on the Polygon network into the provided field, then click on
          the 'Search' button. Once you do this, our system will automatically
          gather and display the Name, Symbol, Description, and Image of your
          collection. Just a little reminder: to proceed with the import, your
          contract should have at least one NFT minted.
        </Typography>
      </Box>
      <Box
        sx={{
          mt: { md: "15px", mobile: "20px" },
          p: { md: "40px 65px", mobile: "20px 10px" },
        }}
      >
        <Box
          onClick={() => history.push(`/brand/${DAO?.id}/collection`)}
          className={classes.backButtonOuter}
          sx={{
            display: "flex",
            cursor: "pointer",
            mb: { md: "50px", mobile: "30px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mr: "8px",
            }}
          >
            <img
              className={"backArrow"}
              style={{ transition: "0.4s ease-in" }}
              src={backArrow}
              alt={"back-arrow"}
              width={"15px"}
              height={"15px"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box className={classes.backButton}>back to collections</Box>
          </Box>
        </Box>
        <Box
          sx={{
            mb: "50px",
            display: "flex",
            textAlign: "left",
          }}
        >
          <Typography
            sx={{
              fontSize: { md: "30px !important", mobile: "24px !important" },
            }}
            variant={"h1"}
          >
            step 1: basic collection information
          </Typography>
        </Box>
        <Paper
          elevation={0}
          sx={{
            width: "100%",
            backgroundColor: "rgba(27, 30, 47, 0.5)",
            px: { md: "50px", mobile: "10px" },
            py: { md: "50px", mobile: "50px" },
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h4"}>NFT Collection Address</Typography>
                  <UI.Tooltip>
                    {`Enter the Contract Address: This is the unique identifier of your NFT collection on the blockchain. It typically starts with 0x`}
                  </UI.Tooltip>
                </Box>

                <Controller
                  name={"basic_info.nft_collection_address"}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <UI.TextField
                      {...field}
                      placeholder={"Enter NFT Collection Address"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={(event) =>
                              triggerSearch(
                                getValues("basic_info.nft_collection_address")
                              )
                            }
                            onMouseDown={() => {}}
                            edge="end"
                          >
                            <SavedSearchIcon sx={{ color: "white" }} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h4"}>Name</Typography>
                </Box>

                <Controller
                  name={"basic_info.name"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.TextField
                      disabled
                      {...field}
                      placeholder={"This field is auto generated"}
                    />
                  )}
                />
                {errors?.basic_info?.name?.type === "required" && (
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    <Alert
                      sx={{ color: (theme) => theme.palette.colors.darkRed }}
                      variant="outlined"
                      severity="error"
                    >
                      Name is required
                    </Alert>
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h4"}>Symbol</Typography>
                </Box>

                <Controller
                  name={"basic_info.symbol"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.TextField
                      disabled
                      {...field}
                      placeholder={"This field is auto generated"}
                    />
                  )}
                />
                {errors.basic_info?.symbol?.type === "required" && (
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    <Alert
                      sx={{ color: (theme) => theme.palette.colors.darkRed }}
                      variant="outlined"
                      severity="error"
                    >
                      Symbol is required
                    </Alert>
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h4"}>Description</Typography>
                </Box>

                <Controller
                  name={"basic_info.description"}
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <UI.TextField
                      disabled
                      multiline
                      rows={3}
                      {...field}
                      placeholder={"This field is auto generated"}
                    />
                  )}
                />
                {errors.basic_info?.description?.type === "required" && (
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    <Alert
                      sx={{ color: (theme) => theme.palette.colors.darkRed }}
                      variant="outlined"
                      severity="error"
                    >
                      Description is required
                    </Alert>
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid item md={12} mobile={12}>
            <Box sx={{ display: "flex", mb: "10px", mt: "20px" }}>
              <Typography variant={"h4"}>NFT PICTURE</Typography>
            </Box>
            <>
              {watched_picture_url ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {watched_picture_url.file.type.startsWith("image") ? (
                    <img
                      alt={"file-preview"}
                      style={{ height: "280px" }}
                      src={watched_picture_url.ipfs}
                    />
                  ) : (
                    <video
                      playsInline
                      width="auto"
                      height={"270px"}
                      controls
                      controlsList="nodownload"
                    >
                      <source src={watched_picture_url.ipfs} />
                    </video>
                  )}
                  {errors.basic_info?.picture_url?.type === "required" && (
                    <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                      <Alert
                        sx={{ color: (theme) => theme.palette.colors.darkRed }}
                        variant="outlined"
                        severity="error"
                      >
                        Picture is required
                      </Alert>
                    </Typography>
                  )}
                </Box>
              ) : (
                <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                  <Alert
                    sx={{ color: "#4DB8FF" }}
                    variant="outlined"
                    severity="info"
                  >
                    Image will be shown after import is successful
                  </Alert>
                </Typography>
              )}
            </>
          </Grid>
        </Paper>
      </Box>
      <Box
        sx={{
          width: "100%",
          mt: "40px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "210px" }}>
            <UI.Button
              disabled={
                !!Object.keys(errors).length ||
                !isConnected ||
                !loggedInUser?.email_verified
              }
              sx={{
                filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
              }}
              title={`Save & Continue`}
              type={"primary"}
            />
            <UI.Button
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                const allValues = getValues();
                await onSubmit(allValues, "save");
              }}
              disabled={!isConnected || !loggedInUser?.email_verified}
              sx={{
                mt: "10px",
                filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
              }}
              title={"Save for later"}
              type={"terciary"}
            />
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default Index;
