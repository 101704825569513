import Gateway from "../../@components/gateway";

const useService = () => {
  const gateway = Gateway.useGateway();

  const info = () => {
    return gateway.get(`dao/${window.env.QVRSE_DAO_ID}/treasury/info/`);
  };

  return {
    info,
  };
};

export default useService;
