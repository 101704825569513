import CenterMode from "./CenterMode";
import BlogMode from "./BlogMode.js";
import TokenList from "./TokenList.js";

const Components = {
  CenterMode,
  BlogMode,
  TokenList,
};

export default Components;
