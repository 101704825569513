import Gateway from "../../../../@components/gateway";

const useService = (daoId) => {
  const gateway = Gateway.useGateway();

  const save = (data) => {
    return gateway.post(`dao/${daoId}/treasury/tokens/new/`, data);
  };

  const remove = (tokenId) => {
    return gateway.post(`dao/${daoId}/treasury/tokens/${tokenId}/delete/`);
  };

  const list = () => {
    return gateway.get(`dao/${daoId}/treasury/tokens/`);
  };

  return {
    save,
    list,
    remove,
  };
};

export default useService;
