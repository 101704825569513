import React from "react";
import { useFormDataContext } from "../context";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../@components/UI";
import { makeStyles } from "@mui/styles";
import backArrow from "../../../../static/back-arrow.png";
import Utility from "../../../../daos/@id/proposal/collection/extraUtilities";
import AccordionItem from "../../../../daos/@id/proposal/collection/extraUtilities/AccordionItem";
import Link from "@mui/material/Link";
import linkedInLogo from "../../../../static/linkedin.png";
import discordLogo from "../../../../static/discord.png";
import twitterLogo from "../../../../static/twitter.png";
import telegramLogo from "../../../../static/telegram.png";
import instagramLogo from "../../../../static/instagram.png";
import facebookLogo from "../../../../static/facebook.png";
import { useUserContext } from "../../../../@components/userContext";
import { useAccount } from "wagmi";
import stripeLogo from "../../../../static/stripe.png";
import paypalLogo from "../../../../static/paypal.png";
import cryptoLogo from "../../../../static/crypto.png";
import EditIcon from "@mui/icons-material/Edit";
import TokenDialog from "./TokenDialog";
import GovernanceDialog from "./GovernanceDialog";
import Tooltip from "@mui/material/Tooltip";
import documentIcon from "../../../../static/pdf-upload.png";
import maticLogo from "../../../../static/matic-logo.png";
import usdtLogo from "../../../../static/usdt-logo.png";
import usdcLogo from "../../../../static/usdc-icon.png";

const useStyles = makeStyles((theme) => ({
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },

  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
  },

  backButton: {
    textAlign: "left",
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
}));

const Index = ({ nextStep, previouseStep, onSave }) => {
  const { user: loggedInUser } = useUserContext();
  const { isConnected } = useAccount();
  const classes = useStyles();
  const { formData } = useFormDataContext();

  return (
    <Box
      sx={{
        p: { md: "40px 100px", mobile: "50px 10px 60px" },
        pt: "0px !important",
      }}
    >
      <Box
        onClick={() => previouseStep()}
        className={classes.backButtonOuter}
        sx={{
          display: "flex",
          cursor: "pointer",
          mb: { md: "50px", mobile: "30px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mr: "8px",
          }}
        >
          <img
            className={"backArrow"}
            style={{ transition: "0.4s ease-in" }}
            src={backArrow}
            alt={"back-arrow"}
            width={"15px"}
            height={"15px"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box className={classes.backButton}>Back to payment method</Box>
        </Box>
      </Box>
      <Box
        sx={{
          mb: "63px",
          display: "flex",
          flexDirection: { md: "row", mobile: "column" },
          textAlign: "left",
        }}
      >
        <Box>
          <Typography variant={"h1"} sx={{ fontSize: "30px !important" }}>
            STEP 3: Review Information
          </Typography>
        </Box>
      </Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ textAlign: "left" }}
      >
        <Grid item md={12} mobile={12} sx={{ pt: "0px !important" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              mb: { md: "100px", mobile: "30px" },
            }}
          >
            <Box
              sx={{
                width: "250px",
                margin: "0 auto",
                display: "flex",
                justifyContent: "center",
                height: "250px",
                overflow: "hidden",
                borderRadius: "50%",
                border: "2px solid transparent",
                "&:hover": {
                  boxShadow: "#944dff 0px 0px 15px 0px",
                  border: "2px solid #944dff",
                  transform: "scale(1.1)",
                  transition: "all 0.2s ease 0s",
                },
              }}
            >
              <Box
                component={"img"}
                sx={{
                  textAlign: "center",
                  objectFit: "cover",
                }}
                src={formData.avatar_url?.ipfs}
                alt={"dao avatar"}
              />
            </Box>
            <Box
              sx={{
                textAlign: "center",
                pt: "25px",
                margin: "0 auto",

                maxWidth: { mobile: "350px", md: "500px" },
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <Typography variant={"h2"}>
                <UI.OverflowTip>{formData?.name}</UI.OverflowTip>
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mt: { md: "30px", mobile: "18px" } }}>
            <Typography variant={"h2"} sx={{ mb: "20px" }}>
              Basic brand information
            </Typography>
            <UI.Accordion
              sx={{ background: "rgba(27, 30, 47, 0.5)" }}
              readonly
              title={"BUSINESS information about your brand"}
            >
              <Utility.AccordionItem
                title={"Company name"}
                value={`${formData?.company_name}`}
              />
              <Utility.AccordionItem
                title={"Business address"}
                value={`${formData?.business_address}`}
              />
              <Utility.AccordionItem
                title={"City"}
                value={`${formData?.city}`}
              />
              <Utility.AccordionItem
                title={"Postal code"}
                value={`${formData?.zip_code}`}
              />
              <Utility.AccordionItem
                title={"VAT number"}
                value={`${formData?.vat_number}`}
              />
              <Utility.AccordionItem
                title={"Country of incorporation"}
                value={`${formData?.country_of_incorporation.label}`}
              />
              <Utility.AccordionItem
                title={"Identification number"}
                value={`${formData?.identification_number}`}
              />
              <Utility.AccordionItem
                title={`Business ${
                  formData?.is_vat_registered ? "not" : "is"
                } VAT registered`}
                value={``}
              />
            </UI.Accordion>
          </Box>
          <Box sx={{ mt: { md: "30px", mobile: "18px" } }}>
            <UI.Accordion
              sx={{ background: "rgba(27, 30, 47, 0.5)" }}
              readonly
              title={"additional information about your brand"}
            >
              <Utility.AccordionItem
                title={"Brand description"}
                value={`${formData?.description}`}
              />
              {formData?.webpage_url && (
                <Box sx={{ display: { md: "flex", mobile: "none" } }}>
                  <Link
                    target="_blank"
                    href={formData?.webpage_url}
                    underline="hover"
                    sx={{
                      mt: "20px",
                      maxWidth: { mobile: "350px", md: "500px" },
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontSize: { md: "16px", mobile: "14px" },
                    }}
                  >
                    {formData?.webpage_url}
                  </Link>
                </Box>
              )}
              {formData?.whitepaper_url?.ipfs && (
                <Box>
                  <Link
                    target="_blank"
                    href={formData?.whitepaper_url?.ipfs}
                    underline="hover"
                  >
                    <Tooltip title={"Document"} arrow>
                      <Box
                        alt={"more mainUtility document"}
                        component={"img"}
                        src={documentIcon}
                        sx={{
                          cursor: "pointer",
                          width: "26px",
                          mr: "30px",
                          mt: "20px",
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                          transition: "all 0.5s ease-in-out",
                        }}
                      />
                    </Tooltip>
                  </Link>
                </Box>
              )}
            </UI.Accordion>
          </Box>
          {(formData?.socials?.linkedin ||
            formData?.socials?.discord ||
            formData?.socials?.twitter ||
            formData?.socials?.facebook ||
            formData?.socials?.instagram ||
            formData?.socials?.telegram) && (
            <Box sx={{ mt: { md: "30px", mobile: "18px" } }}>
              <UI.Accordion
                sx={{ background: "rgba(27, 30, 47, 0.5)" }}
                readonly
                title={"Socials"}
              >
                <Box sx={{ display: "flex", pb: "30px" }}>
                  {formData?.socials?.linkedin && (
                    <Link
                      target="_blank"
                      href={formData?.socials?.linkedin}
                      underline="hover"
                    >
                      <Box
                        component={"img"}
                        src={linkedInLogo}
                        sx={{
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                          transition: "all 0.5s ease-in-out",
                          cursor: "pointer",
                          width: "35px",
                          height: "35px",
                          mr: "17px",
                          display: { md: "block", mobile: "none" },
                        }}
                      />
                    </Link>
                  )}
                  {formData?.socials?.discord && (
                    <Link
                      target="_blank"
                      href={formData?.socials?.discord}
                      underline="hover"
                    >
                      <Box
                        component={"img"}
                        src={discordLogo}
                        sx={{
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                          transition: "all 0.5s ease-in-out",
                          cursor: "pointer",
                          width: "35px",
                          height: "35px",
                          mr: "17px",
                          display: { md: "block", mobile: "none" },
                        }}
                      />
                    </Link>
                  )}
                  {formData?.socials?.twitter && (
                    <Link
                      target="_blank"
                      href={formData?.socials?.twitter}
                      underline="hover"
                    >
                      <Box
                        component={"img"}
                        src={twitterLogo}
                        sx={{
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                          transition: "all 0.5s ease-in-out",
                          cursor: "pointer",
                          width: "35px",
                          height: "35px",
                          mr: "17px",
                          display: { md: "block", mobile: "none" },
                        }}
                      />
                    </Link>
                  )}
                  {formData?.socials?.telegram && (
                    <Link
                      target="_blank"
                      href={formData?.socials?.telegram}
                      underline="hover"
                    >
                      <Box
                        component={"img"}
                        src={telegramLogo}
                        sx={{
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                          transition: "all 0.5s ease-in-out",
                          cursor: "pointer",
                          width: "35px",
                          height: "35px",
                          mr: "17px",
                          display: { md: "block", mobile: "none" },
                        }}
                      />
                    </Link>
                  )}
                  {formData?.socials?.instagram && (
                    <Link
                      target="_blank"
                      href={formData?.socials?.instagram}
                      underline="hover"
                    >
                      <Box
                        component={"img"}
                        src={instagramLogo}
                        sx={{
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                          transition: "all 0.5s ease-in-out",
                          cursor: "pointer",
                          width: "35px",
                          height: "35px",
                          mr: "17px",
                          display: { md: "block", mobile: "none" },
                        }}
                      />
                    </Link>
                  )}
                  {formData?.socials?.facebook && (
                    <Link
                      target="_blank"
                      href={formData?.socials?.facebook}
                      underline="hover"
                    >
                      <Box
                        component={"img"}
                        src={facebookLogo}
                        sx={{
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                          transition: "all 0.5s ease-in-out",
                          cursor: "pointer",
                          width: "35px",
                          height: "35px",
                          mr: "17px",
                          display: { md: "block", mobile: "none" },
                        }}
                      />
                    </Link>
                  )}
                </Box>
              </UI.Accordion>
            </Box>
          )}

          <Box sx={{ mt: "50px" }}>
            <Typography variant={"h2"} sx={{ mb: "20px" }}>
              Payment Method
            </Typography>
            <UI.Accordion
              sx={{ background: "rgba(27, 30, 47, 0.5)" }}
              readonly
              title={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    component={"img"}
                    src={cryptoLogo}
                    sx={{ width: "40px" }}
                  />
                  <Box
                    component={"span"}
                    sx={{
                      ml: "30px",
                      fontSize: "18px",
                      fontFamily: "Quantico",
                      textTransform: "uppercase",
                      fontWeight: "700",
                      color: "#ffffff !important",
                    }}
                  >
                    CRYPTO CURRENCIES
                  </Box>
                </Box>
              }
            >
              <Tooltip title={"MATIC"} arrow>
                <Box
                  component={"img"}
                  src={maticLogo}
                  sx={{
                    width: "22px",
                    height: "22px",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                    transition: "all 0.5s ease-in-out",
                  }}
                />
              </Tooltip>
              <Tooltip title={"USDT"} arrow>
                <Box
                  component={"img"}
                  src={usdtLogo}
                  sx={{
                    width: "22px",
                    height: "22px",
                    ml: "14px",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                    transition: "all 0.5s ease-in-out",
                  }}
                />
              </Tooltip>
              <Tooltip title={"USDC"} arrow>
                <Box
                  component={"img"}
                  src={usdcLogo}
                  sx={{
                    width: "22px",
                    height: "22px",
                    ml: "14px",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                    transition: "all 0.5s ease-in-out",
                  }}
                />
              </Tooltip>
            </UI.Accordion>
            {formData?.payment?.paypal?.selected && (
              <Box sx={{ mt: { md: "30px", mobile: "18px" } }}>
                <UI.Accordion
                  sx={{ background: "rgba(27, 30, 47, 0.5)" }}
                  readonly
                  title={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        component={"img"}
                        src={paypalLogo}
                        sx={{ width: "40px" }}
                      />
                      <Box
                        component={"span"}
                        sx={{
                          ml: "30px",
                          fontSize: "18px",
                          fontFamily: "Quantico",
                          textTransform: "uppercase",
                          fontWeight: "700",
                          color: "#ffffff !important",
                        }}
                      >
                        PAYPAL
                      </Box>
                    </Box>
                  }
                >
                  <Utility.AccordionItem
                    title={"Secret Key"}
                    value={`${formData?.payment?.paypal?.secret_key}`}
                  />
                  <Utility.AccordionItem
                    title={"Public Key"}
                    value={`${formData?.payment?.paypal?.public_key}`}
                  />
                  <Utility.AccordionItem
                    title={"Web hook"}
                    value={`${formData?.payment?.paypal?.web_hook}`}
                  />
                </UI.Accordion>
              </Box>
            )}
          </Box>
          {formData?.payment?.stripe?.selected && (
            <Box sx={{ mt: { md: "30px", mobile: "18px" } }}>
              <UI.Accordion
                sx={{ background: "rgba(27, 30, 47, 0.5)" }}
                readonly
                title={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      component={"img"}
                      src={stripeLogo}
                      sx={{ width: "40px" }}
                    />
                    <Box
                      component={"span"}
                      sx={{
                        ml: "30px",
                        fontSize: "18px",
                        fontFamily: "Quantico",
                        textTransform: "uppercase",
                        fontWeight: "700",
                        color: "#ffffff !important",
                      }}
                    >
                      STRIPE
                    </Box>
                  </Box>
                }
              >
                <Utility.AccordionItem
                  title={"Secret Key"}
                  value={`${formData?.payment?.stripe?.secret_key}`}
                />
                <Utility.AccordionItem
                  title={"Public Key"}
                  value={`${formData?.payment?.stripe?.public_key}`}
                />
              </UI.Accordion>
            </Box>
          )}
          <Box sx={{ mt: "50px" }}>
            <Typography variant={"h2"} sx={{ mb: "20px" }}>
              GOVERNANCE - FOR CRYPTO PAYMENTS ONLY
            </Typography>
            <Typography
              variant={"subtitle1"}
              sx={{ mb: "40px", textAlign: { md: "justify", mobile: "left" } }}
            >
              When customers pay with cryptocurrency, the funds go directly to
              the brand’s treasury. To ensure the funds are safe, Qvrse has set
              up a process where they can only be withdrawn through a vote. This
              enables that important decisions about funds can be made by a
              group of people. To help simplify this process, Qvrse has created
              a default brand governance token that serves as a voting right and
              default governance settings that can both be customised now or
              later.
            </Typography>
            <UI.Accordion
              sx={{ background: "rgba(27, 30, 47, 0.5)" }}
              logo={formData?.token_icon_url?.ipfs}
              readonly
              title={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "rgb(132 128 128 / 60%);",
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: { mobile: "185.4px", md: "500px" },
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <UI.OverflowTip>{`${formData?.token_name} `}</UI.OverflowTip>
                  </Box>
                  <Box
                    sx={{
                      maxWidth: "185.4px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {" "}
                    <UI.OverflowTip>
                      <Box sx={{ whiteSpace: "pre-wrap" }}>
                        {` (${formData?.token_symbol})`}
                      </Box>
                    </UI.OverflowTip>
                  </Box>
                  <TokenDialog.Action
                    action={
                      <Box
                        sx={{
                          display: "flex",
                          cursor: "pointer",
                          transition: "all 0.5 ease-in-out",
                          "&:hover": {
                            transform: "scale(1.1)",
                            color: "white",
                          },
                        }}
                      >
                        <Tooltip title={"Edit"} arrow>
                          <EditIcon
                            sx={{
                              ml: { md: "20px", mobile: "14px" },
                              color: "#ffffff",
                              width: "18px",
                            }}
                          />
                        </Tooltip>
                      </Box>
                    }
                    onSave={onSave}
                  />
                </Box>
              }
            >
              <AccordionItem title={"Token Type"} value={`ERC20`} />
              <AccordionItem
                title={"Token Supply"}
                value={`${formData?.token_supply}`}
              />
            </UI.Accordion>
          </Box>
          <Box sx={{ mt: { md: "30px", mobile: "18px" } }}>
            <UI.Accordion
              sx={{ background: "rgba(27, 30, 47, 0.5)" }}
              readonly
              title={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "rgb(132 128 128 / 60%);",
                  }}
                >
                  Governance settings
                  <GovernanceDialog.Action
                    action={
                      <Box
                        sx={{
                          display: "flex",
                          cursor: "pointer",
                          transition: "all 0.5 ease-in-out",
                          "&:hover": {
                            transform: "scale(1.1)",
                            color: "white",
                          },
                        }}
                      >
                        <Tooltip title={"Edit"} arrow>
                          <EditIcon
                            sx={{
                              ml: { md: "20px", mobile: "14px" },
                              color: "#ffffff",
                              width: "18px",
                            }}
                          />
                        </Tooltip>
                      </Box>
                    }
                    onSave={onSave}
                  />
                </Box>
              }
            >
              <Utility.AccordionItem
                title={"Minimum participation / Quorum: "}
                value={`${formData?.quorum}%`}
              />
              <Utility.AccordionItem
                title={"Voting Delay"}
                value={`${formData?.voting_delay} blocks`}
              />
              <Utility.AccordionItem
                title={"Voting period"}
                value={`${formData?.voting_period} blocks`}
              />
              <Utility.AccordionItem
                title={"Timelock delay"}
                value={`${formData?.timelock_delay}`}
              />
            </UI.Accordion>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          width: "100%",
          mt: { md: "80px", mobile: "60px" },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "210px" }}>
            <UI.Button
              disabled={!isConnected || !loggedInUser?.email_verified}
              title={"Save & Continue"}
              type={"primary"}
              onClick={() => nextStep()}
            />
          </Box>
        </Box>
      </Box>
      {/*<TokenDialog toggleOpen={toggleTokenDialog} onSave={onSave} />*/}
      {/*<GovernanceDialog toggleOpen={toggleGovernanceDialog} onSave={onSave} />*/}
    </Box>
  );
};

export default Index;
