import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import UI from "../../@components/UI";
import truncateEthAddress from "truncate-eth-address";
import moment from "moment";

const Item = ({ dateTime, avatar, address, stakedAmount, voteWay, token }) => {
  return (
    <Box
      sx={{
        cursor: "pointer",
        padding: { md: "16px 30px", mobile: "10px 15px" },
        mb: "15px",
        backgroundColor: "rgba(27, 30, 47, 0.5);",
        display: "flex",
        border: "1px solid transparent",
        justifyContent: "space-between",
        "&:hover": {
          boxShadow: "#944dff 0px 0px 10px 0px",
          border: "1px solid #944DFF",
        },
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            mr: { md: "20px", mobile: "14px" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Avatar src={avatar} alt={"avatar"} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant={"subtitle1"}>
            <UI.CopyToClipboard
              label={truncateEthAddress(address)}
              value={address}
              showBorder
            />
          </Typography>
        </Box>
        <Box sx={{ display: "none", alignItems: "center", ml: "30px" }}>
          <Typography
            variant={"subtitle1"}
            sx={{ fontSize: "14px !important", color: "#7a7e92" }}
          >
            {moment(dateTime).format("DD MMM YYYY HH:mm")}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: { md: "flex", mobile: "none" },
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant={"subtitle1"} sx={{ mr: "20px", fontSize: "14px !important", color: "#7a7e92" }}>
            {stakedAmount} {token?.token_symbol}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {voteWay === 1 && 
            <Typography 
              variant={"h4"} 
              sx={{
                alignItems: "left",
                justifyContent: "end",
                display: "flex",
                lineHeight: { md: "34px", mobile: "21px"},
              }}
            >
              YES
            </Typography>}
          {voteWay === 2 &&
            <Typography 
              variant={"h4"} 
              sx={{
                alignItems: "left",
                justifyContent: "end",
                display: "flex",
                lineHeight: { md: "34px", mobile: "21px"},
              }}
            >
              NO
            </Typography>}
          {voteWay === 0 && 
            <Typography 
              variant={"h4"} 
              sx={{
                alignItems: "left",
                justifyContent: "end",
                display: "flex",
                lineHeight: { md: "34px", mobile: "21px"},
              }}
            >
              NO
            </Typography>}
          <Box sx={{ display: {md: "none", mobile: "flex" }, alignItems: "right" }}>
            <Typography
              variant={"subtitle1"}
              sx={{ fontSize: "13px !important", color: "#7a7e92" }}
            >
              {moment(dateTime).format("DD MMM YYYY HH:mm")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const List = ({ DAO, items }) => {
  return (
    items?.length > 0 &&
    items.map((item, index) => (
      <Item
        token={DAO?.dao_token}
        index={index}
        dateTime={item?.datetime_created}
        avatar={item?.avatar_url}
        address={item?.voter_adress}
        stakedAmount={item?.voting_power_tokens_amount.toFixed(1)}
        profileUrl={item?.profileUrl}
        voteWay={item?.vote_decision_numeric}
      />
    ))
  );
};

export default List;
