import React from "react";
import Vote from "../../vote";

const Index = ({ DAO, limit, filter = "active", gridItem }) => {
  return (
    <Vote.List DAO={DAO} filter={filter} limit={limit} gridItem={gridItem} />
  );
};

export default Index;
