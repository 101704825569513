import React from "react";
import Crypto from "./crypto";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Box from "@mui/material/Box";
import Fiat from "./fiat";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { useUserContext } from "../../../../../@components/userContext";
import { useAccount } from "wagmi";
import LogInDialogFlow from "../../../../../profile/dialogs/LogInDialogFlow";
import CreateAccountDialog from "../../../../../profile/dialogs/RegisterDialog";
import { useConnectModal } from "@rainbow-me/rainbowkit";

const UserWithWallet = ({
  loggedInUser,
  onToggleEnablePurchase,
  collection,
  collectionId,
  DAO,
  onRequestPassword,
  onSuccess,
  isConnected,
}) => {
  const { openConnectModal } = useConnectModal();
  return (
    <>
      {loggedInUser && loggedInUser?.address !== "" && !isConnected && (
        <>
          <Crypto.Button
            onToggleEnablePurchase={onToggleEnablePurchase}
            title={"Get Free NFT"}
            collection={collection}
            collectionId={collectionId}
            DAO={DAO}
            onRequestPassword={onRequestPassword}
            onSuccess={onSuccess}
            startIcon={<AccountBalanceWalletIcon />}
          />
          <Typography
            variant={"subtitle1"}
            sx={{
              mt: "15px",
              display: "block",
            }}
          >
            <Alert variant="outlined" severity="info" sx={{ color: "#4DB8FF" }}>
              To get the NFT connect wallet.
              <Box
                sx={{
                  ml: "5px",
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                  color: "#944DFF",
                  fontWeight: "bold",
                }}
                onClick={() => openConnectModal()}
                component={"span"}
              >
                Connect
              </Box>
            </Alert>
          </Typography>
        </>
      )}
      {loggedInUser?.address !== "" && isConnected && (
        <Crypto.Button
          onToggleEnablePurchase={onToggleEnablePurchase}
          title={"Get Free NFT"}
          collection={collection}
          collectionId={collectionId}
          DAO={DAO}
          onRequestPassword={onRequestPassword}
          onSuccess={onSuccess}
          startIcon={<AccountBalanceWalletIcon />}
        />
      )}
    </>
  );
};

const UserWithoutWallet = ({
  loggedInUser,
  isConnected,
  onSuccess,
  onToggleEnablePurchase,
  onRequestPassword,
  collection,
}) => {
  return (
    <>
      {loggedInUser?.address === "" && !isConnected && (
        <Box
          sx={{ display: "flex", mt: "20px" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Fiat.Button
            isFreeMintNoWallet={true}
            onToggleEnablePurchase={onToggleEnablePurchase}
            title={"Get Free NFT"}
            collection={collection}
            onRequestPassword={onRequestPassword}
            onSuccess={onSuccess}
          />
        </Box>
      )}
    </>
  );
};

const FreeNFT = ({
  collection,
  collectionId,
  DAO,
  onToggleEnablePurchase,
  onRequestPassword,
  onSuccess,
}) => {
  const { user: loggedInUser } = useUserContext();
  const { isConnected } = useAccount();
  const [triggerLoginDialog, setTriggerLoginDialog] = React.useState(null);
  const [triggerCreateAccountDialog, setTriggerCreateAccountDialog] =
    React.useState(null);
  return (
    <Box sx={{ width: "100%" }}>
      <UserWithWallet
        loggedInUser={loggedInUser}
        collection={collection}
        collectionId={collectionId}
        onToggleEnablePurchase={onToggleEnablePurchase}
        onRequestPassword={onRequestPassword}
        DAO={DAO}
        onSuccess={onSuccess}
        isConnected={isConnected}
      />
      <UserWithoutWallet
        loggedInUser={loggedInUser}
        collection={collection}
        collectionId={collectionId}
        onToggleEnablePurchase={onToggleEnablePurchase}
        onRequestPassword={onRequestPassword}
        DAO={DAO}
        onSuccess={onSuccess}
        isConnected={isConnected}
      />
      {!loggedInUser && !isConnected && (
        <>
          <Box
            sx={{ display: "flex", mt: "20px", justifyContent: "center" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Fiat.Button
              isFreeMintNoWallet={true}
              onToggleEnablePurchase={onToggleEnablePurchase}
              title={"Get Free NFT"}
              collection={collection}
              onRequestPassword={onRequestPassword}
              onSuccess={onSuccess}
            />
          </Box>
          <Typography
            variant={"subtitle1"}
            sx={{
              mt: "15px",
              display: "block",
            }}
          >
            <Alert variant="outlined" severity="info" sx={{ color: "#4DB8FF" }}>
              To get the NFT
              <Box
                sx={{
                  ml: "5px",
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                  color: "#944DFF",
                  fontWeight: "bold",
                }}
                onClick={() => setTriggerLoginDialog(Math.random())}
                component={"span"}
              >
                Log In
              </Box>{" "}
              or
              <Box
                sx={{
                  ml: "5px",
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                  color: "#944DFF",
                  fontWeight: "bold",
                }}
                onClick={() => setTriggerCreateAccountDialog(Math.random())}
                component={"span"}
              >
                Create Account
              </Box>
            </Alert>
          </Typography>
        </>
      )}
      <LogInDialogFlow
        trigger={triggerLoginDialog}
        onClose={() => setTriggerLoginDialog(null)}
      />

      <CreateAccountDialog
        toggleOpen={triggerCreateAccountDialog}
        onRegister={() => {
          setTriggerCreateAccountDialog(null);
        }}
        showLogInDialog={() => {
          setTriggerCreateAccountDialog(Math.random());
          setTriggerLoginDialog(Math.random());
        }}
      />
    </Box>
  );
};

export default FreeNFT;
