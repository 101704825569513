import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import UI from "../../../@components/UI";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useAccount } from "wagmi";
import useService from "./useService";
import { useSnackbar } from "notistack";
import Alert from "@mui/material/Alert";
import CustomHooks from "../../../@components/hooks";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import Proposals from "../proposal";
import { useUserRole } from "../context";

const useStyles = makeStyles(() => ({
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },
  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Create = ({ DAO }) => {
  const { enqueueSnackbar } = useSnackbar();
  const service = useService(DAO?.id);
  const history = useHistory();
  const classes = useStyles();
  const { isConnected } = useAccount();
  const [isUserValid, setIsUserValid] = React.useState(null);
  const { isBrandMember } = useUserRole();
  const [existingUser, setExistingUser] = React.useState(null);
  CustomHooks.useProtectedRoute(DAO, isBrandMember);

  const { mutateAsync: addMember, isLoading } = CustomHooks.usePost(
    service.add,
    {
      onSuccess: () => {
        enqueueSnackbar("Succesfully added to members", {
          variant: "success",
        });
        history.push({
          pathname: `/brand/${DAO?.id}/membership`,
          state: { scrollToTop: true },
        });
      },
    }
  );

  const { data: existingMembers, refetch } = CustomHooks.useFetch(
    "members",
    service.list,
    {
      enabled: false,
    }
  );

  React.useEffect(() => {
    DAO && refetch();
  }, [DAO, refetch]);

  const { mutate: checkUser } = CustomHooks.usePost(service.checkUser, {
    onSuccess: (response) => {
      const { address } = response;

      setValue("address", address, {
        shouldValidate: true,
      });
      if (!address) {
        setError("address", { type: "required" }, { shouldFocus: true });
      }
      const ifExistingUser = existingMembers?.find(
        (member) => member.user.address === address
      );
      if (ifExistingUser) {
        enqueueSnackbar("User is already a member", {
          variant: "error",
        });
        return;
      }
      setExistingUser(response);
      setIsUserValid(true);
    },
    onError: (e) => {
      setIsUserValid(false);
    },
  });

  const {
    control,
    setValue,
    setError,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: "",
      role: "admin",
      token_amount: 1,
    },
  });

  const emailValue = useWatch({ control, name: "email" });
  const roleValue = useWatch({ control, name: "role" });
  const tokenAmount = useWatch({ control, name: "token_amount" });
  const addressValue = useWatch({ control, name: "address" });

  const onSubmit = async (form) => {
    const { email, role } = form;
    await addMember({ email, role });
  };

  return (
    <Paper
      elevation={0}
      sx={{ width: "100%", background: "rgba(27, 30, 47, 0.5)" }}
    >
      <UI.Busy.FullscreenIndicator show={isLoading} />
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", mobile: "column" },
          justifyContent: { md: "space-between", mobile: "center" },
          px: { md: "70px", mobile: "0px" },
          pb: "20px",
          pt: { md: "55px", mobile: "30px" },
        }}
      >
        <UI.BackButton
          fontSize={"14px !important"}
          title={"BACK TO MEMBERSHIP"}
          onClick={() =>
            history.push({
              pathname: `/brand/${DAO?.id}/membership`,
              state: { scrollToTop: true },
            })
          }
        />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: "100%",
            px: { md: "80px", mobile: "10px" },
            pb: "75px",
          }}
        >
          <Typography
            variant={"h1"}
            sx={{
              fontSize: "30px !important",
              lineHeight: "20px !important",
              mb: "25px",
              mt: "10px",
            }}
          >
            ADD MEMBER
          </Typography>

          <Typography variant={"subtitle1"}>
            Expand the brand by adding a new member! You have the option to
            choose between three roles. Please note that all roles require the
            individual to have a Qvrse account. Additionally, it is essential to
            have their Qvrse accounts connected with their blockchain wallets,
            as they will need to perform signing actions.
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item mobile={12} md={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px", mt: "30px" }}>
                  <Typography
                    variant={"h2"}
                    sx={{ fontSize: "18px !important" }}
                  >
                    EMAIL ADDRESS
                  </Typography>
                  <UI.Tooltip>
                    Provide the email address of the brand member you’d like to
                    add.
                  </UI.Tooltip>
                </Box>

                <Controller
                  name={"email"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.TextField
                      {...field}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          checkUser(emailValue);
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <Box sx={{ width: "210px" }}>
                            <UI.Button
                              title={"Check user"}
                              type={"button"}
                              startIcon={<PersonSearchIcon />}
                              onClick={() => checkUser(emailValue)}
                            />
                          </Box>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                  {errors.amount?.type === "required" && (
                    <Alert
                      sx={{ color: (theme) => theme.palette.colors.darkRed }}
                      variant="outlined"
                      severity="error"
                    >
                      Amount is required
                    </Alert>
                  )}
                </Typography>

                {isUserValid == null && (
                  <Typography variant={"subtitle1"} sx={{ mt: "5px" }}>
                    <Alert
                      variant="outlined"
                      severity="info"
                      sx={{ color: "#4DB8FF" }}
                    >
                      {`To be added as a member, a user must have a QVRSE account.`}
                    </Alert>
                  </Typography>
                )}
                {isUserValid === false && (
                  <Typography variant={"subtitle1"} sx={{ mt: "5px" }}>
                    <Alert
                      variant="outlined"
                      severity="error"
                      sx={{ color: (theme) => theme.palette.colors.darkRed }}
                    >
                      {`User does not exist. Add user to the QVRSE first and try again.`}
                    </Alert>
                  </Typography>
                )}
                {isUserValid === true && (
                  <Typography variant={"subtitle1"} sx={{ mt: "5px" }}>
                    <Alert variant="outlined" severity="success">
                      {`Great news! We have found the user you were looking for. You can now proceed with the next step.`}
                    </Alert>
                  </Typography>
                )}
              </Box>
            </Grid>
            {isUserValid && (
              <Grid item mobile={12} md={12}>
                <Box className={classes.inputOuter}>
                  <Box sx={{ display: "flex", mb: "10px" }}>
                    <Typography
                      variant={"h2"}
                      sx={{ fontSize: "18px !important" }}
                    >
                      Wallet Address
                    </Typography>
                    <UI.Tooltip>
                      Wallet address of the new member you’d like to add.
                    </UI.Tooltip>
                  </Box>

                  <Controller
                    name={"address"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <UI.TextField
                        disabled
                        {...field}
                        placeholder={"This field is auto generated"}
                      />
                    )}
                  />
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors.address?.type === "required" && (
                      <Alert
                        sx={{ color: (theme) => theme.palette.colors.darkRed }}
                        variant="outlined"
                        severity="error"
                      >
                        The user's account is not connected with a wallet yet.
                        Please inform the user to connect their wallet with
                        their account before they can be added as a member.
                      </Alert>
                    )}
                  </Typography>
                </Box>
              </Grid>
            )}
            {isUserValid && (
              <Grid item mobile={12} md={12}>
                <Box className={classes.inputOuter}>
                  <Box sx={{ display: "flex", mb: "10px" }}>
                    <Typography
                      variant={"h2"}
                      sx={{ fontSize: "18px !important" }}
                    >
                      Role
                    </Typography>
                    <UI.Tooltip>
                      Assign the appropriate role to the new member.
                    </UI.Tooltip>
                  </Box>

                  <Controller
                    name={"role"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <UI.Select {...field}>
                        <MenuItem value={"admin"}>Admin</MenuItem>
                        <MenuItem value={"moderator"}>Moderator</MenuItem>
                        <MenuItem value={"ticket_redeemer"}>
                          Ticket Redeemer
                        </MenuItem>
                        )
                      </UI.Select>
                    )}
                  />

                  <Typography
                    variant={"subtitle1"}
                    sx={{
                      fontSize: "13px !important",
                      lineHeight: "17.5px",
                      mt: "12px",
                    }}
                  >
                    <span>
                      <Box
                        component={"span"}
                        sx={{
                          fontWeight: "bold",
                          color: "#944dff",
                          fontSize: "16px !important",
                        }}
                      >
                        **
                      </Box>{" "}
                      <Box component={"span"} sx={{ fontWeight: "bold" }}>
                        1. ADMIN:
                      </Box>{" "}
                      Full control and authority over the brand. For security
                      reasons, the proposal to appoint a new admin undergoes a
                      voting process by existing admins.{" "}
                      <Box component={"span"} sx={{ fontWeight: "bold" }}>
                        2. MODERATOR:
                      </Box>{" "}
                      All admin privileges, except for voting on brand
                      proposals.{" "}
                      <Box component={"span"} sx={{ fontWeight: "bold" }}>
                        3. TICKET REDEEMER:
                      </Box>{" "}
                      Only NFT perks redeem.{" "}
                    </span>
                  </Typography>

                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors.role?.type === "required" && (
                      <Alert
                        sx={{ color: (theme) => theme.palette.colors.darkRed }}
                        variant="outlined"
                        severity="error"
                      >
                        Role is required
                      </Alert>
                    )}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
          <Box
            sx={{
              width: "100%",
              mt: "45px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {roleValue !== "admin" && (
                <Box sx={{ width: "210px" }}>
                  <UI.Button
                    disabled={
                      !isConnected ||
                      !isUserValid ||
                      Object.keys(errors).length > 0
                    }
                    title={"Add Member"}
                    type={"primary"}
                  />
                </Box>
              )}
              {roleValue === "admin" && (
                <Proposals.AddMember
                  DAO={DAO}
                  emailAddress={emailValue}
                  amountTokens={tokenAmount}
                  walletAddress={addressValue}
                  userProfileImage={existingUser?.avatar_url}
                  isUserValid={isUserValid}
                  errors={errors}
                />
              )}
            </Box>
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default Create;
