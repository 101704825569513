import Gateway from "../../../@components/gateway";

const useService = (daoId) => {
  const gateway = Gateway.useGateway();

  const save = (data) => {
    return gateway.post(`dao/${daoId}/proposal/`, data);
  };

  const proposals = async (statusList) => {
    if (statusList && statusList.length > 0) {
      return proposalsByStatus(statusList);
    } else {
      return gateway.get(`dao/${daoId}/proposal/all/`);
    }
  };

  const proposalsByStatus = async (statusList = []) => {
    return gateway.post(`dao/${daoId}/proposal/votes_by_status/`, {
      status_list: statusList,
    });
  };

  const setProposalExecuted = async (proposalId, data) => {
    return gateway.post(`dao/${daoId}/proposal/${proposalId}/execute/`, data);
  };

  const proposal = async (id) => {
    return gateway.get(`dao/${daoId}/proposal/${id}/`);
  };

  return {
    save,
    proposals,
    proposal,
    setProposalExecuted,
  };
};

export default useService;
