import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import UI from "../../@components/UI";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import { Controller, useForm, useWatch } from "react-hook-form";
import Alert from "@mui/material/Alert";
import useHandleErrors from "../../@components/UI/useHandleErrors";
import { useSnackbar } from "notistack";
import useService from "../useService";
import { useUserContext } from "../../@components/userContext";
import Auth from "../../@components/auth";
import googleLoginIcon from "../../static/google-login.png";
import { useGoogleLogin } from "@react-oauth/google";

const RegisterDialog = ({ toggleOpen, onRegister, showLogInDialog }) => {
  const { register, registerWithGoogle } = Auth.useAuth();
  const { setUser: setUserContext } = useUserContext();
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const { handleApiError } = useHandleErrors();
  const { enqueueSnackbar } = useSnackbar();
  const service = useService();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
      termsAndConditions: false,
    },
    mode: "all",
  });

  const password = useWatch({ control, name: "password" });

  const sendConfirmationEmail = async () => {
    await service
      .sendConfirmationEmail()
      .then(() => {
        onRegister();
        setIsLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const googleAuth = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await googleRegister(tokenResponse.access_token);
    },
  });

  const googleRegister = async (access_token) => {
    setError(null);
    setIsLoading(true);
    await registerWithGoogle(access_token)
      .then((response) => {
        const { user } = response;
        setUserContext(user);

        enqueueSnackbar("User was registered successfully", {
          variant: "success",
        });
        setIsLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setError("Account does not exists");
        } else {
          setError(error?.response?.data.message);
        }
        setIsLoading(false);
      });
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const data = {
      email: formData.email,
      password_1: formData.password,
      password_2: formData.confirmPassword,
    };

    await register(data)
      .then(async (response) => {
        const { user } = response;
        enqueueSnackbar("User was registered successfully", {
          variant: "success",
        });

        setUserContext(user);
        await sendConfirmationEmail();
      })
      .catch((error) => {
        handleApiError(error);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);
  const handleClose = () => {
    reset();
    setOpen(false);
  };
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          backgroundColor: "#1B1E2F !important",
          padding: { md: "40px 100px 50px", mobile: "30px 10px" },
          width: "700px",
          margin: { mobile: "0px" },
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ p: { mobile: "10px", md: "0px" } }}>
        <CloseIcon
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 30,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Typography
                variant={"h6"}
                sx={{ fontSize: "30px", textAlign: "center" }}
              >
                Create Account
              </Typography>
              <Typography
                variant={"subtitle1"}
                sx={{ fontSize: "15px !important", textAlign: "center" }}
              >
                Already have an account?{" "}
                <Box
                  onClick={() => {
                    reset();
                    showLogInDialog();
                  }}
                  component={"span"}
                  sx={{
                    cursor: "pointer",
                    color: (theme) => theme.palette.colors.primary,
                  }}
                >
                  Log In
                </Box>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { md: "row", mobile: "column" },
                  justifyContent: { md: "center", mobile: "center" },
                  mt: "40px",
                  height: "44px",
                }}
              >
                <Box
                  onClick={() => googleAuth()}
                  sx={{
                    margin: { mobile: "0 auto", md: "unset" },
                    cursor: "pointer",
                    display: "flex",
                    width: "256px",
                    p: "10px 20px",
                    alignItems: "center",
                    backgroundColor: "rgba(51, 54, 69, 1);",
                    border: "1px solid transparent",
                    "&:hover": {
                      border: "1px solid transparent",
                      filter: "drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.10))",
                      transition: "all 0.2s ease",
                      backgroundColor: "#616479",
                    },
                  }}
                >
                  <Box
                    component={"img"}
                    src={googleLoginIcon}
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <Typography
                    variant={"h2"}
                    sx={{
                      ml: "15px",
                      fontSize: "16px !important",
                      textTransform: "uppercase",
                    }}
                  >
                    Create with google
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  my: "30px",
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <Box
                  component={"span"}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    borderBottom: "1px solid #303346",
                    width: "130px",
                    left: { md: "90px", mobile: "20px" },
                  }}
                />
                <Typography variant={"subtitle2"} sx={{ mx: "20px" }}>
                  or
                </Typography>
                <Box
                  component={"span"}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    borderBottom: "1px solid #303346",
                    width: "130px",
                    right: { md: "90px", mobile: "20px" },
                  }}
                />
              </Box>
              <Box sx={{ mt: "30px", px: "10px" }}>
                <Box>
                  <Controller
                    name={"email"}
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message:
                          "Please enter a valid email address. (e.g. user@example.com)",
                      },
                    }}
                    render={({ field }) => (
                      <UI.TextField
                        {...field}
                        backgroundcolor={"#303346"}
                        fullWidth
                        placeholder={"Email"}
                      />
                    )}
                  />
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors.email && (
                      <Alert
                        sx={{
                          color: (theme) => theme.palette.colors.darkRed,
                        }}
                        variant="outlined"
                        severity="error"
                      >
                        {errors.email?.type === "pattern" &&
                          errors.email.message}
                        {errors.email?.type === "required" &&
                          "Email is required"}
                      </Alert>
                    )}
                  </Typography>
                </Box>
                <Box sx={{ mt: "16px" }}>
                  <Controller
                    name={"password"}
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: /^.{8,}$/,
                        message: "Password must be at least 8 characters long",
                      },
                    }}
                    render={({ field }) => (
                      <UI.PasswordField
                        {...field}
                        backgroundcolor={"#303346"}
                        fullWidth
                        placeholder={"Password"}
                      />
                    )}
                  />
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors.password && (
                      <Alert
                        sx={{
                          color: (theme) => theme.palette.colors.darkRed,
                        }}
                        variant="outlined"
                        severity="error"
                      >
                        {errors.password?.type === "pattern" &&
                          errors.password.message}
                        {errors.password?.type === "required" &&
                          "Password is required"}
                      </Alert>
                    )}
                  </Typography>
                </Box>
                <Box sx={{ mt: " 16px" }}>
                  <Controller
                    name={"confirmPassword"}
                    control={control}
                    rules={{
                      required: true,
                      validate: (value) =>
                        value === password || "Password does not match",
                    }}
                    render={({ field }) => (
                      <UI.PasswordField
                        {...field}
                        backgroundcolor={"#303346"}
                        fullWidth
                        placeholder={"Confirm Password"}
                      />
                    )}
                  />
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors.confirmPassword && (
                      <Alert
                        sx={{
                          color: (theme) => theme.palette.colors.darkRed,
                        }}
                        variant="outlined"
                        severity="error"
                      >
                        {errors.confirmPassword?.type === "validate" &&
                          errors.confirmPassword.message}
                        {errors.confirmPassword?.type === "required" &&
                          "Password confirm is required"}
                      </Alert>
                    )}
                  </Typography>
                </Box>
                <Box sx={{ mt: "26px" }}>
                  <Box sx={{ display: "flex" }}>
                    <Controller
                      name={"termsAndConditions"}
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <UI.Checkbox
                          {...field}
                          sx={{ mr: { mobile: "0px", md: "16px" } }}
                        />
                      )}
                    />
                    <Typography
                      variant={"subtitle1"}
                      sx={{ fontSize: "16px !important" }}
                    >
                      By creating an account you agree to our Terms & Conditions
                      and Privacy Policy.
                    </Typography>
                  </Box>
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors.termsAndConditions?.type === "required" && (
                      <Alert
                        sx={{
                          color: (theme) => theme.palette.colors.darkRed,
                        }}
                        variant="outlined"
                        severity="error"
                      >
                        Terms and Conditions is required
                      </Alert>
                    )}
                  </Typography>
                </Box>
                <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                  {error && (
                    <Alert
                      sx={{
                        color: (theme) => theme.palette.colors.darkRed,
                      }}
                      variant="outlined"
                      severity="error"
                    >
                      {error}
                    </Alert>
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: { md: "40px", mobile: "40px" },
                  px: "15px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <UI.Button
                  disabled={Object.keys(errors).length}
                  sx={{ width: "210px" }}
                  title={"Create Account"}
                  type={"primary"}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle2"}>Register in progress ...</Typography>
        <Typography variant={"subtitle2"}>
          Please do not close this window.
        </Typography>
      </UI.Busy.FullscreenIndicator>
    </MaterialDialog>
  );
};

export default RegisterDialog;
