import React from "react";
import Box from "@mui/material/Box";
import Utility from "../../daos/@id/proposal/collection/extraUtilities";
import UI from "../../@components/UI";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import LinkIcon from "@mui/icons-material/Link";
import Tooltip from "@mui/material/Tooltip";
import discordIcon from "../../static/discord-icon.png";
import DiscordAccessDialog from "../dialogs/DiscordAccessDialog";
import useMediaQuery from "@mui/material/useMediaQuery";

const Utilities = ({ collection }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <>
      {collection?.utilities ? (
        <Box>
          {collection?.utilities?.map((item, index) => {
            const customAction = item.custom_info_fields?.find(
              (field) => field.input_type === "button"
            );

            const allAccordionItems = (
              <Box>
                <Box sx={{ display: { mobile: "block", md: "none" } }}>
                  <Box
                    sx={{ mb: "25px", display: "flex", alignItems: "center" }}
                  >
                    <UI.UtilityTag
                      utilityType={item?.type}
                      isMainUtility={item?.is_main_utility}
                    />
                    {customAction && isMobile && (
                      <Box
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          window.open(customAction.value, "_blank");
                        }}
                        sx={{
                          ml: "10px",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Tooltip title={customAction.title} arrow>
                          <Box
                            sx={{
                              width: "32px",
                              height: "32px",
                              borderRadius: "50%",
                              backgroundColor: "rgba(148, 77, 255, 1);",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <LinkIcon />
                          </Box>
                        </Tooltip>
                      </Box>
                    )}
                    {item.type === "discord_access" && isMobile && (
                      <Box
                        sx={{
                          ml: "10px",
                          display: "flex",
                          cursor: "pointer",
                        }}
                      >
                        <Tooltip title={"Discord"} arrow>
                          <Box
                            sx={{ my: "5px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <DiscordAccessDialog.Action
                              item={collection}
                              element={
                                <Box
                                  sx={{
                                    width: "32px",
                                    height: "32px",
                                    borderRadius: "50%",
                                    backgroundColor: "rgba(148, 77, 255, 1);",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Box
                                    component={"img"}
                                    src={discordIcon}
                                    sx={{
                                      width: "23px",
                                      height: "23px",
                                      filter: "invert(1)",
                                    }}
                                  />
                                </Box>
                              }
                            />
                          </Box>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ mb: "5px" }}>
                    <Utility.AccordionItem
                      key={2}
                      title={"Title"}
                      value={item?.name}
                    />
                  </Box>
                </Box>
                {item?.description && (
                  <Utility.AccordionItem
                    key={2}
                    title={"Description"}
                    value={
                      <UI.ReadMore
                        sx={{
                          fontFamily: "Quantico",
                          overflowWrap: "anywhere",
                          textTransform: "unset",
                          fontWeight: "bold",
                          opacity: 1,
                          color: "rgba(255, 255, 255, 1)",
                        }}
                        maxTextLength={180}
                      >
                        {item?.description}
                      </UI.ReadMore>
                    }
                  />
                )}
                {item?.custom_info_fields?.map((field) => {
                  if (field?.type || field?.input_type === "button") {
                    return null;
                  }
                  return (
                    <Utility.AccordionItem
                      key={field?.id}
                      title={field?.title}
                      value={field?.value}
                      type={field?.type || field?.input_type}
                    />
                  );
                })}

                <Utility.AccordionItem
                  key={1}
                  title={"Price"}
                  value={`${item?.price} ${collection?.currency}`}
                />
              </Box>
            );

            return (
              <Box
                sx={{
                  mb: "15px",
                  border: item?.is_main_utility ? "2px solid #944dff" : "none",
                  boxShadow: item?.is_main_utility
                    ? "0px 0px 10px 0px #944dff"
                    : "none",
                }}
                key={index}
              >
                <UI.Accordion
                  sx={{ background: "rgba(17, 20, 34, 0.7)" }}
                  readonly
                  title={
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant={"h4"}
                          sx={{
                            mr: "15px",
                            maxWidth: { mobile: "250px", md: "600px" },
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <UI.OverflowTip> {item?.name}</UI.OverflowTip>
                        </Typography>
                        {customAction && !isMobile && (
                          <Box
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              window.open(customAction.value, "_blank");
                            }}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <Tooltip title={customAction.title} arrow>
                              <Box
                                sx={{
                                  width: "32px",
                                  height: "32px",
                                  borderRadius: "50%",
                                  backgroundColor: "rgba(148, 77, 255, 1);",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <LinkIcon />
                              </Box>
                            </Tooltip>
                          </Box>
                        )}
                        {item.type === "discord_access" && !isMobile && (
                          <Box
                            sx={{
                              ml: "10px",
                              display: "flex",
                              cursor: "pointer",
                            }}
                          >
                            <Tooltip title={"Discord"} arrow>
                              <Box
                                sx={{ my: "5px" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <DiscordAccessDialog.Action
                                  item={collection}
                                  element={
                                    <Box
                                      sx={{
                                        width: "32px",
                                        height: "32px",
                                        borderRadius: "50%",
                                        backgroundColor:
                                          "rgba(148, 77, 255, 1);",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Box
                                        component={"img"}
                                        src={discordIcon}
                                        sx={{
                                          width: "23px",
                                          height: "23px",
                                          filter: "invert(1)",
                                        }}
                                      />
                                    </Box>
                                  }
                                />
                              </Box>
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{
                          mr: "20px",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "100px",
                          justifyContent: "center",
                          backgroundColor: item?.is_redeemed
                            ? "rgba(255, 193, 68, 1);"
                            : "rgba(80, 175, 149, 1);",
                          width: "73px",
                          height: "23px",
                          p: "4px 14px",
                        }}
                      >
                        <Typography
                          variant={"h2"}
                          sx={{
                            fontSize: "10px !important",
                            lineHeight: "normal",
                          }}
                        >
                          {item?.is_redeemed ? "Redeemed" : "Available"}
                        </Typography>
                      </Box>
                    </Box>
                  }
                >
                  {allAccordionItems}
                </UI.Accordion>
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box>
          <Box sx={{ mb: "15px" }} key={1}>
            <UI.Accordion
              sx={{ background: "rgba(17, 20, 34, 0.7)" }}
              readonly
              title={
                <Skeleton
                  height={"30px"}
                  width={"100px"}
                  variant={"rectangular"}
                />
              }
            />
          </Box>
          <Box sx={{ mb: "15px" }} key={2}>
            <UI.Accordion
              sx={{ background: "rgba(17, 20, 34, 0.7)" }}
              readonly
              title={
                <Skeleton
                  height={"30px"}
                  width={"100px"}
                  variant={"rectangular"}
                />
              }
            />
          </Box>
          <Box sx={{ mb: "15px" }} key={3}>
            <UI.Accordion
              sx={{ background: "rgba(17, 20, 34, 0.7)" }}
              readonly
              title={
                <Skeleton
                  height={"30px"}
                  width={"100px"}
                  variant={"rectangular"}
                />
              }
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Utilities;
