import React from "react";
import Box from "@mui/material/Box";
import SettingsIcon from "@mui/icons-material/Settings";
import { useHistory, useLocation } from "react-router-dom";

const SettingsButton = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#1B1E2F",
          borderRadius: "50%",
          marginLeft: "15px",

          width: "38px",
          height: "38px",
          transition: "all 0.5s ease-in-out",
          boxShadow: location.pathname.startsWith(`/profile/settings`)
            ? "#944dff 0px 0px 10px 0px"
            : "none",
          border: location.pathname.startsWith(`/profile/settings`)
            ? "1px solid #944dff"
            : "1px solid transparent",
          transform: location.pathname.startsWith(`/profile/settings`)
            ? "scale(1.1) rotate(-90deg)"
            : "scale(1) rotate(0deg)",

          "&:hover": {
            boxShadow: "#944dff 0px 0px 10px 0px",
            border: "1px solid #944dff",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      >
        <SettingsIcon
          sx={{ cursor: "pointer" }}
          onClick={() => {
            history.push({
              pathname: `/profile/settings`,
              state: { scrollToTop: false },
            });
          }}
        />
      </Box>
    </>
  );
};

export default SettingsButton;
