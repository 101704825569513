import React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UI from "../../../../@components/UI";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const CollectInfoDialog = ({ open, title, onClose, onAdd }) => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      placeholder: "",
      tooltip: "",
    },
    mode: "all",
  });

  const onSubmit = (values) => {
    onAdd(values);
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "800px",
          p: { md: "50px", mobile: "30px 20px" },
          margin: { md: "32px", mobile: "10px" },
          backgroundColor: (theme) => theme.palette.colors.darkGray,
        },
      }}
      onClose={() => {
        reset();
        onClose();
      }}
      open={open}
    >
      <CloseIcon
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 30,
          top: 30,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <form>
        <Typography
          sx={{ display: { md: "flex", mobile: "none" } }}
          variant={"h2"}
        >
          {title}
        </Typography>
        <Typography
          sx={{ lineHeight: "20px", display: { md: "none", mobile: "flex" } }}
          variant={"h4"}
        >
          {title}
        </Typography>
        <Box sx={{ mt: "20px" }}>
          <Box>
            <Typography variant={"subtitle1"} sx={{fontSize:"16px !important"}}>
              Enter the additional information you need to ask your customers.
            </Typography>
            <Typography variant={"subtitle1"}></Typography>
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", my: "10px" }}>
              <Typography variant={"h4"}>INFO TYPE</Typography>
              <UI.Tooltip>
                Enter the title of the additional information you need from your
                customers.{" "}
              </UI.Tooltip>
            </Box>
            <Controller
              name={"title"}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UI.TextField
                  {...field}
                  backgroundcolor={"#303346"}
                  placeholder={"e.g. T-SHIRT SIZE"}
                />
              )}
            />
            {errors.title?.type === "required" && (
              <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                <Alert
                  sx={{ color: (theme) => theme.palette.colors.darkRed }}
                  variant="outlined"
                  severity="error"
                >
                  Info type is required
                </Alert>
              </Typography>
            )}
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h4"}>POSSIBILE ANSWERS</Typography>
              <UI.Tooltip>Enter the question.</UI.Tooltip>
            </Box>
            <Controller
              name={"placeholder"}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UI.TextField
                  {...field}
                  backgroundcolor={"#303346"}
                  placeholder={"e.g. S,M,L,XL"}
                />
              )}
            />
            {errors.placeholder?.type === "required" && (
              <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                <Alert
                  sx={{ color: (theme) => theme.palette.colors.darkRed }}
                  variant="outlined"
                  severity="error"
                >
                  Possible answers is required
                </Alert>
              </Typography>
            )}
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h4"}>INFO’S TOOLTIP</Typography>
              <UI.Tooltip>
                Enter the information for the question’s question mark.
              </UI.Tooltip>
            </Box>
            <Controller
              name={"tooltip"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <UI.TextField
                  {...field}
                  backgroundcolor={"#303346"}
                  placeholder={"e.g. Enter your T-Shirt size"}
                />
              )}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: "50px" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <UI.Button
              type={"primary"}
              onClick={handleSubmit(onSubmit)}
              title={"Add Field"}
              sx={{
                width: "150px",
                height: "50px",
                filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
              }}
            />
          </Box>
        </Box>
      </form>
    </Dialog>
  );
};

const Action = ({ element: Component, title, onAdd }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Box component={"span"} onClick={() => setOpen(!open)}>
        {Component}
      </Box>

      <CollectInfoDialog
        open={open}
        title={title}
        onClose={() => setOpen(false)}
        onAdd={(values) => {
          setOpen(false);
          onAdd(values);
        }}
      />
    </>
  );
};

CollectInfoDialog.Action = Action;

export default CollectInfoDialog;
