import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../@components/UI";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";

const Iframe = ({ url }) => {
  return (
    <Box>
      <iframe
        title={"animation"}
        style={{ border: "none" }}
        src={url}
        width="100%"
        height="300px"
      />
    </Box>
  );
};

const Ticket = ({
  brandName,
  brandAvatar,
  name,
  data,
  fileType,
  mainUtility,
  animationUrl,
}) => {
  const [previewfile, setPreviewfile] = React.useState(null);
  return (
    <Box
      sx={{
        width: "300px",
        "-webkit-transform": "translateZ(0)",
        "-webkit-perspective": "1000",
        "-webkit-backface-visibility": "hidden",

        boxShadow: "0px 0px 20px #944DFF",
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.colors.darkGray,
          border: "2px solid #944DFF",
          borderBottom: "none",
          padding: "20px",
          height: "88px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box>
              {brandName ? (
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    fontSize: "13px !important",
                    color: "#7A7E92",
                    maxWidth: "185.4px",
                  }}
                >
                  <UI.OverflowTip>{`${brandName}`}</UI.OverflowTip>
                </Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"130px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {name ? (
              <Typography
                variant={"h2"}
                sx={{
                  lineHeight: "unset !important",
                  fontSize: "1rem !important",
                  maxWidth: "185.4px",
                }}
              >
                <UI.OverflowTip>{`${name}`}</UI.OverflowTip>
              </Typography>
            ) : (
              <Skeleton
                height={"30px"}
                width={"130px"}
                variant={"rectangular"}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar src={brandAvatar} alt={"avatar"} />
          </Box>
        </Box>
      </Box>
      <Box
        onClick={() => setPreviewfile(data)}
        sx={{
          cursor: "pointer",
          textAlign: "center",
          height: "300px",
          width: "300px",
          overflow: "hidden",
          borderLeft: "2px solid #944DFF",
          borderRight: "2px solid #944DFF",
        }}
      >
        <Box
          sx={{
            height: "300px",
            width: "100%",
            position: "relative",
          }}
        >
          <Box
            component={"span"}
            sx={{
              boxSizing: "border-box",
              display: "block",
              overflow: "hidden",
              width: "initial",
              height: "initial",
              background: "none",
              opacity: 1,
              border: "0px",
              margin: "0px",
              padding: "0px",
              position: "absolute",
              inset: 0,
              lineHeight: "0",
              fontSize: "0",
            }}
          >
            {animationUrl === null ? (
              data ? (
                fileType?.startsWith("video") ? (
                  <video
                    playsInline
                    width="100%"
                    height={"auto"}
                    autoplay="autoplay"
                    loop
                    muted
                  >
                    <source src={data} />
                  </video>
                ) : (
                  <UI.ImageWithSkeleton
                    sx={{
                      position: "absolute",
                      inset: 0,
                      boxSizing: "border-box",
                      padding: 0,
                      border: "none",
                      margin: "auto",
                      display: "block",
                      width: 0,
                      height: 0,
                      minWidth: "100%",
                      maxWidth: "100%",
                      minHeight: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                    src={data}
                    width={"100%"}
                    alt={"slider-item"}
                  />
                )
              ) : (
                <Skeleton
                  height={"300px"}
                  width={"300px"}
                  variant={"rectangular"}
                />
              )
            ) : (
              <Iframe url={animationUrl} />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.colors.darkGray,
          border: "2px solid #944DFF",
          borderTop: "none",
          padding: "20px",
          height: "88px",

          alignItems: "center",
          display: "flex",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <UI.UtilityTag utilityType={mainUtility?.type} />
          <Box
            sx={{
              ml: "10px",
              borderRadius: "100px",
              backgroundColor: "#50AF95",
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              p: "4px 14px",
              height: "23px"
            }}
          >
            <Typography
              variant={"h2"}
              sx={{
                fontSize: "10px !important",
                lineHeight: "unset",
              }}
            >
              LIMITED
            </Typography>
          </Box>
        </Box>
      </Box>
      <Dialog
        onClose={() => setPreviewfile(null)}
        open={!!previewfile}
        sx={{
          "& .MuiPaper-root": {
            maxWidth: "1200px",
          },
        }}
        PaperProps={{
          maxWidth: "1200px",
        }}
      >
        {previewfile && fileType?.startsWith("video") && (
          <video
            playsInline
            width="100%"
            height={"auto"}
            autoplay="autoplay"
            loop
          >
            <source src={previewfile} />
          </video>
        )}
        {previewfile && fileType?.startsWith("image") && (
          <Box
            component={"img"}
            sx={{
              maxHeight: { md: "600px", mobile: "auto" },
              width: { md: "auto", mobile: "100%" },
              margin: "0 auto",
            }}
            src={previewfile}
          />
        )}
      </Dialog>
    </Box>
  );
};

export default React.memo(Ticket);
