import React from "react";
import Box from "@mui/material/Box";
import useCollectionService from "../../collection/useService";
import CollectionSlider from "./CollectionSlider";
import UI from "../../../../@components/UI";
import { useHistory, useLocation } from "react-router-dom";
import CustomHooks from "../../../../@components/hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import Busy from "../../../../@components/UI/busy";
import { useUserRole } from "../../context";

const Index = ({ DAO }) => {
  const { isBrandMember } = useUserRole();
  const history = useHistory();
  const location = useLocation();
  const collectionService = useCollectionService(DAO?.id);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const {
    data: items,
    isLoading: collectionsLoading,
    refetch: fetchCollections,
  } = CustomHooks.useFetch("collections", collectionService.list, {
    enabled: false,
    select: (response) => {
      // Filter the items based on the visibility condition
      const filteredItems = response.filter((item) => {
        // Return all items if user has ADMIN or MODERATOR role
        if (isBrandMember) {
          return true;
        }
        // Filter out items that have item.visible = false
        return item.visible !== false;
      });

      return filteredItems;
    },
  });

  React.useEffect(() => {
    if (DAO) {
      fetchCollections();
    }
  }, [DAO, fetchCollections]);

  return (
    <>
      <Box sx={{ mt: "10px" }}>
        {!isMobile && (
          <>
            {!collectionsLoading && items && items.length > 0 && (
              <CollectionSlider items={items} busy={collectionsLoading} />
            )}
            {collectionsLoading && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Busy.Indicator />
              </Box>
            )}
            {!collectionsLoading && items && items.length === 0 && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <UI.NoResults text={"No collections found :("} />
              </Box>
            )}
          </>
        )}
        {isMobile && (
          <>
            {items && items.length !== 0 && <UI.SwiperMobile items={items} />}
            {collectionsLoading && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Busy.Indicator />
              </Box>
            )}
            {!collectionsLoading && items && items.length === 0 && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <UI.NoResults text={"No collections found :("} />
              </Box>
            )}
          </>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: { md: "center", mobile: "center" },
          position: "relative",
          mt: { md: "0px", mobile: "60px" },
        }}
      >
        <Box
          sx={{
            display: { md: "flex", mobile: "flex" },
            mb: { md: "80px", mobile: "10px" },
            mt: { md: "40px", mobile: "0px" }
          }}
        >
          <Box sx={{ width: "178px" }}>
            <UI.Button
              onClick={() => history.push(`${location.pathname}/collection`)}
              title={"view all"}
              type={"primary"}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Index;
