import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import EmailConfirmationIcon from "../../static/email-confirmation.png";
import { useUserContext } from "../../@components/userContext";
import useService from "../useService";
import useHandleErrors from "../../@components/UI/useHandleErrors";
import { useSnackbar } from "notistack";

const EmailConfirmationDialog = ({ toggleOpen }) => {
  const { user: loggedInUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const service = useService();
  const { handleApiError } = useHandleErrors();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);

  const sendConfirmationEmail = async () => {
    await service
      .sendConfirmationEmail()
      .then(() => {
        enqueueSnackbar("Confirmation email was sent", {
          variant: "success",
        });
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          backgroundColor: "#1B1E2F !important",
          padding: { md: "40px 80px", mobile: "10px 0px 15px" },
          width: "700px",
          textAlign: "center",
          margin: { mobile: "15px" },
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <CloseIcon
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 30,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <Grid container>
          <Grid item xs={12} md={12}>
            <Box
              component={"img"}
              src={EmailConfirmationIcon}
              sx={{ width: "80px", display: "flex", margin: "0 auto" }}
            />
            <Typography
              variant={"h6"}
              sx={{ fontSize: "30px", textAlign: "center", mt: {md:"50px", mobile: "30px"} }}
            >
              Please confirm your email!
            </Typography>
            <Typography variant={"subtitle1"} sx={{ mt: "20px" }}>
              We've sent an email to{" "}
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                {loggedInUser?.email}
              </Box>{" "}
              to verify your email address. Once you receive it, please click on the provided link to complete your registration.
            </Typography>
            <Box sx={{ mt: {md:"40px", mobile: "20px"}, borderBottom: "1px solid #303346" }}></Box>
            <Box sx={{ mt: {md:"40px", mobile: "20px"}, px: "10px" }}>
              <Typography
                variant={"subtitle1"}
                sx={{ fontSize: "15px !important", textAlign: "center" }}
              >
                If you haven't received the email,{" "}
                <Box
                  onClick={() => sendConfirmationEmail()}
                  component={"span"}
                  sx={{
                    cursor: "pointer",
                    fontWeight: 400,
                    color: (theme) => theme.palette.colors.primary,
                  }}
                >
                   click here
                </Box>{" "}
                to resend the confirmation email.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </MaterialDialog>
  );
};

export default EmailConfirmationDialog;
