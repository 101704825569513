import React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import useProposalService from "../../../proposal/useService";
import UI from "../../../../../@components/UI";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import QPEOPLE from "../../../qpeople";
import VotingResults from "../VotingResults";
import VotingPanel from "./VotingPanel";
import useHandleErrors from "../../../../../@components/UI/useHandleErrors";
import { useBlockContext } from "../../../../../@components/contextAPI";
import moment from "moment";
import Utility from "../../../proposal/collection/extraUtilities";
import Ticket from "../../../proposal/collection/review/Ticket";
import AccordionItem from "../../../proposal/collection/extraUtilities/AccordionItem";
import Link from "@mui/material/Link";
import DescriptionIcon from "@mui/icons-material/Description";
import Alert from "@mui/material/Alert";
import { useUserRole } from "../../../context";
import CustomHooks from "../../../../../@components/hooks";

const Index = ({ DAO }) => {
  const history = useHistory();
  const { id } = useParams();
  const { handleApiError } = useHandleErrors();
  const proposalService = useProposalService(DAO?.id);
  const [proposal, setProposal] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState("Estimating...");
  const { isCalculatingBlockTimes, getFutureBlockDateTime } = useBlockContext();
  const { isBrandMember } = useUserRole();
  CustomHooks.useProtectedRoute(DAO, isBrandMember);

  React.useEffect(() => {
    const fetchData = async () => {
      if (!isCalculatingBlockTimes && proposal) {
        const endDateTime = await getFutureBlockDateTime(
          parseInt(proposal?.end_block)
        );
        setEndDate(moment(endDateTime).format("DD MMM YYYY HH:mm"));
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [isCalculatingBlockTimes, proposal]);

  const fetchDatabaseData = async () => {
    proposalService
      .proposal(id)
      .then((response) => {
        setProposal(response.data);
        setStartDate(
          moment(response.data?.datetime_created).format("DD MMM YYYY HH:mm")
        );
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  React.useEffect(() => {
    id && fetchDatabaseData();
    // eslint-disable-next-line
  }, [id]);
  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Paper
        elevation={0}
        sx={{ width: "100%", backgroundColor: "transparent" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { md: "70px", mobile: "0px" },
            pb: "40px",
            pt: { md: "70px", mobile: "40px" },
          }}
        >
          <UI.BackButton 
          fontSize={"14px !important"}
          title={"BACK TO VOTES"} 
          onClick={() =>
            history.push({
              pathname: `/brand/${DAO?.id}/vote`,
              state: { scrollToTop: true },
            })
          }
        />
        </Box>
        <Box
          sx={{
            width: "100%",
            px: { md: "80px", mobile: "10px" },
            pb: "75px",
          }}
        >
          <Typography
            variant={"h1"}
            sx={{
              fontSize: "30px !important",
              lineHeight: "20px !important",
              mb: "25px",
              mt: "10px",
            }}
          >
            ADD NEW MEMBER
          </Typography>
          <Grid container>
            <Grid item mobile={12} md={12}>
              <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                <Alert
                  variant="outlined"
                  severity="info"
                  sx={{ color: "#4DB8FF" }}
                >
                  {proposal && (
                    <Box sx={{ display: "flex" }}>
                      {`Proposal created: ${startDate}`}
                    </Box>
                  )}
                </Alert>
              </Typography>
            </Grid>
            <Grid item mobile={12} md={12}>
              <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                <Alert
                  variant="outlined"
                  severity="info"
                  sx={{ color: "#4DB8FF" }}
                >
                  {proposal && (
                    <Box sx={{ display: "flex" }}>
                      {`Proposal expires: ${endDate}`}
                    </Box>
                  )}
                </Alert>
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item mobile={12} md={12}>
              <Box sx={{ pt: "50px !important" }}>
                <Typography variant={"h2"} sx={{ mt: "20px" }}>
                  {proposal?.data?.name}
                </Typography>
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    mt: { md: "10px", mobile: "0px", lineHeight: "25px" },
                    pr: "30px",
                  }}
                >
                  {proposal?.data?.description}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "80px",
                    mb: "80px",
                  }}
                >
                  <Box sx={{ mb: "30px" }}>
                    <Typography variant={"subtitle3"}>Preview</Typography>

                    <Ticket
                      name={proposal?.data?.name}
                      price={proposal?.data?.total_price_with_tax}
                      image={proposal?.data?.nft_url}
                      availableTickets={0}
                      ticketLimit={proposal?.data?.supply}
                      tokenLogo={proposal?.data?.payment_token?.icon}
                      onClick={() => {}}
                    />
                  </Box>
                </Box>
                <Box sx={{ mt: "30px" }}>
                  <Box sx={{ mb: "30px" }}>
                    <Typography variant={"h2"}>Utilities</Typography>
                  </Box>
                  {proposal?.data?.extra_utilities?.map((item, index) => (
                    <Box sx={{ mb: "15px" }} key={index}>
                      <UI.Accordion readonly title={item?.name}>
                        <Utility.AccordionItem
                          title={"Price"}
                          value={`${item?.price} ${proposal?.data?.payment_token?.symbol}`}
                        />
                        <Utility.AccordionItem
                          title={"Tax"}
                          value={`${item?.tax} %`}
                        />
                        {item?.custom_info_fields?.map((field, index) => (
                          <Utility.AccordionItem
                            key={index}
                            title={field?.title}
                            value={field?.value}
                          />
                        ))}
                        {item?.files?.ipfs && (
                          <AccordionItem
                            key={index}
                            title={`More info`}
                            value={
                              <Link
                                target="_blank"
                                href={item?.files?.ipfs}
                                underline="hover"
                              >
                                <DescriptionIcon sx={{ color: "white" }} />
                              </Link>
                            }
                          />
                        )}
                      </UI.Accordion>
                    </Box>
                  ))}
                </Box>
                <Box sx={{ mb: "30px", mt: "65px" }}>
                  <Typography variant={"h2"}>PRICE</Typography>
                </Box>
                <Box>
                  <Paper
                    sx={{
                      backgroundColor: (theme) => theme.palette.colors.darkGray,
                      height: "80px",
                      padding: { md: "0px 35px", mobile: "0px 20px" },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{ display: { mobile: "flex", md: "none" } }}
                        variant={"h4"}
                      >
                        TOTAL PRICE WITH TAX
                      </Typography>
                      <Typography
                        sx={{ display: { mobile: "none", md: "flex" } }}
                        variant={"h2"}
                      >
                        TOTAL PRICE WITH TAX
                      </Typography>
                      <Typography
                        sx={{ display: { mobile: "flex", md: "none" } }}
                        variant={"h4"}
                      >{`${proposal?.data?.total_price_with_tax} ${proposal?.data?.payment_token?.symbol}`}</Typography>
                      <Typography
                        sx={{ display: { mobile: "none", md: "flex" } }}
                        variant={"h2"}
                      >{`${proposal?.data?.total_price_with_tax} ${proposal?.data?.payment_token?.symbol}`}</Typography>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </Grid>
            <Grid
              sx={{ mt: { mobile: "50px", md: "0px" } }}
              item
              mobile={12}
              md={12}
            >
              <Box
                sx={{
                  mt: { mobile: "10px", md: "0px" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ mb: "30px", mt: "65px" }}>
                  <Typography variant={"h2"}>Voting</Typography>
                </Box>
                <VotingResults DAO={DAO} proposal={proposal} />
                <VotingPanel
                  DAO={DAO}
                  proposal={proposal}
                  onChange={() => fetchDatabaseData()}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Paper
        sx={{
          backgroundColor: "rgba(27, 30, 47, 0.5)",
          width: "100%",
          marginLeft: { md: "20px", mobile: "0px" },
          mt: "70px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: { md: "75px 80px", mobile: "20px 10px" },
          }}
        >
          <Typography variant={"h2"}>PARTICIPANTS</Typography>
          <Box sx={{ mt: "40px" }}>
            {proposal?.votes?.length > 0 && (
              <QPEOPLE.List DAO={DAO} items={proposal.votes} />
            )}
            {proposal?.votes?.length === 0 && (
              <UI.NoResults text={"No Participants found :("} />
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default Index;
