import React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UI from "../../../../@components/UI";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const CustomActionDialog = ({ open, title, onClose, onAdd }) => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      value: "",
      type: "button",
    },
    mode: "all",
  });

  const onSubmit = (values) => {
    onAdd(values);
    reset();
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "800px",
          p: { md: "50px", mobile: "30px 20px" },
          margin: { md: "32px", mobile: "10px" },
          backgroundColor: (theme) => theme.palette.colors.darkGray,
        },
      }}
      onClose={() => {
        reset();
        onClose();
      }}
      open={open}
    >
      <CloseIcon
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 30,
          top: 30,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <form>
        <Typography variant={"h2"}>{title}</Typography>
        <Box sx={{ mt: "20px" }}>
          <Box>
            <Typography
              variant={"subtitle1"}
              sx={{ fontSize: "16px !important" }}
            >
              This button will be displayed on a ticket in users profile page.
              User can click on this button to redirect to the external url.
            </Typography>
            <Typography variant={"subtitle1"}></Typography>
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", my: "10px" }}>
              <Typography variant={"h4"}>EXTERNAL URL</Typography>
              <UI.Tooltip>
                Enter the external url that will redirect on button click.
              </UI.Tooltip>
            </Box>
            <Controller
              name={"value"}
              control={control}
              rules={{
                required: true,
                pattern: {
                  value:
                    /^(http(s?):\/\/.)[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/g,
                  message: "Invalid URL format",
                },
              }}
              render={({ field }) => (
                <UI.TextField
                  {...field}
                  backgroundcolor={"#303346"}
                  placeholder={"e.g. https://example.com"}
                />
              )}
            />
            {errors.value?.type === "required" && (
              <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                <Alert
                  sx={{ color: (theme) => theme.palette.colors.darkRed }}
                  variant="outlined"
                  severity="error"
                >
                  External url is required
                </Alert>
              </Typography>
            )}
            {errors?.value?.type === "pattern" && (
              <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                <Alert
                  sx={{
                    color: (theme) => theme.palette.colors.darkRed,
                  }}
                  variant="outlined"
                  severity="error"
                >
                  {errors?.value?.message}
                </Alert>
              </Typography>
            )}
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h4"}>Add Button Title</Typography>
              <UI.Tooltip>Provide the additional information.</UI.Tooltip>
            </Box>
            <Controller
              name={"name"}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UI.TextField
                  {...field}
                  backgroundcolor={"#303346"}
                  placeholder={"e.g. View Gallery, View Marketplace"}
                />
              )}
            />
            {errors.name?.type === "required" && (
              <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                <Alert
                  sx={{ color: (theme) => theme.palette.colors.darkRed }}
                  variant="outlined"
                  severity="error"
                >
                  Title is required
                </Alert>
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: "20px",
              px: "15px",
              justifyContent: "center",
            }}
          >
            <UI.Button
              type={"primary"}
              title={"Add Link"}
              onClick={handleSubmit(onSubmit)}
              sx={{
                width: "150px",
                height: "50px",
                filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
              }}
            />
          </Box>
        </Box>
      </form>
    </Dialog>
  );
};

const Action = ({ element: Component, title, onAdd }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Box component={"span"} onClick={() => setOpen(!open)}>
        {Component}
      </Box>

      <CustomActionDialog
        open={open}
        title={title}
        onClose={() => setOpen(false)}
        onAdd={(values) => {
          setOpen(false);
          onAdd(values);
        }}
      />
    </>
  );
};

CustomActionDialog.Action = Action;

export default CustomActionDialog;
