import React from "react";
import Collections from "../../collections";
import useCollectionService from "../../collections/useService";
import CustomHooks from "../../@components/hooks";

const Index = () => {
  const collectionService = useCollectionService();

  const { data: collections, isLoading } = CustomHooks.useFetch(
    "collections",
    collectionService.list
  );

  return (
    <Collections.List
      items={collections}
      busy={isLoading}
      limit={{ from: 0, to: 12 }}
    />
  );
};

export default Index;
