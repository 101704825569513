import React from "react";
import UI from "../../../../../../../@components/UI";
import { useUserContext } from "../../../../../../../@components/userContext";

const StripeButton = (props) => {
  const { user: loggedInUser } = useUserContext();
  const { title, orderId, collection, onSuccess, onCanceled, ...other } = props;
  const access_token = localStorage.getItem("access_token");
  const formRef = React.useRef();

  return (
    <>
      <form
        ref={formRef}
        action={`${window.env.GATEWAY}/payments/stripe/create-checkout-session/`}
        method="POST"
      >
        <input
          type="hidden"
          name="collection_id"
          value={collection?.id}
        ></input>
        <input type="hidden" name="order_id" value={orderId}></input>
        <input type="hidden" name="user_id" value={loggedInUser?.id}></input>
        <input
          type="hidden"
          name="Authorization"
          value={`Bearer ${access_token}`}
        />
        <UI.Button
          disabled={collection.stripe === false}
          sx={{ height: "42px", width: "220px" }}
          type={"primary"}
          title={title}
          {...other}
        />
      </form>
    </>
  );
};

export default StripeButton;
