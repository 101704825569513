import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { ethers } from "ethers";
import UI from "../../../../@components/UI";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useBlockchainState from "./useBlockchainState";
import Alert from "@mui/material/Alert";
import { useBlockContext } from "../../../../@components/contextAPI";
import moment from "moment";
import governorArtifact from "../../../../abis/contracts/modules/governor/GovernorERC20.sol/DaoGovernorERC20.json";
import customHooks from "../../../../@components/hooks";

const VotingResults = ({ DAO, proposal }) => {
  const provider = customHooks.useEthersProvider();
  const [voteEnd, setVoteEnd] = React.useState("0");
  const [voteStart, setVoteStart] = React.useState(null);
  const [timelockEta, setTimelockEta] = React.useState(null);
  const { currentVotes, proposalState, totalStakedAmount } = useBlockchainState(
    DAO,
    proposal
  );

  const { isCalculatingBlockTimes, getFutureBlockDateTime } = useBlockContext();

  React.useEffect(() => {
    const fetchData = async () => {
      if (!isCalculatingBlockTimes && proposal && DAO) {
        const governor = new ethers.Contract(
          DAO?.governor_contract?.address,
          governorArtifact.abi,
          provider
        );

        const proposalEtaTimestampData = await governor.proposalEta(
          proposal?.proposal_hash_id
        );
        const proposalEta = ethers.BigNumber.from(
          proposalEtaTimestampData
        ).toString();

        setTimelockEta(moment(proposalEta * 1000).format("DD MMM YYYY HH:mm"));

        const voteStartBlockData = await governor.proposalSnapshot(
          proposal?.proposal_hash_id
        );
        const voteStartBlock =
          ethers.BigNumber.from(voteStartBlockData).toString();
        const voteEndBlockData = await governor.proposalDeadline(
          proposal?.proposal_hash_id
        );
        const voteEndBlock = ethers.BigNumber.from(voteEndBlockData).toString();

        const voteStartDateTime = await getFutureBlockDateTime(
          parseInt(voteStartBlock)
        );

        const voteEndDateTime = await getFutureBlockDateTime(
          parseInt(voteEndBlock)
        );

        setVoteStart(moment(voteStartDateTime).format("DD MMM YYYY HH:mm"));
        setVoteEnd(moment(voteEndDateTime).format("DD MMM YYYY HH:mm"));
      }
    };
    DAO && proposal && fetchData();
    // eslint-disable-next-line
  }, [isCalculatingBlockTimes, proposal, DAO]);

  return (
    <Paper
      sx={{
        p: { md: "40px", mobile: "20px" },
        backgroundColor: "rgba(27, 30, 47, 0.5)",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant={"h2"}>Results</Typography>
          {proposalState ? (
            <Typography
              variant={"h4"}
              sx={{ color: (theme) => theme.palette.colors.primary }}
            >
              {proposalState}
            </Typography>
          ) : (
            <Skeleton variant={"rectangular"} height={"35px"} width={"80px"} />
          )}
        </Box>
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant={"h4"}>{`YES`}</Typography>
          {currentVotes ? (
            <Typography variant={"h4"}>
              {`${
                ethers.formatEther(currentVotes?.forVotes).toString() || 0
              } VOTES`}
            </Typography>
          ) : (
            <Skeleton variant={"rectangular"} />
          )}
        </Box>

        {currentVotes ? (
          <Box>
            <UI.ProgressBar
              value={
                (ethers.formatEther(currentVotes?.forVotes) * 100) /
                ethers.formatEther(totalStakedAmount)
              }
            />
          </Box>
        ) : (
          <Skeleton variant={"rectangular"} />
        )}
        <Box
          sx={{
            mt: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant={"h4"}>{`NO`}</Typography>
          {currentVotes ? (
            <Typography variant={"h4"}>
              {`${
                ethers.formatEther(currentVotes?.againstVotes).toString() || 0
              } VOTES`}
            </Typography>
          ) : (
            <Skeleton variant={"rectangular"} />
          )}
        </Box>
        <Box>
          {currentVotes ? (
            <UI.ProgressBar
              value={
                (ethers.formatEther(currentVotes?.againstVotes) * 100) /
                ethers.formatEther(totalStakedAmount)
              }
            />
          ) : (
            <Skeleton variant={"rectangular"} />
          )}
        </Box>
        <Box
          sx={{
            mt: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant={"h4"}>{`ABSTAIN`}</Typography>
          {currentVotes ? (
            <Typography variant={"h4"}>
              {`${
                ethers.formatEther(currentVotes?.abstainVotes).toString() || 0
              } VOTES`}
            </Typography>
          ) : (
            <Skeleton variant={"rectangular"} />
          )}
        </Box>
        {currentVotes ? (
          <Box>
            <UI.ProgressBar
              value={
                (ethers.formatEther(currentVotes?.abstainVotes) * 100) /
                ethers.formatEther(totalStakedAmount)
              }
            />
          </Box>
        ) : (
          <Skeleton variant={"rectangular"} />
        )}
      </Box>
      <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
        {proposalState && proposalState.toLowerCase() === "pending" && (
          <Alert variant="outlined" severity="info" sx={{ color: "#4DB8FF" }}>
            {voteStart ? `Voting will start on ${voteStart}` : `Estimating...`}
          </Alert>
        )}
        {proposalState && proposalState.toLowerCase() === "active" && (
          <Alert variant="outlined" severity="info" sx={{ color: "#4DB8FF" }}>
            {voteStart ? `Voting will end on ${voteEnd}` : `Estimating...`}
          </Alert>
        )}
        {proposalState && proposalState.toLowerCase() === "queued" && (
          <Alert variant="outlined" severity="info" sx={{ color: "#4DB8FF" }}>
            <Box sx={{ display: "flex" }}>
              {voteStart
                ? `Vote will be ready to execute on ${timelockEta}`
                : `Estimating...`}
            </Box>
          </Alert>
        )}
      </Typography>
    </Paper>
  );
};

export default VotingResults;
