import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useHistory, useLocation } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

const Menu = ({ user, currentUrl }) => {
  let location = useLocation();
  const history = useHistory();
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (user && location.pathname) {
      if (location.pathname.startsWith(`/profile/created`)) {
        setValue(1);
      } else if (location.pathname.startsWith(`/profile/finance`)) {
        setValue(2);
      } else if (location.pathname.startsWith(`/profile/settings`)) {
        //setValue(2);
      } else {
        setValue(0);
      }
    }
    // eslint-disable-next-line
  }, [user, location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        margin: "35px 0px",
        display: {
          md: "block",
          mobile: "none",
        },
      }}
    >
      {user ? (
        <Box sx={{ width: "100%" }}>
          <Tabs
            TabIndicatorProps={{
              sx: {
                backgroundColor: (theme) =>
                  !location.pathname.startsWith(`/profile/settings`)
                    ? theme.palette.colors.primary
                    : "transparent",
              },
            }}
            scrollButtons
            allowScrollButtonsMobile
            value={value}
            onChange={handleChange}
            centered
          >
            <Tab
              index={"home"}
              sx={{
                "&.MuiTab-root": {
                  transition: "all 0.3s ease-in-out",
                  width: "145px",
                  fontSize: "16px",
                  color: location.pathname.startsWith(`/profile/settings`)
                    ? "#666771 !important"
                    : "#fff",
                  backgroundColor: location.pathname.startsWith(
                    `/profile/settings`
                  )
                    ? "#0d0f1f !important"
                    : "transparent",
                },
              }}
              onClick={() => history.push(`${currentUrl}`)}
              label="Home"
            />
            <Tab
              index={"created"}
              sx={{
                "&.MuiTab-root": {
                  transition: "all 0.3s ease-in-out",
                  width: "145px",
                  fontSize: "16px",
                  color: location.pathname.startsWith(`/profile/settings`)
                    ? "#666771 !important"
                    : "#fff",
                  backgroundColor: location.pathname.startsWith(
                    `/profile/settings`
                  )
                    ? "#0d0f1f !important"
                    : "transparent",
                },
              }}
              onClick={() => history.push(`${currentUrl}/created`)}
              label="Created"
            />
            <Tab
              index={"finance"}
              sx={{
                "&.MuiTab-root": {
                  transition: "all 0.3s ease-in-out",
                  width: "145px",
                  fontSize: "16px",
                  color: location.pathname.startsWith(`/profile/settings`)
                    ? "#666771 !important"
                    : "#fff",
                  backgroundColor: location.pathname.startsWith(
                    `/profile/settings`
                  )
                    ? "#0d0f1f !important"
                    : "transparent",
                },
              }}
              onClick={() => history.push(`${currentUrl}/finance`)}
              label="Finance"
            />
          </Tabs>
        </Box>
      ) : (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box sx={{ display: "flex" }}>
            <Skeleton variant="rectangular" width={145} height={70} />
            <Skeleton
              sx={{ ml: "2px" }}
              variant="rectangular"
              width={145}
              height={70}
            />
            <Skeleton
              sx={{ ml: "2px" }}
              variant="rectangular"
              width={145}
              height={70}
            />
            <Skeleton
              sx={{ ml: "2px" }}
              variant="rectangular"
              width={145}
              height={70}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Menu;
