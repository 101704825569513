import { useState, createContext, useContext } from "react";

export const FormContext = createContext();

export default function FormProvider({ children }) {
  const [data, setData] = useState({
    main_utility: {
      type: "digital_content",
      displayName: "Digital Content",
      is_main_utility: true,
      name: "",
      price: null,
      description: "",
      custom_info_fields: [],
      request_info_fields: [],
    },
    extra_utilities: [],
    basic_info: {
      picture_url: "",
      name: "",
      symbol: "",
      supply: "",
      description: "",
      scheduled_date_time: null,
      is_scheduled_later: false,
      visible: true,
    },
    payment: {
      crypto: {
        enabled: true,
        currency: "MATIC", //USDT, USDC, MATIC
        token: null, //USDT, USDC, MATIC
      },
      fiat: {
        paypal: false,
        stripe: false,
        currency: "EUR", //USD, EUR,
      },
    },
    isFreeMintEnabled: false,
    totalPrice: 0,
  });

  const setFormValues = (values) => {
    setData((prevValues) => ({
      ...prevValues,
      ...values,
    }));
  };

  return (
    <FormContext.Provider value={{ data, setFormValues }}>
      {children}
    </FormContext.Provider>
  );
}

export const useFormData = () => useContext(FormContext);
