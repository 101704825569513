import React from "react";
import Preview from "../settings/plugin/Preview";
import Dialog from "@mui/material/Dialog";
import CustomHooks from "../../../@components/hooks";

const Index = ({ DAO }) => {
  const [settings, setSettings] = React.useState(null);
  let query = CustomHooks.useQuery();
  const redirectUrl = query.get("redirectUrl");

  React.useEffect(() => {
    if (DAO) {
      if (DAO?.dao_style.length > 0) {
        setSettings(DAO?.dao_style[0].data);
      } else {
        setSettings({
          collections: [],
          background_color: "#080A19",
          text_color: "#ffffff",
          text2_color: "#ffffff",
          card_background_color: "#121424",
          card_text_color: "#ffffff",
          perks_background_color: "#121424",
          perks_text_color: "#ffffff",
          perks_badge_color: "#944DFF",
          perks_badge_text_color: "#ffffff",
          border_color: "#944DFF",
          button_color: "#944DFF",
        });
      }
    }
  }, [DAO]);

  return (
    <>
      <Dialog
        fullScreen
        open={true}
        PaperProps={{
          sx: {
            backgroundColor: settings?.background_color,
          },
        }}
      >
        {settings && (
          <Preview
            DAO={DAO}
            settings={settings}
            onBuy={(collectionId) => {
              window.open(
                (process.env.NODE_ENV === "development" &&
                  `http://localhost:3000/brand/${DAO?.id}/collection/${collectionId}?redirectUrl=${redirectUrl}`) ||
                  (window.env.CHAIN === "MUMBAI" &&
                    `http://mumbai.qvrse.io/brand/${DAO?.id}/collection/${collectionId}?redirectUrl=${redirectUrl}`) ||
                  (window.env.CHAIN === "POLYGON" &&
                    `http://dapp.qvrse.io/brand/${DAO?.id}/collection/${collectionId}?redirectUrl=${redirectUrl}`),
                "_top"
              );
            }}
          />
        )}
      </Dialog>
    </>
  );
};

export default Index;
