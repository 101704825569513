import { ConnectButton } from "@rainbow-me/rainbowkit";
import QvrsButton from "../Button";
import Box from "@mui/material/Box";
import React from "react";
import walletLoginIcon from "../../../static/wallet-login.png";
import Typography from "@mui/material/Typography";

const Button = ({ title, signInBusy, isAuthenticated }) => {
  return (
    <ConnectButton.Custom>
      {({ account, chain, openChainModal, openConnectModal, mounted }) => {
        return (
          <div
            {...(!mounted && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (
                !mounted ||
                !account ||
                !chain ||
                (!isAuthenticated && !signInBusy)
              ) {
                return (
                  <Box
                    onClick={openConnectModal}
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        margin: { mobile: "0 auto", md: "unset" },
                        cursor: "pointer",
                        display: "flex",
                        width: "251px",
                        height: "44px",
                        p: "10px 26px",
                        alignItems: "center",
                        backgroundColor: "rgba(51, 54, 69, 1);",
                        border: "1px solid transparent",
                        "&:hover": {
                          border: "1px solid transparent",
                          filter: "drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.10))",
                          transition: "all 0.2s ease",
                          backgroundColor: "#616479",
                        },
                      }}
                    >
                      <Box
                        component={"img"}
                        src={walletLoginIcon}
                        sx={{ width: "20px", height: "20px" }}
                      />
                      <Typography
                        variant={"h2"}
                        sx={{
                          ml: "15px",
                          fontSize: "16px !important",
                          textTransform: "uppercase",
                        }}
                      >
                        Log in with wallet
                      </Typography>
                    </Box>
                  </Box>
                );
              } else if (signInBusy) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <Box
                      onClick={openConnectModal}
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          margin: { mobile: "0 auto", md: "unset" },
                          cursor: "pointer",
                          display: "flex",
                          width: "251px",
                          height: "44px",
                          p: "10px 26px",
                          alignItems: "center",
                          backgroundColor: "rgba(51, 54, 69, 1);",
                          border: "1px solid transparent",
                          "&:hover": {
                            border: "1px solid transparent",
                            filter: "drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.10))",
                            transition: "all 0.2s ease",
                            backgroundColor: "#616479",
                          },
                        }}
                      >
                        <Box
                          component={"img"}
                          src={walletLoginIcon}
                          sx={{ width: "20px", height: "20px" }}
                        />
                        <Typography
                          variant={"h2"}
                          sx={{
                            ml: "15px",
                            fontSize: "16px !important",
                            textTransform: "uppercase",
                          }}
                        >
                          Logging In ...
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              }
              if (chain.unsupported) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                      mr: "10px",
                    }}
                  >
                    <QvrsButton
                      sx={{
                        height: { md: "67px", mobile: "40px" },
                        backgroundColor: "#1b1e2f",
                      }}
                      onClick={openChainModal}
                      title={"Wrong network"}
                      type={"secondary"}
                    />
                  </Box>
                );
              }
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <Box
                    onClick={openConnectModal}
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        margin: { mobile: "0 auto", md: "unset" },
                        cursor: "pointer",
                        display: "flex",
                        width: "251px",
                        height: "44px",
                        p: "10px 26px",
                        alignItems: "center",
                        backgroundColor: "rgba(51, 54, 69, 1);",
                        border: "1px solid transparent",
                        "&:hover": {
                          border: "1px solid transparent",
                          filter: "drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.10))",
                          transition: "all 0.2s ease",
                          backgroundColor: "#616479",
                        },
                      }}
                    >
                      <Box
                        component={"img"}
                        src={walletLoginIcon}
                        sx={{ width: "20px", height: "20px" }}
                      />
                      <Typography
                        variant={"h2"}
                        sx={{
                          ml: "15px",
                          fontSize: "16px !important",
                          textTransform: "uppercase",
                        }}
                      >
                        Logged In
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default Button;
