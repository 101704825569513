import React, { useState } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import useService from "../useService";
// Import Swiper styles
// Styles must use direct files imports
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import Skeleton from "@mui/material/Skeleton";

import { Navigation, Autoplay } from "swiper";

import "./styles.css";

import Typography from "@mui/material/Typography";

import CustomHooks from "../../@components/hooks";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  actionText: {
    backgroundImage:
      "linear-gradient(to right, #944DFF, #944DFF 50%, #ffffff 50%)",
    backgroundSize: "200% 100%",
    backgroundPosition: "-100%",
    display: "inline-block",
    padding: "5px 0",
    position: "relative",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    transition: "all 0.3s ease-in-out",
    "&:before": {
      content: '""',
      background: "#944DFF",
      display: "block",
      position: "absolute",
      bottom: "-3px",
      left: 0,
      width: 0,
      height: "3px",
      transition: "all 0.3s ease-in-out",
    },
    "&:hover": {
      backgroundPosition: "0",
    },
    "&:hover::before": {
      width: "100%",
    },
  },
}));

const Item = ({ item }) => {
  const history = useHistory();
  const classes = useStyles();
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <Box
      sx={{
        position: "relative",
        maxHeight: { md: "500px", xxl: "720px" },
      }}
    >
      <>
        {!imageLoaded && <SkeletonItem />}
        <Box
          component={"img"}
          onLoad={() => setImageLoaded(true)}
          sx={{
            inset: "0px",
            boxSizing: "border-box",
            padding: "0px",
            border: "none",
            margin: "auto",
            display: "block",
            width: "0px",
            height: "0px",
            minWidth: "100%",
            maxWidth: "100%",
            minHeight: "100%",
            maxHeight: "100%",
            objectFit: "cover",
            ...{ visibility: imageLoaded ? "visible" : "hidden" },
          }}
          src={item.background_image_url}
        />
      </>

      <Box
        sx={{
          position: "absolute",
          display: imageLoaded ? "flex" : "none",
          width: "100%",
          height: "100%",
          left: "0",
          top: "0",
          background:
            "linear-gradient(90deg, rgba(8, 10, 25, 0.35) 0%, rgba(8, 10, 25, 0.15) 34.92%, rgba(8, 10, 25, 0) 100%);",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            ml: "8%",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: { mobile: "absolute", md: "unset" },
              bottom: { mobile: "30px", md: "unset" },
              pr: { mobile: "15px", md: "unset" },
            }}
          >
            <Box>
              <Typography
                variant={"h2"}
                sx={{
                  fontSize: {
                    md: "40px !important",
                    mobile: "24px !important",
                  },
                  whiteSpace: "pre-line",
                  lineHeight: { md: "57px", mobile: "30px" },
                }}
              >
                {item.title}
              </Typography>
              <Typography
                variant={"subtitle2"}
                sx={{
                  fontSize: {
                    md: "18px !important",
                    mobile: "14px !important",
                  },
                  mt: { md: "12px", mobile: "5px" },
                  lineHeight: { md: "23px", mobile: "17px" },
                }}
              >
                {item.subtitle}
              </Typography>
            </Box>
            <Box
              onClick={() => {
                history.push({
                  pathname: item.action.url,
                  state: { scrollToTop: true },
                });
              }}
              sx={{
                cursor: "pointer",
                mt: { md: "30px", mobile: "15px" },
                borderBottom: "3px solid #FFFFFF",
                width: "fit-content",
              }}
            >
              <Typography
                className={classes.actionText}
                variant={"h4"}
                sx={{
                  fontWeight: "700 !important",
                  fontSize: "16px !important",
                }}
              >
                {item.action.text}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SkeletonItem = () => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        maxHeight: { md: "660px", xl: "800px" },
      }}
    >
      <Skeleton
        variant={"rectangular"}
        sx={{
          display: "block",
          width: "100%",
          height: { md: "460px", xl: "500px", mobile: "400px" },
          margin: 0,
        }}
      />
    </Box>
  );
};

const FeaturedCollections = () => {
  const service = useService();

  const { data: items, isLoading } = CustomHooks.useFetch(
    "news",
    service.featured
  );

  return (
    <>
      <Swiper
        grabCursor={true}
        slidesPerView={1}
        spaceBetween={20}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
          pauseOnMouseEnter: false,
        }}
        loop={true}
        loopFillGroupWithBlank={true}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="featuredCollections"
      >
        {items &&
          !isLoading &&
          items?.length > 0 &&
          items.map((item) => (
            <SwiperSlide key={item.id}>
              <Item item={item} />
            </SwiperSlide>
          ))}
      </Swiper>
      {isLoading && <SkeletonItem />}
    </>
  );
};

export default FeaturedCollections;
