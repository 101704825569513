import React from "react";
import UI from "../../../../@components/UI";
import useBlockchainState from "../useBlockchainState";
import useVote from "../useVote";
import { handleVote } from "../utils";

const VotingPanel = ({ DAO, proposal, onChange }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { vote } = useVote(DAO);

  const { votingPower } = useBlockchainState(DAO, proposal);

  return (
    <React.Fragment>
      <UI.Busy.FullscreenIndicator show={isLoading} />
      <UI.VotingPoll
        hideQueue
        hideExecute
        DAO={DAO}
        proposal={proposal}
        onVote={async (option) => {
          setIsLoading(true);
          await handleVote(option, { voteFunc: vote, proposal, votingPower })
            .then(async (response) => {
              setIsLoading(false);
              onChange(response);
            })
            .catch((reason) => {
              setIsLoading(false);
            });
        }}
      />
    </React.Fragment>
  );
};

export default VotingPanel;
