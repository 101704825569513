import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const ShadowTitle = ({ title, subtitle, children }) => {
  return (
    <Box sx={{ textAlign: { mobile: "left", md: "center" } }}>
      {children}
      <Typography
        variant={"mainTitle"}
        sx={{
          textAlign: { mobile: "left", md: "center" },
          position: "relative",
        }}
      >
        {title}
      </Typography>

      {subtitle && (
        <Typography
          variant={"mainSubtitle"}
          sx={{ textAlign: { mobile: "left", md: "center" } }}
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default ShadowTitle;
