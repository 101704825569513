import React from "react";
import Box from "@mui/material/Box";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Dialog from "@mui/material/Dialog";

const useStyles = makeStyles(() => ({
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  },
  thumb: {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
    position: "relative",
    "&:hover $overlay": {
      opacity: 1,
    },
  },
  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  },
  img: {
    display: "block",
    width: "auto",
    height: "100%",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    opacity: 0,
    transition: ".5s ease",
    backgroundColor: "#944DFF",
  },

  text: {
    color: "white",
    fontSize: "16px",
    position: "absolute",
    top: "50%",
    left: "50%",
    "-webkit-transform": "translate(-50%, -50%)",
    "-ms-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
}));

const DropzoneVideo = (props) => {
  const { title, onChange } = props;
  const classes = useStyles();
  const [files, setFiles] = React.useState([]);
  const [showPreviewFile, setShowPreviewFile] = React.useState(null);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "video/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        })
      );
    },
  });

  React.useEffect(() => {
    files && onChange(files);
  }, [files, onChange]);

  const thumbs = files.map((file) => (
    <Box
      sx={{ cursor: "pointer" }}
      onClick={() => {
        setShowPreviewFile(file.preview);
      }}
      className={classes.thumb}
      key={file.name}
    >
      <Box className={classes.thumbInner}>
        <video playsInline width="400">
          <source src={file.preview} />
        </video>
      </Box>
      <Box className={classes.overlay}>
        <Box className={classes.text}>Preview</Box>
      </Box>
    </Box>
  ));

  React.useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Box
        variant="raised"
        component="span"
        sx={{
          cursor: "pointer",
          padding: "17px",
          borderRadius: "0px",
          border: "3px dotted #1B1E2F",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: { md: "500px", mobile: "100%" },
          "&:hover": {
            borderRadius: "0px",
            border: "3px dotted #944DFF",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <Paper sx={{ backgroundColor: "#1B1E2F" }}>
              <Box
                sx={{
                  p: { md: "33px", mobile: "15px" },
                  display: "flex",
                  width: { md: "454px", mobile: "100%" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    flex: 2,
                  }}
                >
                  <Typography
                    sx={{
                      display: {
                        md: "block",
                        mobile: "none",
                        textAlign: "left",
                        fontSize: "19px !important",
                      },
                    }}
                    variant={"h2"}
                  >
                    {title}
                  </Typography>
                  <Typography
                    sx={{
                      display: {
                        md: "none",
                        mobile: "block",
                        textAlign: "left",
                        lineHeight: "1.5 !important"
                      },
                    }}
                    variant={"h4"}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant={"subtitle1"}
                    sx={{
                      mt: { md: "10px", mobile: "0px" },
                      textAlign: "left",
                      fontSize: {
                        md: "14px !important",
                        mobile: "13px !important",
                      },
                    }}
                  >
                    (MP4, up to 10 MB)
                  </Typography>
                </Box>
                <Box sx={{ color: "#944DFF", flex: 1 }}>
                  <FileUploadIcon 
                    sx={{
                      fontSize: "80px",
                      pt: { md: "0px", mobile: "15px" },
                      pl: { md: "0px", mobile: "10px" },
                    }}  
                  />
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
      <Box className={classes.thumbsContainer}>{thumbs}</Box>
      {showPreviewFile != null && (
        <Dialog
          fullWidth
          onClose={() => setShowPreviewFile(null)}
          open={true}
          sx={{
            "& .MuiPaper-root": {
              maxWidth: "max-content",
            },
          }}
          PaperProps={{
            maxWidth: "1200px",
          }}
        >
          <video playsInline controls controlsList="nodownload">
            <source src={showPreviewFile} />
          </video>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default DropzoneVideo;
