import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../@components/UI";
import useService from "./useService";
import Avatar from "@mui/material/Avatar";
import truncateEthAddress from "truncate-eth-address";
import SkeletonList from "./SkeletonList";
import { useHistory, useLocation } from "react-router-dom";
import CustomHooks from "../../@components/hooks";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MaterialAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Utility from "../../daos/@id/proposal/collection/extraUtilities";
import moment from "moment";
import Link from "@mui/material/Link";

const Item = ({ id, logo, address, amount, memberSince, stakes }) => {
  const location = useLocation();
  const history = useHistory();
  return (
    <Box
      sx={{
        mb: "15px",
      }}
    >
      <MaterialAccordion
        TransitionProps={{ unmountOnExit: true }}
        sx={{
          backgroundColor: "rgba(27, 30, 47, 0.5)",
        }}
      >
        <AccordionSummary
          sx={{
            height: "80px",
            padding: { md: "18px 30px", mobile: "15px" },
          }}
          expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
        >
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ paddingRight: { md: "80px", mobile: "0px" } }}>
              <Box
                sx={{ display: "flex" }}
                onClick={(e) => e.stopPropagation()}
              >
                <Box sx={{ mr: "20px" }}>
                  <Avatar src={logo} alt={"avatar"} />
                </Box>
                <Typography
                  variant={"h4"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <UI.CopyToClipboard
                    label={truncateEthAddress(address || "")}
                    value={address || ""}
                    showBorder
                  />
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  mr: "20px",
                }}
              >
                <Typography
                  variant={"h4"}
                  sx={{
                    fontSize: {
                      mobile: "14px !important",
                      md: "16px !important",
                    },
                  }}
                >{`${amount} QVRS`}</Typography>
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: { md: "15px  35px", mobile: "15px  15px" },
          }}
        >
          <Utility.AccordionItem title={"Member since"} value={memberSince} />
          <Utility.AccordionItem
            title={"Locked tokens"}
            value={
              <Box>
                {stakes.map((item) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant={"h4"}
                      sx={{
                        width: "100px",
                        fontSize: {
                          mobile: "14px !important",
                          md: "16px !important",
                        },
                      }}
                    >
                      {item?.amount.toFixed(1)} {"QVRS"}
                    </Typography>
                    <Box sx={{ ml: "10px" }}>
                      <Link
                        onClick={() =>
                          history.push(`${location.pathname}/${item.id}`)
                        }
                        sx={{
                          color: "#ffffff",
                          textDecoration: "unset",
                          "&:hover": {
                            color: "#944DFF",
                            textDecoration: "underline",
                            textDecorationColor: "#944DFF;",
                          },
                          ml: "5px",
                          cursor: "pointer",
                        }}
                      >
                        See more
                      </Link>
                    </Box>
                  </Box>
                ))}
              </Box>
            }
          />
        </AccordionDetails>
      </MaterialAccordion>
    </Box>
  );
};

const List = ({ DAO }) => {
  const service = useService();

  const { data: items, isLoading } = CustomHooks.useFetch(
    "members",
    service.list,
    {
      select: (data) => {
        return groupByUserAddress(data);
      },
    }
  );

  function groupByUserAddress(items) {
    const groupedByAddress = items.reduce((acc, item) => {
      const key = item?.user?.address;

      if (key) {
        if (!acc[key]) {
          // Define the initial structure for this user.
          acc[key] = {
            user: {
              ...item.user,
              allStakesAmount: 0,
              memberSince: item.datetime_created,
            },
            stakes: [],
          };
        } else {
          // If this stake's datetime_created is earlier, update memberSince.
          if (item.datetime_created < acc[key].user.memberSince) {
            acc[key].user.memberSince = item.datetime_created;
          }
        }

        // Copy the item excluding the user.
        const { user, ...restOfItem } = item;

        // Update the allStakesAmount.
        acc[key].user.allStakesAmount += restOfItem.amount;

        acc[key].stakes.push(restOfItem);
      }

      return acc;
    }, {});

    // Convert the object into an array.
    return Object.values(groupedByAddress);
  }

  return (
    <Box>
      {isLoading && <SkeletonList />}
      {!isLoading &&
        items &&
        items.length > 0 &&
        items.map((item) => (
          <Item
            token={DAO?.dao_token}
            id={item?.id}
            memberSince={moment(item?.user?.memberSince).format(
              "DD MMM YYYY HH:mm"
            )}
            stakes={item?.stakes}
            address={item?.user?.address}
            logo={item?.user?.avatar_url}
            amount={item.user?.allStakesAmount.toFixed(1)}
          />
        ))}
      {!isLoading && items && items.length === 0 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <UI.NoResults text={"No Active Members Found :("} />
        </Box>
      )}
    </Box>
  );
};

export default List;
