import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MaterialDialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import CustomHooks from "../../@components/hooks";
import useService from "../useService";
import Link from "@mui/material/Link";
import truncateEthAddress from "truncate-eth-address";
import UI from "../../@components/UI";
import discordIcon from "../../static/discord-icon-circle.png";

const DiscordAccessDialog = ({ open, onClose, item }) => {
  const service = useService();
  const [discordAccessCode, setDiscordAccessCode] = React.useState(null);
  const {
    mutate: generateDiscordCode,
    isLoading: generatingDiscordAccessCode,
  } = CustomHooks.usePost(service.generateDiscordCode, {
    onSuccess: (response) => {
      const { code } = response.data;
      setDiscordAccessCode(code);
    },
  });

  React.useEffect(() => {
    const fetchData = async () => {
      await generateDiscordCode({
        daoId: item.dao_id,
        collectionId: item.event_id,
        nftId: item.nft_id,
      });
    };
    open && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          backgroundColor: "#1B1E2F !important",
          padding: { md: "30px 80px", mobile: "25px 0px" },
          width: "700px",
          textAlign: "center",
          margin: { mobile: "15px" },
        },
      }}
      open={open}
      onClose={onClose}
    >
      <CloseIcon
        onClick={onClose}
        sx={{
          position: "absolute",
          right: {md: "30px", mobile: "15px"},
          top: {md: "30px", mobile: "33px"},
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <DialogTitle sx={{ p: "8px 24px", textAlign: {md: "center", mobile: "start"} }}>               
        Discord Access
        <Box
          component={"img"}
          src={discordIcon}
          sx={{
            ml: {md: "20px", mobile: "14px"},
            mr:"5px",
            width: {md: "23px", mobile: "20px"},
            height: {md: "23px", mobile: "20px"},
          }}
        />
      </DialogTitle>
      <DialogContent sx={{ p: "0px 24px 5px 24px", textAlign: "left" }}>
        <Box sx={{ mt: "10px" }}>
          <DialogContentText>
            <Box sx={{ mb: {md:"20px", mobile:"15px"} }}>
              <Typography variant={"subtitle1"}>1. Copy Access Code</Typography>
              <Box sx={{ mt: "5px" }}>
                {!generatingDiscordAccessCode && (
                  <UI.CopyToClipboardPassword
                    showBorder
                    sx={{ fontSize: "16px !important" }}
                    label={truncateEthAddress(discordAccessCode || "")}
                    value={`!verify ${discordAccessCode}` || ""}
                  />
                )}
                {generatingDiscordAccessCode && (
                  <Typography variant={"subtitle1"}>
                    Generating Access code...
                  </Typography>
                )}
              </Box>
            </Box>
            <Box sx={{ mb: {md:"20px", mobile:"15px"} }}>
              <Typography variant={"subtitle1"}>
                2. Go to the Discord channel - {" "}
                <Link
                target="_blank"
                  sx={{ textDecorationColor: "#944DFF;" }}
                  href={item.invite_link}
                >
                  {item.channel_name}
                </Link>
              </Typography>
              
            </Box>
            <Box sx={{ mb: {md:"20px", mobile:"15px"} }}>
              <Typography variant={"subtitle1"}>
                3. Paste the Access Code in the Discord channel
              </Typography>
            </Box>
            <Box>
              <Typography variant={"subtitle1"}>
                4. After successful verification, you will be able to access the{" "}
                <b>{item.channel_name}</b> channel
              </Typography>
            </Box>
          </DialogContentText>
        </Box>
        <Box sx={{ mt: "10px" }}></Box>
      </DialogContent>

      <Box sx={{ mt: { md: "20px", mobile: "10px" } }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: "10px",
            pb: {md:"15px", mobile:"10px"},
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <UI.Button
              type={"primary"}
              title={"OK"}
              onClick={onClose}
              sx={{
                width: "150px",
                height: {md:"50px", mobile: "40px"},
                filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
              }}
            />
          </Box>
        </Box>
      </Box>

    </MaterialDialog>
  );
};

const Action = ({ element: Component, onConfirm, item }) => {
  const [open, setOpen] = React.useState(null);

  return (
    <>
      <Box component={"span"} onClick={() => setOpen(!open)}>
        {Component}
      </Box>

      <DiscordAccessDialog
        open={open}
        item={item}
        onClose={() => setOpen(false)}
        onConfirm={() => {
          onConfirm();
          setOpen(false);
        }}
      />
    </>
  );
};

DiscordAccessDialog.Action = Action;

export default DiscordAccessDialog;
