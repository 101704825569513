import React from "react";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import prevoteIcon from "../../../static/prevote-icon.png";
import activeIcon from "../../../static/active-icon.png";
import pendingIcon from "../../../static/pending-icon.png";
import executedIcon from "../../../static/executed-icon.png";
import defeatedIcon from "../../../static/defeated-icon.png";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
  stepLabel: {
    fontFamily: "Quantico !important",
  },
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "#944DFF",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "#944DFF",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#5B5E72",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "#5B5E72",
  zIndex: 1,
  color: "#fff",
  width: 45,
  height: 45,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    background: "#944DFF",
    boxShadow: "0px 0px 30px rgb(138 38 255 / 50%)",
  }),
  ...(ownerState.completed && {
    background: "#944DFF",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: (
      <Box
        component={"img"}
        src={prevoteIcon}
        sx={{ width: "15px", height: "18px" }}
      />
    ),
    2: (
      <Box
        component={"img"}
        src={activeIcon}
        sx={{ width: "15px", height: "18px" }}
      />
    ),
    3: (
      <Box
        component={"img"}
        src={pendingIcon}
        sx={{ width: "15px", height: "18px" }}
      />
    ),
    4: (
      <Box
        component={"img"}
        src={executedIcon}
        sx={{ width: "21px", height: "16px" }}
      />
    ),
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function ColorlibStepIconDefeated(props) {
  const { active, completed, className } = props;
  const icons = {
    1: (
      <Box
        component={"img"}
        src={prevoteIcon}
        sx={{ width: "15px", height: "18px" }}
      />
    ),
    2: (
      <Box
        component={"img"}
        src={activeIcon}
        sx={{ width: "15px", height: "18px" }}
      />
    ),
    3: (
      <Box
        component={"img"}
        src={pendingIcon}
        sx={{ width: "15px", height: "18px" }}
      />
    ),
    4: (
      <Box
        component={"img"}
        src={defeatedIcon}
        sx={{ width: "16px", height: "16px" }}
      />
    ),
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const FormStepper = ({ steps, activeStep, proposalState }) => {
  const classes = useStyles();
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            classes={{ label: classes.stepLabel }}
            StepIconComponent={
              proposalState === "Defeated"
                ? ColorlibStepIconDefeated
                : ColorlibStepIcon
            }
          >
            <Typography
              variant={"h2"}
              sx={{ 
                fontSize: { md: "16px !important", mobile: "13px !important" },
                lineHeight: { md: "22px !important", mobile: "18px !important" },
                fontWeight: "bold" }}
            >
              {label}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default FormStepper;
