const _findTokenIdFromTotalSupply = async (nftContractInstance) => {
  try {
    const totalSupply = await nftContractInstance.totalSupply();
    if (totalSupply.gt(0)) {
      return await nftContractInstance.tokenByIndex(totalSupply.sub(1));
    }
    console.log("No tokens found in the contract");
  } catch (error) {
    console.log("Cannot find token ID");
  }
};

const _findTokenIdFromLogs = async (provider, nftContractInstance) => {
  try {
    const filter = nftContractInstance.filters.Transfer(
      "0x0000000000000000000000000000000000000000",
      null,
      null
    );
    return _searchInBlocks(provider, filter, nftContractInstance);
  } catch (error) {
    console.log("Cannot find token ID");
  }
};

// Search for the token in the block logs
const _searchInBlocks = async (provider, filter, nftContractInstance) => {
  let latestBlock = await provider.getBlockNumber();
  let earliestBlock = 0;

  while (earliestBlock <= latestBlock) {
    const middleBlock = Math.floor((latestBlock + earliestBlock) / 2);
    const logs = await provider.getLogs({
      fromBlock: middleBlock,
      toBlock: "latest",
      address: nftContractInstance.address,
      topics: filter.topics,
    });

    if (logs.length > 0) {
      const events = logs.map((log) =>
        nftContractInstance.interface.parseLog(log)
      );
      if (events.length > 0) {
        return events[events.length - 1].args.tokenId.toString();
      }
      break;
    } else {
      latestBlock = middleBlock - 1;
    }
  }
};

export const getNFTData = async (provider, nftContractInstance) => {
  return new Promise(async (resolve, reject) => {
    if (nftContractInstance) {
      try {
        await nftContractInstance.name();
      } catch (e) {
        reject("Collection not found on this network");
      }

      const supportsMetadataExtension = await _checkForMetadataExtension(
        nftContractInstance
      );
      if (supportsMetadataExtension) {
        resolve(_processTokenData(provider, nftContractInstance));
      } else {
        reject("Collection does not support Metadata extension");
      }
    } else {
      reject("Collection not found on this network");
    }
  });
};

// Check if the NFT contract supports the ERC721 Metadata Extension
const _checkForMetadataExtension = async (nftContractInstance) => {
  const interfaceId = "0x5b5e139f";
  return await nftContractInstance.supportsInterface(interfaceId);
};

// Process token data
const _processTokenData = async (provider, nftContractInstance) => {
  const tokenId = await _getTokenId(provider, nftContractInstance);
  return tokenId;
};

// Get the token ID
const _getTokenId = async (provider, nftContractInstance) => {
  let tokenId = 0;
  try {
    await nftContractInstance.tokenURI(tokenId);
  } catch (e) {
    tokenId = parseInt(
      await _findTokenIdFromEnumerableOrLogs(provider, nftContractInstance)
    );
  }
  return tokenId;
};

// Find the token ID from either total supply or logs
const _findTokenIdFromEnumerableOrLogs = async (
  provider,
  nftContractInstance
) => {
  const interfaceId = "0x780e9d63"; // ERC721 Enumerable Extension
  const supportsEnumerableExtension =
    await nftContractInstance.supportsInterface(interfaceId);

  if (supportsEnumerableExtension) {
    return await _findTokenIdFromTotalSupply(nftContractInstance);
  } else {
    return await _findTokenIdFromLogs(provider, nftContractInstance);
  }
};

export const getMetadata = async (url) => {
  let modifiedUrl = url;

  // Check if the URL is an IPFS URL
  if (url.startsWith("ipfs://")) {
    const ipfsHash = url.replace("ipfs://", "");
    modifiedUrl = `https://hazellabs.infura-ipfs.io/ipfs/${ipfsHash}`;
  }

  return await fetch(modifiedUrl)
    .then((response) => response.json())
    .then((data) => {
      return { image: data.image, description: data.description };
    });
};

export const getContentTypeFromUrl = async (url) => {
  return await fetch(url)
    .then((response) => response.headers.get("content-type"))
    .then((contentType) => {
      return contentType;
    });
};
