import React from "react";
import Box from "@mui/material/Box";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// Styles must use direct files imports
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module

import { Pagination } from "swiper";

import "./styles.css";
import Dialog from "@mui/material/Dialog";
import playButtonImg from "../../../static/play.png";

const ImageItem = ({ item, onClick }) => {
  return (
    <Box
      onClick={() => onClick(item)}
      sx={{
        cursor: "pointer",
        backgroundColor: "#0f1224",
        textAlign: "center",
        maxHeight: "800px",
      }}
    >
      <Box
        component={"img"}
        sx={{ width: "100%", maxHeight: "800px !important" }}
        src={item.link}
      />
    </Box>
  );
};

const VideoItem = ({ item, onClick }) => {
  return (
    <Box
      onClick={() => onClick(item)}
      sx={{
        cursor: "pointer",
        backgroundColor: "#0f1224",
        textAlign: "center",
        width: "100%",
        maxHeight: "800px",
      }}
    >
      <video playsInline width="auto" height={"400px"}>
        <source src={item.link} />
      </video>
      <Box
        sx={{
          margin: "0",
          position: "absolute",
          top: "50%",
          left: "50%",
          filter: "drop-shadow(4px 14px 12px black) brightness(0.8)",
          transform: "translate(-50%, -50%)",
          "&:hover": {
            filter: "brightness(2)",
          },
        }}
      >
        <Box
          component={"img"}
          sx={{ width: "100px !important" }}
          src={playButtonImg}
          alt={"play-video"}
        />
      </Box>
    </Box>
  );
};
const PreviewSwiper = ({ items }) => {
  const [previewFile, setPreviewFile] = React.useState(false);

  return (
    <Box className={"previewSwiper"}>
      {items?.length && (
        <Swiper
          pagination={true}
          modules={[Pagination]}
          grabCursor={true}
          className="mySwiper"
        >
          {items.map((item) => (
            <Box key={item.id}>
              <SwiperSlide>
                {(item.media_type.toLowerCase().startsWith("image") ||
                  item.file_type.toLowerCase().startsWith("gif")) && (
                  <ImageItem
                    item={item}
                    onClick={(file) => setPreviewFile(file)}
                  />
                )}
                {item.media_type.startsWith("video") && (
                  <VideoItem
                    onClick={(file) => setPreviewFile(file)}
                    item={item}
                  />
                )}
              </SwiperSlide>
            </Box>
          ))}
        </Swiper>
      )}
      <Dialog
        fullWidth
        onClose={() => setPreviewFile(null)}
        open={previewFile}
        sx={{
          "& .MuiPaper-root": {
            background: "transparent",
            boxShadow: "none",
            maxWidth: "max-content",
          },
        }}
        PaperProps={{
          maxWidth: "1200px",
        }}
      >
        {previewFile &&
          (previewFile.media_type === "image" ||
            previewFile.file_type === "gif") && (
            <Box
              component={"img"}
              sx={{
                maxHeight: { md: "600px", mobile: "auto" },
                width: { md: "auto", mobile: "100%" },
                margin: "0 auto",
              }}
              src={previewFile.link}
            />
          )}
        {previewFile && previewFile.media_type === "video" && (
          <Box
            component={"video"}
            controls
            autoPlay
            sx={{
              maxHeight: { md: "600px", mobile: "auto" },
              width: { md: "auto", mobile: "100%" },
              margin: "0 auto",
            }}
          >
            <source src={previewFile.link} />
          </Box>
        )}
      </Dialog>
    </Box>
  );
};

export default PreviewSwiper;
