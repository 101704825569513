import React from "react";
import Typography from "@mui/material/Typography";

const PerkItem = ({ perk, numOfPerks }) => {
  return (
    <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
      {`${numOfPerks}x ${perk?.title}`}
    </Typography>
  );
};

const Perk = ({ collection, perkName, numOfPerks }) => {
  const perks = {
    digital_content: {
      title: "Digital content",
      description:
        "Suspendisse blandit ante a nulla venenatis aliquam. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      button: {
        title: "Got to Feed",
        link: `/brand/${collection?.dao_id}/feed`,
      },
    },
    ticket: {
      title: "Ticket",
      description:
        "Suspendisse blandit ante a nulla venenatis aliquam. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      button: { title: "QR Code", link: `/brand/${collection?.dao_id}/feed` },
    },
    service: {
      title: "Service",
      description:
        "Suspendisse blandit ante a nulla venenatis aliquam. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      button: { title: "QR Code", link: `/brand/${collection?.dao_id}/feed` },
    },
    product: {
      title: "Product",
      description:
        "Suspendisse blandit ante a nulla venenatis aliquam. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      button: {
        title: "Go to Collection",
        link: `/brand/${collection?.dao_id}/collection/${collection?.id}`,
      },
    },
    merch: {
      title: "Merch",
      description:
        "Suspendisse blandit ante a nulla venenatis aliquam. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      button: {
        title: "Go to Collection",
        link: `/brand/${collection?.dao_id}/collection/${collection?.id}`,
      },
    },
    discord_access: {
      title: "Discord Access",
      description:
        "Suspendisse blandit ante a nulla venenatis aliquam. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      button: {
        title: "Go to Collection",
        link: `/profile`,
      },
    },
    custom: {
      title: "Custom",
      description:
        "Suspendisse blandit ante a nulla venenatis aliquam. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      button: {
        title: "Go to Collection",
        link: `/brand/${collection?.dao_id}/collection/${collection?.id}`,
      },
    },
  };

  return <PerkItem perk={perks[perkName]} numOfPerks={numOfPerks} />;
};

export default Perk;
