import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Indicator from "./Indicator";

const FullscreenIndicator = ({ show, children }) => {
  React.useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
      document.body.style.pointerEvents = "none";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.pointerEvents = "unset";
    }
  }, [show]);

  return (
    <Backdrop
      transitionDuration={500}
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        pointerEvents: "none",
      }}
      open={show}
    >
      <Box sx={{ textAlign: "center" }}>
        <Indicator />
        {children == null && (
          <>
            <Typography variant={"subtitle2"}>
              Waiting for your wallet to confirm this action ...
            </Typography>
            <Typography variant={"subtitle2"}>
              Please do not close this window.
            </Typography>
          </>
        )}
        {children != null && <>{children}</>}
      </Box>
    </Backdrop>
  );
};

export default FullscreenIndicator;
