import { ConnectButton } from "@rainbow-me/rainbowkit";
import QvrsButton from "../Button";
import Box from "@mui/material/Box";
import React from "react";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    left: "-15px",
    top: "10px",
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: "0 0 0 2px #0f1224",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const StyledBadgeNotConnected = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    left: "-10px",
    top: "10px",
    backgroundColor: "#ff6767",
    color: "#ff6767",
    boxShadow: "0 0 0 2px #0f1224",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const MobileButton = ({ signInBusy, isAuthenticated }) => {
  return (
    <ConnectButton.Custom>
      {({ account, chain, openChainModal, openConnectModal, mounted }) => {
        return (
          <div
            {...(!mounted && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (
                !mounted ||
                !account ||
                !chain ||
                (!isAuthenticated && !signInBusy)
              ) {
                return (
                  <Box
                    sx={{
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      style={{
                        fontFamily: "Quantico",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "rgba(255, 255, 255, 0.8)",
                        height: "60px",
                        ml: "5px",
                        border: "none",
                        borderRadius: "5px",
                        display: "flex",
                        backgroundColor: "transparent",
                        alignItems: "center",
                        ":hover": {
                          color: "rgba(255, 255, 255, 1)",
                        },
                      }}
                    >
                      <StyledBadgeNotConnected
                        onClick={openConnectModal}
                        sx={{
                          "&:hover": {
                            color: "rgba(255, 255, 255, 1)",
                          },
                        }}
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        variant="dot"
                      >
                        Not connected
                      </StyledBadgeNotConnected>
                    </Box>
                  </Box>
                );
              } else if (signInBusy) {
                return (
                  <Box
                    sx={{
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      style={{
                        fontFamily: "Quantico",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "rgba(255, 255, 255, 0.8)",
                        height: "60px",
                        ml: "5px",
                        border: "none",
                        borderRadius: "5px",
                        display: "flex",
                        backgroundColor: "transparent",
                        alignItems: "center",
                        ":hover": {
                          color: "rgba(255, 255, 255, 1)",
                        },
                      }}
                    >
                      <StyledBadgeNotConnected
                        sx={{
                          "&:hover": {
                            color: "rgba(255, 255, 255, 1)",
                          },
                        }}
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        variant="dot"
                      >
                        Logging in...
                      </StyledBadgeNotConnected>
                    </Box>
                  </Box>
                );
              }
              if (chain.unsupported) {
                return (
                  <QvrsButton
                    onClick={openChainModal}
                    title={"Wrong network"}
                    type={"secondary"}
                  />
                );
              }
              return (
                <div style={{ display: "flex", gap: 10 }}>
                  <Box
                    sx={{
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      onClick={openChainModal}
                      style={{
                        fontFamily: "Quantico",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "rgba(255, 255, 255, 0.8)",
                        height: "60px",
                        cursor: "pointer",
                        border: "none",
                        borderRadius: "5px",
                        display: "flex",
                        backgroundColor: "transparent",
                        alignItems: "center",
                        ":hover": {
                          color: "rgba(255, 255, 255, 1)",
                        },
                      }}
                    >
                      <StyledBadge
                        sx={{
                          "&:hover": {
                            color: "rgba(255, 255, 255, 1)",
                          },
                        }}
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        variant="dot"
                      >
                        {chain.name === "Polygon Mumbai"
                          ? "Mumbai"
                          : chain.name}
                      </StyledBadge>
                    </Box>
                  </Box>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default MobileButton;
