import Gateway from "../../../@components/gateway";

const useService = () => {
  const gateway = Gateway.useGateway();

  const save = (data) => {
    return gateway.post(
      `dao/${window.env.QVRSE_DAO_ID}/treasury/transactions/new/`,
      data
    );
  };

  const list = () => {
    return gateway.get(`dao/${window.env.QVRSE_DAO_ID}/treasury/transactions/`);
  };

  return {
    save,
    list,
  };
};

export default useService;
