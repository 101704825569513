import Gateway from "../../../@components/gateway";

const useService = (daoId) => {
  const gateway = Gateway.useGateway();

  const create = async (data) => {
    const response = await gateway.post(
      `dao/${daoId}/stake/submit_stake/`,
      data
    );
    return response.data;
  };

  const add = async (data) => {
    const response = await gateway.post(`dao/${daoId}/add_member/`, data);
    return response.data;
  };

  const checkUser = async (searchString) => {
    const response = await gateway.post(`search_user/`, {
      search: searchString,
    });
    return response.data;
  };

  const closeStake = async (id) => {
    return gateway.post(`dao/${daoId}/stake/${id}/close_stake/`);
  };

  const removeMembership = async (data) => {
    return gateway.post(`dao/${daoId}/remove_member/`, data);
  };

  const stake = async (id) => {
    return gateway.get(`dao/${daoId}/stake/${id}/`);
  };

  const list = async () => {
    const response = await gateway.get(`dao/${daoId}/members/`);
    return response.data;
  };

  const sendConfirmationEmail = async (roleId) => {
    const response = await gateway.post(`role/${roleId}/resend_confirm_email/`);
    return response.data;
  };

  return {
    create,
    add,
    closeStake,
    stake,
    list,
    checkUser,
    sendConfirmationEmail,
    removeMembership,
  };
};

export default useService;
