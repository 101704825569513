import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import UI from "../../../@components/UI";
import Grid from "@mui/material/Grid";
import SmallSkeletonList from "./SmallSkeletonList";
import useService from "./useService";
import CustomHooks from "../../../@components/hooks";
import { useHistory } from "react-router-dom";

const Item = ({ item }) => {
  const history = useHistory();
  return (
    <Grid item mobile={12} tablet={6} sm={6} md={6} lg={6}>
      <Paper
        sx={{
          backgroundColor: "rgba(27, 30, 47, 0.5);",
        }}
      >
        <Box
          onClick={() =>
            history.push({
              pathname: item?.id === 1 ? `/qvrse` : `/brand/${item?.id}`,
              state: { scrollToTop: true },
            })
          }
          sx={{
            cursor: "pointer",
            border: "1px solid transparent",
            borderRadius: "0px",
            "&:hover": {
              boxShadow: "#944dff 0px 0px 10px 0px",
              border: "1px solid #944DFF",
            },
            display: "flex",
            justifyContent: "space-between",
            padding: { md: "17px 20px", mobile: "20px 10px" },
          }}
        >
          <Box sx={{ paddingRight: { md: "80px", mobile: "0px" } }}>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ mr: "20px" }}>
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    overflow: "hidden",
                    borderRadius: "50%",
                  }}
                >
                  <Box
                    component={"img"}
                    src={item?.avatar_url}
                    alt={"avatar"}
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      textIndent: "10000px",
                    }}
                  />
                </Box>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant={"h4"}
                  sx={{
                    fontSize: "18px !important",
                    lineHeight: "25px !important",

                    maxWidth: { mobile: "295px", md: "300px" },
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item?.name}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

const SmallList = ({ limit = { from: 0, to: -1 } }) => {
  const service = useService();
  const { data: brands, isLoading: brandsLoading } = CustomHooks.useFetch(
    "brands",
    service.list
  );

  return (
    <Grid container spacing={2}>
      {brandsLoading && <SmallSkeletonList />}
      {!brandsLoading &&
        brands &&
        brands.length > 0 &&
        brands.map((item, index) => <Item key={index} item={item} />)}
      {!brandsLoading && brands && brands.length === 0 && (
        <Grid item mobile={12} tablet={12} sm={12} md={12} lg={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <UI.NoResults text={"You haven’t applied any brands :("} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default SmallList;
