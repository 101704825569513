import React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  number: {
    fontFamily: "Quantico",
    fontWeight: "400",
    fontSize: "80px",
    backgroundClip: "text",
    textFillColor: "transparent",
    lineHeight: "114px",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

const NumberedTitle = ({ number, title, description, gradient }) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        display: { xs: "block", md: "flex" },
        flexDirection: "column",
        width: { xs: "100%", md: "100%" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { mobile: "column", md: "row" },
          justifyContent: "center",
        }}
      >
        <Box
          className={classes.number}
          sx={{
            textAlign: { mobile: "center", md: "unset" },
            backgroundImage: `linear-gradient(149.04deg, #8E54E9 66.78%, rgba(142, 84, 233, 1) ${gradient})`,
          }}
        >
          {number}
        </Box>
        <Box
          sx={{
            ml: { md: "20px", mobile: "0px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant={"h1"}
            sx={{ textAlign: { mobile: "center", md: "unset" } }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
      <Typography variant={"subtitle1"} sx={{ textAlign: "center" }}>
        {description}
      </Typography>
    </Box>
  );
};

export default NumberedTitle;
