import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import royaltyIcon from "../../static/Royalty.png";
import transferIcon from "../../static/Transfer.png";
import voteIcon from "../../static/Vote.png";
import wowIcon from "../../static/Wow.png";

const Specialities = () => {
  return (
    <Box
      sx={{
        height: "60px",
        background: "#0E111F",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        py: "10px",
        px: { md: "50px", lg: "100px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
          Specialities
        </Typography>
      </Box>

      <Box
        sx={{
          width: "fit-content",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            px: "50px",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box
              component={"img"}
              src={transferIcon}
              sx={{
                width: "30px",
                height: "30px",
                transition: "all 0.5s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            />

            <Typography
              variant={"subtitle1"}
              sx={{
                ml: "20px",
                fontSize: "14px !important",
                display: "flex",
                alignItems: "center",
              }}
            >
              Transfer
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            borderRight: "4px solid #080A19",
            borderLeft: "4px solid #080A19",
            px: "50px",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box
              component={"img"}
              src={voteIcon}
              sx={{
                width: "30px",
                height: "30px",
                transition: "all 0.5s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            />

            <Typography
              variant={"subtitle1"}
              sx={{
                ml: "20px",
                fontSize: "14px !important",
                display: "flex",
                alignItems: "center",
              }}
            >
              Vote
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            borderRight: "4px solid #080A19",
            px: "50px",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box
              component={"img"}
              src={wowIcon}
              sx={{
                width: "30px",
                height: "30px",
                transition: "all 0.5s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            />

            <Typography
              variant={"subtitle1"}
              sx={{
                ml: "20px",
                fontSize: "14px !important",
                display: "flex",
                alignItems: "center",
              }}
            >
              Wow
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            pl: "50px",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box
              component={"img"}
              src={royaltyIcon}
              sx={{
                width: "30px",
                height: "30px",
                transition: "all 0.5s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            />
            <Typography
              variant={"subtitle1"}
              sx={{
                ml: "20px",
                fontSize: "14px !important",
                display: "flex",
                alignItems: "center",
              }}
            >
              Royalty
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Specialities;
