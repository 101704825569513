import { useSnackbar } from "notistack";

// const MetamaskErrorResolver = (errorCode) => {
//   switch (errorCode) {
//     case "ACTION_REJECTED": {
//       return "User rejected signing";
//     }
//     utilities: {
//       return;
//     }
//   }
// };

const useHandleErrors = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleApiError = (data) => {
    let error = data?.response?.data;

    if (error?.message == null && data?.message && error?.detail == null) {
      error = { message: data?.message };
    }

    if (error?.detail) {
      error = { message: error?.detail };
    }

    enqueueSnackbar(error?.message || "Something went wrong", {
      variant: "error",
    });
  };
  const handleMetamaskError = (error) => {
    if (error?.data?.message) {
      if (error?.data?.message.startsWith("err: insufficient funds")) {
        enqueueSnackbar("Insufficient funds", { variant: "error" });
      } else {
        enqueueSnackbar(error?.data.reason || "Something went wrong", {
          variant: "error",
        });
      }
    } else {
      if (error?.message) {
        enqueueSnackbar(error?.message || "Something went wrong", {
          variant: "error",
        });
      } else if (
        error?.reason?.startsWith(
          "execution reverted: Governor: proposer votes below proposal threshold"
        )
      ) {
        enqueueSnackbar(
          "You do not have the authority to create voting proposals.",
          { variant: "error" }
        );
      } else {
        enqueueSnackbar(error?.reason || "Something went wrong", {
          variant: "error",
        });
      }
    }
  };
  return {
    handleMetamaskError,
    handleApiError,
  };
};

export default useHandleErrors;
