import React from "react";
import Slider from "react-slick";
import SliderIndicator from "../../../static/slider-indicator.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  prevButton: {
    "&.MuiIconButton-root": {
      position: "absolute",
      width: "30px",
      height: "30px",
      left: "-50px",
      top: "180px",
      zIndex: "10",
      color: "white",
      borderRadius: "0px",
      backgroundColor: "#333645",
    },
    "&.MuiIconButton-root:hover": {
      backgroundColor: "#737687",
    },
  },
  nextButton: {
    "&.MuiIconButton-root": {
      position: "absolute",
      width: "30px",
      height: "30px",
      right: "-50px",
      top: "180px",
      zIndex: "10",
      color: "white",
      borderRadius: "0px",
      backgroundColor: "#333645",
    },
    "&.MuiIconButton-root:hover": {
      backgroundColor: "#737687",
    },
  },
  title: {
    marginTop: "30px",
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "40px",
    color: theme.palette.text.primary,
  },
  description: {
    marginTop: "30px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "23px",
    color: theme.palette.text.secondary,
  },
  root: {
    "& :hover": {
      backgroundColor: "#5d606d",
    },
  },
}));

function PrevArrow(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <Box className={classes.root}>
      <IconButton
        variant={"contained"}
        className={classes.prevButton}
        onClick={onClick}
      >
        <ArrowBackIosIcon
          sx={{
            left: "10px",
            width: "15px",
            position: "absolute",
            pointerEvents: "none",
          }}
        />
      </IconButton>
    </Box>
  );
}

function NextArrow(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <Box className={classes.root}>
      <IconButton
        variant={"contained"}
        className={classes.nextButton}
        onClick={onClick}
      >
        <ArrowForwardIosIcon sx={{ width: "15px", pointerEvents: "none" }} />
      </IconButton>
    </Box>
  );
}

const CenterMode = ({ items }) => {
  const settings = {
    dots: true,
    appendDots: (dots) => <ul> {dots} </ul>,
    customPaging: () => {
      return (
        <img
          width={"20px"}
          height={"20px"}
          alt={"slider-indicator"}
          src={SliderIndicator}
        />
      );
    },
    arrows: true,
    centerMode: false,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    autoplay: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };
  return (
    <div>
      <Slider {...settings}>
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Slider>
    </div>
  );
};

const Item = (props) => {
  const classes = useStyles();

  return (
    <Box
      onClick={() => console.log("redirect to blog")}
      sx={{
        cursor: "pointer",
        p: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "600px",
      }}
    >
      <Box sx={{ margin: "0 auto" }}>
        {props.item.img && (
          <img
            src={props.item.img}
            width={props.item.width}
            alt={"slider-item"}
          />
        )}
      </Box>
      <Box className={classes.title}>{props.item.title}</Box>
      <Box className={classes.description}>{props.item.description}</Box>
    </Box>
  );
};
export default CenterMode;
