import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../@components/UI";

const Promotion = ({ image, title, subtitle, content, url }) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: { md: "row", mobile: "column" } }}
    >
      <Box
        sx={{
          flex: 1,
          maxWidth: { md: "50%", mobile: "100%" },
          height: "auto",
          overflow: "hidden",
        }}
      >
        <Box
          component={"img"}
          src={image}
          sx={{ 
            width: "100%", 
            height: "100%",
            maxHeight: "440px"
           }}
        />
      </Box>
      <Box sx={{ flex: 1, background: "rgba(27, 30, 47, 0.5)" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            ml: { md: "100px", mobile: "0px" },
            mr: { md: "120px", mobile: "0px" },
            padding: { mobile: "40px 15px 45px", md: "unset" },
          }}
        >
          <Box sx={{ flexDirection: "column" }}>
            <Typography
              variant={"h4"}
              sx={{
                fontSize: { md: "30px !important", mobile: "27px !important" },
                whiteSpace: "pre-line",
                lineHeight: "1.3",
              }}
            >
              {title}
            </Typography>
            <Typography
              variant={"subtitle1"}
              sx={{ fontSize: "16px !important", mt: { md: "10px", mobile: "12px" }, mb: "18px" }}
            >
              {subtitle}
            </Typography>
            <Typography
              variant={"subtitle1"}
              sx={{ fontSize: "16px !important", mt: { md: "10px", mobile: "12px" } }}
            >
              {content}
            </Typography>
            <UI.Button
              type={"primary"}
              size={"small"}
              onClick={() => {}}
              sx={{
                mt: "30px",
                width: "150px",
                height: "42px",
                display: "none",
              }}
              title={"See More"}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Promotion;
