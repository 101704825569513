import React from "react";

const SvgLoading = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      margin: "auto",
      background: "0 0",
      display: "block",
      shapeRendering: "auto",
      animationPlayState: "running",
      animationDelay: "0s",
    }}
    width={181}
    height={181}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <rect
      x={47.5}
      y={24}
      rx={1.56}
      ry={1.56}
      width={5}
      height={12}
      fill="#944DFF"
      style={{
        animationPlayState: "running",
        animationDelay: "0s",
      }}
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="0.9900990099009901s"
        begin="-0.9075907590759075s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47.5}
      y={24}
      rx={1.56}
      ry={1.56}
      width={5}
      height={12}
      fill="#944DFF"
      style={{
        animationPlayState: "running",
        animationDelay: "0s",
      }}
      transform="rotate(30 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="0.9900990099009901s"
        begin="-0.825082508250825s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47.5}
      y={24}
      rx={1.56}
      ry={1.56}
      width={5}
      height={12}
      fill="#944DFF"
      style={{
        animationPlayState: "running",
        animationDelay: "0s",
      }}
      transform="rotate(60 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="0.9900990099009901s"
        begin="-0.7425742574257426s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47.5}
      y={24}
      rx={1.56}
      ry={1.56}
      width={5}
      height={12}
      fill="#944DFF"
      style={{
        animationPlayState: "running",
        animationDelay: "0s",
      }}
      transform="rotate(90 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="0.9900990099009901s"
        begin="-0.6600660066006601s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47.5}
      y={24}
      rx={1.56}
      ry={1.56}
      width={5}
      height={12}
      fill="#944DFF"
      style={{
        animationPlayState: "running",
        animationDelay: "0s",
      }}
      transform="rotate(120 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="0.9900990099009901s"
        begin="-0.5775577557755776s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47.5}
      y={24}
      rx={1.56}
      ry={1.56}
      width={5}
      height={12}
      fill="#944DFF"
      style={{
        animationPlayState: "running",
        animationDelay: "0s",
      }}
      transform="rotate(150 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="0.9900990099009901s"
        begin="-0.495049504950495s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47.5}
      y={24}
      rx={1.56}
      ry={1.56}
      width={5}
      height={12}
      fill="#944DFF"
      style={{
        animationPlayState: "running",
        animationDelay: "0s",
      }}
      transform="rotate(180 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="0.9900990099009901s"
        begin="-0.4125412541254125s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47.5}
      y={24}
      rx={1.56}
      ry={1.56}
      width={5}
      height={12}
      fill="#944DFF"
      style={{
        animationPlayState: "running",
        animationDelay: "0s",
      }}
      transform="rotate(210 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="0.9900990099009901s"
        begin="-0.33003300330033003s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47.5}
      y={24}
      rx={1.56}
      ry={1.56}
      width={5}
      height={12}
      fill="#944DFF"
      style={{
        animationPlayState: "running",
        animationDelay: "0s",
      }}
      transform="rotate(240 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="0.9900990099009901s"
        begin="-0.2475247524752475s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47.5}
      y={24}
      rx={1.56}
      ry={1.56}
      width={5}
      height={12}
      fill="#944DFF"
      style={{
        animationPlayState: "running",
        animationDelay: "0s",
      }}
      transform="rotate(270 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="0.9900990099009901s"
        begin="-0.16501650165016502s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47.5}
      y={24}
      rx={1.56}
      ry={1.56}
      width={5}
      height={12}
      fill="#944DFF"
      style={{
        animationPlayState: "running",
        animationDelay: "0s",
      }}
      transform="rotate(300 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="0.9900990099009901s"
        begin="-0.08250825082508251s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47.5}
      y={24}
      rx={1.56}
      ry={1.56}
      width={5}
      height={12}
      fill="#944DFF"
      style={{
        animationPlayState: "running",
        animationDelay: "0s",
      }}
      transform="rotate(330 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="0.9900990099009901s"
        begin="0s"
        repeatCount="indefinite"
      />
    </rect>
  </svg>
);

const Indicator = () => {
  return <SvgLoading />;
};

export default Indicator;
