import React from "react";
import Box from "@mui/material/Box";
import UtilityItem from "./UtilityItem";
import UI from "../../../../@components/UI";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

const Utilities = ({ utilityTagsVisible = true, customStyles, collection }) => {
  return (
    <>
      {collection?.utilities ? (
        <Box>
          {collection?.utilities?.map((item, index) => {
            const allAccordionItems = (
              <Box>
                <Box sx={{ display: { mobile: "block", md: "none" } }}>
                  {utilityTagsVisible && (
                    <Box sx={{ mb: "25px" }}>
                      <UI.UtilityTag
                        customStyles={{
                          backgroundColor: customStyles.perks_badge_color,
                          perks_badge_text_color:
                            customStyles.perks_badge_text_color,
                        }}
                        utilityType={item?.type}
                        isMainUtility={item?.is_main_utility}
                      />
                    </Box>
                  )}
                  <Box sx={{ mb: "5px" }}>
                    <UtilityItem key={2} customStyles={customStyles} title={"Title"} value={item?.name} />
                  </Box>
                </Box>
                {item?.description && (
                  <UtilityItem
                    key={2}
                    customStyles={customStyles}
                    title={"Description"}
                    value={
                      <UI.ReadMore
                        color={customStyles.card_text_color}
                        sx={{
                          fontFamily: "Quantico",
                          overflowWrap: "anywhere",
                          textTransform: "unset",
                          fontWeight: "bold",
                          opacity: 1,
                        }}
                          maxTextLength={180}
                        >
                        {item?.description}
                      </UI.ReadMore>
                    }
                  />
                )}
                {item?.custom_info_fields?.map((field) => {
                  if (field?.type || field?.input_type === "button") {
                    return null;
                  }
                  return (
                    <UtilityItem
                      customStyles={customStyles}
                      key={field?.id}
                      title={field?.title}
                      value={field?.value}
                      type={field?.type || field?.input_type}
                    />
                  );
                })}

                <UtilityItem
                  customStyles={customStyles}
                  key={1}
                  title={"Price"}
                  value={`${item?.price} ${collection?.currency}`}
                />
              </Box>
            );

            return (
              <Box
                sx={{
                  mb: "10px",
                  border: item?.is_main_utility
                    ? customStyles
                      ? customStyles.border_color
                      : "2px solid #944dff"
                    : "none",
                  boxShadow: item?.is_main_utility
                    ? customStyles
                      ? customStyles.border_color
                      : "0px 0px 10px 0px #944dff"
                    : "none",
                }}
                key={index}
              >
                <UI.Accordion
                  customStyles={customStyles}
                  sx={{
                    background: customStyles
                      ? customStyles.card_background_color
                      : "rgba(27, 30, 47, 1)",
                      color: customStyles
                      ? customStyles.card_text_color
                      : "rgba(27, 30, 47, 1)",
                  }}
                  readonly
                  title={
                    <Box sx={{ display: "flex"}}>
                      <Typography
                        variant={"h4"}
                        sx={{
                          color: customStyles
                            ? customStyles.card_text_color
                            : "rgba(27, 30, 47, 1)",
                          mr: "15px",
                          maxWidth: { mobile: "250px", md: "600px" },
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          fontSize:"16px",
                        }}
                      >
                        <UI.OverflowTip> {item?.name}</UI.OverflowTip>
                      </Typography>
                      {utilityTagsVisible && (
                        <Box
                          sx={{
                            display: { md: "flex", mobile: "none" },
                            alignItems: "center",
                          }}
                        >
                          <UI.UtilityTag
                            customStyles={{
                              backgroundColor: customStyles.perks_badge_color,
                              perks_badge_text_color:
                                customStyles.perks_badge_text_color,
                            }}
                            utilityType={item?.type}
                            isMainUtility={item?.is_main_utility}
                          />
                        </Box>
                      )}
                    </Box>
                  }
                >
                  {allAccordionItems}
                </UI.Accordion>
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box>
          <Box sx={{ mb: "15px" }} key={1}>
            <UI.Accordion
              sx={{ background: "rgba(27, 30, 47, 0.5)" }}
              readonly
              title={
                <Skeleton
                  height={"30px"}
                  width={"100px"}
                  variant={"rectangular"}
                />
              }
            />
          </Box>
          <Box sx={{ mb: "15px" }} key={2}>
            <UI.Accordion
              sx={{ background: "rgba(27, 30, 47, 0.5)" }}
              readonly
              title={
                <Skeleton
                  height={"30px"}
                  width={"100px"}
                  variant={"rectangular"}
                />
              }
            />
          </Box>
          <Box sx={{ mb: "15px" }} key={3}>
            <UI.Accordion
              sx={{ background: "rgba(27, 30, 47, 0.5)" }}
              readonly
              title={
                <Skeleton
                  height={"30px"}
                  width={"100px"}
                  variant={"rectangular"}
                />
              }
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Utilities;
