import * as React from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

const QvrseSlider = styled(Slider)({
  color: "#944dff",
});

export default function CustomizedSlider(props) {
  const { defaultValue, value, ...other } = props;

  return (
    <QvrseSlider
      size="small"
      aria-label="slider"
      defaultValue={value}
      valueLabelDisplay="off"
      {...other}
    />
  );
}
