import React from "react";
import { SketchPicker } from "react-color";
import { Box, Popover } from "@material-ui/core";
import UI from "./index";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";

const ColorPicker = ({ value, onChange }) => {
  const [color, setColor] = React.useState(value);
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    if (value) setColor(value);
  }, [value]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    onChange(color.hex);
  };

  const handleInputChange = (input) => {
    onChange("#" + input.target.value);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          padding: "5px",
          display: "flex",
          alignItems: "center",
          borderRadius: "0px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          marginRight: "10px",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            borderRadius: "0px",
            background: color,
          }}
        />
      </Box>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={displayColorPicker}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Box
          sx={{
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
          }}
          onClick={handleClose}
        />
        <SketchPicker
          disableAlpha
          color={color}
          onChange={(color) => setColor(color.hex)}
          onChangeComplete={handleChange}
        />
      </Popover>
      <UI.TextField
        startAdornment={
          <InputAdornment position="start" sx={{ mr: "3px !important" }}>
            <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
              #
            </Typography>
          </InputAdornment>
        }
        value={value.replace("#", "")}
        onChange={handleInputChange}
        backgroundcolor={"rgba(27, 30, 47, 0.5)"}
        placeholder={"fff"}
      />
    </Box>
  );
};

export default ColorPicker;
