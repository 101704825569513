import React from "react";
import MaterialTextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  notchedOutline: {
    borderColor: "transparent !important",
  },
  disabled: {
    "-webkit-text-fill-color": "rgb(255 255 255 / 30%) !important",
  },
  removeArrows: {
    "& .MuiOutlinedInput-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
    },
  },
}));

const NumberField = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <MaterialTextField
      size={isMobile ? "small" : "large"}
      {...props}
      ref={ref}
      className={classes.removeArrows}
      type="number"
      onWheel={(event) => event.currentTarget.querySelector("input")?.blur()}
      onChange={(event) =>
        event.target.value >= 0 && props.onChange(event.target.value)
      }
      fullwidth
      InputProps={{
        startAdornment: props.startAdornment,
        endAdornment: props.endAdornment,
        style: {
          color: "#fff",
        },
        classes: {
          root: classes.cssOutlinedInput,
          notchedOutline: classes.notchedOutline,
          disabled: classes.disabled,
        },
      }}
      InputLabelProps={{
        style: {
          color: "#fff",
        },
      }}
      sx={{
        width: "100%",
        color: "black",
        backgroundColor: props?.backgroundcolor
          ? props?.backgroundcolor
          : "#1B1E2F",
      }}
      variant="outlined"
      value={props.value || ""}
    />
  );
});

export default NumberField;
