import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import UI from "../../@components/UI";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import { Controller, useForm, useWatch } from "react-hook-form";
import Alert from "@mui/material/Alert";
import useHandleErrors from "../../@components/UI/useHandleErrors";
import { useSnackbar } from "notistack";
import useService from "../useService";
import { useUserContext } from "../../@components/userContext";

const RegisterDialog = ({ toggleOpen }) => {
  const { user: loggedInUser } = useUserContext();
  const [isLoading, setIsLoading] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const { handleApiError } = useHandleErrors();
  const { enqueueSnackbar } = useSnackbar();
  const service = useService();

  const resetPassword = async () => {
    await service
      .resetPassword({ email: loggedInUser?.email })
      .then(async () => {
        enqueueSnackbar("Password reset email was successfully sent", {
          variant: "success",
        });
        setOpen(false);
      })
      .catch((error) => {
        handleApiError(error);
        setIsLoading(false);
      });
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      old_password: "",
      new_password: "",
    },
    mode: "all",
  });

  const new_password = useWatch({ control, name: "new_password" });

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const data = {
      old_password: formData.old_password,
      new_password: formData.new_password,
    };

    await service
      .changePassword(data)
      .then(async () => {
        enqueueSnackbar("Password was successfully changed", {
          variant: "success",
        });
        setIsLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        handleApiError(error);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);
  const handleClose = () => {
    setOpen(false);
    reset();
  };
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          backgroundColor: "#1B1E2F !important",
          padding: { md: "40px 30px", mobile: "40px 10px" },
          width: "700px",
          margin: { mobile: "0px" },
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ p: { mobile: "0px" } }}>
        <CloseIcon
          onClick={() => {
            setOpen(false);
            reset();
          }}
          sx={{
            position: "absolute",
            right: 30,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />

        <Grid container>
          <Grid item mobile={12} md={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography
                variant={"h6"}
                sx={{ fontSize: "30px", textAlign: {md:"center", mobile:"left" }, px: "10px" }}
              >
                Change Password
              </Typography>

              <Box sx={{ mt:  {md:"30px", mobile:"10px" }, px: "10px" }}>
                <Typography
                  variant={"subtitle1"}
                  sx={{ fontSize: "16px", mb:  {md:"40px", mobile:"30px" } }}
                >
                  A strong password helps prevent unauthorized access to your
                  account.
                </Typography>
                <Box sx={{ mt: "26px" }}>
                  <Typography
                    variant={"h5"}
                    sx={{ fontSize: "16px !important", mb: "5px" }}
                  >
                    Current Password
                  </Typography>
                  <Controller
                    name={"old_password"}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <UI.PasswordField
                        {...field}
                        backgroundcolor={"#303346"}
                        fullWidth
                        placeholder={"Old Password"}
                      />
                    )}
                  />
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors.old_password && (
                      <Alert
                        sx={{
                          color: (theme) => theme.palette.colors.darkRed,
                        }}
                        variant="outlined"
                        severity="error"
                      >
                        {errors.old_password?.type === "required" &&
                          "Password is required"}
                      </Alert>
                    )}
                  </Typography>
                  <Typography
                    variant={"subtitle1"}
                    sx={{ fontSize: "16px !important", mt: {md:"10px", mobile:"0px"} }}
                  >
                    Forgot you password?{" "}
                    <Box
                      onClick={() => resetPassword()}
                      component={"span"}
                      sx={{
                        cursor: "pointer",
                        color: (theme) => theme.palette.colors.primary,
                      }}
                    >
                      Reset password
                    </Box>
                  </Typography>
                </Box>
                <Box sx={{ mt: "26px" }}>
                  <Typography
                    variant={"h5"}
                    sx={{ fontSize: "16px !important", mb: "5px" }}
                  >
                    New Password
                  </Typography>
                  <Controller
                    name={"new_password"}
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: /^.{8,}$/,
                        message: "Password must be at least 8 characters long",
                      },
                    }}
                    render={({ field }) => (
                      <UI.PasswordField
                        {...field}
                        backgroundcolor={"#303346"}
                        fullWidth
                        placeholder={"New Password"}
                      />
                    )}
                  />
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors.new_password && (
                      <Alert
                        sx={{
                          color: (theme) => theme.palette.colors.darkRed,
                        }}
                        variant="outlined"
                        severity="error"
                      >
                        {errors.new_password?.type === "pattern" &&
                          errors.new_password.message}
                        {errors.new_password?.type === "required" &&
                          "New password is required"}
                      </Alert>
                    )}
                  </Typography>
                </Box>
                <Box sx={{ mt: "26px" }}>
                  <Typography
                    variant={"h5"}
                    sx={{ fontSize: "16px !important", mb: "5px" }}
                  >
                    Confirm Password
                  </Typography>
                  <Controller
                    name={"confirmPassword"}
                    control={control}
                    rules={{
                      required: true,
                      validate: (value) =>
                        value === new_password || "Password does not match",
                    }}
                    render={({ field }) => (
                      <UI.PasswordField
                        {...field}
                        backgroundcolor={"#303346"}
                        fullWidth
                        placeholder={"Confirm New Password"}
                      />
                    )}
                  />
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors.confirmPassword && (
                      <Alert
                        sx={{
                          color: (theme) => theme.palette.colors.darkRed,
                        }}
                        variant="outlined"
                        severity="error"
                      >
                        {errors.confirmPassword?.type === "validate" &&
                          errors.confirmPassword.message}
                        {errors.confirmPassword?.type === "required" &&
                          "Password confirm is required"}
                      </Alert>
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: "45px",
                  px: "15px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <UI.Button
                  disabled={Object.keys(errors).length}
                  sx={{ width: "210px" }}
                  title={"Change Password"}
                  type={"primary"}
                />
              </Box>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle2"}>
          Changing password in progress ...
        </Typography>
        <Typography variant={"subtitle2"}>
          Please do not close this window.
        </Typography>
      </UI.Busy.FullscreenIndicator>
    </MaterialDialog>
  );
};

export default RegisterDialog;
