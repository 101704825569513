import React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UI from "../../../../../@components/UI";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import Alert from "@mui/material/Alert";

const useStyles = makeStyles((theme) => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const RequestInfoDialog = ({ fields, onConfirm, onClose }) => {
  const classes = useStyles();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (form) => {
    onConfirm(form);
  };
  return (
    <Dialog
      fullWidth
      PaperProps={{
        sx: {
          width: "800px",
          p: { md: "50px", mobile: "30px 20px" },
          margin: { md: "32px", mobile: "10px" },
          backgroundColor: (theme) => theme.palette.colors.darkGray,
        },
      }}
      onClose={onClose}
      open={true}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant={"h2"}sx= {{ maxWidth: {mobile:"300px", md:"700px"} }}>Requested Information</Typography>
        <Box sx={{ mt: { md: "40px", mobile: "20px" } }}>
          <Typography variant={"subtitle1"} sx={{ fontSize: "16px !important" }}>
            Please provide the following information so the brand can create
            personalized NFT experiences for you.
          </Typography>
        </Box>
        <Box sx={{ mt: { md: "30px", mobile: "20px" } }}>
          {fields?.map((data) => {
            return (
              <Box className={classes.inputOuter} key={data?.id}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h4"}>{data?.title}</Typography>
                  <UI.Tooltip>{data?.tooltip}</UI.Tooltip>
                </Box>
                <Controller
                  name={data?.title}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.TextField
                      {...field}
                      backgroundcolor={"#303346"}
                      placeholder={data?.placeholder}
                    />
                  )}
                />
                <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                  {errors[data?.title]?.type === "required" && (
                    <Alert
                      sx={{ color: (theme) => theme.palette.colors.darkRed }}
                      variant="outlined"
                      severity="error"
                    >
                      {`${data?.title} is required`}
                    </Alert>
                  )}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: { md: "50px", mobile: "35px" } }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <UI.Button
              type={"primary"}
              title={"Confirm"}
              sx={{
                width: "150px",
                height: "50px",
                filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
              }}
            />
            <UI.Button
              type={"terciary"}
              onClick={onClose}
              title={"Cancel"}
              sx={{
                mt: "20px",
                width: "150px",
                height: "50px",
              }}
            />
          </Box>
        </Box>
      </form>
    </Dialog>
  );
};

export default RequestInfoDialog;
