import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { ethers } from "ethers";
import UI from "../../../@components/UI";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { Controller, useForm } from "react-hook-form";
import useService from "../useService";
import useTokenService from "../../tresuary/tokens/useService";
import { useSnackbar } from "notistack";
import useHandleErrors from "../../../@components/UI/useHandleErrors";
import Alert from "@mui/material/Alert";
import { useMenu } from "../../context";
import { useAccount } from "wagmi";
import governorArtifact from "../../../abis/contracts/modules/governor/GovernorERC20.sol/DaoGovernorERC20.json";
import treasuryArtifact from "../../../abis/contracts/qtech/treasury/Treasury.sol/QtechTreasury.json";
import Skeleton from "@mui/material/Skeleton";
import voteWalletIcon from "../../../static/voteWallet.png";
import customHooks from "../../../@components/hooks";

const useStyles = makeStyles(() => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Index = ({ DAO }) => {
  const signer = customHooks.useEthersSigner();
  const service = useService(DAO?.id);
  const { updateMenu } = useMenu();
  const tokenService = useTokenService(DAO?.id);
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { isConnected } = useAccount();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFetchingTokens, setIsFetchingTokens] = React.useState(false);
  const { handleApiError, handleMetamaskError } = useHandleErrors();
  const [tresuaryTokens, setTresuaryTokens] = React.useState([]);
  const [proposalSent] = React.useState(false);

  React.useEffect(() => {
    setIsFetchingTokens(true);
    const fetchData = async () => {
      await tokenService
        .list()
        .then((response) => {
          const tokens = response.data.tokens.map((token, index) => {
            token.id = index;
            return token;
          });
          setTresuaryTokens(tokens);
          //Set utilities value
          setValue("token_id", response.data.tokens[0].id);
        })
        .catch((error) => {
          handleApiError(error);
        })
        .finally(() => {
          setIsFetchingTokens(false);
        });
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      proposal_type: "withdraw",
      amount: "",
      recipient: "",
      description: "",
      reference: "",
      document_url: "",
      token_id: "",
    },
  });

  const encodeNativeFunctioncall = (data) => {
    const treasury = new ethers.Contract(
      DAO.treasury_contract.address,
      treasuryArtifact.abi,
      signer
    );
    return treasury.interface.encodeFunctionData(
      "transferNativeTokens(address recipient, uint256 amount)",
      [data.recipient, ethers.parseEther(data.amount)]
    );
  };

  const encodeERC20FunctionCall = (data) => {
    const treasury = new ethers.Contract(
      DAO.treasury_contract.address,
      treasuryArtifact.abi,
      signer
    );
    return treasury.interface.encodeFunctionData(
      "transferERC20Tokens(address tokenAddress, address recipient, uint256 amount)",
      [data.token.token_address, data.recipient, ethers.parseEther(data.amount)]
    );
  };

  const onSubmit = async (form) => {
    const selectedToken = tresuaryTokens.find(
      (token) => token.id === form.token_id
    );
    const data = {
      ...form,
      document_url: form.document_url.ipfs,
      token: selectedToken,
    };
    setIsLoading(true);

    let encodedFunctionCall;
    if (data.token.token_type === "NATIVE") {
      encodedFunctionCall = encodeNativeFunctioncall(data);
    } else {
      encodedFunctionCall = encodeERC20FunctionCall(data);
    }

    try {
      const proposalData = {
        targets: [DAO?.treasury_contract?.address],
        values: [0],
        calldatas: [encodedFunctionCall],
        description: data.description,
      };

      const governor = new ethers.Contract(
        DAO?.governor_contract?.address,
        governorArtifact.abi,
        signer
      );

      const proposeTx = await governor.propose(
        proposalData.targets,
        proposalData.values,
        proposalData.calldatas,
        proposalData.description
      );

      const proposeReceipt = await proposeTx.wait(1);

      const proposalId = proposeReceipt.logs[0].args.proposalId.toString();

      // Save proposal to BE.
      service
        .save({
          proposal_type: "withdraw",
          proposal_hash_id: proposalId,
          calldatas: [],
          signatures: [],
          targets: [],
          start_block: Number(
            proposeReceipt.logs[0].args.startBlock
          ).toString(),
          end_block: Number(proposeReceipt.logs[0].args.endBlock).toString(),
          status: "Active",
          progress: 0,
          data: {
            proposal_data: proposalData,
            ...data,
          },
        })
        .then((response) => {
          enqueueSnackbar("Proposal succesfully sent.", { variant: "success" });
          updateMenu();
          setIsLoading(false);
          history.push({
            pathname: `/qvrse/vote/${response?.data?.id}/withdraw`,
            state: { scrollToTop: true },
          });
        })
        .catch((error) => {
          handleApiError(error);
          setIsLoading(false);
        });
    } catch (error) {
      handleMetamaskError(error);
      setIsLoading(false);
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{ width: "100%", background: "rgba(27, 30, 47, 0.5)" }}
    >
      <UI.Busy.FullscreenIndicator show={isLoading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { md: "70px", mobile: "0px" },
            pb: "20px",
            pt: { md: "55px", mobile: "30px" },
          }}
        >
          <UI.BackButton
            fontSize={"14px !important"}
            title={"BACK TO TRESUARY"}
            onClick={() => history.go(-1)}
          />
        </Box>

        {!proposalSent && (
          <Box
            sx={{
              width: "100%",
              px: { md: "80px", mobile: "10px" },
              pb: "10px",
            }}
          >
            <Typography
              variant={"h1"}
              sx={{
                fontSize: "30px !important",
                lineHeight: "20px !important",
                mb: "25px",
                mt: "10px",
              }}
            >
              WITHDRAW FUNDS
            </Typography>

            <Typography variant={"subtitle1"} sx={{ mb: "30px" }}>
              Request for funds withdrawal. Once the request is sent, proposal
              will be sent for voting. If the voting is succesfull, funds will
              be transfered to the recipient address.
            </Typography>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item md={12} mobile={12}>
                <Box className={classes.inputOuter} sx={{ mb: "15px" }}>
                  <Box sx={{ display: "flex", mb: "10px" }}>
                    <Typography
                      variant={"h2"}
                      sx={{ fontSize: "18px !important" }}
                    >
                      Token
                    </Typography>
                    <UI.Tooltip>Token to withdraw</UI.Tooltip>
                  </Box>

                  {!isFetchingTokens ? (
                    <Controller
                      name={"token_id"}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <UI.Select
                          {...field}
                          disabled={!!isLoading}
                          id={"token_id"}
                        >
                          {tresuaryTokens?.map((token) => {
                            return (
                              <MenuItem value={token.id}>
                                {token?.token_symbol}
                              </MenuItem>
                            );
                          })}
                        </UI.Select>
                      )}
                    />
                  ) : (
                    <Skeleton variant={"rectangular"} height={"56px"} />
                  )}
                </Box>
              </Grid>
              <Grid item md={12} mobile={12}>
                <Box className={classes.inputOuter} sx={{ mb: "0px" }}>
                  <Box sx={{ display: "flex", mb: "10px" }}>
                    <Typography
                      variant={"h2"}
                      sx={{ fontSize: "18px !important" }}
                    >
                      Amount
                    </Typography>
                    <UI.Tooltip>Amount to withdraw</UI.Tooltip>
                  </Box>
                  <Controller
                    name={"amount"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <UI.TextField {...field} placeholder={"Amount"} />
                    )}
                  />
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors.amount?.type === "required" && (
                      <Alert
                        sx={{ color: (theme) => theme.palette.colors.darkRed }}
                        variant="outlined"
                        severity="error"
                      >
                        Amount is required
                      </Alert>
                    )}
                  </Typography>
                </Box>
              </Grid>

              <Grid item md={12} mobile={12}>
                <Box className={classes.inputOuter} sx={{ mb: "0px" }}>
                  <Box sx={{ display: "flex", mb: "10px" }}>
                    <Typography
                      variant={"h2"}
                      sx={{ fontSize: "18px !important" }}
                    >
                      Recipient
                    </Typography>
                    <UI.Tooltip>
                      Address of the recipient who will recieve the tokens
                    </UI.Tooltip>
                  </Box>
                  <Controller
                    name={"recipient"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <UI.TextField {...field} placeholder={"Recipient"} />
                    )}
                  />
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors.recipient?.type === "required" && (
                      <Alert
                        sx={{ color: (theme) => theme.palette.colors.darkRed }}
                        variant="outlined"
                        severity="error"
                      >
                        Recipient is required
                      </Alert>
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} mobile={12}>
                <Box className={classes.inputOuter} sx={{ mb: "0px" }}>
                  <Box sx={{ display: "flex", mb: "10px" }}>
                    <Typography
                      variant={"h2"}
                      sx={{ fontSize: "18px !important" }}
                    >
                      Reference
                    </Typography>
                    <UI.Tooltip>Withdrawal reference</UI.Tooltip>
                  </Box>
                  <Controller
                    name={"reference"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <UI.TextField {...field} placeholder={"Reference"} />
                    )}
                  />
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors.reference?.type === "required" && (
                      <Alert
                        sx={{ color: (theme) => theme.palette.colors.darkRed }}
                        variant="outlined"
                        severity="error"
                      >
                        Reference is required
                      </Alert>
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} mobile={12}>
                <Box className={classes.inputOuter} sx={{ mb: "0px" }}>
                  <Box sx={{ display: "flex", mb: "10px" }}>
                    <Typography
                      variant={"h2"}
                      sx={{ fontSize: "18px !important" }}
                    >
                      Description
                    </Typography>
                    <UI.Tooltip>
                      Describe in detail about this expense
                    </UI.Tooltip>
                  </Box>
                  <Controller
                    name={"description"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <UI.TextField
                        multiline
                        rows={6}
                        {...field}
                        placeholder={"Description"}
                      />
                    )}
                  />
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors.description?.type === "required" && (
                      <Alert
                        sx={{ color: (theme) => theme.palette.colors.darkRed }}
                        variant="outlined"
                        severity="error"
                      >
                        Description is required
                      </Alert>
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} mobile={12}>
                <Box
                  sx={{
                    mt: "0px",
                    pt: "0px !important",
                  }}
                >
                  <Controller
                    name={"document_url"}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <UI.Upload
                        {...field}
                        id={"profile"}
                        title={"Upload a document"}
                        subtitle={"(PDF, up to 10 MB)"}
                        acceptFiles={"application/pdf"}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box
          sx={{
            width: "100%",
            pb: "75px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "220px", mt: "0px" }}>
              <UI.Button
                disabled={!isConnected || DAO?.isMember === false}
                title={"withdraw"}
                type={"primary"}
                startIcon={
                  <Box
                    component={"img"}
                    src={voteWalletIcon}
                    sx={{ width: "43px", height: "19px", mr: "8px" }}
                  />
                }
              />
            </Box>
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default Index;
