import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UI from "../../../../../@components/UI";
import truncateEthAddress from "truncate-eth-address";
import backArrow from "../../../../../static/back-arrow.png";

const AccordionItem = ({ item }) => {
  return (
    <Box sx={{ display: "flex", mb: "10px" }}>
      <Box
        sx={{
          display: "flex",
          mt: "5px",
          mr: "20px",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <img
          className={"backArrow"}
          style={{
            transition: "0.4s ease-in",
            transform: "rotate(180deg)",
          }}
          src={backArrow}
          alt={"back-arrow"}
          width={"15px"}
          height={"15px"}
        />
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant={"subtitle1"}>{`${item?.title}: `}</Typography>
        </Box>
        {
          <Box>
            <Typography
              variant={"h4"}
              sx={{
                overflowWrap: "anywhere",
                textTransform: "unset",
                lineHeight: "25px",
                fontWeight: "bold",
                ml: "10px",
              }}
            >
              {item?.value}
            </Typography>
          </Box>
        }
      </Box>
    </Box>
  );
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#ffffff" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const List = ({ items, onRecieptToggle, onNftToggle }) => {
  return (
    <Box>
      {items?.map((item) => {
        const additionalInfoKeys = Object.keys(item.additional_info);
        return (
          <Accordion
            sx={{
              backgroundColor: (theme) => theme.palette.colors.darkGray,
              mb: "16px",
            }}
          >
            <AccordionSummary
              sx={{ height: "90px", padding: { md: "35px", mobile: "15px" } }}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant={"subtitle2"}
                    sx={{
                      lineHeight: "unset",
                      display: { md: "flex", mobile: "none" },
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >{`TICKET ID: ${item.ticket_id}`}</Typography>
                  <Typography
                    variant={"subtitle2"}
                    sx={{
                      lineHeight: "unset",
                      display: { md: "none", mobile: "flex" },
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >{`#${item.ticket_id}`}</Typography>
                  <Box
                    sx={{
                      ml: "30px",
                      display: { md: "flex", mobile: "none" },
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant={"subtitle1"}>
                      {item?.user && (
                        <Box onClick={(e) => e.stopPropagation()}>
                          <UI.CopyToClipboard
                            label={item?.user?.email}
                            value={item?.user?.email}
                            showBorder
                          />
                        </Box>
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box>
                    <UI.Checkbox
                      sx={{ mr: { mobile: "0px", md: "16px" } }}
                      value={item.nft_used}
                      label={"NFT utilized"}
                      onClick={(e) => {
                        e.stopPropagation();
                        onNftToggle(item.id);
                      }}
                    />
                  </Box>
                  <Box sx={{ ml: { md: "30px", mobile: "10px" } }}>
                    <UI.Checkbox
                      sx={{ mr: { mobile: "0px", md: "16px" } }}
                      value={item.receipt_issued}
                      label={"Receipt sent"}
                      onClick={(e) => {
                        e.stopPropagation();
                        onRecieptToggle(item.id);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{ padding: { md: "15px  35px", mobile: "15px 15px" } }}
            >
              <Box sx={{ display: { md: "none", mobile: "block" } }}>
                <AccordionItem
                  item={{
                    title: "Email",
                    value: (
                      <Box
                        sx={{ overflowWrap: "anywhere" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {item?.user?.email}
                      </Box>
                    ),
                  }}
                />
              </Box>
              <Box>
                <AccordionItem
                  item={{
                    title: "Wallet address",
                    value: (
                      <Box onClick={(e) => e.stopPropagation()}>
                        <UI.CopyToClipboard
                          label={truncateEthAddress(item?.buyer_address)}
                          value={item?.buyer_address}
                          showBorder
                        />
                      </Box>
                    ),
                  }}
                />
              </Box>
              {additionalInfoKeys.map((key) => {
                return (
                  <AccordionItem
                    item={{ title: key, value: item.additional_info[key] }}
                  />
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default List;
