import React from "react";
import MobileButton from "./MobileButton";
import DesktopButton from "./DesktopButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAccount, useDisconnect } from "wagmi";
import { useUserContext } from "../../userContext";
import WrongAccountDialog from "../../../profile/dialogs/WrongAccountDialog";
import AccountAlreadyAssignedDialog from "../../../profile/dialogs/AccountAlreadyAssignedDialog";
import AccountNotAssignedDialog from "../../../profile/dialogs/AccountNotAssignedDialog";
import Auth from "../../auth";
import useHandleErrors from "../useHandleErrors";

const Index = ({ onProfileClick }) => {
  const { user: loggedInUser } = useUserContext();
  const { disconnect } = useDisconnect();
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [signInBusy, setSignInBusy] = React.useState(true);
  const [showWrongAccountDialog, setShowWrongAccountDialog] =
    React.useState(false);
  const [showAccountAssignedDialog, setShowAccountAssignedDialog] =
    React.useState(false);
  const [showAccountNotAssignedDialog, setShowAccountNotAssignedDialog] =
    React.useState(false);
  const [connectingWalletInProgress, setConnectingWalletInProgress] =
    React.useState(false);
  const { connectWalletToAccount, logout } = Auth.useAuth();
  const { handleMetamaskError } = useHandleErrors();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { isConnecting, isConnected, address } = useAccount();

  //Preserve state on reload
  React.useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    if (access_token && isConnected) {
      setIsAuthenticated(true);
      setSignInBusy(false);
    }
  }, [isConnected]);

  React.useEffect(() => {
    if (isConnecting) {
      setConnectingWalletInProgress(true);
    }
  }, [isConnecting]);

  //Connect Wallet
  React.useEffect(() => {
    if (connectingWalletInProgress && isConnected) {
      connectWalletToAccount()
        .then(() => {
          setIsAuthenticated(true);
          setSignInBusy(false);
          window.location.reload();
        })
        .catch((error) => {
          setSignInBusy(true);
          setIsAuthenticated(false);
          if (error?.type === "metamask") {
            handleMetamaskError(error?.error);
            disconnect();
          } else {
            if (
              loggedInUser?.address &&
              error.response.data.message.startsWith("you are not")
            ) {
              setShowWrongAccountDialog(Math.random());
            } else if (
              error.response.data.message.includes("is not assigned to profile")
            ) {
              setShowAccountNotAssignedDialog(Math.random());
            } else if (
              error.response.data.message.includes("is already assigned")
            ) {
              setShowAccountAssignedDialog(Math.random());
            }
          }
        });
    }
    // eslint-disable-next-line
  }, [isConnected]);

  // If user is logged in and metamask account changes. logout current user and reload page.
  React.useEffect(() => {
    if (isAuthenticated) {
      logout();
      window.location.reload();
    }

    // eslint-disable-next-line
  }, [address]);

  //Disconnect wallet on page reload if error is present.
  React.useEffect(() => {
    if (
      showAccountAssignedDialog ||
      showAccountNotAssignedDialog ||
      showWrongAccountDialog
    ) {
      window.onbeforeunload = function () {
        disconnect();
        return true;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [
    disconnect,
    showWrongAccountDialog,
    showAccountNotAssignedDialog,
    showAccountAssignedDialog,
  ]);

  return (
    <>
      {isMobile ? (
        <MobileButton
          onProfileClick={onProfileClick}
          isAuthenticated={isAuthenticated}
          signInBusy={signInBusy}
        />
      ) : (
        <DesktopButton
          onProfileClick={onProfileClick}
          isAuthenticated={isAuthenticated}
          signInBusy={signInBusy}
        />
      )}
      <WrongAccountDialog toggleOpen={showWrongAccountDialog} />
      <AccountAlreadyAssignedDialog toggleOpen={showAccountAssignedDialog} />
      <AccountNotAssignedDialog toggleOpen={showAccountNotAssignedDialog} />
    </>
  );
};

export default Index;
