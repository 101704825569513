import React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import useProposalService from "../../../proposal/useService";
import UI from "../../../../@components/UI";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import QPEOPLE from "../../../qpeople";
import VotingPanel from "./VotingPanel";
import Link from "@mui/material/Link";
import Stepper from "../Stepper";
import useBlockchainState from "../useBlockchainState";
import useExternalURL from "../../../../@components/hooks/useExternalURL";
import linkedInLogo from "../../../../static/linkedin.png";
import discordLogo from "../../../../static/discord.png";
import twitterLogo from "../../../../static/twitter.png";
import telegramLogo from "../../../../static/telegram.png";
import instagramLogo from "../../../../static/instagram.png";
import facebookLogo from "../../../../static/facebook.png";
import Tooltip from "@mui/material/Tooltip";
import documentIcon from "../../../../static/pdf-upload.png";

const steps = ["Pre-vote", "Active", "Pending", "Executed"];

const SocialItem = ({ logo, url }) => {
  const { getURL } = useExternalURL();
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Link target="_blank" href={getURL(url)} underline="hover">
        <Box
          component={"img"}
          src={logo}
          sx={{
            "&:hover": {
              transform: "scale(1.1)",
            },

            transition: "all 0.5s ease-in-out",
            cursor: "pointer",
            width: "35px",
            height: "35px",
          }}
        />
      </Link>
    </Box>
  );
};

const Item = (props) => {
  const { children } = props;
  return (
    <Box {...props}>
      <Box
        sx={{
          background: "rgba(27, 30, 47, 0.5)",
          display: "flex",
          alignItems: "center",
          py: { md: "18px", mobile: "15px" },
          pl: { md: "30px", mobile: "15px" },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const Index = ({ DAO }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const history = useHistory();
  const { id } = useParams();
  const [messageFromBrand, setMessageFromBrand] = React.useState(false);
  const proposalService = useProposalService();
  const [proposal, setProposal] = React.useState(null);
  const { proposalState } = useBlockchainState(DAO, proposal);

  React.useEffect(() => {
    if (proposalState === "Pending") {
      setActiveStep(0);
    } else if (proposalState === "Active") {
      setActiveStep(1);
    } else if (proposalState === "Queued" || proposalState === "Succeeded") {
      setActiveStep(2);
    } else if (proposalState === "Executed") {
      setActiveStep(3);
    } else if (proposalState === "Defeated") {
      setActiveStep(3);
    }
  }, [proposalState]);

  const fetchDatabaseData = async () => {
    proposalService
      .proposal(id)
      .then((response) => {
        setProposal(response.data);
        response.data.data.messageToUsers &&
          setMessageFromBrand(response.data.data.messageToUsers);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  React.useEffect(() => {
    id && fetchDatabaseData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          backgroundColor: "transparent",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              mt: { md: "50px", mobile: "0px" },
              display: { md: "block", mobile: "none" },
            }}
          >
            <Stepper
              steps={[
                "Pre-vote",
                "Active",
                "Pending",
                proposalState === "Defeated" ? "Defeated" : "Executed",
              ]}
              proposalState={proposalState}
              activeStep={activeStep}
            />
          </Box>
          <Box
            sx={{
              mt: { md: "50px", mobile: "0px" },
              display: { md: "none", mobile: "block" },
            }}
          >
            <Stepper steps={steps} activeStep={activeStep} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { md: "70px", mobile: "0px" },
            pb: "20px",
            pt: { md: "40px", mobile: "40px" },
          }}
        >
          <UI.BackButton
            fontSize={"14px !important"}
            title={"Back to votes"}
            onClick={() => history.push(`/qvrse/vote`)}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: { md: "75px 80px", mobile: "20px 10px" },
            pt: "0px !important",
          }}
        >
          <Grid container>
            <Grid item mobile={12} md={12}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: { mobile: "55px", md: "70px" },
                    mt: "0px",
                  }}
                >
                  <Box
                    component={"img"}
                    sx={{
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      mr: "20px",
                      textAlign: "center",
                      objectFit: "cover",
                    }}
                    src={proposal?.data?.avatar_url}
                    alt={"dao avatar"}
                  />

                  <Typography
                    variant={"h2"}
                    sx={{
                      fontSize: "30px !important",
                      lineHeight: "20px !important",
                      wordBreak: "break-word",
                    }}
                  >
                    {`NEW BRAND`}
                  </Typography>
                </Box>
                <Typography
                  variant={"h2"}
                  sx={{
                    fontSize: "24px !important",
                    lineHeight: "20px !important",
                    mb: "23px",
                  }}
                >
                  {proposal?.data?.name}
                </Typography>
                <Item>
                  <Box sx={{ flexDirection: "column", display: "flex" }}>
                    <Typography
                      variant={"h2"}
                      sx={{
                        fontSize: {
                          md: "18px !important",
                          mobile: "16px !important",
                        },
                        mr: "10px",
                        lineHeight: "1.5",
                      }}
                    >
                      Description
                    </Typography>
                    <Typography
                      variant={"subtitle1"}
                      sx={{
                        mt: "6px",
                        pr: "30px",
                        fontSize: {
                          md: "18px !important",
                          mobile: "16px !important",
                        },
                        lineHeight: "25px",
                        wordBreak: "break-word",
                        color: "rgba(255, 255, 255, 0.8);",
                      }}
                    >
                      {proposal?.data?.description}
                    </Typography>
                    {proposal?.data?.whitepaper_url && (
                      <Box
                        sx={{
                          display: "flex",
                          mt: { mobile: "12px", md: "20px" },
                        }}
                      >
                        {proposal?.data?.whitepaper_url && (
                          <Link
                            target="_blank"
                            href={proposal?.data?.whitepaper_url}
                            underline="hover"
                          >
                            <Tooltip title={"Document"} arrow>
                              <Box
                                alt={"brand document"}
                                component={"img"}
                                src={documentIcon}
                                sx={{
                                  cursor: "pointer",
                                  width: "26px",
                                  height: "35px",

                                  "&:hover": {
                                    transform: "scale(1.1)",
                                  },
                                  transition: "all 0.5s ease-in-out",
                                }}
                              />
                            </Tooltip>
                          </Link>
                        )}
                      </Box>
                    )}
                  </Box>
                </Item>
                {proposal?.data?.webpage_url && (
                  <Item sx={{ mt: { mobile: "12px", md: "18px" } }}>
                    <Box sx={{ flexDirection: "column", display: "flex" }}>
                      <Typography
                        variant={"h2"}
                        sx={{
                          fontSize: {
                            md: "18px !important",
                            mobile: "16px !important",
                          },
                          mr: "10px",
                          lineHeight: "1.5",
                        }}
                      >
                        Website
                      </Typography>
                      <Link
                        sx={{ mt: "6px" }}
                        target="_blank"
                        href={proposal?.data?.webpage_url}
                        underline="hover"
                      >
                        {proposal?.data?.webpage_url}
                      </Link>
                    </Box>
                  </Item>
                )}
                {proposal?.data?.socials &&
                  Object.keys(proposal?.data?.socials).length > 0 && (
                    <Item sx={{ mt: { mobile: "12px", md: "18px" } }}>
                      <Box sx={{ flexDirection: "column", display: "flex" }}>
                        <Typography
                          variant={"h2"}
                          sx={{
                            fontSize: {
                              md: "18px !important",
                              mobile: "16px !important",
                            },
                            mr: "10px",
                            lineHeight: "1.5",
                          }}
                        >
                          Socials
                        </Typography>
                        <Box
                          sx={{
                            mt: "16px",
                            display: "flex",
                          }}
                        >
                          {proposal?.data?.socials?.linkedin && (
                            <Box sx={{ mr: "8px" }}>
                              <SocialItem
                                logo={linkedInLogo}
                                url={proposal?.data?.socials?.linkedin}
                              />
                            </Box>
                          )}
                          {proposal?.data?.socials?.discord && (
                            <Box sx={{ mx: "8px" }}>
                              <SocialItem
                                logo={discordLogo}
                                url={proposal?.data?.socials?.discord}
                              />
                            </Box>
                          )}
                          {proposal?.data?.socials?.twitter && (
                            <Box sx={{ mx: "8px" }}>
                              <SocialItem
                                logo={twitterLogo}
                                url={proposal?.data?.socials?.twitter}
                              />
                            </Box>
                          )}
                          {proposal?.data?.socials?.telegram && (
                            <Box sx={{ mx: "8px" }}>
                              <SocialItem
                                logo={telegramLogo}
                                url={proposal?.data?.socials?.telegram}
                              />
                            </Box>
                          )}
                          {proposal?.data?.socials?.instagram && (
                            <Box sx={{ mx: "8px" }}>
                              <SocialItem
                                logo={instagramLogo}
                                url={proposal?.data?.socials?.instagram}
                              />
                            </Box>
                          )}
                          {proposal?.data?.socials?.facebook && (
                            <Box sx={{ mx: "8px" }}>
                              <SocialItem
                                logo={facebookLogo}
                                url={proposal?.data?.socials?.facebook}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Item>
                  )}
                <Item sx={{ mt: { mobile: "12px", md: "18px" } }}>
                  <Box sx={{ flexDirection: "column", display: "flex" }}>
                    <Typography
                      variant={"h2"}
                      sx={{
                        fontSize: {
                          md: "18px !important",
                          mobile: "16px !important",
                        },
                        mr: "10px",
                        lineHeight: "1.5",
                      }}
                    >
                      Message for the QVRSE community
                    </Typography>
                    <Typography
                      variant={"subtitle1"}
                      sx={{
                        mt: "6px",
                        pr: "30px",
                        fontSize: {
                          md: "18px !important",
                          mobile: "16px !important",
                        },
                        lineHeight: "25px",
                        wordBreak: "break-word",
                        color: "rgba(255, 255, 255, 0.8);",
                      }}
                    >
                      {messageFromBrand}
                    </Typography>
                  </Box>
                </Item>
              </Box>
            </Grid>
            <Grid
              sx={{ mt: { mobile: "0px", md: "0px" } }}
              item
              mobile={12}
              md={12}
            >
              <Box
                sx={{
                  mt: "0px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ mb: { mobile: "12px", md: "20px" }, mt: "50px" }}>
                  <Typography variant={"h2"}>Voting</Typography>
                </Box>
                <VotingPanel
                  DAO={DAO}
                  proposal={proposal}
                  onChange={() => fetchDatabaseData()}
                />
              </Box>
            </Grid>
            <Grid
              sx={{ mt: { mobile: "50px", md: "50px" } }}
              item
              mobile={12}
              md={12}
            >
              <Box>
                <Typography variant={"h2"}>PARTICIPANTS</Typography>
                <Box sx={{ mt: { mobile: "12px", md: "20px" } }}>
                  {proposal?.votes?.length > 0 && (
                    <QPEOPLE.List items={proposal.votes} />
                  )}
                  {proposal?.votes?.length === 0 && (
                    <UI.NoResults text={"No Participants found :("} />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default Index;
