import Box from "@mui/material/Box";
import forwardArrow from "../../../../../static/forward-arrow.png";
import Typography from "@mui/material/Typography";
import React from "react";
import moment from "moment";

const AccordionItem = (props) => {
  const type = props?.type;
  let displayValue = props?.value;

  if (type === "date") {
    displayValue = moment(props?.value).format("DD MMM YYYY");
  } else if (type === "time") {
    displayValue = moment(props?.value).format("HH:mm");
  }
  return (
    <Box sx={{ display: "flex", mb: "10px" }}>
      <Box
        sx={{
          display: "flex",
          mt: "5px",
          mr: { md: "20px", mobile: "15px" },
        }}
      >
        <img
          className={"backArrow"}
          src={forwardArrow}
          alt={"back-arrow"}
          width={"15px"}
          height={"15px"}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <Typography variant={"subtitle1"}>{`${props?.title}${
            displayValue !== "" ? ":" : ""
          } `}</Typography>
        </Box>
        {
          <Box>
            <Typography
              variant={"h4"}
              sx={{
                fontSize: { mobile: "14px", md: "18px" },
                overflowWrap: "anywhere",
                textTransform: "unset",
                lineHeight: { md: "25px", mobile: "20px" },
                fontWeight: "bold",
              }}
            >
              {displayValue}
            </Typography>
          </Box>
        }
      </Box>
    </Box>
  );
};

export default AccordionItem;
