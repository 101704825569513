import { useAccount, useSignMessage, useDisconnect } from "wagmi";
import axios from "axios";
import { useHistory } from "react-router-dom";

const gateway = window.env.GATEWAY;

const useAuth = () => {
  const { disconnect } = useDisconnect();
  const { address } = useAccount();
  const history = useHistory();
  const { signMessageAsync } = useSignMessage();

  const register = (data) => {
    return new Promise(async (resolve, reject) => {
      axios
        .postForm(`${gateway}/register`, data)
        .then(async (res) => {
          const { access, refresh } = res.data;
          access && localStorage.setItem("access_token", access);
          refresh && localStorage.setItem("refresh_token", refresh);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const login = (data) => {
    return new Promise(async (resolve, reject) => {
      axios
        .postForm(`${gateway}/login`, data)
        .then(async (res) => {
          const { access, refresh } = res.data;
          access && localStorage.setItem("access_token", access);
          refresh && localStorage.setItem("refresh_token", refresh);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const loginWithGoogle = (access_token) => {
    return new Promise(async (resolve, reject) => {
      axios
        .post(
          `${gateway}/google_login`,
          {},
          {
            headers: {
              Authorization: `${access_token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          const { access, refresh } = res.data;
          access && localStorage.setItem("access_token", access);
          refresh && localStorage.setItem("refresh_token", refresh);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const registerWithGoogle = (access_token) => {
    return new Promise(async (resolve, reject) => {
      axios
        .post(
          `${gateway}/google_register`,
          {},
          {
            headers: {
              Authorization: `${access_token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          const { access, refresh } = res.data;
          access && localStorage.setItem("access_token", access);
          refresh && localStorage.setItem("refresh_token", refresh);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    disconnect();
    history.push("/");
    window.location.reload();
  };

  const connectWalletToAccount = () => {
    return new Promise(async (resolve, reject) => {
      const access_token = localStorage.getItem("access_token");

      if (access_token != null) {
        axios
          .get(`${gateway}/users/nonce`, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          })
          .then(async (res) => {
            const message = res.data;
            try {
              const signature = await signMessageAsync({
                message,
              });
              axios
                .post(
                  `${gateway}/users/${address}/signature`,
                  {
                    signature,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${access_token}`,
                    },
                  }
                )
                .then((res) => {
                  resolve(res.data);
                })
                .catch((error) => {
                  if (error.response) {
                    reject(error);
                  } else {
                    reject({ type: "metamask", error });
                  }
                });
            } catch (error) {
              if (error.response) {
                reject(error);
              } else {
                reject({ type: "metamask", error });
              }
            }
          });
      } else {
        resolve(true);
      }
    });
  };

  const loginWithWallet = () => {
    return new Promise(async (resolve, reject) => {
      const access_token = localStorage.getItem("access_token");
      if (access_token == null) {
        axios
          .get(`${gateway}/users/${address}/nonce`)
          .then(async (res) => {
            const message = res.data;
            try {
              const signature = await signMessageAsync({
                message,
              });

              axios
                .post(`${gateway}/users/${address}/signature`, {
                  signature,
                })
                .then((res) => {
                  const { access, refresh } = res.data;
                  access && localStorage.setItem("access_token", access);
                  refresh && localStorage.setItem("refresh_token", refresh);
                  resolve(res.data);
                })
                .catch((error) => {
                  reject(error);
                });
            } catch (error) {
              if (error.response) {
                reject(error);
              } else {
                reject({ type: "metamask", error });
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              reject(error);
            } else {
              reject({ type: "metamask", error });
            }
          });
      } else {
        resolve(true);
      }
    });
  };

  const disconnectWallet = () => {
    disconnect();
  };

  return {
    register,
    login,
    logout,
    loginWithGoogle,
    disconnectWallet,
    connectWalletToAccount,
    loginWithWallet,
    registerWithGoogle,
  };
};

export default useAuth;
