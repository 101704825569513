import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import UI from "../../../../@components/UI";
import { Controller, useForm } from "react-hook-form";
import useService from "./useService";
import { useSnackbar } from "notistack";
import CustomHooks from "../../../../@components/hooks";
import { useUserRole } from "../../context";

const useStyles = makeStyles((theme) => ({
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },
  inputOuter: {
    marginTop: "33px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Index = ({ DAO, refetch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const service = useService(DAO?.id);
  const classes = useStyles();
  const { isBrandMember } = useUserRole();
  CustomHooks.useProtectedRoute(DAO, isBrandMember);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      company_name: DAO?.company_name,
      city: DAO?.city,
      zip_code: DAO?.zip_code,
      business_address: DAO?.business_address,
      vat_number: DAO?.vat_number,
      country_of_incorporation: DAO?.country_of_incorporation,
      identification_number: DAO?.identification_number,
      is_vat_registered: DAO?.is_vat_registered,
    },
  });

  React.useEffect(() => {
    if (DAO) {
      reset({
        company_name: DAO?.company_name,
        city: DAO?.city,
        zip_code: DAO?.zip_code,
        business_address: DAO?.business_address,
        vat_number: DAO?.vat_number,
        country_of_incorporation: JSON.parse(DAO?.country_of_incorporation),
        identification_number: DAO?.identification_number,
        is_vat_registered: DAO?.is_vat_registered,
      });
    }
    // eslint-disable-next-line
  }, [DAO]);

  const { mutate: saveSettings } = CustomHooks.usePost(service.save, {
    onSuccess: () => {
      enqueueSnackbar("Settings succesfully updated", {
        variant: "success",
      });
      refetch();
    },
  });

  const onSubmit = async (form) => {
    const data = {
      ...form,
      avatar_url: form?.avatar_url?.ipfs,
      whitepaper_url: form?.whitepaper_url?.ipfs,
      country_of_incorporation: JSON.stringify(form?.country_of_incorporation),
    };

    saveSettings(data);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: { md: "70px", mobile: "10px" },
          pb: "20px",
          pt: { md: "30px", mobile: "30px" },
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            variant={"h1"}
            sx={{ fontSize: "30px !important", lineHeight: "20px !important" }}
          >
            BILLING SETTINGS
          </Typography>
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ padding: { md: "10px 70px", mobile: "0px 10px" } }}>
          <Typography variant={"subtitle1"} sx={{ mb: "30px" }}>
            Here you can edit or add more info about your brand.
          </Typography>

          <Box className={classes.inputOuter} sx={{ mt: "40px" }}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
                Company Name
              </Typography>
            </Box>

            <Controller
              name={"company_name"}
              control={control}
              rules={{ required: true }}
              render={({ field }) => <UI.TextField {...field} />}
            />
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
                Business Address
              </Typography>
            </Box>

            <Controller
              name={"business_address"}
              control={control}
              rules={{ required: true }}
              render={({ field }) => <UI.TextField {...field} />}
            />
          </Box>

          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
                Country of Incorporation
              </Typography>
            </Box>

            {DAO && (
              <Controller
                name={"country_of_incorporation"}
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <UI.CountrySelect
                    {...field}
                    defaultValue={JSON.parse(DAO?.country_of_incorporation)}
                  />
                )}
              />
            )}
          </Box>

          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "18px" }}>
              <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
                Postal Code
              </Typography>
            </Box>

            <Controller
              name={"zip_code"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => <UI.TextField {...field} />}
            />
          </Box>

          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "18px" }}>
              <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
                City
              </Typography>
            </Box>

            <Controller
              name={"city"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => <UI.TextField {...field} />}
            />
          </Box>

          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "18px" }}>
              <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
                VAT number
              </Typography>
            </Box>

            <Controller
              name={"vat_number"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => <UI.TextField {...field} />}
            />
          </Box>

          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "18px" }}>
              <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
                Identification number
              </Typography>
            </Box>

            <Controller
              name={"identification_number"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => <UI.TextField {...field} />}
            />
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Controller
                name={"is_vat_registered"}
                rules={{ required: false }}
                control={control}
                render={({ field }) => (
                  <UI.Checkbox
                    {...field}
                    nomargin={"true"}
                    label={
                      <Box component={"span"} sx={{ fontWeight: "bold" }}>
                        BUSINESS NOT VAT REGISTERED
                      </Box>
                    }
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            mt: {md:"100px", mobile:"20px"},
            pb: {md:"60px", mobile:"40px"},
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "210px", mt: { md: "0px", mobile: "20px" } }}>
            <UI.Button title={"Save Changes"} type={"primary"} />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Index;
