import React from "react";
import Slider from "react-slick";
import SliderIndicator from "../../../static/slider-indicator.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  prevButton: {
    "&.MuiIconButton-root": {
      position: "absolute",
      width: "30px",
      height: "30px",
      left: "280px",
      top: "80px",
      zIndex: "10",
      color: "white",
      borderRadius: "0px",
      backgroundColor: "#333645",
    },
    "&.MuiIconButton-root:hover": {
      backgroundColor: "#737687",
    },
  },
  nextButton: {
    "&.MuiIconButton-root": {
      position: "absolute",
      width: "30px",
      height: "30px",
      right: "280px",
      top: "80px",
      zIndex: "10",
      color: "white",
      borderRadius: "0px",
      backgroundColor: "#333645",
    },
    "&.MuiIconButton-root:hover": {
      backgroundColor: "#737687",
    },
  },
  root: {
    "& :hover": {
      backgroundColor: "#5d606d",
    },
  },
}));

function PrevArrow(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <Box className={classes.root}>
      <IconButton
        variant={"contained"}
        className={classes.prevButton}
        onClick={onClick}
      >
        <ArrowBackIosIcon
          sx={{
            left: "10px",
            width: "15px",
            position: "absolute",
            pointerEvents: "none",
          }}
        />
      </IconButton>
    </Box>
  );
}

function NextArrow(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <Box className={classes.root}>
      <IconButton
        variant={"contained"}
        className={classes.nextButton}
        onClick={onClick}
      >
        <ArrowForwardIosIcon sx={{ width: "15px", pointerEvents: "none" }} />
      </IconButton>
    </Box>
  );
}

const CenterMode = ({ items }) => {
  const settings = {
    dots: true,
    appendDots: (dots) => <ul style={{ top: "250px" }}> {dots} </ul>,
    customPaging: () => {
      return (
        <img
          width={"20px"}
          height={"20px"}
          alt={"slider-indicator"}
          src={SliderIndicator}
        />
      );
    },
    arrows: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
    pauseOnHover: true,
    swipeToSlide: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500,
    autoplay: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };
  return (
    <div>
      <Slider {...settings}>
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Slider>
    </div>
  );
};

const Item = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "200px",
      }}
    >
      <Box sx={{ margin: "0 auto" }}>
        {props.item.img && (
          <img
            className={"centerModeImg"}
            src={props.item.img}
            width={props.item.width}
            alt={"slider-item"}
          />
        )}
      </Box>
    </Box>
  );
};
export default CenterMode;
