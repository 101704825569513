import React from "react";
import Slider from "react-slick";
import SliderIndicator from "../../../static/slider-indicator.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const useStyles = makeStyles((theme) => ({
  prevButton: {
    "&.MuiIconButton-root": {
      position: "absolute",
      width: "30px",
      height: "30px",
      left: "-50px",
      top: "60px",
      zIndex: "10",
      color: "white",
      borderRadius: "0px",
      backgroundColor: "#333645",
    },
    "&.MuiIconButton-root:hover": {
      backgroundColor: "#737687",
    },
  },
  nextButton: {
    "&.MuiIconButton-root": {
      position: "absolute",
      width: "30px",
      height: "30px",
      right: "-50px",
      top: "60px",
      zIndex: "10",
      color: "white",
      borderRadius: "0px",
      backgroundColor: "#333645",
    },
    "&.MuiIconButton-root:hover": {
      backgroundColor: "#737687",
    },
  },
  title: {
    marginTop: "30px",
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "40px",
    color: theme.palette.text.primary,
  },
  description: {
    marginTop: "30px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "23px",
    color: theme.palette.text.secondary,
  },
  root: {
    "& :hover": {
      backgroundColor: "#5d606d",
    },
  },
  amount: {
    marginTop: "15px",
    fontFamily: "Quantico",
    fontWeight: "700",
    fontSize: "25px",
    lineHeight: "23px",
    color: theme.palette.text.primary,
  },
  tokenOuter: {
    position: "relative",
    "&:hover .removeIcon": {
      display: "flex",
    },
  },
}));

function PrevArrow(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <Box className={classes.root}>
      <IconButton
        variant={"contained"}
        className={classes.prevButton}
        onClick={onClick}
      >
        <ArrowBackIosIcon
          sx={{
            left: "10px",
            width: "15px",
            position: "absolute",
            pointerEvents: "none",
          }}
        />
      </IconButton>
    </Box>
  );
}

function NextArrow(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <Box className={classes.root}>
      <IconButton
        variant={"contained"}
        className={classes.nextButton}
        onClick={onClick}
      >
        <ArrowForwardIosIcon sx={{ width: "15px", pointerEvents: "none" }} />
      </IconButton>
    </Box>
  );
}

const TokenList = ({ items, onRemove }) => {
  const settings = {
    dots: true,
    appendDots: (dots) => <ul> {dots} </ul>,
    customPaging: () => {
      return (
        <img
          width={"20px"}
          height={"20px"}
          alt={"slider-indicator"}
          src={SliderIndicator}
        />
      );
    },
    arrows: true,
    centerMode: false,
    infinite: true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
    speed: 500,
    autoplay: true,
    pauseOnHover: true,
    swipeToSlide: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };
  return (
    <div>
      {items.length ? (
        <Slider {...settings}>
          {items.map((item, i) => (
            <Item key={i} item={item} onRemove={onRemove} />
          ))}
        </Slider>
      ) : (
        <Slider {...settings}>
          <Item />
          <Item />
          <Item />
          <Item />
        </Slider>
      )}
    </div>
  );
};

const Item = (props) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.tokenOuter}
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <Box sx={{ height: { md: "200px", mobile: "150px" } }}>
        <Box
          sx={{ textAlign: "center", height: { md: "80px", mobile: "50px" } }}
        >
          {!props?.item?.isDefaultToken && !props?.item?.isDaoToken && (
            <Box
              className={"removeIcon"}
              sx={{
                display: "none",
                position: "absolute",
                left: "calc(50% + 50px)",
                cursor: "pointer",
                color: "white",
              }}
            >
              <HighlightOffIcon
                onClick={() => props.onRemove(props?.item?.id)}
              />
            </Box>
          )}
          <Box
            sx={{
              display: { md: "inline-block", mobile: "none" },
              width: "70px",
            }}
          >
            {props?.item?.logo ? (
              <img
                style={{ display: "initial" }}
                width={"100%"}
                src={props.item.logo}
                alt={"tokens-logo"}
              />
            ) : (
              <Skeleton variant={"circular"} height={"70px"} width={"70px"} />
            )}
          </Box>
          <Box
            sx={{
              display: { md: "none", mobile: "inline-block" },
              width: "40px",
            }}
          >
            {props?.item?.logo ? (
              <img
                style={{ display: "initial" }}
                width={"100%"}
                src={props.item.logo}
                alt={"tokens-logo"}
              />
            ) : (
              <Skeleton variant={"circular"} height={"40px"} width={"40px"} />
            )}
          </Box>
        </Box>
        {props?.item?.balance ? (
          <Typography variant={"h4"} sx={{ textAlign: "center" }}>
            {`${props.item.balance}`}
          </Typography>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Skeleton variant={"rectangular"} width={"100px"} />
          </Box>
        )}
        {props?.item?.symbol ? (
          <Typography
            variant={"h4"}
            sx={{ textAlign: "center", lineHeight: "20px" }}
          >
            {`${props.item.symbol}`}
          </Typography>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Skeleton variant={"rectangular"} width={"100px"} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default TokenList;
