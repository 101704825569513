import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import UI from "../../../@components/UI";
import useService from "../proposal/useService";
import Avatar from "@mui/material/Avatar";
import useHandleErrors from "../../../@components/UI/useHandleErrors";
import { useHistory } from "react-router-dom";
import SkeletonList from "./SkeletonList";
import settingsIcon from "../../../static/settings_icon.png";
import withdrawIcon from "../../../static/withdraw_icon.png";
import Grid from "@mui/material/Grid";

const Item = ({ id, logo, name, item, daoId }) => {
  const history = useHistory();
  return (
    <Paper
      sx={{
        backgroundColor: "rgba(27, 30, 47, 0.5)",
        mb: { md: "7px", mobile: "3px" },
      }}
    >
      <Box
        onClick={() =>
          history.push({
            pathname:
              (item.proposal_type === "addMember" &&
                `/brand/${daoId}/vote/${id}/addMember`) ||
              (item.proposal_type === "withdraw" &&
                `/brand/${daoId}/vote/${id}/withdraw`) ||
              (item.proposal_type === "settings" &&
                `/brand/${daoId}/vote/${id}/settings`) ||
              (item.proposal_type === "collection" &&
                `/brand/${daoId}/vote/${id}/collection`),
            state: { scrollToTop: true },
          })
        }
        sx={{
          cursor: "pointer",
          border: "1px solid transparent",
          "&:hover": {
            boxShadow: "#944dff 0px 0px 10px 0px",
            border: "1px solid #944DFF",
          },
          display: "flex",
          justifyContent: "space-between",
          padding: { md: "18px 21px 18px 32px", mobile: "18px 10px" },
        }}
      >
        <Box sx={{ paddingRight: { md: "80px", mobile: "0px" } }}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ mr: "20px" }}>
              <Avatar
                sx={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#1B1E2F",
                }}
                alt={"avatar"}
              >
                {item.proposal_type === "applyForDao" ? (
                  <Box
                    component={"img"}
                    src={item?.data?.avatar_url}
                    sx={{ width: "auto", height: "inherit" }}
                  />
                ) : item.proposal_type === "settings" ? (
                  <Box
                    component={"img"}
                    src={settingsIcon}
                    sx={{ width: "17px", height: "17px" }}
                  />
                ) : item.proposal_type === "withdraw" ? (
                  <Box
                    component={"img"}
                    src={withdrawIcon}
                    sx={{ width: "17px", height: "17px" }}
                  />
                ) : (
                  <Box
                    component={"img"}
                    src={item?.data?.userProfileImage}
                    sx={{ width: "auto", height: "inherit" }}
                  />
                )}
              </Avatar>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant={"h4"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  lineHeight: "24px !important",
                }}
              >
                {name}
              </Typography>
              <Typography
                variant={"subtitle1"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  fontSize: "13px !important",
                  color: "#7A7E92",
                  lineHeight: "18px !important",
                }}
              >
                {`${item.status} vote`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              mr: { md: "13px", mobile: "0px" }
            }}
          >
            <Box
              sx={{
                borderRadius: "100px",
                minWidth: "65px",
                px: "12px",
                height: "23px",
                backgroundColor:
                  item.status === "Executed"
                    ? "#50AF95"
                    : item.status === "Defeated"
                    ? "#F8275C"
                    : "#FFC144",
                textAlign: "center",
              }}
            >
              <Typography
                variant={"h2"}
                sx={{
                  fontSize: "10px !important",
                  lineHeight: "21px !important",
                }}
              >
                {(item.status === "Queued") || (item.status === "Succeeded") ? "Pending" : item.status}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

const List = ({ DAO, filter, limit, gridItem = 6 }) => {
  const service = useService(DAO?.id);
  const [items, setItems] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { handleApiError } = useHandleErrors();

  React.useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await service
        .proposals(filter)
        .then((response) => {
          let items = response.data.results;

          if (limit != null) {
            items = items.splice(0, limit);
          }
          items && setItems(items);

          setLoading(false);
        })
        .catch((error) => {
          handleApiError(error);
          setLoading(false);
        });
    }

    DAO && fetchData();
    // eslint-disable-next-line
  }, [DAO]);

  const getName = (proposalType) => {
    switch (proposalType) {
      case "withdraw":
        return "WITHDRAW FUNDS";
      case "settings":
        return "NEW SETTINGS";
      case "addMember":
        return "NEW ADMIN";
      default:
        return "NEW VOTE";
    }
  };

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {loading && <SkeletonList />}
      {!loading &&
        items &&
        items.length > 0 &&
        items.map((item) => {
          return (
            <Grid item md={gridItem} mobile={12}>
              <Item
                daoId={DAO?.id}
                logo={item?.avatar_url || null}
                id={item.id}
                name={getName(item?.proposal_type)}
                item={item}
              />
            </Grid>
          );
        })}
      {!loading && items && items.length === 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <UI.NoResults text={"No votes found :("} />
        </Box>
      )}
    </Grid>
  );
};

export default List;
