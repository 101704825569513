import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// Styles must use direct files imports
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import "swiper/modules/effect-cards/effect-cards.scss";

import "./styles.css";

import { EffectCards } from "swiper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../index.js";
import { useHistory } from "react-router-dom";

const Item = ({ disableClick, theme, item }) => {
  const history = useHistory();
  return (
    <Box
      key={item.id}
      onClick={() => {
        if (disableClick) {
          return;
        }
        history.push({
          pathname: `/brand/${item?.dao_id}/collection/${item?.id}`,
          state: { scrollToTop: true },
        });
      }}
      sx={{
        background: theme ? theme.card_background_color : "",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          variant={"h2"}
          sx={{
            color: theme ? theme.card_text_color : "",
            p: "8px",
            fontSize: "12px !important",
            maxWidth: "185.4px",
          }}
        >
          <UI.OverflowTip>{`${item.name}`}</UI.OverflowTip>
        </Typography>
      </Box>
      {item.nft_file_type.startsWith("image") && (
        <Box
          sx={{
            position: "relative",
            height: "200px",
            width: "100%",
            maxHeight: "100%",
            maxWidth: "100%",
            overflow: "hidden",
            borderRadius: "0px",
            background:
              "linear-gradient(180deg, rgb(8 10 25 / 0%) 54.17%, rgb(8 10 25 / 68%) 100%)",
          }}
        >
          <UI.ImageWithSkeleton
            sx={{
              inset: "0px",
              boxSizing: "border-box",
              padding: "0px",
              border: "none",
              margin: "auto",
              display: "block",
              width: "0px",
              height: "0px",
              minWidth: "100%",
              maxWidth: "100%",
              minHeight: "100%",
              maxHeight: "100%",
              objectFit: "cover",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
            src={item.nft_image_url}
          />
        </Box>
      )}
      {item.nft_file_type.startsWith("video") && (
        <Box
          sx={{
            position: "relative",
            height: "200px",
            width: "100%",
            maxHeight: "100%",
            maxWidth: "100%",
            overflow: "hidden",
            borderRadius: "0px",
            background:
              "linear-gradient(180deg, rgb(8 10 25 / 0%) 54.17%, rgb(8 10 25 / 68%) 100%)",
          }}
        >
          <Box
            sx={{
              inset: "0px",
              boxSizing: "border-box",
              padding: "0px",
              border: "none",
              margin: "auto",
              display: "block",
              width: "0px",
              height: "0px",
              minWidth: "100%",
              maxWidth: "100%",
              minHeight: "100%",
              maxHeight: "100%",
              objectFit: "cover",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
          >
            <video
              playsInline
              width={"100%"}
              height={"auto"}
              autoPlay="autoplay"
              loop
              muted
            >
              <source src={item.nft_image_url} />
            </video>
          </Box>
        </Box>
      )}

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          variant={"h2"}
          sx={{
            color: theme ? theme.card_text_color : "",
            p: "8px",
            fontSize: "12px !important",
            maxWidth: "185.4px",
          }}
        >
          <UI.OverflowTip>{`${item.nft_amount_minted} / ${item.nft_amount_limit}`}</UI.OverflowTip>
        </Typography>
      </Box>
    </Box>
  );
};

export default function App({
  disableClick,
  theme,
  onSlideChange,
  items,
  from = 0,
  to = 5,
}) {
  const swiperRef = React.useRef(null);

  React.useEffect(() => {
    const swiperContainer = swiperRef.current;
    if (theme && swiperContainer) {
      const swiperSlides = swiperContainer.querySelectorAll(".swiper-slide");

      swiperSlides.forEach((swiperSlide) => {
        swiperSlide.style.boxShadow = `${theme.border_color} 0px 0px 10px 0px`;
        swiperSlide.style.border = `2px solid ${theme.border_color}`;
      });
    }
  }, [theme]);

  return (
    <>
      <Swiper
        ref={swiperRef}
        onSlideChange={onSlideChange}
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards]}
        className="mobileSwiper"
      >
        {items?.slice(from, to).map((item, index) => (
          <SwiperSlide key={"mobile_swiper_" + index}>
            <Item disableClick={disableClick} theme={theme} item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
