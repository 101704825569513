import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../@components/UI";
import Preview from "./Preview";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import { CopyToClipboard as ReactCopyToClipBoard } from "react-copy-to-clipboard";
import CustomHooks from "../../../../@components/hooks";
import { useSnackbar } from "notistack";
import useService from "./useService";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import publicIcon from "../../../../static/public_icon.png";
import collectionIcon from "../../../../static/collection_icon.png";
import Collection from "../../collection";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles((theme) => ({
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },
  inputOuter: {
    marginTop: "33px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Index = ({ DAO }) => {
  const service = useService(DAO?.id);
  const [visibleCollections, setVisibleCollections] = React.useState("all");
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [triggerRefetch, setTriggerRefetch] = React.useState(null);
  const [afterResetForm, setAfterResetForm] = React.useState(false);
  const [tooltipMessage, setTooltipMessage] =
    React.useState("Copy to Clipboard");

  const iframeCode = `<iframe scrolling="no" frameBorder="0" style={{height: "100%",width: "100%",border: "none",overflow: "hidden", }} src="${
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : window.env.CHAIN === "MUMBAI"
      ? "https://mumbai.qvrse.io"
      : "https://dapp.qvrse.io"
  }/brand/${DAO?.id}/showroom" title="${DAO?.name} showroom" />`;

  const { control, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      collections: [],
      background_color: "#080A19",
      text_color: "#ffffff",
      card_background_color: "#121424",
      card_text_color: "#ffffff",
      border_color: "#944DFF",
      button_color: "#944DFF",
    },
  });

  const collectionsValue = watch("collections");

  React.useEffect(() => {
    if (collectionsValue && afterResetForm) {
      setTriggerRefetch(Math.random());
    }
  }, [collectionsValue, afterResetForm]);

  React.useEffect(() => {
    if (DAO) {
      reset(DAO?.dao_style[0]?.data);
      if (DAO?.dao_style[0]?.data?.collections?.length > 0) {
        setVisibleCollections("collections");
      }
      setAfterResetForm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DAO]);

  const colorValues = watch();

  const { mutate: saveStyles, isLoading: isSaving } = CustomHooks.usePost(
    service.save,
    {
      onSuccess: async () => {
        enqueueSnackbar("Styles were saved successfully", {
          variant: "success",
        });
      },
    }
  );

  const onSubmit = async (form) => {
    if (visibleCollections === "all") {
      form.collections = [];
    }
    console.log(form);
    await saveStyles(form);
  };
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <UI.Busy.FullscreenIndicator show={isSaving}>
        <Typography variant={"subtitle2"}>
          Saving styles in progress ...
        </Typography>
        <Typography variant={"subtitle2"}>
          Please do not close this window.
        </Typography>
      </UI.Busy.FullscreenIndicator>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: { md: "70px", mobile: "10px" },
          pb: "20px",
          pt: { md: "30px", mobile: "30px" },
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            variant={"h1"}
            sx={{ fontSize: "30px !important", lineHeight: "20px !important" }}
          >
            PLUGIN SETTINGS
          </Typography>
        </Box>
      </Box>
      <Box sx={{ padding: { md: "10px 70px", mobile: "0px 10px" } }}>
        <Typography variant={"subtitle1"} sx={{ mb: "0px" }}>
          Here you can customize your showroom plugin that you can embed into
          your website.
        </Typography>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ padding: { md: "0px 70px", mobile: "0px 10px" } }}>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
                Get Code
              </Typography>
            </Box>

            <UI.TextField
              value={iframeCode}
              disabled
              endAdornment={
                <>
                  <ReactCopyToClipBoard
                    text={iframeCode}
                    onCopy={() => {
                      setTooltipMessage("Copied!");
                      setTimeout(
                        () => setTooltipMessage("Copy to Clipboard"),
                        1000
                      );
                    }}
                  >
                    <IconButton
                      sx={{
                        color: "#ffffff",
                        mr: "0px",
                        width: { md: "40px", mobile: "32px" },
                        height: { md: "40px", mobile: "32px" },
                        backgroundColor: "#944DFF",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          backgroundColor: "#944DFF",
                          transform: "scale(1.1)",
                        },
                      }}
                      aria-label="Copy to clipboard"
                      onClick={() => setTooltipMessage("Copied!")}
                      edge="end"
                    >
                      <Tooltip
                        title={tooltipMessage}
                        disableFocusListener
                        arrow
                      >
                        <ContentCopyIcon
                          sx={{ fontSize: "15px", color: "white !important" }}
                        />
                      </Tooltip>
                    </IconButton>
                  </ReactCopyToClipBoard>
                </>
              }
            />
            <Alert
              variant="outlined"
              severity="info"
              sx={{ mt: "15px", color: "#4DB8FF" }}
            >
              {
                "Place this code wherever you want the plugin to appear on your page."
              }
            </Alert>
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px", mt: "0" }}>
              <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
                Visible Collections
              </Typography>
              <UI.Tooltip>
                <Typography
                  variant={"subtitle1"}
                  sx={{ fontSize: "14px !important" }}
                >
                  All Collections - All collections will be visible in the
                  showroom.
                </Typography>
                <Typography
                  variant={"subtitle1"}
                  sx={{ mt: "8px", fontSize: "14px !important" }}
                >
                  Select Collection - Only chosen collections will be visible in
                  the showroom.
                </Typography>
              </UI.Tooltip>
            </Box>
            <FormControl>
              <RadioGroup
                aria-labelledby="visibleCollections"
                name="visibleCollections"
                value={visibleCollections}
                onChange={(event) => setVisibleCollections(event.target.value)}
              >
                <FormControlLabel
                  value="all"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: (theme) => theme.palette.colors.primary,
                        },
                      }}
                    />
                  }
                  label={
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          component={"img"}
                          sx={{
                            width: "20px",
                            height: "20px",
                            marginRight: "7px",
                          }}
                          alt={"all collections"}
                          src={publicIcon}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant={"subtitle1"}>
                          All Collections
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="collections"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: (theme) => theme.palette.colors.primary,
                        },
                      }}
                    />
                  }
                  label={
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          component={"img"}
                          alt={"selected collections only"}
                          sx={{
                            width: "25px",
                            height: "18px",
                            marginRight: "7px",
                          }}
                          src={collectionIcon}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant={"subtitle1"}>
                          Select Collections
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {visibleCollections === "collections" && (
            <Grid item md={6} mobile={12}>
              <Box sx={{ mt: "0px" }} className={classes.inputOuter}>
                <Controller
                  name={"collections"}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <Collection.Select
                      defaultValue={DAO?.dao_style[0]?.data?.collections || []}
                      {...field}
                      DAO={DAO}
                    />
                  )}
                />
              </Box>
            </Grid>
          )}
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
                Background Color
              </Typography>
            </Box>
            <Controller
              name={"background_color"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => <UI.ColorPicker {...field} />}
            />
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
                Text Color
              </Typography>
            </Box>
            <Controller
              name={"text_color"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => <UI.ColorPicker {...field} />}
            />
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
                Card Background Color
              </Typography>
            </Box>
            <Controller
              name={"card_background_color"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => <UI.ColorPicker {...field} />}
            />
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
                Card Text Color
              </Typography>
            </Box>
            <Controller
              name={"card_text_color"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => <UI.ColorPicker {...field} />}
            />
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
                Border Color
              </Typography>
            </Box>
            <Controller
              name={"border_color"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => <UI.ColorPicker {...field} />}
            />
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
                Button Color
              </Typography>
            </Box>
            <Controller
              name={"button_color"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => <UI.ColorPicker {...field} />}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              mt: { md: "100px", mobile: "20px" },
              pb: { md: "60px", mobile: "40px" },
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "210px", mt: { md: "0px", mobile: "20px" } }}>
              <UI.Button title={"Save Changes"} type={"primary"} />
            </Box>
          </Box>
        </Box>
      </form>
      <Box sx={{ mt: "20px" }}>
        <Box sx={{ padding: { md: "10px 70px", mobile: "0px 0px" } }}>
          <Box
            sx={{
              display: "flex",
              mb: "10px",
              p: { md: "10px 0px", mobile: "10px 10px" },
            }}
          >
            <Typography variant={"h2"} sx={{ fontSize: "24px !important" }}>
              Preview
            </Typography>
          </Box>
          {colorValues && DAO && (
            <Preview
              triggerRefetch={triggerRefetch}
              DAO={DAO}
              settings={colorValues}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Index;
