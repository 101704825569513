import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Qvrse from "./qvrse";
import DAO from "./daos";
import Explore from "./explore";
import Collections from "./collections";
import Profile from "./profile";
import QrTicketScan from "./qrTicketScan";
import CssBaseline from "@material-ui/core/CssBaseline";
import GlobalStyles from "@material-ui/core/GlobalStyles";
import { GoogleOAuthProvider } from "@react-oauth/google";

import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import UserProvider from "./@components/userContext";
import "@rainbow-me/rainbowkit/dist/index.css";
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import { SnackbarProvider } from "notistack";
import { configureChains, createConfig, useAccount, WagmiConfig } from "wagmi";
import { hardhat, polygonMumbai, polygon } from "wagmi/chains";
import { infuraProvider } from "wagmi/providers/infura";

import { QueryClient, QueryClientProvider } from "react-query";

import QvrseProvider from "./@components/contextAPI";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import UI from "./@components/UI";
import Themes from "./@components/designSystem/themes";
import { makeStyles } from "@mui/styles";
import backgroundStars from "./static/background-stars.png";

const getChainsForEnvironment = () => {
  if (window.env.CHAIN === "POLYGON") {
    console.log("POLYGON chain");
    return [polygon];
  } else if (window.env.CHAIN === "MUMBAI") {
    console.log("MUMBAI chain");
    return [polygonMumbai];
  } else {
    console.log("Return utilities chains");
    return [hardhat, polygonMumbai, polygon];
  }
};

const { chains, publicClient } = configureChains(
  getChainsForEnvironment(),
  [infuraProvider({ apiKey: "ed8f41cd961b407ca7310eec767be5a7" })],
  { stallTimeout: 10000, retryCount: 5, retryDelay: 2000 }
);

const { connectors } = getDefaultWallets({
  appName: "QVRSE",
  projectId: "baab8c3625087d0dadd45a3c5b5c815e",
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

const useStyles = makeStyles((theme) => ({
  containerRoot: {
    backgroundImage: `url(${backgroundStars})`,
    backgroundRepeat: "repeat",
    backgroundSize: "100%",
  },
}));

const Index = () => {
  const [selectedTheme, setSelectedTheme] = React.useState("dark");
  const location = useLocation();
  const classes = useStyles(location);
  const { address } = useAccount();
  const addressRef = React.useRef(address);
  const [resetDate, setResetDate] = React.useState(0);

  const showroomMatch = useRouteMatch("/brand/:brandId/showroom");
  const isShowRoomPage = showroomMatch !== null;

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
      },
    },
  });

  React.useEffect(() => {
    if (!address && addressRef.current) {
      setResetDate(new Date().getTime());
    }
    addressRef.current = address;
  }, [address]);

  return (
    <RainbowKitProvider
      key={`rainbowkit-${resetDate}`}
      chains={chains}
      theme={darkTheme({
        accentColor: "#944DFF",
        accentColorForeground: "white",
        borderRadius: "none",
        fontStack: "system",
        overlayBlur: "small",
      })}
    >
      <GoogleOAuthProvider
        clientId={
          "1013355947383-g54tjubhuenvaqnkop6g8hcpfn3710li.apps.googleusercontent.com"
        }
      >
        <UI.ScrollToTop />
        <UserProvider>
          <QueryClientProvider client={queryClient}>
            <Box className={classes.containerRoot}>
              <ThemeProvider
                theme={selectedTheme === "dark" ? Themes.Dark : Themes.Light}
              >
                <CssBaseline />
                <GlobalStyles
                  styles={{
                    "& .collectionSlider .slick-list": {
                      paddingLeft: "50px !important",
                      paddingRight: "50px !important",
                      paddingBottom: "50px !important",
                    },
                    "ul.slick-dots li": {
                      opacity: 0.2,
                    },
                    "ul.slick-dots li.slick-active": {
                      opacity: 1,
                    },
                    "& .slick-slide.slick-center img": {
                      transform: "scale(1.1)",
                      filter: "grayscale(0) brightness(1)",
                    },
                    "& .collectionSlider .slick-slide.slick-center": {
                      transform: "scale(1)",
                      filter: "grayscale(0) brightness(1)",
                    },
                    "& .collectionSlider .slick-slide": {
                      transform: "scale(0.8)",
                      filter: "grayscale(0) brightness(0.7)",
                    },
                    "& .collectionSlider .slick-slide > div:first-of-type": {
                      display: "flex",
                      justifyContent: "center",
                    },
                    "& .slick-slide .centerModeImg": {
                      transform: "scale(0.5)",
                      filter: "grayscale(1) brightness(0.5)",
                    },
                    "& :root": {
                      colorScheme: selectedTheme === "dark" ? "dark" : "light",
                    },
                  }}
                />
                <SnackbarProvider
                  preventDuplicate
                  maxSnack={3}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  autoHideDuration={5000}
                >
                  <QvrseProvider>
                    {isShowRoomPage === false && (
                      <UI.AppBar
                        onThemeChange={(e, isDarkMode) =>
                          isDarkMode
                            ? setSelectedTheme("dark")
                            : setSelectedTheme("light")
                        }
                      />
                    )}

                    <Switch>
                      <Route path="/qvrse" component={Qvrse} />
                      <Route
                        path="/qr-ticket-scan/:id/:salt"
                        component={QrTicketScan}
                      />
                      <Route path="/brand" component={DAO} />
                      <Route path="/collection" component={Collections} />
                      <Route path="/profile" component={Profile} />
                      <Route path="/" component={Explore} />

                      <Redirect from="*" to="/" />
                    </Switch>
                    {isShowRoomPage === false && (
                      <Box sx={{ pt: "50px" }}>
                        <UI.Footer />
                      </Box>
                    )}
                  </QvrseProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </Box>
          </QueryClientProvider>
        </UserProvider>
      </GoogleOAuthProvider>
    </RainbowKitProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={""}>
      <WagmiConfig config={wagmiConfig}>
        <Index />
      </WagmiConfig>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
