import React from "react";
import Box from "@mui/material/Box";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// Styles must use direct files imports
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module

import { Navigation, Autoplay, Pagination } from "swiper";

import "./styles.css";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import UI from "../../../../@components/UI";
import { useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";

const Item = ({ disableClick, utilityTagsVisible, theme, item }) => {
  const history = useHistory();
  return (
    <Box
      onClick={() => {
        if (disableClick) {
          return;
        }
        history.push({
          pathname: `/brand/${item?.dao_id}/collection/${item?.id}`,
          state: { scrollToTop: true },
        });
      }}
      sx={{
        textAlign: "left",
        cursor: "pointer",
        margin: "0 auto",
        width: "293px",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        opacity: item.visible ? 1 : 0.5,
      }}
    >
      <Box
        sx={{
          backgroundColor: theme
            ? theme.card_background_color
            : (theme) => theme.palette.colors.darkGray,
          borderBottom: "none",
          padding: "10px 20px ",
          height: "54px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box>
              {item?.name ? (
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    color: theme ? theme.card_text_color : "#7A7E92",
                    fontSize: "13px !important",
                    maxWidth: "185.4px",
                  }}
                >
                  <UI.OverflowTip>{`${item?.dao?.name}`}</UI.OverflowTip>
                </Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"130px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {item?.name ? (
              <Typography
                variant={"h2"}
                sx={{
                  color: theme ? theme.card_text_color : "#7A7E92",
                  lineHeight: "22px !important",
                  fontSize: "1rem !important",
                  maxWidth: "185.4px",
                }}
              >
                <UI.OverflowTip>{`${item?.name}`}</UI.OverflowTip>
              </Typography>
            ) : (
              <Skeleton
                height={"30px"}
                width={"130px"}
                variant={"rectangular"}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar src={item?.dao?.avatar_url} alt={"avatar"} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: "293px",
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          component={"span"}
          sx={{
            boxSizing: "border-box",
            display: "block",
            overflow: "hidden",
            width: "initial",
            height: "initial",
            background: "none",
            opacity: 1,
            border: "0px",
            margin: "0px",
            padding: "0px",
            position: "absolute",
            inset: 0,
            lineHeight: "0",
            fontSize: "0",
          }}
        >
          {item ? (
            item.nft_file_type?.toLowerCase().startsWith("video") ? (
              <video
                playsInline
                width="100%"
                height={"auto"}
                autoplay="autoplay"
                loop
                muted
              >
                <source src={item.nft_image_url} />
              </video>
            ) : (
              <UI.ImageWithSkeleton
                sx={{
                  position: "absolute",
                  inset: 0,
                  boxSizing: "border-box",
                  padding: 0,
                  border: "none",
                  margin: "auto",
                  display: "block",
                  width: 0,
                  height: 0,
                  minWidth: "100%",
                  maxWidth: "100%",
                  minHeight: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
                src={item.nft_image_url}
                width={"100%"}
                alt={"slider-item"}
              />
            )
          ) : (
            <Skeleton
              height={"300px"}
              width={"300px"}
              variant={"rectangular"}
            />
          )}
          {!item.visible && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                inset: 0,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                color: "#fff",
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              PRIVATE
            </Box>
          )}
        </Box>

        {utilityTagsVisible && (
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              bottom: "16px",
              right: "16px",
            }}
          >
            <UI.UtilityTag
              utilityType={
                item.utilities.find(
                  (utility) => utility.is_main_utility === true
                )?.type || ""
              }
            />
            <Box
              sx={{
                ml: "10px",
                borderRadius: "100px",
                backgroundColor: "#50AF95",
                width: "fit-content",
                display: "flex",
                alignItems: "center",
                p: "4px 14px",
                height: "14px"
              }}
            >
              <Typography
                variant={"h2"}
                sx={{
                  fontSize: "10px !important",
                  lineHeight: "unset",
                }}
              >
                LIMITED
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: "10px 10px",
          backgroundColor: theme
            ? theme.card_background_color
            : (theme) => theme.palette.colors.darkGray,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "20px", height: "20px", ml: "10px" }}>
            <UI.PaymentIcon currency={item?.currency} />
          </Box>

          {item?.total_price_with_tax !== "0.00" && (
            <Typography
              variant={"h1"}
              sx={{
                color: theme ? theme.card_text_color : "#7A7E92",
                fontSize: "16px !important",
                ml: "10px",
                lineHeight: "unset !important",
              }}
            >
              {`${item?.total_price_with_tax}`}
            </Typography>
          )}
          {item?.total_price_with_tax === "0.00" && (
            <Typography
              variant={"h1"}
              sx={{
                color: theme ? theme.card_text_color : "#7A7E92",
                fontSize: "16px !important",
                ml: "10px",
                lineHeight: "unset !important",
              }}
            >
              FREE
            </Typography>
          )}
        </Box>
        <Typography
          variant={"h2"}
          sx={{
            color: theme ? theme.card_text_color : "",
            p: "10px",
            fontSize: "1rem !important",
            maxWidth: "185.4px",
          }}
        >
          <UI.OverflowTip>{`${item.nft_amount_minted} / ${item.nft_amount_limit}`}</UI.OverflowTip>
        </Typography>
      </Box>
    </Box>
  );
};

const CollectionSlider = ({
  theme,
  onSlideChange,
  items,
  disableClick,
  utilityTagsVisible = true,
  isLoopEnabled = true,
  isPaginationEnabled = true,
}) => {
  const swiperRef = React.useRef(null);

  return (
    <>
      <Swiper
        ref={swiperRef}
        grabCursor={true}
        slidesPerView={3}
        spaceBetween={20}
        onSlideChange={onSlideChange}
        speed={500}
        initialSlide={items.length > 3 ? 2 : 0}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }}
        loop={isLoopEnabled && items.length > 3}
        centeredSlides={true}
        navigation={true}
        pagination={{
          enabled: isPaginationEnabled,
          dynamicBullets: true,
          clickable: true,
        }}
        modules={[Autoplay, Navigation, Pagination]}
        className="desktopCollectionSlider"
      >
        {items &&
          items.map((item, index) => (
            <SwiperSlide key={index}>
              <Item
                disableClick={disableClick}
                utilityTagsVisible={utilityTagsVisible}
                theme={theme}
                item={item}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
};

export default CollectionSlider;
