import { ConnectButton } from "@rainbow-me/rainbowkit";
import QvrsButton from "../Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import React from "react";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    left: "-15px",
    top: "9.5px",
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: "0 0 0 2px #0f1224",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const DesktopButton = ({ signInBusy, isAuthenticated }) => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        mounted,
      }) => {
        return (
          <div
            {...(!mounted && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (
                !mounted ||
                !account ||
                !chain ||
                (!isAuthenticated && !signInBusy)
              ) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                      mr: "10px",
                    }}
                  >
                    <QvrsButton
                      sx={{
                        height: "42px",
                      }}
                      onClick={openConnectModal}
                      title={"Connect Wallet"}
                      type={"secondary"}
                    />
                  </Box>
                );
              } else if (signInBusy) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                      mr: "10px",
                    }}
                  >
                    <QvrsButton
                      sx={{
                        height: "42px",
                      }}
                      onClick={openConnectModal}
                      title={"logging in..."}
                      type={"secondary"}
                    />
                  </Box>
                );
              }
              if (chain.unsupported) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                      mr: "10px",
                    }}
                  >
                    <QvrsButton
                      sx={{
                        height: "42px",
                        display: { md: "flex", mobile: "none" },
                      }}
                      onClick={openChainModal}
                      title={"Wrong network"}
                      type={"secondary"}
                    />
                  </Box>
                );
              }
              return (
                <div style={{ display: "flex", gap: 10 }}>
                  <Box
                    sx={{
                      display: { md: "flex", mobile: "none" },
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      onClick={openChainModal}
                      style={{
                        fontFamily: "Quantico",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        fontSize: "13px",
                        color: "rgba(255, 255, 255, 0.8)",
                        height: "50px",
                        cursor: "pointer",
                        border: "none",
                        borderRadius: "5px",
                        display: "flex",
                        padding: "20px 18px",
                        backgroundColor: "transparent",
                        alignItems: "center",
                        ":hover": {
                          color: "rgba(255, 255, 255, 1)",
                        },
                      }}
                    >
                      <StyledBadge
                        sx={{
                          "&:hover": {
                            color: "rgba(255, 255, 255, 1)",
                          },
                        }}
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        variant="dot"
                      >
                        {chain.name}
                      </StyledBadge>
                    </Box>
                  </Box>
                  <IconButton
                    onClick={openAccountModal}
                    size="large"
                    aria-label="wallet"
                    sx={{
                      display: { mobile: "none", md: "flex" },
                      fontSize: "1.8rem",
                      color: "rgba(255, 255, 255, 0.8)",
                      "&:hover": {
                        color: "rgba(255, 255, 255, 1)",
                      },
                    }}
                  >
                    <AccountBalanceWalletIcon sx={{ fontSize: "2rem" }} />
                  </IconButton>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default DesktopButton;
