import React from "react";

import { useHistory, useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Featured from "./featured";
import NewCollections from "./newCollections";
import PaymentOptions from "./paymentOptions";
import Promotion from "./promotion";
import promotionBanner from "../static/promotion.png";
import promotionBanner2 from "../static/nft_sell.jpg";
import Trending from "./trending";
import Specialities from "./specialities";
import Collections from "./collections";
import Typography from "@mui/material/Typography";
import UI from "../@components/UI";
import CustomHooks from "../@components/hooks";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import RedirectToProfileDialog from "./RedirectToProfileDialog";

const PromotionItem = ({ text }) => {
  return (
    <Box component={"span"} sx={{ display: "flex" }}>
      <DoubleArrowIcon sx={{ mr: "8px", color: "#944dff", width: "18px" }} />
      {text}
    </Box>
  );
};

const Index = () => {
  const history = useHistory();
  let query = CustomHooks.useQuery();
  const location = useLocation();

  const [emailRedirectDialogText, setEmailRedirectDialogText] =
    React.useState(null);

  const [emailRedirectToProfile, setEmailRedirectToProfile] =
    React.useState(null);

  React.useEffect(() => {
    const email_redirect = query.get("email_redirect");
    const email_redirect_profile = query.get("email_redirect_profile");
    if (email_redirect) setEmailRedirectDialogText(email_redirect);
    if (email_redirect_profile)
      setEmailRedirectToProfile(email_redirect_profile);
  }, [query]);

  return (
    <Container
      maxWidth={false}
      sx={{ minHeight: "100vh", px: { md: "0px", mobile: "10px" } }}
    >
      {emailRedirectDialogText && (
        <UI.InfoDialog
          contentText={emailRedirectDialogText}
          onClose={() => {
            setEmailRedirectDialogText(null);
            history.push(`${location.pathname}`);
          }}
          onConfirm={() => {
            setEmailRedirectDialogText(null);
            history.push(`${location.pathname}`);
          }}
        />
      )}
      {emailRedirectToProfile && (
        <RedirectToProfileDialog
          contentText={emailRedirectToProfile}
          onClose={() => {
            setEmailRedirectToProfile(null);
            history.push(`${location.pathname}`);
          }}
          onConfirm={() => {
            setEmailRedirectToProfile(null);
            history.push(`/profile`);
          }}
        />
      )}
      <Box sx={{ px: { lg: "100px", md: "50px" } }}>
        <Featured />
        <Box sx={{ mt: { md: "100px", mobile: "110px" } }}>
          <NewCollections
            onClick={(collection) => {
              history.push({
                pathname: `/brand/${collection.dao.id}/collection/${collection.id}`,
                state: { scrollToTop: true },
              });
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          my: { md: "100px", mobile: "120px" },
        }}
      >
        <Box sx={{ display: { md: "block", mobile: "none" } }}>
          <PaymentOptions />
        </Box>
      </Box>

      <Box sx={{ px: { lg: "100px", md: "50px" } }}>
        <Box>
          <Promotion
            image={promotionBanner2}
            title={`THE ULTIMATE KEY TO AN EPIC EVENT JOURNEY`}
            subtitle={
              "Unlock pre-event hype and post-event vibes with our all-digital, multi-functional NFT ticket."
            }
            content={
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <PromotionItem
                  text={"Dive into post-event perks and must-see content"}
                />
                <PromotionItem
                  text={"Keep your ticket as a digital memory flex"}
                />
                <PromotionItem
                  text={"Connect with the brand's dope community channel"}
                />
                <PromotionItem
                  text={"Resell with ease on the secondary market"}
                />
                <PromotionItem
                  text={"Bye fake tickets, hello NFT card authenticity"}
                />
              </Box>
            }
            url={"/"}
          />
        </Box>
        <Box sx={{ my: "100px" }}>
          <Trending
            onClick={(id) => {
              history.push({
                pathname: id === 1 ? `/qvrse` : `/brand/${id}`,
                state: { scrollToTop: true },
              });
            }}
          />
        </Box>
        <Box>
          <Promotion
            image={promotionBanner}
            title={`ELEVATE YOUR SUBSCRIPTION GAME WITH NFTS`}
            subtitle={"Dive into the digital wave of NFT gated subscriptions."}
            content={
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <PromotionItem
                  text={"Subs & memberships now as digital assets"}
                />
                <PromotionItem text={"You + brand = content decision squad"} />
                <PromotionItem text={"Stay flexible, no monthly commitment"} />
                <PromotionItem text={"Experiences customized for you"} />
              </Box>
            }
            url={"/"}
          />
        </Box>
      </Box>
      <Box sx={{ my: "70px" }}>
        <Box sx={{ display: { md: "block", mobile: "none" } }}>
          <Specialities />
        </Box>
      </Box>
      <Box
        sx={{
          px: { lg: "100px", md: "50px" },
          mt: { mobile: "90px", md: "0px" },
          pb: "80px",
        }}
      >
        <Typography
          variant={"h4"}
          sx={{
            fontSize: { md: "30px !important", mobile: "27px !important" },
            mb: "30px",
            display: { md: "none", mobile: "flex" },
          }}
        >
          {"Collections"}
        </Typography>
        <Collections />
      </Box>
    </Container>
  );
};

export default Index;
