import React from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useSnackbar } from "notistack";
import { useUserContext } from "../../../../../../../@components/userContext";
import { useAccount } from "wagmi";

const Button = ({
  onSuccess,
  onError,
  orderId,
  onRequestPassword,
  collection,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user: loggedInUser } = useUserContext();
  const { isConnected } = useAccount();
  return (
    <>
      <PayPalButtons
        disabled={collection.paypal === false || !loggedInUser?.email_verified}
        style={{
          layout: "horizontal",
          tagline: false,
          shape: "rect",
          color: "gold",
          height: 42,
        }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: collection?.total_price_with_tax,
                },
                custom_id: orderId,
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then(function (details) {
            enqueueSnackbar("Payment completed", {
              variant: "success",
            });
            if (loggedInUser?.address === "" && !isConnected) {
              //user has no wallet connected
              onRequestPassword(orderId);
            } else {
              onSuccess();
            }
          });
        }}
        onCancel={() =>
          enqueueSnackbar(
            "You cancelled the payment. Try again by clicking the PayPal button",
            {
              variant: "error",
            }
          )
        }
        onError={(err) => {
          enqueueSnackbar(
            "There was an error processing your payment. If this error please contact support.",
            {
              variant: "error",
            }
          );
          onError();
        }}
      />
    </>
  );
};

export default Button;
