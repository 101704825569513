import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import UI from "../../@components/UI";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import { Controller, useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import useHandleErrors from "../../@components/UI/useHandleErrors";
import { useSnackbar } from "notistack";
import { ethers } from "ethers/lib.esm";
import nftVaultArtifact from "../../abis/contracts/qtech/NFTVault/NFTVault.sol/NFTVault.json";
import { useAccount } from "wagmi";
import customHooks from "../../@components/hooks";

const UnlockNFTDialog = ({ nftVaultAddress, open, onClose }) => {
  const signer = customHooks.useEthersSigner();
  const [isLoading, setIsLoading] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { handleMetamaskError } = useHandleErrors();
  const { isConnected, address } = useAccount();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
    },
    mode: "all",
  });

  const unlockNFT = async (password) => {
    setIsLoading(true);
    const nftVault = new ethers.Contract(
      nftVaultAddress,
      nftVaultArtifact.abi,
      signer
    );

    try {
      const tx = await nftVault.withdraw(password);
      await tx.wait();
      enqueueSnackbar("NFT was successfully sent to your wallet", {
        variant: "success",
      });
    } catch (e) {
      handleMetamaskError(e.message);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const onSubmit = async (data) => {
    await unlockNFT(data.password);
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  const handlePaste = (e) => {
    e.preventDefault();
    let plainText = e.clipboardData.getData("text/plain");
    plainText = plainText.replace(/\s+/g, ""); // remove all whitespace
    setValue("password", plainText); // set the plain text into the input
    e.target.value = plainText; // set the plain text into the input
  };
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          backgroundColor: "#1B1E2F !important",
          padding: { md: "40px 30px", mobile: "40px 10px" },
          width: "700px",
          margin: { mobile: "0px" },
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ p: { mobile: "0px" } }}>
        <CloseIcon
          onClick={() => {
            onClose();
            reset();
          }}
          sx={{
            position: "absolute",
            right: 30,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <Grid container>
          <Grid item mobile={12} md={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography
                variant={"h6"}
                sx={{ fontSize: "30px", textAlign: "center" }}
              >
                Enter NFT Password
              </Typography>
              <Box sx={{ mt: "45px", px: "10px" }}>
                <Box>
                  <Typography
                    variant={"h5"}
                    sx={{ fontSize: "16px !important", mb: "5px" }}
                  >
                    Password
                  </Typography>
                  <Controller
                    name={"password"}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <UI.TextField
                        {...field}
                        multiline={true}
                        rows={4}
                        onPaste={handlePaste}
                        backgroundcolor={"#303346"}
                        fullWidth
                        placeholder={"Password"}
                      />
                    )}
                  />
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors.password && (
                      <Alert
                        sx={{
                          color: (theme) => theme.palette.colors.darkRed,
                        }}
                        variant="outlined"
                        severity="error"
                      >
                        {errors.password?.type === "required" &&
                          "Password is required"}
                      </Alert>
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: "45px",
                  px: "15px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <UI.Button
                  disabled={
                    Object.keys(errors).length ||
                    isConnected === false ||
                    !address
                  }
                  sx={{ width: "fit-content" }}
                  title={"Move to your wallet"}
                  type={"primary"}
                />
              </Box>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle2"}>
          Transferring NFT to your wallet ...
        </Typography>
        <Typography variant={"subtitle2"}>
          Please do not close this window.
        </Typography>
      </UI.Busy.FullscreenIndicator>
    </MaterialDialog>
  );
};

const Action = ({ element: Component }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Box
        component={"span"}
        onClick={(e) => {
          !Component.props.disabled && setOpen(!open);
        }}
      >
        {Component}
      </Box>
      <UnlockNFTDialog
        nftVaultAddress={Component.props.nftVaultAddress}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

UnlockNFTDialog.Action = Action;

export default UnlockNFTDialog;
