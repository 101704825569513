import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "./Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const Confirm = ({ title, children, onClose }) => {
  return (
    <Dialog onClose={onClose} open={true}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          type={"primary"}
          onClick={onClose}
          title={"OK"}
          sx={{
            width: "100px",
            height: "50px",
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
