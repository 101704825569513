import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../../@components/UI";
import Paper from "@mui/material/Paper";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import AddRequestInfoDialog from "../AdditionalInfoDialog";
import CollectInfoDialog from "../CollectInfoDialog";
import CustomActionButton from "../CustomActionDialog";
import AddField from "../AddField";
import Grid from "@mui/material/Grid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LinkIcon from "@mui/icons-material/Link";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles(() => ({
  inputOuter: {
    marginBottom: "25px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Form = () => {
  const classes = useStyles();
  const [customActionButton, setCustomActionButton] = React.useState(null);
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const displayName = getValues("main_utility.displayName");
  const utilityType = getValues("main_utility.type");
  const {
    fields: customInfoFields,
    append: appendCustomInfoFields,
    remove: removeCustomInfoFields,
  } = useFieldArray({
    control,
    name: "main_utility.custom_info_fields",
  });

  const {
    fields: requestInfoFields,
    append: appendRequestInfoFields,
    remove: removeRequestInfoFields,
  } = useFieldArray({
    control,
    name: "main_utility.request_info_fields",
  });

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        backgroundColor: "rgba(27, 30, 47, 0.5)",
        px: { md: "50px", mobile: "10px" },
        py: { md: "50px", mobile: "50px" },
      }}
    >
      <Box sx={{ display: "flex", mb: "30px", alignItems: "center" }}>
        <UI.UtilityTag sx={{ height: "23px" }} utilityType={utilityType} />

        <Typography variant={"h2"} sx={{ ml: "15px" }}>
          {displayName}
        </Typography>
      </Box>
      <Box className={classes.inputOuter}>
        <Box sx={{ display: "flex", mb: "10px" }}>
          <Typography variant={"h4"}>Name</Typography>
        </Box>

        <Controller
          name={"main_utility.name"}
          control={control}
          rules={{ required: true }}
          render={({ field }) => <UI.TextField {...field} />}
        />
        {errors.main_utility?.name?.type === "required" && (
          <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
            <Alert
              sx={{ color: (theme) => theme.palette.colors.darkRed }}
              variant="outlined"
              severity="error"
            >
              Title is required
            </Alert>
          </Typography>
        )}
      </Box>
      <Box className={classes.inputOuter}>
        <Box sx={{ display: "flex", mb: "10px" }}>
          <Typography
            variant={"h4"}
          >{`Describe your ${displayName}`}</Typography>
        </Box>

        <Controller
          name={"main_utility.description"}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <UI.TextField multiline rows={4} {...field} placeholder={""} />
          )}
        />
        {errors.main_utility?.description?.type === "required" && (
          <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
            <Alert
              sx={{ color: (theme) => theme.palette.colors.darkRed }}
              variant="outlined"
              severity="error"
            >
              Description is required
            </Alert>
          </Typography>
        )}
      </Box>
      {customInfoFields.length > 0 &&
        customInfoFields?.map((item, index) => {
          if (item?.type === "button") {
            //setCustomActionButton(item);
            // Skip the rest of this iteration if type is 'button'
            return null;
          }

          return (
            <Box className={classes.inputOuter}>
              <Box sx={{ display: "flex", mb: "10px" }}>
                <Typography variant={"h4"}>{item?.name}</Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Controller
                  name={`main_utility.custom_info_fields.${index}.value`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.Inputs.GetInputByType
                      type={item?.type}
                      {...field}
                      placeholder={item?.placeholder}
                    />
                  )}
                />

                <Box
                  onClick={() => removeCustomInfoFields(index)}
                  sx={{
                    cursor: "pointer",
                    ml: "8px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <DeleteForeverIcon
                    sx={{
                      color: (theme) => theme.palette.colors.darkRed,
                      opacity: "0.8",
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                  />
                </Box>
              </Box>
              {Object.keys(errors).length > 0 &&
                errors?.main_utility?.custom_info_fields &&
                errors?.main_utility?.custom_info_fields[index]?.value?.type ===
                  "required" && (
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    <Alert
                      sx={{ color: (theme) => theme.palette.colors.darkRed }}
                      variant="outlined"
                      severity="error"
                    >
                      {`${item?.name} is required`}
                    </Alert>
                  </Typography>
                )}
            </Box>
          );
        })}
      <Box>
        <Box
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <AddRequestInfoDialog.Action
            title={"ADDITIONAL INFORMATION"}
            onAdd={(customField) => {
              appendCustomInfoFields(customField);
            }}
            element={
              <AddField
                title={"additional information"}
                subtitle={
                  "(Provide additional information to buyers of your collection)"
                }
              />
            }
          />
        </Box>
      </Box>
      {requestInfoFields.map((item, index) => (
        <Grid item md={12} mobile={12} key={index}>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h4"}>{item?.title}</Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Controller
                name={`main_utility.request_info_fields.${index}.value`}
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <UI.TextField {...field} placeholder={item?.placeholder} />
                )}
              />
              <Box
                onClick={() => removeRequestInfoFields(index)}
                sx={{
                  cursor: "pointer",
                  ml: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <DeleteForeverIcon
                  sx={{
                    color: (theme) => theme.palette.colors.darkRed,
                    opacity: "0.8",
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
      <Box sx={{ mt: "10px" }}>
        <Box
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <CollectInfoDialog.Action
            title={"Collect Information"}
            onAdd={(customField) => {
              appendRequestInfoFields(customField);
            }}
            element={
              <AddField
                title={"collect information"}
                subtitle={
                  "(Collect information from buyers of your collection)"
                }
              />
            }
          />
        </Box>
      </Box>
      {customActionButton == null && (
        <Box sx={{ mt: "10px" }}>
          <Box
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <CustomActionButton.Action
              title={"Add Custom Link"}
              onAdd={(customField) => {
                setCustomActionButton(customField);
                appendCustomInfoFields(customField);
              }}
              element={
                <AddField
                  title={"Custom Link"}
                  subtitle={
                    "(Embed a custom link that redirects your customers to the page you've selected)"
                  }
                />
              }
            />
          </Box>
        </Box>
      )}
      {customActionButton != null && (
        <Box sx={{mb: "10px", mt:"15px" }}>
          <Typography variant={"h4"} sx={{ mb:"9px" }}>{`Custom Link`}</Typography>
          <Box sx={{ display:"flex" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.open(customActionButton.value, "_blank");
              }}
            >
              <Tooltip title={customActionButton.name} arrow>
                <Box
                  sx={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    backgroundColor: "rgba(148, 77, 255, 1);",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LinkIcon />
                </Box>
              </Tooltip>
            </Box>
            <Box
              onClick={()=> setCustomActionButton(null)}
              sx={{
                cursor: "pointer",
                ml: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <DeleteForeverIcon
                sx={{
                  color: (theme) => theme.palette.colors.darkRed,
                  opacity: "0.8",
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default Form;
