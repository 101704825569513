import React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import documentIcon from "../../../../static/pdf-upload.png";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    display: "inline",
    width: "100%",
  },
  readHide: {
    color: "rgb(255 255 255)",
    cursor: "pointer",
    opacity: 0.8,
    fontWeight: "400",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const ReadMore = (props) => {
  const { children, maxTextLength = 400 } = props;
  const classes = useStyles();

  const text = children;
  const [isReadMore, setIsReadMore] = React.useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <Typography variant={"subtitle1"} {...props}>
      <Box component={"span"} className={classes.text}>
        {isReadMore && text.length > maxTextLength
          ? text.slice(0, maxTextLength) + "... "
          : text + " "}
        {text.length > maxTextLength && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "30px",
              fontWeight: "700",
            }}
            onClick={toggleReadMore}
            className={classes.readHide}
          >
            {isReadMore ? "Show more" : "Show less"}
          </Box>
        )}
      </Box>
    </Typography>
  );
};

const Index = ({ DAO }) => {
  return (
    <Container maxWidth={"md"} sx={{ textAlign: "center" }}>
      {DAO?.description && (
        <Box sx={{ mt: "20px" }}>
          <ReadMore>{DAO?.description}</ReadMore>
        </Box>
      )}
      {DAO?.whitepaper_url && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: "40px" }}>
          {DAO?.whitepaper_url && (
            <Link target="_blank" href={DAO?.whitepaper_url} underline="hover">
              <Tooltip title={"Document"} arrow>
                <Box
                  alt={"more mainUtility document"}
                  component={"img"}
                  src={documentIcon}
                  sx={{
                    cursor: "pointer",
                    width: "26px",
                    height: "35px",

                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                    transition: "all 0.5s ease-in-out",
                  }}
                />
              </Tooltip>
            </Link>
          )}
        </Box>
      )}
    </Container>
  );
};

export default Index;
