import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SettingsButton from "./SettingsButton";
import UI from "../@components/UI";
import { useAccount } from "wagmi";
import { useHistory } from "react-router-dom";

const Header = ({ DAO, isUserMaintainer }) => {
  const history = useHistory();
  const { isConnected } = useAccount();
  return (
    <Box
      onClick={() => {
        history.push(`/qvrse`);
      }}
      sx={{
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        margin: "0 auto",
      }}
    >
      <Paper
        sx={{
          borderRadius: "50%",
          width: "80px",
          height: "80px",
          display: { md: "flex", mobile: "none" },
          mt: "0px",
          overflow: "hidden",
          transition: "all 0.5s ease-in-out",
          border: "1px solid transparent",
          "&:hover": {
            boxShadow: "#944dff 0px 0px 10px 0px",
            border: "1px solid #944dff",
            transform: "scale(1.1)",
          },
        }}
      >
        {DAO?.avatar_url ? (
          <Box
            component={"img"}
            sx={{
              width: "100%",
              height: "100%",
              margin: "0 auto",
              textAlign: "center",
              objectFit: "cover",
            }}
            src={DAO?.avatar_url}
            alt={"dao-logo"}
          />
        ) : (
          <Skeleton variant={"circle"} width={80} height={80} />
        )}
      </Paper>
      {DAO ? (
        <Box
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mr: "10px" }}>
            <Typography
              sx={{
                maxWidth: "610px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                ml: { md: "32px", mobile: "0px" },
                textAlign: { md: "left", mobile: "center" },
                fontSize: "24px !important",
                display: { md: "block" },
              }}
              variant={"h2"}
            >
              <UI.OverflowTip> {DAO?.name}</UI.OverflowTip>
            </Typography>
          </Box>
          {isConnected && isUserMaintainer && <SettingsButton DAO={DAO} />}
        </Box>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Skeleton
            sx={{ ml: "40px" }}
            variant={"text"}
            width={200}
            height={50}
          />
        </Box>
      )}
    </Box>
  );
};

export default Header;
