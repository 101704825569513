import * as React from "react";
import MaterialSelect from "@mui/material/Select";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  dropdownStyle: {
    backgroundColor: "#1B1E2F",
    marginTop: "10px",
    borderRadius: "0px",
  },
  dropdownListStyle: {
    "& .Mui-selected": {
      backgroundColor: "#1B1E2F",
      color: "#fff",
    },
    "& .MuiMenuItem-root": {
      color: "#7A7E92",
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "#1B1E2F",
      color: "#7A7E92",
    },
  },
  icon: {
    right: "30px",
    fontSize: "30px",
    color: theme.palette.text.primary + " !important",
  },
}));

const Select = (props) => {
  const classes = useStyles();
  const { value, defaultOpen, defaultValue, onChange, children, ...other } =
    props;

  return (
    <MaterialSelect
      sx={{
        color: "#fff",
        backgroundColor: "#1B1E2F",
        width: "100%",
        fontFamily: "Open Sans",
        textAlign: "left",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "19px",
        height: "55px",
      }}
      MenuProps={{
        classes: {
          paper: classes.dropdownStyle,
          list: classes.dropdownListStyle,
        },
      }}
      defaultOpen={defaultOpen}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      displayEmpty
      inputProps={{
        classes: {
          icon: classes.icon,
        },
      }}
      {...other}
    >
      {children}
    </MaterialSelect>
  );
};

export default Select;
