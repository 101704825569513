import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const UtilityTag = (props) => {
  const { utilityType, isMainUtility = true, customStyles } = props;
  const getUtilityName = () => {
    if (utilityType === "digital_content") {
      return "Digital Content";
    } else if (utilityType === "product") {
      return "Product";
    } else if (utilityType === "service") {
      return "Service";
    } else if (utilityType === "ticket") {
      return "Event Ticket";
    } else if (utilityType === "merch") {
      return "Merchandise";
    } else if (utilityType === "discord_access") {
      return "Discord Access";
    } else if (utilityType === "custom") {
      return "Custom";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "100px",
        backgroundColor: isMainUtility ? "#944DFF" : "#5B5E72",
        width: "fit-content",
        p: "4px 14px",
        lineHeight: "unset",
        ...props.sx,
        ...customStyles,
      }}
    >
      <Typography
        variant={"h4"}
        sx={{
          fontSize: "10px !important",
          lineHeight: "normal",
          ...(customStyles?.perks_badge_text_color && {
            color: customStyles.perks_badge_text_color,
          }),
        }}
      >
        {getUtilityName()}
      </Typography>
    </Box>
  );
};

export default UtilityTag;
