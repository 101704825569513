import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { styled } from "@mui/material/styles";
import MaterialButton from "@mui/material/Button";
import { purple } from "@mui/material/colors";
import useMediaQuery from "@mui/material/useMediaQuery";

const QvrsButton = styled(MaterialButton)(
  ({ theme, type, ismobile, height }) => ({
    color: theme.palette.getContrastText(purple[500]),
    border: "1px solid transparent",
    filter: "drop-shadow(0px 0px 10px rgba(138, 38, 255, 0.5))",
    borderRadius: "0px",
    boxShadow: "none",
    backgroundColor:
      (type === "primary" && theme.palette.buttons.primary) ||
      (type === "button" && theme.palette.buttons.primary) ||
      (type === "secondary" && theme.palette.buttons.secondary) ||
      (type === "terciary" && theme.palette.buttons.terciary),

    "&:hover": {
      border: "1px solid #944DFF",
      filter: "drop-shadow(0px 0px 10px rgba(138, 38, 255, 0.8))",
      transition: "all 0.2s ease",
      boxShadow: "none",
      backgroundColor:
        (type === "primary" && "#b185f2") ||
        (type === "button" && "#b185f2") ||
        (type === "secondary" && "#5B5E72") ||
        (type === "terciary" && "unset"),
    },
    "&:disabled": {
      color: "rgb(255 255 255 / 100%)",
      opacity: "0.26",
    },

    width: "100%",
    height: ismobile === "true" ? "42px" : "42px",
    fontFamily: "Quantico",
    fontWeight: "700",
    fontSize: "16px",
  })
);

const options = ["Disable purchase"];

export default function SplitButton({
  title,
  type,
  onClick,
  onMenuItemClick,
  isPurchaseEnabled,
  hideDropDownButton,
  ...props
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    onMenuItemClick(event, index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        sx={{
          "& .MuiButton-root": {
            borderColor: "transparent !important",
            borderRight: hideDropDownButton
              ? "1px solid transparent !important"
              : "1px solid transparent !important",
          },
        }}
      >
        <QvrsButton
          {...props}
          onClick={onClick}
          variant={"contained"}
          ismobile={isMobile ? "true" : "false"}
          type={type}
        >
          {title}
        </QvrsButton>
        {!hideDropDownButton && (
          <Button
            sx={{
              backgroundColor: "#944DFF",
              mr: "12px",
              border: "1px solid transparent",
              borderRadius: "100px !important",
              filter: "drop-shadow(0px 0px 10px rgba(138, 38, 255, 0.5))",
              padding: "4px 8px",
              width: "42px",
              height: "42px",
              "&:hover": {
                border: "1px solid transparent",
                filter: "drop-shadow(0px 0px 10px rgba(138, 38, 255, 0.8))",
                transition: "all 0.2s ease",
                boxShadow: "none",
                backgroundColor:
                  (type === "primary" && "#b185f2") ||
                  (type === "button" && "#b185f2") ||
                  (type === "secondary" && "#5B5E72") ||
                  (type === "terciary" && "unset"),
              },
              "&:disabled": {
                color: "rgb(255 255 255 / 100%)",
                opacity: "0.26",
              },
            }}
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper sx={{ backgroundColor:"#1B1E2F", mt: "12px", mb:"10px" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      sx= {{fontFamily: "Open Sans", fontSize: "14px", padding: "3px 22px 6px", minHeight: "34px" }}
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {isPurchaseEnabled
                        ? "Disable purchase"
                        : "Enable purchase"}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
