import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import UI from "../../../@components/UI";
import { useHistory, useLocation } from "react-router-dom";

import List from "./List";
import Create from "./create";
import { useUserRole } from "../context";

const Index = ({ DAO }) => {
  const location = useLocation();
  const history = useHistory();
  const { isBrandMember } = useUserRole();

  return (
    <React.Fragment>
      {DAO && (
        <Paper
          elevation={0}
          sx={{ width: "100%", pb: "50px", background: "transparent" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              px: { md: "70px", mobile: "10px" },
              pb: "50px",
              pt: { md: "40px", mobile: "40px" },
            }}
          >
            {isBrandMember && (
              <Box sx={{ display: { md: "flex", mobile: "flex" } }}>
                <Box sx={{ width: "178px" }}>
                  <UI.Button
                    onClick={() => history.push(`${location.pathname}/new`)}
                    title={"create post"}
                    type={"primary"}
                  />
                </Box>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              width: { md: "600px", mobile: "100%" },
              margin: "0 auto",
            }}
          >
            <List DAO={DAO} />
          </Box>
        </Paper>
      )}
    </React.Fragment>
  );
};

Index.List = List;
Index.Create = Create;

export default Index;
