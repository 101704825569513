import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    display: "inline",
    width: "100%",
    fontSize: ({ fontSize }) => {
      return fontSize ? fontSize : "16px";
    },
    color: ({ color }) => {
      return color ? color : "rgb(255 255 255)";
    },
    lineHeight: "19px",
  },
  readHide: {
    color: ({ color }) => {
      return color ? color : "rgb(255 255 255)";
    },
    cursor: "pointer",
    opacity: 1,
    fontWeight: "800",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const ReadMore = (props) => {
  const { children, maxTextLength = 200, fontSize, color, ...other } = props;
  const classes = useStyles({ fontSize, color });

  const text = children;
  const [isReadMore, setIsReadMore] = React.useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <Typography variant={"subtitle1"} {...other}>
      <Box component={"span"} className={classes.text}>
        {isReadMore && text.length > maxTextLength
          ? text.slice(0, maxTextLength) + "... "
          : text + " "}
        {text.length > maxTextLength && (
          <Box
            component={"span"}
            onClick={toggleReadMore}
            className={classes.readHide}
          >
            {isReadMore ? "See more" : "Show less"}
          </Box>
        )}
      </Box>
    </Typography>
  );
};

export default ReadMore;
