import React from "react";
import Container from "@mui/material/Container";
import { Route, Redirect, Switch, useParams } from "react-router-dom";

import useHandleErrors from "../@components/UI/useHandleErrors";

import MenuProvider from "./context";
import Home from "./home";
import Tresuary from "./tresuary";
import Votes from "./vote";
import Vote from "./vote/@id";
import Proposal from "./proposal";
import Memberships from "./membership";
import Membership from "./membership/@id";
import QPeople from "./qpeople";
import useService from "./useService";
import Token from "./token";
import Settings from "./settings";

import Menu from "./Menu";
import MobileMenu from "./MobileMenu";
import { useAccount } from "wagmi";
import Header from "./Header";
import useMediaQuery from "@mui/material/useMediaQuery";

const Index = ({ match }) => {
  const { id } = useParams();
  const service = useService(id);
  const { handleApiError } = useHandleErrors();
  const { address } = useAccount();
  const [isUserMaintainer, setIsUserMaintainer] = React.useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [DAO, setDao] = React.useState(null);

  const fetchData = async () => {
    await service
      .dao()
      .then((response) => {
        setDao(response);
        if (response.maintainer_address === address) {
          setIsUserMaintainer(true);
        }
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <MenuProvider>
        <ContainerWrapper>
          {!isMobile && (
            <Header DAO={DAO} isUserMaintainer={isUserMaintainer} />
          )}

          {!isMobile && <Menu DAO={DAO} currentUrl={match?.url} />}
          {isMobile && <MobileMenu DAO={DAO} currentUrl={match?.url} />}

          <Switch>
            <Route path={`${match.url}/info/edit`}>
              <Home.Edit DAO={DAO} />
            </Route>

            <Route path={`${match.url}/tresuary/withdraw`}>
              <Proposal.Withdraw DAO={DAO} />
            </Route>
            <Route path={`${match.url}/tresuary/tokens/new`}>
              <Tresuary.Tokens.Create DAO={DAO} />
            </Route>
            <Route path={`${match.url}/tresuary`}>
              <Tresuary DAO={DAO} />
            </Route>

            <Route path={`${match.url}/vote/:id/apply`}>
              <Vote.ApplyForDao DAO={DAO} />
            </Route>
            <Route path={`${match.url}/vote/:id/withdraw`}>
              <Vote.Withdraw DAO={DAO} />
            </Route>
            <Route path={`${match.url}/vote/:id/settings`}>
              <Vote.Settings DAO={DAO} />
            </Route>
            <Route path={`${match.url}/vote`}>
              <Votes DAO={DAO} />
            </Route>
            <Route path={`${match.url}/token`}>
              <Token DAO={DAO} />
            </Route>
            <Route path={`${match.url}/settings`}>
              <Settings DAO={DAO} />
            </Route>

            <Route path={`${match.url}/qpeople`} component={QPeople} />
            <Route path={`${match.url}/membership/new`}>
              <Memberships.Create DAO={DAO} />
            </Route>
            <Route path={`${match.url}/membership/:id`}>
              <Membership DAO={DAO} />
            </Route>

            <Route path={`${match.url}/membership`}>
              <Memberships DAO={DAO} />
            </Route>

            <Route path={`${match.url}/`}>
              <Home DAO={DAO} />
            </Route>
            <Route path="/" component={Home} />
            <Redirect from="*" to="/" />
          </Switch>
        </ContainerWrapper>
      </MenuProvider>
    </React.Fragment>
  );
};

const ContainerWrapper = ({ children }) => {
  return (
    <Container
      maxWidth={"md"}
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingLeft: { mobile: "0px" },
        paddingRight: { mobile: "0px" },
      }}
    >
      {children}
    </Container>
  );
};

export default Index;
