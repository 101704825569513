import Gateway from "../../../../@components/gateway";

const useService = (daoId) => {
  const gateway = Gateway.useGateway();

  const save = (data) => {
    return gateway.post(`dao/${daoId}/info/edit_style/`, { style: data });
  };

  const styles = async () => {
    const response = await gateway.get(`/dao/${daoId}/styles/`);
    return response.data;
  };

  return {
    save,
    styles,
  };
};

export default useService;
