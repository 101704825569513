import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Controller } from "react-hook-form";
import UI from "../../../../../@components/UI";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

const MultiUpload = ({
  setTotalMintLimit,
  control,
  errors,
  setValue,
  selectedRandomMint,
  data,
  getValues,
}) => {
  const [collectionFiles, setCollectionFiles] = React.useState(null);

  const calculateTotalMintLimit = () => {
    let sum = 0;
    for (let i = 0; i < collectionFiles?.length; i++) {
      const mintLimit = getValues(
        `basic_info.collection_images.${i}.limit_to_mint`
      );
      sum += parseInt(mintLimit, 10);
    }

    setTotalMintLimit(sum);
  };

  React.useEffect(() => {
    if (collectionFiles && collectionFiles.length > 0) {
      setValue("basic_info.picture_url", {
        ipfs: "https://hazellabs.infura-ipfs.io/ipfs/QmQ1uMhZzhwDHKiRCqRRACAhPvzgwDsvAox9ciRuTzgpCr",
        file: { type: "image/png" },
      });
      calculateTotalMintLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionFiles]);

  return (
    <>
      <Grid item md={12} mobile={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "15px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <UI.Dropzone
            onChange={(files) => {
              setCollectionFiles(files);
            }}
            defaultValue={data?.basic_info?.collection_images}
            id={"files"}
            title={"Upload Your NFTs"}
            acceptFiles={"image/*"}
          />

          {errors.basic_info?.files?.type === "required" && (
            <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
              <Alert
                sx={{
                  color: (theme) => theme.palette.colors.darkRed,
                }}
                variant="outlined"
                severity="error"
              >
                Picture is required
              </Alert>
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            mb: "0px !important",
            marginBottom: "15px",
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              mb: "10px",
              mt: "23px !important",
              flexDirection: "column",
            }}
          >
            {collectionFiles?.length > 0 &&
              collectionFiles.map((file, index) => {
                return (
                  <Grid item md={12} mobile={12}>
                    <Box
                      sx={{
                        marginBottom: "15px",
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            flex: 4,
                          }}
                        >
                          <Box sx={{ display: "flex", mb: "10px" }}>
                            <Typography variant={"h4"}>NFT NAME</Typography>
                            <UI.Tooltip>
                              Name of the NFT to be minted
                            </UI.Tooltip>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Controller
                              name={`basic_info.collection_images.${index}.image_url`}
                              control={control}
                              defaultValue={file.ipfs}
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => (
                                <Box
                                  component={"img"}
                                  sx={{
                                    marginRight: "10px",
                                    width: "50px",
                                    height: "50px",
                                    objectFit: "contain",
                                  }}
                                  src={file.preview}
                                  alt="Collection"
                                />
                              )}
                            />
                            <Controller
                              name={`basic_info.collection_images.${index}.name`}
                              control={control}
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => (
                                <UI.TextField
                                  {...field}
                                  placeholder={`NFT ${index}`}
                                />
                              )}
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            flex: 1,
                          }}
                        >
                          <Box sx={{ display: "flex", mb: "10px" }}>
                            <Typography variant={"h4"}>Amount</Typography>
                            <UI.Tooltip>
                              How many times this NFT can be minted
                            </UI.Tooltip>
                          </Box>
                          <Controller
                            name={`basic_info.collection_images.${index}.limit_to_mint`}
                            control={control}
                            rules={{
                              required: true,
                            }}
                            defaultValue={1}
                            render={({ field }) => (
                              <UI.TextField
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  calculateTotalMintLimit();
                                }}
                                placeholder={"Amount"}
                                style={{ marginLeft: "10px", width: "100%" }} // adjust width as needed
                              />
                            )}
                          />
                        </Box>
                      </Box>

                      {Object.keys(errors).length > 0 &&
                        errors.basic_info?.collection_images &&
                        errors.basic_info.collection_images[index]
                          ?.limit_to_mint?.type === "required" && (
                          <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                            <Alert
                              sx={{
                                color: (theme) => theme.palette.colors.darkRed,
                              }}
                              variant="outlined"
                              severity="error"
                            >
                              Amount is required
                            </Alert>
                          </Typography>
                        )}
                      {Object.keys(errors).length > 0 &&
                        errors.basic_info?.collection_images &&
                        errors.basic_info.collection_images[index]?.name
                          ?.type === "required" && (
                          <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                            <Alert
                              sx={{
                                color: (theme) => theme.palette.colors.darkRed,
                              }}
                              variant="outlined"
                              severity="error"
                            >
                              Name is required
                            </Alert>
                          </Typography>
                        )}
                    </Box>
                  </Grid>
                );
              })}
          </Box>
        </Box>
      </Grid>
      <Box
        sx={{
          mb: "0px !important",
          marginBottom: "15px",
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            mb: "10px",
            mt: "23px !important",
          }}
        >
          <Typography variant={"h4"}>Random mint</Typography>
          <UI.Tooltip>
            {`When enabled, the NFTs will be minted in a random order.`}
          </UI.Tooltip>
        </Box>
        <UI.Checkbox
          onClick={() => {
            setValue("basic_info.random_images", !selectedRandomMint, {
              shouldDirty: true,
            });
          }}
          defaultChecked={false}
          value={selectedRandomMint}
          nomargin={"true"}
          label={
            <Box
              component={"span"}
              sx={{
                fontWeight: "regular",
                fontFamily: "Open Sans",
                fontSize: "16px",
              }}
            >
              Random Mint
            </Box>
          }
        />
      </Box>
    </>
  );
};

export default MultiUpload;
