import React from "react";
import { useSnackbar } from "notistack";
import useVoteService from "../../vote/useService";
import { useAccount } from "wagmi";
import useHandleErrors from "../../../@components/UI/useHandleErrors";
import { ethers } from "ethers";
import governorArtifact from "../../../abis/contracts/qtech/governor/Governor.sol/QtechGovernor.json";
import customHooks from "../../../@components/hooks";

const useVote = (DAO) => {
  const signer = customHooks.useEthersSigner();
  const [busy, setBusy] = React.useState(false);
  const voteService = useVoteService();
  const { address } = useAccount();
  const { handleMetamaskError, handleApiError } = useHandleErrors();
  const { enqueueSnackbar } = useSnackbar();

  const _castVote = async (proposal, voteWay) => {
    return new Promise(async (resolve, reject) => {
      try {
        const governor = new ethers.Contract(
          DAO?.governor_contract?.address,
          governorArtifact.abi,
          signer
        );
        const voteTxResponse = await governor.castVoteWithReason(
          proposal.proposal_hash_id,
          voteWay,
          "I voted!"
        );
        const voteReceipt = await voteTxResponse.wait(1);
        resolve(voteReceipt);
      } catch (error) {
        handleMetamaskError(error);
        reject(error);
      }
    });
  };

  const _updateVote = async (proposal, voteId) => {
    return new Promise(async (resolve, reject) => {
      voteService
        .updateVote(proposal.id, voteId)
        .then((response) => {
          enqueueSnackbar("Succesfully voted", { variant: "success" });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const vote = async (proposal, voteWay, votingPower) => {
    setBusy(true);

    let voteId;
    return new Promise((resolve, reject) => {
      //Save vote to BE
      voteService
        .vote(proposal.id, {
          address: address,
          vote_decision: parseInt(voteWay),
          voting_power: parseInt(votingPower),
        })
        .then(async (response) => {
          voteId = response?.data?.id;
          //Save vote to smart contract
          _castVote(proposal, voteWay)
            .then(async (response) => {
              if (response) {
                //update vote in BE
                _updateVote(proposal, voteId)
                  .then((response) => {
                    resolve(response);
                  })
                  .catch((error) => {
                    handleApiError(error);
                    reject(error);
                  });
              }
            })
            .catch((error) => {
              handleMetamaskError(error);
              reject(error);
            });
        })
        .catch((error) => {
          handleApiError(error);
          reject(error);
        })
        .finally(() => {
          setBusy(false);
        });
    });
  };

  return {
    vote,
    busy,
  };
};

export default useVote;
