import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import UI from "../@components/UI";
import Grid from "@mui/material/Grid";
import SmallSkeletonList from "./SmallSkeletonList";
import { useHistory } from "react-router-dom";

const Item = ({ item }) => {
  const history = useHistory();
  return (
    <Grid item mobile={12} tablet={12} sm={12} md={6} lg={6}>
      <Paper
        sx={{
          backgroundColor: "rgba(27, 30, 47, 0.5);",
        }}
      >
        <Box
          onClick={() =>
            history.push({
              pathname: `/brand/${item?.dao_id}/collection/${item?.id}`,
              state: { scrollToTop: true },
            })
          }
          sx={{
            cursor: "pointer",
            border: "1px solid transparent",
            borderRadius: "0px",
            "&:hover": {
              boxShadow: "#944dff 0px 0px 10px 0px",
              border: "1px solid #944DFF",
            },
            display: "flex",
            justifyContent: "space-between",
            padding: { md: "17px 20px", mobile: "20px 10px" },
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ mr: "20px" }}>
              {item.nft_file_type?.startsWith("video") ? (
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    overflow: "hidden",
                  }}
                >
                  <video
                    playsInline
                    style={{
                      width: "100%",
                      height: "100%",
                      textAlign: "center",
                      objectFit: "cover",
                      color: "transparent",
                      textIndent: "10000px",
                    }}
                    autoplay="autoplay"
                    loop
                    muted
                  >
                    <source src={item.nft_image_url} />
                  </video>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    component={"img"}
                    src={item.nft_image_url}
                    alt={"avatar"}
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      textIndent: "10000px",
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box>
              <Typography
                variant={"h4"}
                sx={{
                  fontSize: { md: "18px !important", mobile: "16px !important" },
                  lineHeight: "20px !important",
                  maxWidth: { mobile: "170px", md: "300px" },
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {item.name}
              </Typography>
              <Typography
                variant={"subtitle2"}
                sx={{
                  color: "#7A7E92",
                  fontSize: "13px !important",
                  maxWidth: { mobile: "170px", md: "300px" },
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {item?.dao?.name}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display:{ md:"flex", mobile:"none"}}}>
            <Box
              sx={{
                mr: "20px",
                width: "85px",
                display: { md: "flex", mobile: "none" },
                alignItems: "center",
                position: "relative",
              }}
            >
              <Box sx={{ width: "20px", height: "20px" }}>
                <UI.PaymentIcon currency={item.currency || "MATIC"} />
              </Box>

              {item.total_price_with_tax !== "0.00" && (
                <Typography
                  variant={"h4"}
                  sx={{
                    fontSize: "14px !important",
                    ml: "20px",
                    right: "0px",
                    position: "absolute",
                  }}
                >
                  {parseFloat(item.total_price_with_tax).toFixed(2)}
                </Typography>
              )}
              {item.total_price_with_tax === "0.00" && (
                <Typography
                  variant={"h4"}
                  sx={{
                    fontSize: "14px !important",
                    ml: "20px",
                    right: "0px",
                    position: "absolute",
                    textAlign: "end"
                  }}
                >
                  FREE
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                maxWidth: "60px",
                width: "60px",
              }}
            >
              <Typography variant={"h4"} sx={{ fontSize: "14px !important", textAlign: "end" }}>
                <UI.OverflowTip>
                  {`${item.nft_amount_minted} / ${item.nft_amount_limit}`}
                </UI.OverflowTip>
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: { md:"none", mobile:"flex"}, flexDirection: "column" }}>
            <Box sx={{ display:"flex" }}>
              <Box sx={{ width: "14px", height: "14px" }}>
                <UI.PaymentIcon currency={item.currency || "MATIC"} />
              </Box>
              {item.total_price_with_tax !== "0.00" && (
                <Typography
                  variant={"h4"}
                  sx={{
                    fontSize: "14px !important",
                    ml: "11px",
                    textAlign: "end",
                    lineHeight: "1.6 !important",
                  }}
                >
                  {parseFloat(item.total_price_with_tax).toFixed(2)}
                </Typography>
              )}
              
              {item.total_price_with_tax === "0.00" && (
                <Typography
                  variant={"h4"}
                  sx={{
                    fontSize: "14px !important",
                    ml: "11px",
                    textAlign: "end",
                    lineHeight: "1.6 !important",
                  }}
                >
                  FREE
                </Typography>
              )}
            </Box> 
              <Typography 
                variant={"h4"} 
                sx={{ 
                  color: "#7A7E92",
                  fontSize: "13px !important",
                  textAlign: "end",
                  lineHeight: "1.4 !important",
                }}
              >
                <UI.OverflowTip>
                  {`${item.nft_amount_minted} / ${item.nft_amount_limit}`}
                </UI.OverflowTip>
              </Typography>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

const SmallList = ({ limit = { from: 0, to: -1 }, items, busy }) => {
  return (
    <Grid container spacing={2}>
      {busy && <SmallSkeletonList />}

      {!busy &&
        items &&
        items.length > 0 &&
        items
          .slice(limit.from, limit.to === -1 ? items.length : limit.to)
          .map((item) => <Item item={item} key={item.id} />)}
      {!busy && items && items.length === 0 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <UI.NoResults text={"No collections found :("} />
        </Box>
      )}
    </Grid>
  );
};

export default SmallList;
