import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../@components/UI";
import Alert from "@mui/material/Alert";
import ChangePasswordDialog from "../dialogs/ChangePasswordDialog";
import useHandleErrors from "../../@components/UI/useHandleErrors";
import { useSnackbar } from "notistack";
import useService from "../useService";

const ResetPasswordButton = ({ user }) => {
  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    React.useState(null);
  const service = useService();
  const { handleApiError } = useHandleErrors();
  const { enqueueSnackbar } = useSnackbar();

  const resetPassword = async () => {
    await service
      .resetPassword({ email: user?.email })
      .then(async () => {
        enqueueSnackbar(
          "Generated password was successfully sent to your email",
          {
            variant: "success",
          }
        );
      })
      .catch((error) => {
        handleApiError(error);
      });
  };
  return (
    <>
      <Box sx={{ display: "flex", mb: "10px" }}>
        <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>
          Password
        </Typography>
      </Box>

      {user?.has_password === true && (
        <>
          <UI.Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowChangePasswordDialog(Math.random());
            }}
            sx={{ width: { md: "250px", mobile: "100%" } }}
            type={"secondary"}
            title={"Change Password"}
          />
          <Typography
            variant={"subtitle1"}
            sx={{ fontSize: "16px !important", mt: "15px" }}
          >
            Forgot you password?{" "}
            <Box
              onClick={() => resetPassword()}
              component={"span"}
              sx={{
                cursor: "pointer",
                color: (theme) => theme.palette.colors.primary,
              }}
            >
              Reset password
            </Box>
          </Typography>
        </>
      )}
      {user?.has_password === false && (
        <>
          <UI.Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              resetPassword();
            }}
            sx={{ width: { md: "250px", mobile: "100%" } }}
            type={"secondary"}
            title={"Generate Password"}
          />
          <Typography
            variant={"subtitle1"}
            sx={{
              mt: "15px",
            }}
          >
            <Alert variant="outlined" severity="info" sx={{ color: "#4DB8FF" }}>
              {
                "Unlock the feature to log in to your account with an email by generating a password. You can change it later here in your profile settings."
              }
            </Alert>
          </Typography>
        </>
      )}
      <ChangePasswordDialog toggleOpen={showChangePasswordDialog} />
    </>
  );
};

export default ResetPasswordButton;
