import React from "react";
import MaterialButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { purple } from "@mui/material/colors";
import useMediaQuery from "@mui/material/useMediaQuery";

const QvrsButton = styled(MaterialButton)(
  ({ theme, type, ismobile, height }) => ({
    color: theme.palette.getContrastText(purple[500]),
    border: "1px solid transparent",
    borderRadius: "0px",
    boxShadow: "none",
    backgroundColor:
      (type === "primary" && theme.palette.buttons.primary) ||
      (type === "button" && theme.palette.buttons.primary) ||
      (type === "secondary" && theme.palette.buttons.secondary) ||
      (type === "terciary" && theme.palette.buttons.terciary),

    "&:hover": {
      border: 
        (type === "primary" && "1px solid #944DFF") ||
        (type === "button" && "1px solid #944DFF"),
      filter: 
      (type === "primary" && "drop-shadow(0px 0px 10px rgba(138, 38, 255, 0.5))") ||
      (type === "button" && "drop-shadow(0px 0px 10px rgba(138, 38, 255, 0.5))")||
      (type === "secondary" && "drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.15))"),
      transition: "all 0.2s ease",
      boxShadow: "none",
      backgroundColor:
        (type === "primary" && "#b185f2") ||
        (type === "button" && "#b185f2") ||
        (type === "secondary" && "#616479") ||
        (type === "terciary" && "unset"),
      color:
        (type === "primary" && "#fff") ||
        (type === "button" && "#fff") ||
        (type === "secondary" && "#fff") ||
        (type === "terciary" && "#944DFF"),
    },
    "&:disabled": {
      color: "rgb(255 255 255 / 100%)",
      opacity: "0.26"
    },

    width: "100%",
    height: ismobile === "true" ? "42px" : "42px",
    fontFamily: "Quantico",
    fontWeight: "700",
    fontSize: "16px",
  })
);

const Button = ({ title, type, onClick, ...props }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <QvrsButton
      {...props}
      onClick={onClick}
      variant={"contained"}
      ismobile={isMobile ? "true" : "false"}
      type={type}
    >
      {title}
    </QvrsButton>
  );
};

export default Button;
