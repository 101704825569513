import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";

const AddField = (props) => {
  const { onClick, title } = props;
  return (
    <Box
      {...props}
      onClick={onClick}
      variant="raised"
      component="span"
      sx={{
        cursor: "pointer",
        padding: "15px",

        border: "3px dotted #1B1E2F",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        height: "90px",
        "&:hover": {
          border: "3px dotted #944DFF",
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "flex-start" }}>
        <AddIcon sx={{ color: "#944DFF", fontSize: "4.5rem" }} />
        <Box
          sx={{
            ml: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ display: { md: "flex", mobile: "none" } }}
            variant={"h2"}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              textAlign: "left",
              lineHeight: "20px",
              display: { md: "none", mobile: "flex" },
            }}
            variant={"h4"}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AddField;
