import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../@components/UI";
import { useHistory } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { useAccount } from "wagmi";
import useService from "./useService";
import { useSnackbar } from "notistack";
import { ethers } from "ethers";
import useHandleErrors from "../../../@components/UI/useHandleErrors";
import erc20TokenArtifact from "../../../abis/contracts/modules/token/ERC20Token.sol/ERC20Token.json";
import stakingArtifact from "../../../abis/contracts/modules/staking/Staking.sol/Staking.json";
import CustomHooks from "../../../@components/hooks";
import { useUserRole } from "../context";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Alert from "@mui/material/Alert";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import customHooks from "../../../@components/hooks";

const VotingPower = ({ DAO }) => {
  const signer = customHooks.useEthersSigner();
  const { enqueueSnackbar } = useSnackbar();
  const service = useService(DAO?.id);
  const history = useHistory();
  const { address, isConnected } = useAccount();
  const { handleMetamaskError } = useHandleErrors();
  const [isLoading, setIsLoading] = React.useState(false);
  const { isBrandMember } = useUserRole();
  CustomHooks.useProtectedRoute(DAO, isBrandMember);
  const { openConnectModal } = useConnectModal();

  const { mutateAsync: addMember } = CustomHooks.usePost(service.create, {
    onSuccess: () => {
      enqueueSnackbar("Succesfully added to members", {
        variant: "success",
      });
      setIsLoading(false);
      history.push({
        pathname: `/brand/${DAO?.id}/membership`,
        state: { scrollToTop: true },
      });
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      amount: "1",
      duration_days: 30,
      user_address: address,
    },
  });

  const amountValue = useWatch({ control, name: "amount" });

  const onSubmit = async (form) => {
    setIsLoading(true);
    try {
      const token = new ethers.Contract(
        DAO.token_contract.address,
        erc20TokenArtifact.abi,
        signer
      );

      const staking = new ethers.Contract(
        DAO.staking_contract.address,
        stakingArtifact.abi,
        signer
      );

      const allowanceTx = await token.increaseAllowance(
        staking.target,
        ethers.parseEther(form.amount)
      );

      await allowanceTx.wait(1);

      const stakeTx = await staking.stakeTokens(
        ethers.parseEther(form.amount),
        0
      );
      const stakeReceipt = await stakeTx.wait(1);

      const event = stakeReceipt.logs.find(
        (data) => data.eventName === "StakeEvent"
      );

      const stakeIdBigNumber = event && event.args["newStake"].stakeId;

      const stakeId = Number(stakeIdBigNumber).toString();
      const data = { ...form, stake_id: stakeId };

      await addMember(data);
    } catch (error) {
      setIsLoading(false);
      handleMetamaskError(error);
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{ width: "100%", background: "rgba(27, 30, 47, 0.5)" }}
    >
      <UI.Busy.FullscreenIndicator show={isLoading} />
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", mobile: "column" },
          justifyContent: { md: "space-between", mobile: "center" },
          px: { md: "70px", mobile: "0px" },
          pb: "20px",
          pt: { md: "55px", mobile: "30px" },
        }}
      >
        <UI.BackButton
          fontSize={"14px !important"}
          title={"BACK TO MEMBERSHIP"}
          onClick={() =>
            history.push({
              pathname: `/brand/${DAO?.id}/membership`,
              state: { scrollToTop: true },
            })
          }
        />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: "100%",
            px: { md: "80px", mobile: "10px" },
            pb: "75px",
          }}
        >
          <Typography
            variant={"h1"}
            sx={{
              fontSize: "30px !important",
              lineHeight: "20px !important",
              mb: "25px",
              mt: "10px",
            }}
          >
            CONFIRM ADMIN STATUS
          </Typography>

          <Typography variant={"subtitle1"}>
            You are one step away from becoming the admin of your brand! To
            complete your role, please click the button below to verify your
            acceptance. You will be asked to sign the approval in your
            blockchain wallet not once, but twice. This double-verification
            process enhances security.
          </Typography>
          <Box
            sx={{
              width: "100%",
              mt: "45px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "260px", mb: "10px" }}>
                <UI.Button
                  disabled={!isConnected || parseInt(amountValue || 0) === 0}
                  title={"CONFIRM ADMIN STATUS"}
                  type={"primary"}
                  startIcon={<AccountBalanceWalletIcon />}
                />
              </Box>
              {!isConnected && (
                <Typography
                  variant={"subtitle1"}
                  sx={{ mt: "5px", mb: "30px" }}
                >
                  <Alert
                    variant="outlined"
                    severity="info"
                    sx={{ color: "#4DB8FF" }}
                  >
                    {`In order to confirm admin status you need to connect with your wallet. `}
                    <Box
                      sx={{
                        ml: "5px",
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                        color: "#944DFF",
                        fontWeight: "bold",
                      }}
                      onClick={() => openConnectModal()}
                      component={"span"}
                    >
                      Connect Wallet
                    </Box>
                  </Alert>
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default VotingPower;
