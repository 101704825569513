import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../@components/UI";
import { useHistory } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import CardSkeletonList from "./CardSkeletonList";

const CardList = ({ items, busy }) => {
  return (
    <Grid container spacing={2} columns={20}>
      {busy && <CardSkeletonList />}
      {items?.length > 0 &&
        items?.map((item, index) => (
          <Item item={item} key={"collection_" + index} />
        ))}
    </Grid>
  );
};

const Item = ({ item }) => {
  return (
    <Grid
      item
      mobile={20}
      md={6.66}
      lg={5}
      xxl={4}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <UI.FlipCard
        frontComponent={<FrontSide item={item} />}
        backComponent={<BackSide item={item} />}
      />
    </Grid>
  );
};

const BackSide = ({ item }) => {
  const history = useHistory();
  return (
    <Box
      sx={{
        cursor: "pointer",
        textAlign: "left",
        margin: "0 auto",
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.colors.darkGray,
          borderBottom: "none",
          padding: "10px 20px ",
          height: "74px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ textAlign: "left" }}>
              {item?.name ? (
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    fontSize: "13px !important",
                    color: "#7A7E92",
                    maxWidth: "185.4px",
                  }}
                >
                  <UI.OverflowTip>{`${item?.dao?.name}`}</UI.OverflowTip>
                </Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"130px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {item?.name ? (
              <Typography
                variant={"h2"}
                sx={{
                  lineHeight: "22px !important",
                  fontSize: "1rem !important",
                  maxWidth: "185.4px",
                }}
              >
                <UI.OverflowTip>{`${item?.name}`}</UI.OverflowTip>
              </Typography>
            ) : (
              <Skeleton
                height={"30px"}
                width={"130px"}
                variant={"rectangular"}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar src={item?.dao?.avatar_url} alt={"avatar"} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          height: "293px",
          flexDirection: "column",
          p: "10px 20px",
          backgroundColor: (theme) => theme.palette.colors.darkGray,
        }}
      >
        {item?.is_imported ? (
          ""
        ) : (
          <Box>
            <Box sx={{ display: "flex", mt: "5px" }}>
              <Typography
                variant={"subtitle1"}
                sx={{
                  fontSize: "13px !important",
                  color: "#7A7E92",
                  mr: "10px",
                }}
              >
                Price
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "20px", height: "20px" }}>
                  <UI.PaymentIcon currency={item?.currency} />
                </Box>
                <Typography
                  variant={"h1"}
                  sx={{
                    fontSize: "16px !important",
                    ml: "10px",
                    lineHeight: "unset !important",
                  }}
                >
                  {`${item?.total_price_with_tax} ${item?.currency}`}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", mt: "5px" }}>
              <Typography
                variant={"subtitle1"}
                sx={{
                  fontSize: "13px !important",
                  color: "#7A7E92",
                  mr: "10px",
                }}
              >
                Availability
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant={"h2"}
                  sx={{
                    fontSize: "16px !important",
                    lineHeight: "unset !important",
                  }}
                >
                  {`${item.nft_amount_minted} / ${item.nft_amount_limit}`}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        <Box sx={{ display: "flex", mt: "10px", maxHeight: "130px" }}>
          <Typography
            variant={"subtitle1"}
            sx={{
              fontSize: "14px !important",
              maxHeight: "130px",
              overflow: "hidden",
              whiteSpace: "normal",
              textAlign: "justify",
              lineHeight: "19px",
              textOverflow: "ellipsis",
            }}
          >
            {item?.description.length > 200
              ? item?.description.slice(0, 200) + " ..."
              : item?.description}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", mt: "20px" }}>
          <Box
            sx={{
              display: "flex",
              bottom: "16px",
              right: "16px",
            }}
          >
            <UI.UtilityTag
              utilityType={
                item.utilities.find(
                  (utility) => utility.is_main_utility === true
                )?.type || ""
              }
            />
            <Box
              sx={{
                ml: "10px",
                borderRadius: "100px",
                backgroundColor: "#50AF95",
                width: "fit-content",
                display: "flex",
                alignItems: "center",
                p: "4px 14px",
                height: "23px",
              }}
            >
              <Typography
                variant={"h2"}
                sx={{
                  fontSize: "10px !important",
                  lineHeight: "unset",
                }}
              >
                {item?.is_imported ? "Imported" : "Limited"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "74px",
          p: "15px 10px",
          backgroundColor: (theme) => theme.palette.colors.darkGray,
        }}
      >
        <Box sx={{ width: "120px" }}>
          <UI.Button
            sx={{ height: "36px" }}
            type={"primary"}
            title={"View"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push({
                pathname: `/brand/${item?.dao_id}/collection/${item?.id}`,
                state: { scrollToTop: true },
              });
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const FrontSide = ({ item }) => {
  return (
    <Box
      sx={{
        textAlign: "left",
        cursor: "pointer",
        margin: "0 auto",
        width: "293px",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        //border: "2px solid transparent",
        // transition: "all 0.2s ease-in-out",
        // "&:hover": {
        //   border: "2px solid #944dff",
        //   boxShadow: "#944dff 0px 0px 10px 0px",
        //   transition: "all 0.2s ease-in-out",
        // },
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.colors.darkGray,
          borderBottom: "none",
          padding: "10px 20px ",
          height: "74px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box>
              {item?.name ? (
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    fontSize: "13px !important",
                    color: "#7A7E92",
                    maxWidth: "185.4px",
                  }}
                >
                  <UI.OverflowTip>{`${item?.dao?.name}`}</UI.OverflowTip>
                </Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"130px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {item?.name ? (
              <Typography
                variant={"h2"}
                sx={{
                  lineHeight: "22px !important",
                  fontSize: "1rem !important",
                  maxWidth: "185.4px",
                }}
              >
                <UI.OverflowTip>{`${item?.name}`}</UI.OverflowTip>
              </Typography>
            ) : (
              <Skeleton
                height={"30px"}
                width={"130px"}
                variant={"rectangular"}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar src={item?.dao?.avatar_url} alt={"avatar"} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: "293px",
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          component={"span"}
          sx={{
            boxSizing: "border-box",
            display: "block",
            overflow: "hidden",
            width: "initial",
            height: "initial",
            background: "none",
            opacity: 1,
            border: "0px",
            margin: "0px",
            padding: "0px",
            position: "absolute",
            inset: 0,
            lineHeight: "0",
            fontSize: "0",
          }}
        >
          {item ? (
            item.nft_file_type?.startsWith("video") ? (
              <video
                width="100%"
                height={"auto"}
                autoplay="autoplay"
                playsInline
                loop
                muted
              >
                <source src={item.nft_image_url} />
              </video>
            ) : (
              <UI.ImageWithSkeleton
                sx={{
                  position: "absolute",
                  inset: 0,
                  boxSizing: "border-box",
                  padding: 0,
                  border: "none",
                  margin: "auto",
                  display: "block",
                  width: 0,
                  height: 0,
                  minWidth: "100%",
                  maxWidth: "100%",
                  minHeight: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
                src={item.nft_image_url}
                width={"100%"}
                alt={"slider-item"}
              />
            )
          ) : (
            <Skeleton
              height={"300px"}
              width={"300px"}
              variant={"rectangular"}
            />
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            position: "absolute",
            bottom: "16px",
            right: "16px",
          }}
        >
          <UI.UtilityTag
            utilityType={
              item.utilities.find((utility) => utility.is_main_utility === true)
                ?.type || ""
            }
          />
          <Box
            sx={{
              ml: "10px",
              borderRadius: "100px",
              backgroundColor: "#50AF95",
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              p: "4px 14px",
              height: "23px",
            }}
          >
            <Typography
              variant={"h2"}
              sx={{
                fontSize: "10px !important",
                lineHeight: "unset",
              }}
            >
              {item?.is_imported ? "Imported" : "Limited"}
            </Typography>
          </Box>
        </Box>
      </Box>

      {item?.is_imported ? (
        <Box
          sx={{
            height: "74px",
            backgroundColor: (theme) => theme.palette.colors.darkGray,
          }}
        ></Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: "10px 10px",
            backgroundColor: (theme) => theme.palette.colors.darkGray,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "20px", height: "20px", ml: "10px" }}>
              <UI.PaymentIcon currency={item?.currency} />
            </Box>

            {item?.total_price_with_tax !== "0.00" && (
              <Typography
                variant={"h1"}
                sx={{
                  fontSize: "16px !important",
                  ml: "10px",
                  lineHeight: "unset !important",
                }}
              >
                {`${item?.total_price_with_tax}`}
              </Typography>
            )}
            {item?.total_price_with_tax === "0.00" && (
              <Typography
                variant={"h1"}
                sx={{
                  fontSize: "16px !important",
                  ml: "10px",
                  lineHeight: "unset !important",
                }}
              >
                FREE
              </Typography>
            )}
          </Box>
          <Typography
            variant={"h2"}
            sx={{
              p: "10px",
              fontSize: "1rem !important",
              maxWidth: "185.4px",
            }}
          >
            <UI.OverflowTip>{`${item.nft_amount_minted} / ${item.nft_amount_limit}`}</UI.OverflowTip>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CardList;
