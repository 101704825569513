import React, { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3002"
    : window.env.PAYMENT_URL;

const QvrsePayment = ({
  loggedInQvrseUser,
  open,
  daoId,
  collectionId,
  onClose,
  onSuccess,
}) => {
  const [isOverlayVisible, setOverlayVisibility] = useState(false);
  const paymentWindowRef = useRef(null);
  const wasPaymentSuccessfulRef = useRef(false);
  let checkWindowClosed = useRef(null); // Using a ref to hold the interval so it's accessible in useEffect

  useEffect(() => {
    if (open) openPaymentWindow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handlePaymentSuccessfulMessage = (event) => {
    if (event.data === "PaymentSuccessful") {
      setOverlayVisibility(false);
      paymentWindowRef.current && paymentWindowRef.current.close();
      wasPaymentSuccessfulRef.current = true;
      window.removeEventListener("message", handlePaymentSuccessfulMessage);
      onSuccess();
    }
  };

  const openPaymentWindow = () => {
    const w = 420;
    const h = 600;
    const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
    const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
    setOverlayVisibility(true);

    paymentWindowRef.current = window.open(
      loggedInQvrseUser
        ? `${baseUrl}/brand/${daoId}/collection/${collectionId}?email=${encodeURIComponent(
            loggedInQvrseUser.email
          )}&source=qvrse`
        : `${baseUrl}/${daoId}/collection/${collectionId}`,
      "paymentWindow",
      `width=${w},height=${h},top=${y},left=${x}`
    );

    // Adding listeners
    window.addEventListener("message", handlePaymentSuccessfulMessage);

    // // If the user closes the pop-up manually
    checkWindowClosed.current = setInterval(() => {
      if (paymentWindowRef.current && paymentWindowRef.current.closed) {
        setOverlayVisibility(false);
        clearInterval(checkWindowClosed.current);
        if (!wasPaymentSuccessfulRef.current) {
          onClose();
        } else {
          wasPaymentSuccessfulRef.current = false; // Reset for future checks
        }
      }
    }, 500);
  };

  return (
    <Dialog
      fullScreen
      open={isOverlayVisible}
      PaperProps={{
        sx: {
          backgroundColor: "rgba(0,0,0,0.28)",
        },
      }}
    ></Dialog>
  );
};

export default QvrsePayment;
