import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "./TextField";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import InputAdornment from "@mui/material/InputAdornment";

const DateTime = React.forwardRef((props, ref) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileDateTimePicker
        {...props}
        ref={ref}
        disablePast
        ampm={false}
        inputFormat="DD MMM YYYY HH:mm"
        renderInput={(params) => (
          <TextField
            {...params}
            endAdornment={
              <InputAdornment position="start">
                <CalendarMonthIcon sx={{ color: "white" }} />
              </InputAdornment>
            }
          />
        )}
      />
    </LocalizationProvider>
  );
});

export default DateTime;
