import React from "react";
import { ethers } from "ethers";
import useHandleErrors from "../../../@components/UI/useHandleErrors";
import governorArtifact from "../../../abis/contracts/qtech/governor/Governor.sol/QtechGovernor.json";
import customHooks from "../../../@components/hooks";

const useQueue = (DAO) => {
  const signer = customHooks.useEthersSigner();
  const [busy, setBusy] = React.useState(false);
  const { handleMetamaskError } = useHandleErrors();

  const queue = async (proposal) => {
    return new Promise(async (resolve, reject) => {
      setBusy(true);
      const proposalData = proposal.data.proposal_data;
      try {
        const governor = new ethers.Contract(
          DAO?.governor_contract?.address,
          governorArtifact.abi,
          signer
        );
        const queueTx = await governor.queue(
          proposalData.targets,
          proposalData.values,
          proposalData.calldatas,
          ethers.keccak256(ethers.toUtf8Bytes(proposalData.description))
        );

        await queueTx.wait(1);
        resolve(queueTx);
      } catch (error) {
        handleMetamaskError(error);
        reject(error);
      }
      setBusy(false);
    });
  };

  return {
    queue,
    busy,
  };
};

export default useQueue;
