import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LogoutIcon from "@mui/icons-material/Logout";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import LoginIcon from "@mui/icons-material/Login";
import StoreIcon from "@mui/icons-material/Store";
import AppsIcon from "@mui/icons-material/Apps";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeIcon from '@mui/icons-material/Home';
import GradingIcon from '@mui/icons-material/Grading';
import Alert from "@mui/material/Alert";
import MobileSidebar from "../MobileSidebar";
import { useAccount } from "wagmi";
import { useAccountModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { useUserContext } from "../../userContext";
import { useHistory, useLocation } from "react-router-dom";
import Auth from "../../auth";

const MobileMenu = ({ triggerMenuOpen, onLogin, onRegister }) => {
  const { isConnected } = useAccount();
  const { logout } = Auth.useAuth();
  const { openAccountModal } = useAccountModal();
  const { openConnectModal } = useConnectModal();
  const { user: loggedInUser } = useUserContext();
  const history = useHistory();
  const location = useLocation();

  return (
    <MobileSidebar toggleMenuOpen={triggerMenuOpen}>
      <Box sx={{ mt: "50px" }}>
        <Box sx={{ p: "0 10px" }}>
          <Box
            onClick={() => {
              history.push({
                pathname: "/",
                state: { scrollToTop: true },
              });
            }}
            sx={{
              display: "flex",
              padding: "10px",
              border: location.pathname === "/" ? `1px solid #944dff` : "unset",
              boxShadow:
                location.pathname === "/"
                  ? `#944dff 0px 0px 10px 0px`
                  : "unset",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mr: "10px",
              }}
            >
              <HomeIcon />
            </Box>
            <Typography variant={"h2"} sx={{fontSize:"20px"}}>Home</Typography>
          </Box>
        </Box>
        <Box sx={{ p: "0 10px" }}>
          <Box
            onClick={() => {
              history.push({
                pathname: "/brand/explore",
                state: { scrollToTop: true },
              });
            }}
            sx={{
              display: "flex",
              padding: "10px",
              border: location.pathname.startsWith("/brand/explore")
                ? `1px solid #944dff`
                : "unset",
              boxShadow: location.pathname.startsWith("/brand/explore")
                ? `#944dff 0px 0px 10px 0px`
                : "unset",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mr: "10px",
              }}
            >
              <StoreIcon />
            </Box>
            <Typography variant={"h2"} sx={{fontSize:"20px"}}>Brands</Typography>
          </Box>
        </Box>
        <Box sx={{ p: "0 10px" }}>
          <Box
            onClick={() => {
              history.push({
                pathname: "/collection/explore",
                state: { scrollToTop: true },
              });
            }}
            sx={{
              display: "flex",
              padding: "10px",
              border: location.pathname.startsWith("/collection/explore")
                ? `1px solid #944dff`
                : "unset",
              boxShadow: location.pathname.startsWith("/collection/explore")
                ? `#944dff 0px 0px 10px 0px`
                : "unset",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mr: "10px",
              }}
            >
              <AppsIcon />
            </Box>
            <Typography variant={"h2"} sx={{fontSize:"20px"}}>Collections</Typography>
          </Box>
        </Box>
        <Box sx={{ p: "0 10px" }}>
          <Box
            onClick={() => {
              history.push({
                pathname: "/brand/apply",
                state: { scrollToTop: true },
              });
            }}
            sx={{
              display: "flex",
              padding: "10px",
              border: location.pathname.startsWith("/brand/apply")
                ? `1px solid #944dff`
                : "unset",
              boxShadow: location.pathname.startsWith("/brand/apply")
                ? `#944dff 0px 0px 10px 0px`
                : "unset",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mr: "10px",
              }}
            >
              <GradingIcon />
            </Box>
            <Typography variant={"h2"} sx={{fontSize:"20px"}}>Apply your Brand</Typography>
          </Box>
        </Box>
        <Box sx={{ p: "0 10px" }}>
          <Box
            sx={{
              display: "flex",
              padding: "10px",
            }}
            onClick={() => {
              history.push({
                pathname: "/qvrse",
                state: { scrollToTop: true },
              });
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mr: "10px",
              }}
            >
              <GroupsIcon sx={{ color: "white" }} />
            </Box>
            <Typography variant={"h2"} sx={{fontSize:"20px"}}>QVRSE DAO</Typography>
          </Box>
        </Box>
        <Divider
          sx={{ borderColor: "rgb(105 105 105 / 12%)", mt: "20px", mb: "20px" }}
        />
        {loggedInUser && (
          <Box sx={{ p: "0 10px" }}>
            <Box
              onClick={() => {
                history.push({
                  pathname: "/profile",
                  state: { scrollToTop: true },
                });
              }}
              sx={{
                display: "flex",
                padding: "10px",
                border: location.pathname.startsWith("/profile")
                  ? `1px solid #944dff`
                  : "unset",
                boxShadow: location.pathname.startsWith("/profile")
                  ? `#944dff 0px 0px 10px 0px`
                  : "unset",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  mr: "10px",
                }}
              >
                <Badge
                  badgeContent={"!"}
                  overlap="circular"
                  color="error"
                  invisible={loggedInUser?.email_verified}
                >
                  <Avatar
                    sx={{ width: "24px", height: "24px" }}
                    src={loggedInUser?.avatar_url}
                    alt={"avatar"}
                  />
                </Badge>
              </Box>
              <Typography variant={"h2"} sx={{fontSize:"20px"}}>PROFILE</Typography>
            </Box>
          </Box>
        )}
        {isConnected && loggedInUser && (
          <Box
            onClick={() => {
              openAccountModal();
            }}
            sx={{ p: "0 10px" }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  mr: "10px",
                }}
              >
                <AccountBalanceWalletIcon sx={{ color: "white" }} />
              </Box>
              <Typography variant={"h2"} sx={{fontSize:"20px"}}>Wallet</Typography>
            </Box>
          </Box>
        )}
        {loggedInUser && !isConnected && (
          <Box
            onClick={() => {
              openConnectModal();
            }}
            sx={{ p: "0 10px" }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  mr: "10px",
                }}
              >
                <AccountBalanceWalletIcon sx={{ color: "white" }} />
              </Box>
              <Typography variant={"h2"} sx={{fontSize:"20px"}}>Connect Wallet</Typography>
            </Box>
          </Box>
        )}
        {loggedInUser && (
          <Box
            onClick={(e) => {
              e.stopPropagation();
              logout();
            }}
            sx={{ p: "0 10px" }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  mr: "10px",
                }}
              >
                <LogoutIcon sx={{ color: "white" }} />
              </Box>
              <Typography variant={"h2"} sx={{fontSize:"20px"}}>Logout</Typography>
            </Box>
          </Box>
        )}
        {!loggedInUser && (
          <Box
            onClick={() => {
              onLogin();
            }}
            sx={{ p: "0 10px" }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  mr: "10px",
                }}
              >
                <LoginIcon sx={{ color: "white" }} />
              </Box>
              <Typography variant={"h2"} sx={{fontSize:"20px"}}>Log in</Typography>
            </Box>
          </Box>
        )}
        {!loggedInUser && (
          <Box
            onClick={() => {
              onRegister();
            }}
            sx={{ p: "0 10px" }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  mr: "10px",
                }}
              >
                <HowToRegIcon sx={{ color: "white" }} />
              </Box>
              <Typography variant={"h2"} sx={{fontSize:"20px"}}>Create Account</Typography>
            </Box>
          </Box>
        )}
        {loggedInUser && !loggedInUser?.email_verified && (
          <Box sx={{ p: "0 10px", mt: "15px" }}>
            <Alert variant="outlined" severity="info" sx={{ color: "#4DB8FF" }}>
              <Typography>
                Email not verified!
                <Link
                  onClick={() => history.push(`/profile/settings`)}
                  sx={{ textDecorationColor: "#944DFF;", ml: "5px" }}
                >
                  Go to Settings
                </Link>
              </Typography>
            </Alert>
          </Box>
        )}
      </Box>
    </MobileSidebar>
  );
};

export default MobileMenu;
