import Gateway from "../@components/gateway";

const useService = () => {
  const gateway = Gateway.useGateway();

  const save = (data) => {
    return gateway.post(`/user/settings/save/`, data);
  };

  const sendConfirmationEmail = () => {
    return gateway.post(`/users/jwt_send_confirm_mail/`);
  };

  const info = async () => {
    const resposne = await gateway.post(`/user/info/`);
    return resposne.data;
  };

  const register = (data) => {
    return gateway.postForm(`/register`, data);
  };

  const login = (data) => {
    return gateway.postForm(`/login`, data);
  };

  const google_login = (data) => {
    return gateway.postForm(`/google_login`, data);
  };

  const resetPassword = (data) => {
    return gateway.postForm(`/send_reset_password_email`, data);
  };

  const nft = async (nftId) => {
    const response = await gateway.post(`user/nft/${nftId}/`);
    return response.data;
  };

  const changePassword = (data) => {
    return gateway.postForm(`/change_password`, data);
  };

  const generateQR = (data) => {
    return gateway.post(`/qr_ticket_scan/refresh_ticket/`, data);
  };

  const generateDiscordCode = (data) => {
    return gateway.post(
      `/dao/${data.daoId}/collection/${data.collectionId}/generate_discord_code/`,
      { nft_id: data.nftId }
    );
  };

  return {
    save,
    info,
    sendConfirmationEmail,
    register,
    login,
    google_login,
    resetPassword,
    changePassword,
    generateQR,
    nft,
    generateDiscordCode,
  };
};

export default useService;
