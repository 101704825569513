import React from "react";
import Box from "@mui/material/Box";
import CollectionSlider from "../../home/collections/CollectionSlider";
import Busy from "../../../../@components/UI/busy";
import UI from "../../../../@components/UI";
import { useUserRole } from "../../context";
import useCollectionService from "../../collection/useService";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomHooks from "../../../../@components/hooks";
import Typography from "@mui/material/Typography";
import Utilities from "./Utilities";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

const Preview = ({ triggerRefetch, DAO, settings, onBuy }) => {
  const { isBrandMember } = useUserRole();
  const collectionService = useCollectionService(DAO?.id);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [currentActiveSlideIndex, setCurrentActiveSlideIndex] =
    React.useState(0);

  React.useEffect(() => {
    if (DAO && triggerRefetch) {
      fetchCollections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DAO, triggerRefetch]);

  const {
    data: items,
    isLoading: collectionsLoading,
    refetch: fetchCollections,
  } = CustomHooks.useFetch(
    [settings?.collections.map((c) => c.id)],
    collectionService.list,
    {
      enabled: false,
      select: (response) => {
        // Filter the items based on the visibility condition
        const filteredItems = response.filter((item) => {
          // Return all items if user has ADMIN or MODERATOR role
          if (isBrandMember) {
            return true;
          }
          // Filter out items that have item.visible = false
          return item.visible !== false;
        });

        return filteredItems;
      },
    }
  );

  React.useEffect(() => {
    if (DAO) {
      fetchCollections();
    }
  }, [DAO, fetchCollections]);
  return (
    <Box
      sx={{
        backgroundColor: settings.background_color,
        padding: {md:"20px 60px 60px", mobile:"20px"},
        height: "auto",
      }}
    >
      <Container
        maxWidth={"md"}
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          paddingLeft: { mobile: "0px" },
          paddingRight: { mobile: "0px" },
        }}
      >
        <Box sx={{ mt: "10px", pt: {md:"40px", mobile: "20px"} }}>
          {!isMobile && (
            <>
              {!collectionsLoading && items && items.length > 0 && (
                <CollectionSlider
                  disableClick
                  utilityTagsVisible={false}
                  isLoopEnabled={false}
                  isPaginationEnabled={false}
                  theme={settings}
                  onSlideChange={(swiperContext) =>
                    setCurrentActiveSlideIndex(swiperContext.activeIndex)
                  }
                  items={items}
                  busy={collectionsLoading}
                />
              )}
              {collectionsLoading && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Busy.Indicator />
                </Box>
              )}
              {!collectionsLoading && items && items.length === 0 && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <UI.NoResults text={"No collections found :("} />
                </Box>
              )}
            </>
          )}
          {isMobile && (
            <>
              {items && items.length !== 0 && (
                <UI.SwiperMobile
                  disableClick
                  theme={settings}
                  onSlideChange={(swiperContext) =>
                    setCurrentActiveSlideIndex(swiperContext.activeIndex)
                  }
                  items={items}
                />
              )}
              {collectionsLoading && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Busy.Indicator />
                </Box>
              )}
              {!collectionsLoading && items && items.length === 0 && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <UI.NoResults text={"No collections found :("} />
                </Box>
              )}
            </>
          )}
        </Box>
        {!collectionsLoading && items && (
          <Box sx={{ mt: "50px" }}>
            <Typography
              variant={"subtitle1"}
              sx={{
                fontSize: "14px !important",
                color: settings.text_color,
                opacity: "0.6 !important",
                lineHeight: "21px !important",
              }}
            >
              {items[currentActiveSlideIndex]?.dao?.name}
            </Typography>
            <Typography
              variant={"h2"}
              sx={{ fontSize: "24px !important", color: settings.text_color }}
            >
              {items[currentActiveSlideIndex]?.name}
            </Typography>
            <Box sx={{ mt: "15px" }}>
              <UI.UtilityTag
                customStyles={{
                  backgroundColor: settings.button_color,
                }}
                utilityType={
                  items[currentActiveSlideIndex]?.utilities?.find(
                    (utility) => utility.is_main_utility === true
                  )?.type || ""
                }
              />
            </Box>
            <Box sx={{ mt: "28px" }}>
              <UI.ReadMore
                fontSize={"16px"}
                color={settings.text_color}
                maxTextLength={300}
              >
                {items[currentActiveSlideIndex]?.description
                  ? items[currentActiveSlideIndex]?.description
                  : ""}
              </UI.ReadMore>
            </Box>
            <Box
              sx={{
                mt: "15px",
                mb: "7px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    fontSize: "16px !important",
                    color: settings.text_color,
                    opacity: "0.5",
                  }}
                >
                  Price
                </Typography>
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    ml: "4.5px",
                    fontSize: "10px !important",
                    color: settings.text_color,
                    mt: "2.7px",
                    opacity: "0.5",
                    mr: "2px",
                  }}
                >
                  with tax
                </Typography>
              </Box>

              {items[currentActiveSlideIndex]?.total_price_with_tax > 0 && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant={"h1"}
                    sx={{
                      color: settings.text_color,
                      fontSize: "16px !important",
                      ml: "10px",
                      lineHeight: "unset !important",
                    }}
                  >
                    {`${items[currentActiveSlideIndex]?.total_price_with_tax} ${items[currentActiveSlideIndex]?.currency}`}
                  </Typography>
                </Box>
              )}
              {items[currentActiveSlideIndex]?.total_price_with_tax ===
                "0.00" && (
                <Typography
                  variant={"h1"}
                  sx={{
                    color: settings.text_color,
                    fontSize: "16px !important",
                    ml: "10px",
                    lineHeight: "unset !important",
                  }}
                >
                  FREE
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                mb: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant={"subtitle1"}
                sx={{
                  color: settings.text_color,
                  fontSize: "16px !important",
                  opacity: "0.5",
                }}
              >
                Availability
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant={"h1"}
                  sx={{
                    color: settings.text_color,
                    fontSize: "16px !important",
                    ml: "10px",
                    lineHeight: "unset !important",
                  }}
                >
                  {`${items[currentActiveSlideIndex]?.nft_amount_minted}/${items[currentActiveSlideIndex]?.nft_amount_limit}`}
                </Typography>
              </Box>
            </Box>

            <UI.Button
              disabled={
                items[currentActiveSlideIndex]?.nft_amount_limit -
                  items[currentActiveSlideIndex]?.nft_amount_minted <=
                0
              }
              sx={{
                height: "42px",
                width: "220px",
                backgroundColor: settings.button_color,
                "&:hover": {
                  backgroundColor: settings.button_color,
                  opacity: "0.8",
                  border: settings.button_color,
                  filter: "none",
                }
              }}
              type={"primary"}
              title={"BUY"}
              onClick={() => {
                onBuy(items[currentActiveSlideIndex]?.id);
              }}
            />
            <Grid
              item
              md={12}
              mobile={12}
              sx={{ mt: { mobile: "60px", md: "50px" } }}
            >
              <Box sx={{ mb: "10px" }}>
                <Typography
                  variant={"h2"}
                  sx={{
                    fontSize: "22px !important",
                    color: settings.text_color,
                  }}
                >
                  Perks
                </Typography>
              </Box>
              <Utilities
                utilityTagsVisible={false}
                customStyles={settings}
                collection={items[currentActiveSlideIndex]}
              />
            </Grid>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Preview;
