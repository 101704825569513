import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import UI from "../@components/UI";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import HomeIcon from "@mui/icons-material/Home";
import SettingsButton from "./SettingsButton";
import { useHistory, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import truncateEthAddress from "truncate-eth-address";
import Skeleton from "@mui/material/Skeleton";
import { useAccount } from "wagmi";

const useStyles = makeStyles(() => ({
  categoryItem: {
    width: "190px",
    height: "60px",
    display: "flex",
    alignItems: "center",
    background: "rgba(27, 30, 47, 0.7)",
    marginLeft: "2px",
    marginRight: "2px",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    borderBottom: ({ selected }) => {
      return selected ? "2px solid #944DFF" : "2px solid transparent";
    },
  },
}));

const MobileMenu = ({ user, currentUrl }) => {
  const location = useLocation();
  const history = useHistory();
  const { address, isConnected } = useAccount();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const swiperRef = React.useRef(null);
  const [showSpeedDial, setShowSpeedDial] = React.useState(false);

  React.useEffect(() => {
    const swiper = swiperRef.current.swiper;

    // VotingPower a new Intersection Observer instance
    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowSpeedDial(!entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }
    );

    // Observe the Swiper element
    observer.observe(swiper.el);

    // Cleanup function
    return () => {
      if (swiper.el instanceof Element) {
        observer.unobserve(swiper.el);
      }
    };
  }, []);

  const menuItems = [
    { icon: <HomeIcon />, title: "Home", url: `${currentUrl}` },
    {
      icon: <BuildCircleIcon />,
      title: "Created",
      url: `${currentUrl}/created`,
    },
    {
      icon: <MonetizationOnIcon />,
      title: "Finance",
      url: `${currentUrl}/finance`,
    },
  ];

  return (
    <Box sx={{ display: { mobile: "block", md: "none" }, mb: "30px" }}>
      {showSpeedDial && <UI.SpeedDial items={menuItems} />}
      <Box
        onClick={() => {
          history.push(`/profile`);
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Paper
          sx={{
            borderRadius: "50%",
            width: "125px",
            height: "125px",
            display: "flex",
            mb: "20px",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            overflow: "hidden",
          }}
        >
          {user ? (
            <img
              style={{
                width: "100%",
                height: "100%",
                margin: "0 auto",
                textAlign: "center",
                objectFit: "cover",
              }}
              src={user?.avatar_url}
              alt={"dao-logo"}
            />
          ) : (
            <Avatar sx={{ margin: "0 auto", width: "100%", height: "100%" }} />
          )}
        </Paper>
        {user && (
          <Box
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            sx={{
              position: "absolute",
              bottom: "15px",
              right: "calc(50% + -60px)",
              width: "35px",
              height: "35px",
              zIndex: 2,
            }}
          >
            <SettingsButton />
          </Box>
        )}
      </Box>
      {user ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                maxWidth: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                textAlign: "center",
                fontSize: "24px !important",
                display: { md: "block" },
                lineHeight: "20px !important",
              }}
              variant={"h2"}
            >
              {user?.username || "User"}
            </Typography>
            {isConnected && (
              <Typography
                variant={"h4"}
                sx={{
                  textAlign: "center",
                  width: "170px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <UI.CopyToClipboard
                  label={truncateEthAddress(address || "")}
                  value={address || ""}
                />
              </Typography>
            )}
          </Box>
          {!isMobile && <SettingsButton />}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Skeleton variant={"text"} width={200} height={50} />
        </Box>
      )}
      <Swiper
        ref={swiperRef}
        grabCursor={true}
        slidesPerView={3}
        className="collection-category-swiper"
      >
        <SwiperSlide>
          <Item
            selected={location.pathname === `/profile`}
            onSelect={() => {
              history.push(`${currentUrl}`);
            }}
            index={0}
            title={"Home"}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Item
            selected={!!location.pathname.startsWith(`/profile/created`)}
            onSelect={() => {
              history.push(`${currentUrl}/created`);
            }}
            index={1}
            title={"Created"}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Item
            selected={!!location.pathname.startsWith(`/profile/finance`)}
            onSelect={() => {
              history.push(`${currentUrl}/finance`);
            }}
            index={2}
            title={"Finance"}
          />
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};

const Item = (props) => {
  const { selected, index, onSelect, title, isAdminItem } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const classes = useStyles({ selected, isMobile });
  return (
    <Box
      sx={{ position: "relative" }}
      className={classes.categoryItem}
      onClick={() => onSelect(index)}
      {...props}
    >
      {isAdminItem && (
        <Typography
          variant={"subtitle1"}
          sx={{
            position: "absolute",
            top: "7px",
            left: "50%",
            transform: "translateX(-50%)",
            fontSize: "10px !important",
            lineHeight: "14px",
            textTransform: "none",
            color: selected ? "#7A7E92" : "#474A5B",
          }}
        >
          admin
        </Typography>
      )}
      <Typography
        sx={{
          margin: "0 auto",
          fontWeight: "bold",
          fontSize: "14px !important",
        }}
      >
        {title.toUpperCase()}
      </Typography>
    </Box>
  );
};

export default MobileMenu;
