import React from "react";
import { useUserContext } from "../../../../../../../@components/userContext";
import { useAccount } from "wagmi";
import UI from "../../../../../../../@components/UI";
import CustomHooks from "../../../../../../../@components/hooks";
import useService from "./useService";

const Button = (props) => {
  const { title, onError, orderId, onRequestPassword, collection, ...other } =
    props;
  const service = useService();
  const { user: loggedInUser } = useUserContext();
  const { isConnected } = useAccount();

  const { mutate: create } = CustomHooks.usePost(service.create, {
    onSuccess: async (response) => {
      onRequestPassword({ password: response.password });
    },
  });

  return (
    <>
      <UI.Button
        onClick={() => {
          create({
            orderId,
            collectionId: collection.id,
            daoId: collection.dao_id,
          });
        }}
        disabled={isConnected || loggedInUser?.address}
        sx={{ height: "42px", width: "220px" }}
        type={"primary"}
        title={title}
        {...other}
      />
    </>
  );
};

export default Button;
