import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import UI from "../../../@components/UI";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MaterialDialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";

const RemoveMemberDialog = ({ onClose, onConfirm }) => {
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          padding: { md: "30px 10px 42px 10px", mobile: "0px 0px" },
          width: "550px",
          margin: { mobile: "15px", md: "0px" },
        },
      }}
      open={true}
      onClose={onClose}
    >
      <CloseIcon
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 30,
          top: 30,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <DialogTitle sx={{ p: "8px 24px" }}>Remove Member</DialogTitle>
      <DialogContent sx={{ p: "0px 24px 5px 24px" }}>
        <Box sx={{ mt: "10px" }}>
          <DialogContentText>
            <Typography variant={"subtitle1"}>
              Are you sure you want to remove this member?
            </Typography>
          </DialogContentText>
        </Box>
        <Box sx={{ mt: "10px" }}></Box>
      </DialogContent>
      <Box
        sx={{
          mt: "20px",
          px: "15px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <UI.Button
          sx={{ width: "150px" }}
          title={"Remove"}
          type={"primary"}
          onClick={onConfirm}
        />
      </Box>
    </MaterialDialog>
  );
};

export default RemoveMemberDialog;
