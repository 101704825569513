import React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import useService from "../useService";
import UI from "../../@components/UI";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Ticket from "./Ticket";
import Skeleton from "@mui/material/Skeleton";
import CustomHooks from "../../@components/hooks";
import UnlockNFTDialog from "./UnlockNFTDialog";
import { useAccount } from "wagmi";
import qrIcon from "../../static/qr-icon.png";
import transferIcon from "../../static/transfer-icon.png";
import collectionIcon from "../../static/collection-icon.png";
import openSeaIcon from "../../static/opensea-icon.png";
import vaultIcon from "../../static/vault-icon.png";
import arrowIcon from "../../static/arrow-icon.png";
import walletIcon from "../../static/wallet-icon.png";

import Utilities from "./Utilities";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tooltip from "@mui/material/Tooltip";
import { ethers } from "ethers/lib.esm";
import erc721Artifact from "../../abis/contracts/modules/collection/Collection.sol/Collection.json";
import useHandleErrors from "../../@components/UI/useHandleErrors";
import { useSnackbar } from "notistack";
import customHooks from "../../@components/hooks";

const Index = () => {
  const history = useHistory();
  const { id } = useParams();
  const { isConnected } = useAccount();
  const service = useService();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [mainUtility, setMainUtility] = React.useState(null);
  const [nft, setNft] = React.useState(null);
  const { handleMetamaskError } = useHandleErrors();
  const { enqueueSnackbar } = useSnackbar();
  const signer = customHooks.useEthersSigner();
  const { address: ownerAddress } = useAccount();
  const [loading, setLoading] = React.useState(false);

  const { data: collection } = CustomHooks.useFetch(
    "collection",
    () => service.nft(id),
    {
      onSuccess: async (nft) => {
        setNft(nft);
        setMainUtility(
          nft?.utilities.find((utility) => utility.is_main_utility)
        );
      },
    }
  );

  const transferTicket = async (ticket, recipientAddress) => {
    setLoading(true);

    try {
      const nft_contract = new ethers.Contract(
        ticket?.nft_address,
        erc721Artifact.abi,
        signer
      );

      await nft_contract.transferFrom(
        ownerAddress,
        recipientAddress,
        ticket.nft_id
      );
      setLoading(false);
    } catch (e) {
      handleMetamaskError(e);
      setLoading(false);
    }
  };

  const { mutate: generateQR } = CustomHooks.usePost(service.generateQR, {
    onSuccess: () => {
      enqueueSnackbar("QR Ticket was succesfully sent to your email", {
        variant: "success",
      });
    },
  });

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <UI.Busy.FullscreenIndicator show={loading}>
        <Typography variant={"subtitle2"}>
          Transferring NFT in progress ...
        </Typography>
        <Typography variant={"subtitle2"}>
          Please do not close this window.
        </Typography>
      </UI.Busy.FullscreenIndicator>
      <Paper elevation={0} sx={{ width: "100%", background: "transparent" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { md: "0px", mobile: "10px" },
            pb: "60px",
            pt: { md: "20px", mobile: "20px" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <UI.BackButton
              fontSize={"14px !important"}
              title={"BACK TO PROFILE"}
              onClick={() =>
                history.push({
                  pathname: `/profile`,
                  state: { scrollToTop: true },
                })
              }
              address={collection?.nft_address}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            paddingLeft: { mobile: "10px", md: "0px" },
            paddingRight: { mobile: "10px", md: "0px" },
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={4} mobile={12}>
              <Box
                sx={{
                  display: "flex",
                  mb: "60px",
                  justifyContent: { mobile: "center", md: "unset" },
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Ticket
                    brandName={nft?.dao_info?.name}
                    brandAvatar={nft?.dao_info?.avatar_url}
                    name={nft?.nft_name}
                    fileType={nft?.nft_file_type}
                    data={nft?.nft_image_url}
                    animationUrl={nft?.nft_animation_url || null}
                    mainUtility={mainUtility}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item md={8} mobile={12}>
              <Box sx={{ ml: { md: "60px", mobile: "0px" } }}>
                <Box sx={{ mb: "14px" }}>
                  {nft?.dao_info?.name ? (
                    <Typography
                      variant={"subtitle1"}
                      sx={{
                        fontSize: "16px !important",
                        color: "#7A7E92",
                        lineHeight: "13px !important",
                      }}
                    >
                      {nft?.dao_info?.name}
                    </Typography>
                  ) : (
                    <Skeleton
                      sx={{ mb: "10px" }}
                      height={"15px"}
                      width={"300px"}
                      variant={"rectangular"}
                    />
                  )}
                </Box>
                <Box sx={{ mb: "30px" }}>
                  {nft?.nft_name ? (
                    <Typography
                      variant={"h2"}
                      sx={{
                        lineHeight: {
                          md: "18px !important",
                          mobile: "30px !important",
                        },
                      }}
                    >
                      {`${nft?.nft_name} #${nft?.nft_id}`}
                    </Typography>
                  ) : (
                    <Skeleton
                      height={"30px"}
                      width={"300px"}
                      variant={"rectangular"}
                    />
                  )}
                </Box>
                {nft ? (
                  <Box sx={{ mb: "30px", display: "flex" }}>
                    <UI.UtilityTag utilityType={mainUtility?.type} />
                    <Box
                      sx={{
                        ml: "10px",
                        borderRadius: "100px",
                        backgroundColor: "#50AF95",
                        width: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        p: "4px 14px",
                        height: "23px",
                      }}
                    >
                      <Typography
                        variant={"h2"}
                        sx={{
                          fontSize: "10px !important",
                          lineHeight: "unset",
                        }}
                      >
                        {collection?.is_imported ? "Imported" : "Limited"}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Skeleton
                    sx={{ mb: "30px", display: "flex" }}
                    height={"30px"}
                    width={"300px"}
                    variant={"rectangular"}
                  />
                )}
                <Box sx={{ mt: "55px" }}>
                  <Box
                    onClick={(e) => {
                      e.stopPropagation();
                      generateQR({
                        collection_id: nft.event_id,
                        ticket_id: nft?.nft_id,
                      });
                    }}
                    sx={{
                      display: "flex",
                      cursor: "pointer",
                      transition: "all 0.5s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.01)",
                      },
                      mb: "13px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                        backgroundColor: "rgba(148, 77, 255, 1);",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={qrIcon}
                        sx={{ width: "16px", height: "16px" }}
                      />
                    </Box>
                    <Typography
                      variant={"h2"}
                      sx={{ ml: "12px", fontSize: "14px !important" }}
                    >
                      Generate QR Code
                    </Typography>
                  </Box>
                  <UI.TransferDialog.Action
                    element={
                      <Box
                        onClick={(e) => {
                          !isConnected && e.stopPropagation();
                          !isConnected && e.preventDefault();
                        }}
                        sx={{
                          display: "flex",
                          cursor: !isConnected ? "normal" : "pointer",
                          transition: "all 0.5s ease-in-out",
                          "&:hover": {
                            transform: "scale(1.01)",
                          },
                          mb: "13px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "50%",
                            backgroundColor: !isConnected
                              ? "rgb(52 54 69);"
                              : "rgba(148, 77, 255, 1);",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            component={"img"}
                            src={transferIcon}
                            sx={{
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </Box>
                        <Typography
                          variant={"h2"}
                          sx={{
                            ml: "12px",
                            fontSize: "14px !important",
                            opacity: !isConnected ? 0.5 : 1,
                          }}
                        >
                          transfer to another member
                        </Typography>
                      </Box>
                    }
                    onConfirm={(addressTo) => {
                      isConnected && transferTicket(nft, addressTo);
                    }}
                  />

                  <Box
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      history.push({
                        pathname: `/brand/${nft?.dao_id}/collection/${nft?.event_id}`,
                        state: { scrollToTop: true },
                      });
                    }}
                    sx={{
                      display: "flex",
                      cursor: "pointer",
                      transition: "all 0.5s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.01)",
                      },
                      mb: "13px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                        backgroundColor: "rgba(148, 77, 255, 1);",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={collectionIcon}
                        sx={{ width: "18px" }}
                      />
                    </Box>
                    <Typography
                      variant={"h2"}
                      sx={{ ml: "12px", fontSize: "14px !important" }}
                    >
                      View Collection
                    </Typography>
                  </Box>
                  <Box
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        `https://${
                          process.env.NODE_ENV === "development" ||
                          window.env.CHAIN === "MUMBAI"
                            ? "testnets.opensea.io"
                            : "opensea.io"
                        }/assets/${
                          process.env.NODE_ENV === "development" ||
                          window.env.CHAIN === "MUMBAI"
                            ? "mumbai"
                            : "matic"
                        }/${nft?.nft_address}/${nft.nft_id}`,
                        "_blank"
                      );
                    }}
                    sx={{
                      display: "flex",
                      cursor: "pointer",
                      transition: "all 0.5s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.01)",
                      },
                      mb: "13px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                        backgroundColor: "rgba(148, 77, 255, 1);",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={openSeaIcon}
                        sx={{ width: "16px" }}
                      />
                    </Box>
                    <Typography
                      variant={"h2"}
                      sx={{ ml: "12px", fontSize: "14px !important" }}
                    >
                      View on OpenSea
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ mt: "45px" }}>
                  <Box sx={{ display: "flex", mb: "15px" }}>
                    <Typography
                      variant={"h2"}
                      sx={{ fontSize: "18px !important" }}
                    >
                      NFT STORAGE
                    </Typography>
                    <UI.Tooltip>
                      Before you can transfer your purchased NFT to a personal
                      wallet, you must first connect your account to a
                      compatible crypto wallet. Please note, without a connected
                      wallet, your NFT will remain in our secure storage.
                      Although you still own it, you won't be able to transfer
                      or trade it until you connect a wallet.
                    </UI.Tooltip>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Tooltip title={"NFT is in the storage"} arrow>
                        <Box
                          component={"img"}
                          src={vaultIcon}
                          sx={{
                            width: "20px",
                            height: "20px",
                            filter: nft?.locked_in_vault
                              ? "opacity(1)"
                              : "brightness(0.3)",
                          }}
                        />
                      </Tooltip>
                      <Box
                        component={"img"}
                        src={arrowIcon}
                        sx={{ width: "25px", height: "20px", mx: "18px" }}
                      />
                      <Tooltip title={"NFT is in your wallet"} arrow>
                        <Box
                          component={"img"}
                          src={walletIcon}
                          sx={{
                            width: "20px",
                            height: "20px",
                            filter: nft?.locked_in_vault
                              ? "brightness(1)"
                              : "brightness(5)",
                          }}
                        />
                      </Tooltip>
                    </Box>
                    {nft?.locked_in_vault && (
                      <UnlockNFTDialog.Action
                        element={
                          <UI.Button
                            nftVaultAddress={nft?.nft_vault_address}
                            disabled={!isConnected || !nft?.locked_in_vault}
                            type={"primary"}
                            title={
                              isMobile
                                ? "Move to wallet"
                                : "Move to your wallet"
                            }
                            sx={{ width: "fit-content", ml: "30px" }}
                          />
                        }
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md={12}
              mobile={12}
              sx={{ mt: { mobile: "60px", md: "50px" } }}
            >
              <Box sx={{ mb: "30px" }}>
                <Typography variant={"h2"}>Perks</Typography>
              </Box>
              <Utilities collection={nft} />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default Index;
