import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";
import useService from "./useService";
import CustomHooks from "../../../../@components/hooks";
import { useSnackbar } from "notistack";
import UI from "../../../../@components/UI";

const Poll = ({ data, daoId, postId, refetch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const service = useService(daoId, postId);
  const [userHasVoted, setUserHasVoted] = React.useState(false);
  const [isPollExpired, setIsPollExpired] = React.useState(false);

  const { mutate: vote } = CustomHooks.usePost(service.vote, {
    onSuccess: () => {
      enqueueSnackbar("Succesfully voted", {
        variant: "success",
      });
      refetch();
    },
  });

  React.useEffect(() => {
    if (data) {
      const userHasVoted = data.options.some((option) => option.has_voted);
      setUserHasVoted(userHasVoted);

      const isPollExpired = moment().isAfter(data.datetime_expire);
      setIsPollExpired(isPollExpired);
    }
  }, [data]);

  return (
    <Box
      sx={{
        pt: "10px",
        p: { md: "20px", mobile: "10px" },
        pb: "20px",
        backgroundColor: "#1B1E2F",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant={"h2"}
          sx={{
            fontSize: "16px !important",
            mb: "16px",
            maxWidth: { md: "420px", mobile: "290px" },
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <UI.OverflowTip>{data.title}</UI.OverflowTip>
          </Box>
        </Typography>
        <Box
          sx={{
            mt: "2.5px",
            ml: "10px",
            borderRadius: "100px",
            backgroundColor: isPollExpired ? "#F8285C" : "#50AF95",
            width: "60px",
            p: "0px 9px",
            height: "25px",
            textAlign: "center",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              fontSize: "10px !important",
              lineHeight: "25px",
            }}
          >
            {isPollExpired ? "EXPIRED" : "ACTIVE"}
          </Typography>
        </Box>
      </Box>
      <Box>
        {data.options.map((option) => {
          const percentage = (option.votes / data.total_votes) * 100;

          return (
            <Box
              onClick={() => isPollExpired === false && vote(option?.id)}
              sx={{
                position: "relative",
                cursor: isPollExpired ? "normal" : "pointer",
                border: option?.has_voted
                  ? "1px solid #944dff"
                  : "1px solid transparent",
                transition: "all 0.2s ease-in-out",
                "&:hover": {
                  transform: isPollExpired ? "scale(1)" : "scale(1.02)",

                  border: isPollExpired
                    ? "1px solid transparent"
                    : "1px solid #944dff",
                },
                display: "flex",
                height: "48px",
                mb: { md: "11px", mobile: "9px" },
                backgroundColor: "rgba(51, 54, 69, 1)",
              }}
            >
              <Box
                sx={{
                  transition: "all 0.5s ease-in-out",
                  backgroundColor: `rgba(148, 77, 255, 1)`,
                  width: `${userHasVoted || isPollExpired ? percentage : 0}%`,
                }}
              ></Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    position: "absolute",
                    left: { md: "17px", mobile: "11px" },
                    top: "50%",
                    transform: "translateY(-50%)",
                    ml: "0px",
                    fontWeight: option?.has_voted ? "bold" : "normal",
                    fontSize: "14px !important",
                    maxWidth: { md: "400px", mobile: "270px" },
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <UI.OverflowTip> {option.title}</UI.OverflowTip>
                </Typography>

                <Typography
                  variant={"subtitle1"}
                  sx={{
                    position: "absolute",
                    right: { md: "17px", mobile: "11px" },
                    top: "50%",
                    transform: `translateY(-50%) translateX(${
                      userHasVoted || isPollExpired ? "0" : "100%"
                    })`,
                    ml: "17px",
                    fontWeight: option?.has_voted ? "bold" : "normal",
                    fontSize: "14px !important",
                    transition: "transform 0.4s ease-in-out",
                    visibility:
                      userHasVoted || isPollExpired ? "visible" : "hidden",
                  }}
                >
                  {option.votes} {option.votes === 1 ? "vote" : "votes"}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            variant={"subtitle1"}
            sx={{ fontSize: "14px !important", color: "#7a7e92", pb:{ md: "3px", mobile: "10px" } }}
          >
            {isPollExpired ? (
              <>
                Expired on{" "}
                {moment(data.datetime_expire).format("DD MMM YYYY HH:mm")}
              </>
            ) : (
              <>
                Active until{" "}
                {moment(data.datetime_expire).format("DD MMM YYYY HH:mm")}
              </>
            )}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={"subtitle1"}
            sx={{ fontSize: "14px !important", color: "#7A7E92", pb:{ md: "3px", mobile: "10px" } }}
          >
            {data.total_votes} {data.total_votes === 1 ? "voter" : "voters"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Poll;
