import Gateway from "../../../../@components/gateway";

const useService = (daoId) => {
  const gateway = Gateway.useGateway();

  const save = (data) => {
    return gateway.post(`dao/${daoId}/treasury/transactions/new/`, data);
  };

  const list = () => {
    return gateway.get(`dao/${daoId}/treasury/transactions/`);
  };

  return {
    save,
    list,
  };
};

export default useService;
