import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Logo from "../../../static/qvrse-logo.png";
import QtechTextLogo from "../../../static/qvrse-text-logo.png";
import Button from "@mui/material/Button";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import UI from "../";
import useMediaQuery from "@mui/material/useMediaQuery";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import MobileMenu from "./MobileMenu";
import RegisterDialog from "../../../profile/dialogs/RegisterDialog";
import EmailConfirmationDialog from "../../../profile/dialogs/EmailConfirmation";
import LogInDialog from "../../../profile/dialogs/LogInDialog";
import NoExistingAccountDialog from "../../../profile/dialogs/NoExistingAccountDialog";
import PasswordResetDialog from "../../../profile/dialogs/ResetPasswordDialog";
import { useUserContext } from "../../userContext";
import useUserService from "../../../profile/useService";
import ConnectWallet from "../connectWallet";
import UserAvatar from "./UserAvatar";

const useStyles = makeStyles((theme) => ({
  navButton: {
    color: theme.palette.appbar.text.primary,
    transitionDuration: "500ms",
    transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
    transitionProperty:
      "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter",
    "&:hover": {
      color: theme.palette.colors.primary,
      backgroundColor: "transparent !important",
      "&:before": {
        width: "90%",
      },
    },
    "&:before": {
      content: "",
      position: "absolute",
      bottom: "10px",
      right: 0,
      left: 0,
      display: "block",
      height: "1px",
      width: "90%",
      transition: ".2s ease-in",
      margin: "auto",
    },
  },
  listItemButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
}));

const ElevationScroll = (props) => {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will utilities to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    style: {
      boxShadow: trigger ? "rgb(0 0 0) 5px 0px 10px" : "none",
      background: trigger ? "#0E111F" : "transparent",
    },
  });
};

const MaterialAppBar = (props) => {
  const classes = useStyles();

  const history = useHistory();
  const userService = useUserService();
  const { user: loggedInUser, setUser: setUserContext } = useUserContext();
  const location = useLocation();
  const [triggerMenuOpen, setTriggerMenuOpen] = React.useState(null);
  const [triggerRegisterDialog, setTriggerRegisterDialog] =
    React.useState(null);
  const [triggerLoginDialog, setTriggerLoginDialog] = React.useState(null);
  const [triggerPasswordResetDialog, setTriggerPasswordResetDialog] =
    React.useState(null);
  const [noExistingAccountDialog, setNoExistingAccountDialog] =
    React.useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [triggerEmailConfirmationDialog, setTriggerEmailConfirmation] =
    React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const access_token = localStorage.getItem("access_token");
      if (access_token) {
        await userService
          .info()
          .then(async (response) => {
            setUserContext(response);
          })
          .catch(() => {});
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar
          sx={{
            paddingLeft: { xs: "0px", md: "50px", lg: "100px", mobile: "0px" },
            paddingRight: {
              xs: "0px",
              md: "50px",
              lg: "100px",
              mobile: "0px",
            },
            height: "72px",
            zIndex: 999,
            width: "100%",
            left: 0,
            right: 0,
            margin: "auto",
            backgroundColor: (theme) => theme.palette.appbar.background,
            color: (theme) => theme.palette.text.primary,
            justifyContent: "center",
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between", height: "72px" }}>
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                left: { md: 0, mobile: "16px" },
              }}
            >
              <Box
                onClick={() =>
                  history.push({
                    pathname: "/",
                    state: { scrollToTop: true },
                  })
                }
                sx={{
                  mr: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <img src={Logo} width={"43px"} height={"43px"} alt={"logo"} />
                  <Box
                    sx={{
                      display: { mobile: "flex", md: "flex" },
                      flexDirection: "column",
                      justifyContent: "center",
                      ml: "10px",
                      fontWeight: "bold",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <img src={QtechTextLogo} width={"80px"} alt={"qtech"} />
                      <Box
                        sx={{
                          position: "absolute",
                          top: "30px",
                          right: "-3px",
                          opacity: "0.5",
                          textTransform: "uppercase",
                          fontSize: "10px",
                        }}
                      >
                        Beta
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Box sx={{ display: { mobile: "none", md: "flex" } }}>
                <Button
                  classes={{ root: classes.navButton }}
                  onClick={() =>
                    history.push({
                      pathname: "/brand/explore",
                      state: { scrollToTop: true },
                    })
                  }
                  sx={{
                    color:
                      location.pathname === "/brand/explore"
                        ? (theme) => theme.palette.colors.primary
                        : "unset",
                    my: 2,
                    padding: "0px 20px",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    fontWeight: "700",
                    fontFamily: "Quantico, serif",
                    display: "block",
                  }}
                >
                  Brands
                </Button>
                <Button
                  classes={{ root: classes.navButton }}
                  onClick={() =>
                    history.push({
                      pathname: "/collection/explore",
                      state: { scrollToTop: true },
                    })
                  }
                  sx={{
                    color:
                      location.pathname === "/collection/explore"
                        ? (theme) => theme.palette.colors.primary
                        : "unset",
                    my: 2,
                    padding: "0px 20px",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    fontWeight: "700",
                    fontFamily: "Quantico, serif",
                    display: "block",
                  }}
                >
                  Collections
                </Button>
                <Button
                  classes={{ root: classes.navButton }}
                  onClick={() =>
                    history.push({
                      pathname: "/brand/apply",
                      state: { scrollToTop: true },
                    })
                  }
                  sx={{
                    color:
                      location.pathname === "/brand/apply"
                        ? (theme) => theme.palette.colors.primary
                        : "unset",
                    my: 2,
                    padding: "0px 20px",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    fontWeight: "700",
                    fontFamily: "Quantico, serif",
                    display: "block",
                  }}
                >
                  APPLY YOUR BRAND
                </Button>

                <Button
                  classes={{ root: classes.navButton }}
                  onClick={() =>
                    history.push({
                      pathname: "/qvrse",
                      state: { scrollToTop: true },
                    })
                  }
                  sx={{
                    color: location.pathname.startsWith("/qvrse")
                      ? (theme) => theme.palette.colors.primary
                      : "unset",
                    my: 2,
                    padding: "0px 20px",
                    fontSize: "16px",
                    textDecoration: "none",
                    textTransform: "uppercase",
                    fontWeight: "700",
                    fontFamily: "Quantico, serif",
                    display: "block",
                  }}
                >
                  QVRSE DAO
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                position: "absolute",
                right: { md: 0, mobile: "16px" },
                display: "flex",
                flexDirection: "row",
              }}
            >
              {!isMobile && !loggedInUser && (
                <Box sx={{ display: "flex" }}>
                  <UI.Button
                    sx={{ height: "42px", width: "120px", mr: "10px" }}
                    type={"terciary"}
                    onClick={() => setTriggerLoginDialog(Math.random())}
                    title={"Log In"}
                  />
                  <UI.Button
                    sx={{ height: "42px", width: "180px" }}
                    type={"primary"}
                    onClick={() => setTriggerRegisterDialog(Math.random())}
                    title={"Create Account"}
                  />
                </Box>
              )}
              {!isMobile && loggedInUser && (
                <>
                  <Box sx={{ display: "flex" }}>
                    <ConnectWallet
                      onProfileClick={() => setTriggerMenuOpen(Math.random())}
                    />
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <UserAvatar user={loggedInUser} />
                  </Box>
                </>
              )}

              {isMobile && (
                <>
                  {loggedInUser && (
                    <Box sx={{ display: "flex" }}>
                      <ConnectWallet
                        onProfileClick={() => setTriggerMenuOpen(Math.random())}
                      />
                    </Box>
                  )}
                  <IconButton
                    sx={{ display: { mobile: "flex", md: "none" } }}
                    onClick={() => setTriggerMenuOpen(Math.random())}
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-haspopup="true"
                  >
                    <MenuIcon sx={{ color: "white", fontSize: "2rem" }} />
                  </IconButton>
                </>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <MobileMenu
        triggerMenuOpen={triggerMenuOpen}
        onLogin={() => {
          setTriggerMenuOpen(Math.random());
          setTriggerLoginDialog(Math.random());
        }}
        onRegister={() => setTriggerRegisterDialog(Math.random())}
      />
      <RegisterDialog
        toggleOpen={triggerRegisterDialog}
        onRegister={() => setTriggerEmailConfirmation(Math.random())}
        showLogInDialog={() => {
          setTriggerRegisterDialog(Math.random());
          setTriggerLoginDialog(Math.random());
        }}
      />
      <EmailConfirmationDialog toggleOpen={triggerEmailConfirmationDialog} />
      <LogInDialog
        toggleOpen={triggerLoginDialog}
        showNoExistingAccountError={() => {
          setTriggerLoginDialog(Math.random());
          setNoExistingAccountDialog(Math.random());
        }}
        showCreateAccountDialog={() => {
          setTriggerLoginDialog(Math.random());
          setTriggerRegisterDialog(Math.random());
        }}
        showPasswordResetDialog={() => {
          setTriggerLoginDialog(Math.random());
          setTriggerPasswordResetDialog(Math.random());
        }}
      />
      <NoExistingAccountDialog toggleOpen={noExistingAccountDialog} />
      <PasswordResetDialog toggleOpen={triggerPasswordResetDialog} />
      <Toolbar sx={{ height: "117px" }} variant="dense" />
    </>
  );
};

export default MaterialAppBar;
