import React from "react";
import UI from "../../../../@components/UI";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import Collection from "../../collection";
import { useAccount } from "wagmi";
import { useUserContext } from "../../../../@components/userContext";
import publicIcon from "../../../../static/public_icon.png";
import collectionIcon from "../../../../static/collection_icon.png";
import photoIcon from "../../../../static/photo_icon.png";
import videoIcon from "../../../../static/video_icon.png";
import pollIcon from "../../../../static/poll_icon.png";

import { useSnackbar } from "notistack";
import useService from "../useService";
import CustomHooks from "../../../../@components/hooks";
import { useUserRole } from "../../context";
import Poll from "./Poll";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

const useStyles = makeStyles(() => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "0px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Create = ({ DAO }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const service = useService(DAO?.id);
  const { isConnected } = useAccount();
  const { user: loggedInUser } = useUserContext();
  const classes = useStyles();
  const [visibility, setVisibility] = React.useState("public");
  const { isBrandMember } = useUserRole();
  CustomHooks.useProtectedRoute(DAO, isBrandMember);

  const [selectedMedia, setSelectedMedia] = React.useState(() => "photo");
  const [selectedPoll, setSelectedPoll] = React.useState(false);

  const handleMedia = (event, newSelectedMedia) => {
    setSelectedMedia(newSelectedMedia);
  };

  const { mutate: createPost, isLoading } = CustomHooks.usePost(service.save, {
    onSuccess: () => {
      enqueueSnackbar("Post was created successfully", {
        variant: "success",
      });
      history.push({
        pathname: `/brand/${DAO?.id}/feed`,
        state: { scrollToTop: true },
      });
    },
  });

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      description: "",
      files: [],
      content: {},
      is_public: true,
      collections: [],
    },
  });

  const onSubmit = async (form) => {
    let data = {};
    if (visibility === "public") {
      data.is_public = true;
    } else {
      data.is_public = false;
    }

    data.files = form.files;
    data.description = form.description;
    data.collections = form.collections.map((collection) => collection.id);
    data.content = form.content;
    createPost(data);
  };

  return (
    <Paper
      elevation={0}
      sx={{ width: "100%", backgroundColor: "rgba(27, 30, 47, 0.5)" }}
    >
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle2"}>Posting to the feed ...</Typography>
        <Typography variant={"subtitle2"}>
          Please do not close this window.
        </Typography>
      </UI.Busy.FullscreenIndicator>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { md: "70px", mobile: "0px" },
            pb: "20px",
            pt: { md: "55px", mobile: "30px" },
          }}
        >
          <UI.BackButton
            fontSize={"14px !important"}
            title={"BACK TO FEED"}
            onClick={() =>
              history.push({
                pathname: `/brand/${DAO?.id}/feed`,
                state: { scrollToTop: true },
              })
            }
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            px: { md: "80px", mobile: "10px" },
            pb: "75px",
          }}
        >
          <Typography
            variant={"h1"}
            sx={{
              fontSize: "30px !important",
              lineHeight: "20px !important",
              mb: "25px",
              mt: "10px",
            }}
          >
            CREATE POST
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px", pt: "0px" }}>
                  <Typography
                    variant={"h2"}
                    sx={{ fontSize: "18px !important" }}
                  >
                    Description
                  </Typography>
                </Box>

                <Controller
                  name={"description"}
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <UI.TextField multiline rows={5} {...field} />
                  )}
                />
                <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                  {errors.description?.type === "required" && (
                    <Alert
                      sx={{ color: (theme) => theme.palette.colors.darkRed }}
                      variant="outlined"
                      severity="error"
                    >
                      Description is required
                    </Alert>
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px", mt: "0" }}>
                  <Typography
                    variant={"h2"}
                    sx={{ fontSize: "18px !important" }}
                  >
                    Add to your post
                  </Typography>
                  <UI.Tooltip>
                    Add photos or videos to your post, and include polls within
                    them for added interactivity.
                  </UI.Tooltip>
                </Box>
                <Box
                  sx={{
                    mt: "10px",
                    mb: "0px",
                    display: "flex",
                    width: { mobile: "100%", md: "unset" },
                  }}
                >
                  <ToggleButtonGroup
                    sx={{ flexGrow: { md: "unset", mobile: 2 } }}
                    value={selectedMedia}
                    exclusive
                    onChange={handleMedia}
                    aria-label="select media"
                  >
                    <ToggleButton
                      sx={{
                        padding: "0px",
                        height: "72px",
                        border: "0px",
                        flexGrow: { mobile: 1, md: "unset" },
                        justifyContent: "center",
                      }}
                      value="photo"
                      aria-label="bold"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { md: "row", mobile: "column" },
                          alignItems: "center",
                          width: { md: "160px", mobile: "auto" },
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          component={"img"}
                          sx={{
                            width: "30px",
                            height: "25px",
                            mr: { md: "8px", mobile: "0px" },
                            lineHeight: 1.5,
                          }}
                          alt={"media upload"}
                          src={photoIcon}
                        />
                        <Typography
                          variant={"h1"}
                          sx={{
                            fontSize: {
                              md: "16px !important",
                              mobile: "12px !important",
                            },
                            lineHeight: {
                              md: "unset",
                              mobile: "24px !important",
                            },
                            display: "block",
                          }}
                        >
                          Photo
                        </Typography>
                      </Box>
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        padding: "0px",
                        height: "72px",
                        border: "0px",
                        flexGrow: { mobile: 1, md: "unset" },
                        justifyContent: "center",
                      }}
                      value="video"
                      aria-label="italic"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { md: "row", mobile: "column" },
                          alignItems: "center",
                          width: { md: "160px", mobile: "auto" },

                          justifyContent: "center",
                        }}
                      >
                        <Box
                          component={"img"}
                          sx={{
                            width: "30px",
                            height: "25px",
                            mr: { md: "8px", mobile: "0px" },
                          }}
                          alt={"media upload"}
                          src={videoIcon}
                        />
                        <Typography
                          variant={"h1"}
                          sx={{
                            fontSize: {
                              md: "16px !important",
                              mobile: "12px !important",
                            },
                            lineHeight: {
                              md: "unset",
                              mobile: "24px !important",
                            },
                            display: "block",
                          }}
                        >
                          Video
                        </Typography>
                      </Box>
                    </ToggleButton>
                  </ToggleButtonGroup>

                  <ToggleButton
                    sx={{
                      padding: "0px",
                      height: "72px",
                      border: "0px",
                      flexGrow: { mobile: 1, md: "unset" },
                      justifyContent: "center",
                    }}
                    selected={selectedPoll}
                    onChange={() => {
                      setSelectedPoll(!selectedPoll);
                      if (selectedPoll) {
                        setValue("content", {});
                      }
                    }}
                    aria-label="underlined"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { md: "row", mobile: "column" },
                        alignItems: "center",
                        width: { md: "160px", mobile: "auto" },
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        component={"img"}
                        sx={{
                          width: "30px",
                          height: "25px",
                          mr: { md: "8px", mobile: "0px" },
                        }}
                        alt={"poll"}
                        src={pollIcon}
                      />
                      <Typography
                        variant={"h1"}
                        sx={{
                          fontSize: {
                            md: "16px !important",
                            mobile: "12px !important",
                          },
                          lineHeight: {
                            md: "unset",
                            mobile: "24px !important",
                          },
                          display: "block",
                        }}
                      >
                        Poll
                      </Typography>
                    </Box>
                  </ToggleButton>
                </Box>
                {selectedMedia && (
                  <Box sx={{ mt: { mb: "40px", mobile: "30px" } }}>
                    {selectedMedia === "photo" && (
                      <>
                        <Controller
                          name={"files"}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <UI.Dropzone
                              {...field}
                              id={"files"}
                              title={"Upload Your Photos"}
                              acceptFiles={"image/*"}
                            />
                          )}
                        />
                      </>
                    )}
                    {selectedMedia === "video" && (
                      <>
                        <Controller
                          name={"files"}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <UI.DropzoneVideo
                              {...field}
                              id={"files"}
                              title={"Upload Your Videos"}
                              acceptFiles={"video/*"}
                            />
                          )}
                        />
                      </>
                    )}
                  </Box>
                )}
                {selectedPoll && (
                  <Box sx={{ mt: "0px" }}>
                    <Poll
                      control={control}
                      errors={errors}
                      setValue={setValue}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px", mt: "0" }}>
                  <Typography
                    variant={"h2"}
                    sx={{ fontSize: "18px !important" }}
                  >
                    Visibility
                  </Typography>
                  <UI.Tooltip>
                    <Typography
                      variant={"subtitle1"}
                      sx={{ fontSize: "14px !important" }}
                    >
                      Public - Everyone can see the post.
                    </Typography>
                    <Typography
                      variant={"subtitle1"}
                      sx={{ mt: "8px", fontSize: "14px !important" }}
                    >
                      Collection - Only holders of chosen NFT collections can
                      see the post.
                    </Typography>
                  </UI.Tooltip>
                </Box>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="visibility"
                    name="visibility"
                    value={visibility}
                    onChange={(event) => setVisibility(event.target.value)}
                  >
                    <FormControlLabel
                      value="public"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: (theme) => theme.palette.colors.primary,
                            },
                          }}
                        />
                      }
                      label={
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              component={"img"}
                              sx={{
                                width: "20px",
                                height: "20px",
                                marginRight: "7px",
                              }}
                              alt={"public visibility"}
                              src={publicIcon}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant={"subtitle1"}>
                              Public
                            </Typography>
                          </Box>
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value="collections"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: (theme) => theme.palette.colors.primary,
                            },
                          }}
                        />
                      }
                      label={
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              component={"img"}
                              alt={"collections only visibility"}
                              sx={{
                                width: "25px",
                                height: "18px",
                                marginRight: "7px",
                              }}
                              src={collectionIcon}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant={"subtitle1"}>
                              Collections
                            </Typography>
                          </Box>
                        </Box>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
            {visibility === "collections" && (
              <Grid item md={6} mobile={12}>
                <Box sx={{ mt: "0px" }} className={classes.inputOuter}>
                  <Controller
                    name={"collections"}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Collection.Select {...field} DAO={DAO} />
                    )}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
          <Box
            sx={{
              width: "100%",
              mt: { mb: "50px", mobile: "30px" },
              display: "flex",
              justifyContent: "center",
              pb: { md: "0px", mobile: "0px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: "210px" }}>
                <UI.Button
                  disabled={!isConnected || !loggedInUser?.email_verified}
                  sx={{
                    filter:
                      "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
                  }}
                  title={"Create Post"}
                  type={"primary"}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default Create;
