import React from "react";
import List from "./List";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const Index = ({ DAO }) => {
  return (
    <Paper
      elevation={0}
      sx={{ width: "100%", pb: "100px", background: "transparent" }}
    >
      <Box
        sx={{
          width: "100%",
          padding: { md: "40px 0px", mobile: "40px 0px" },
        }}
      >
        <List DAO={DAO} />
      </Box>
    </Paper>
  );
};

export default Index;
