import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useAccount } from "wagmi";
import UI from "../@components/UI";
import truncateEthAddress from "truncate-eth-address";
import SettingsButton from "./SettingsButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useHistory } from "react-router-dom";

const Header = ({ user }) => {
  const { address, isConnected } = useAccount();
  const history = useHistory();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        margin: "0 auto",
      }}
    >
      <Paper
        onClick={() => {
          history.push(`/profile`);
        }}
        sx={{
          borderRadius: "50%",
          width: "80px",
          height: "80px",
          display: { md: "flex", mobile: "none" },
          mt: "0px",
          overflow: "hidden",
          transition: "all 0.5s ease-in-out",
          border: "2px solid transparent",
          "&:hover": {
            boxShadow: "#944dff 0px 0px 10px 0px",
            border: "2px solid #944dff",
            transform: "scale(1.1)",
          },
        }}
      >
        {user?.avatar_url ? (
          <img
            style={{
              width: "100%",
              height: "100%",
              margin: "0 auto",
              textAlign: "center",
              objectFit: "cover",
            }}
            src={user?.avatar_url}
            alt={"dao-logo"}
          />
        ) : (
          <Skeleton variant={"circle"} width={80} height={80} />
        )}
      </Paper>
      {user ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mr: { md: "10px", mobile: "0px" },
            }}
          >
            <Typography
              sx={{
                maxWidth: "610px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                ml: { md: "32px", mobile: "0px" },
                textAlign: { md: "left", mobile: "center" },
                fontSize: "24px !important",
                display: { md: "block" },
                lineHeight: "20px !important",
              }}
              variant={"h2"}
            >
              {user?.username || "User"}
            </Typography>
            {isConnected && (
              <Typography
                variant={"h4"}
                sx={{
                  ml: "10px",
                  width: "170px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <UI.CopyToClipboard
                  label={truncateEthAddress(address || "")}
                  value={address || ""}
                />
              </Typography>
            )}
          </Box>
          {!isMobile && <SettingsButton />}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ml: { md: "15px", mobile: "0px" },
          }}
        >
          <Skeleton variant={"text"} width={200} height={50} />
        </Box>
      )}
    </Box>
  );
};

export default Header;
