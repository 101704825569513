import React from "react";
import Box from "@mui/material/Box";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import BigList from "./BigList";
import SmallList from "./SmallList";
import useMediaQuery from "@mui/material/useMediaQuery";

const Index = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [display, setDisplay] = React.useState("big");

  React.useEffect(() => {
    if (isMobile) {
      setDisplay("small");
    } else {
      setDisplay("big");
    }
  }, [isMobile]);

  const handleDisplay = (event, newDisplay) => {
    if (newDisplay !== null) {
      setDisplay(newDisplay);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "50px",
        }}
      >
        <ToggleButtonGroup
          value={display}
          exclusive
          onChange={handleDisplay}
          aria-label="item display"
        >
          <ToggleButton value="big" aria-label="big display list">
            <ViewModuleIcon />
          </ToggleButton>
          <ToggleButton value="small" aria-label="small display list">
            <ViewListIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {display === "big" && <BigList />}
      {display === "small" && <SmallList />}
    </>
  );
};
Index.List = BigList;
export default Index;
