import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useAccount } from "wagmi";
import HomeIcon from "@mui/icons-material/Home";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import LoyaltyIcon from "@mui/icons-material/Loyalty";

import UI from "../@components/UI";
import { useUserContext } from "../@components/userContext";
import Typography from "@mui/material/Typography";
import SettingsButton from "./SettingsButton";
import { useHistory, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  categoryItem: {
    width: "190px",
    height: "60px",
    display: "flex",
    alignItems: "center",
    background: "rgba(27, 30, 47, 0.7)",
    marginLeft: "2px",
    marginRight: "2px",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    borderBottom: ({ selected }) => {
      return selected ? "2px solid #944DFF" : "2px solid transparent";
    },
  },
}));

const MobileMenu = ({ DAO, currentUrl, isUserMaintainer }) => {
  const { user: loggedInUser } = useUserContext();
  const { isConnected, address } = useAccount();
  const history = useHistory();
  const [items, setItems] = React.useState([]);
  const location = useLocation();

  const swiperRef = React.useRef(null);
  const [showSpeedDial, setShowSpeedDial] = React.useState(false);

  React.useEffect(() => {
    const swiper = swiperRef.current.swiper;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowSpeedDial(!entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }
    );

    // Observe the Swiper element
    observer.observe(swiper.el);

    // Cleanup function
    return () => {
      if (swiper.el instanceof Element) {
        observer.unobserve(swiper.el);
      }
    };
  }, []);

  const initialSlideToMenuItem = () => {
    if (location.pathname === `${currentUrl}/tresuary`) {
      swiperRef.current.swiper.slideTo(1);
    } else if (location.pathname === `${currentUrl}/vote`) {
      swiperRef.current.swiper.slideTo(2);
    } else if (location.pathname === `${currentUrl}/membership`) {
      swiperRef.current.swiper.slideTo(3);
    } else {
      swiperRef.current.swiper.slideTo(0);
    }
  };

  React.useEffect(() => {
    let menuItems = [
      { icon: <HomeIcon />, title: "Home", url: `${currentUrl}` },

      {
        icon: <AccountBalanceWalletIcon />,
        title: "Tresuary",
        url: `${currentUrl}/tresuary`,
      },
      { icon: <HowToVoteIcon />, title: "Vote", url: `${currentUrl}/vote` },
      {
        icon: <LoyaltyIcon />,
        title: "Membership",
        url: `${currentUrl}/membership`,
      },
    ];

    if (
      DAO &&
      (!DAO?.isMember === true || !isConnected || !loggedInUser?.email_verified)
    ) {
      menuItems = menuItems.filter((item) => item.title !== "Tresuary");
    }
    if (
      DAO &&
      (!DAO?.isMember === true || !isConnected || !loggedInUser?.email_verified)
    ) {
      menuItems = menuItems.filter((item) => item.title !== "Vote");
    }

    setItems(menuItems);
    initialSlideToMenuItem();

    // eslint-disable-next-line
  }, [DAO]);

  return (
    <Box
      sx={{
        mb: "30px",
        display: {
          mobile: "block",
          md: "none",
        },
      }}
    >
      {showSpeedDial && <UI.SpeedDial items={items} />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Paper
          sx={{
            mb: "20px",
            borderRadius: "50%",
            width: { md: "175px", mobile: "120px" },
            height: { md: "175px", mobile: "120px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            overflow: "hidden",
          }}
        >
          <Box
            onClick={() => {
              history.push(`/qvrse`);
            }}
          >
            {DAO?.avatar_url && (
              <img
                style={{ width: "100%", height: "100%", margin: "0 auto" }}
                src={DAO?.avatar_url}
                alt={"dao-logo"}
              />
            )}
          </Box>
          {isConnected && isUserMaintainer && (
            <Box
              sx={{
                position: "absolute",
                bottom: "15px",
                right: "calc(50% + -60px)",
                width: "35px",
                height: "35px",
                zIndex: 2,
              }}
            >
              <SettingsButton DAO={DAO} />
            </Box>
          )}
        </Paper>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: "20px",
        }}
      >
        <Typography
          sx={{ textAlign: "center", fontSize: "24px !important" }}
          variant={"h1"}
        >
          {DAO?.name}{" "}
        </Typography>
      </Box>
      <Swiper
        ref={swiperRef}
        grabCursor={true}
        slidesPerView={3.5}
        className="collection-category-swiper"
      >
        <SwiperSlide>
          <Item
            selected={location.pathname === `/qvrse`}
            onSelect={() => {
              swiperRef.current.swiper.slideTo(0);
              history.push(`${currentUrl}`);
            }}
            index={0}
            title={"Home"}
          />
        </SwiperSlide>

        {(DAO?.maintainer_address?.toLowerCase() === address?.toLowerCase() ||
          DAO?.isMember === true) &&
          isConnected &&
          loggedInUser?.email_verified && (
            <SwiperSlide>
              <Item
                isAdminItem={true}
                selected={!!location.pathname.startsWith(`/qvrse/tresuary`)}
                onSelect={() => {
                  swiperRef.current.swiper.slideTo(1);
                  history.push(`${currentUrl}/tresuary`);
                }}
                index={3}
                title={"Treasury"}
              />
            </SwiperSlide>
          )}
        <SwiperSlide>
          <Item
            selected={!!location.pathname.startsWith(`/qvrse/vote`)}
            onSelect={() => {
              swiperRef.current.swiper.slideTo(2);
              history.push(`${currentUrl}/vote`);
            }}
            index={3}
            title={"Vote"}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Item
            selected={!!location.pathname.startsWith(`/qvrse/membership`)}
            onSelect={() => {
              swiperRef.current.swiper.slideTo(3);
              history.push(`${currentUrl}/membership`);
            }}
            index={3}
            title={"Membership"}
          />
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};

const Item = (props) => {
  const { selected, index, onSelect, title, isAdminItem } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const classes = useStyles({ selected, isMobile });
  return (
    <Box
      sx={{ position: "relative" }}
      className={classes.categoryItem}
      onClick={() => onSelect(index)}
      {...props}
    >
      {isAdminItem && (
        <Typography
          variant={"subtitle1"}
          sx={{
            position: "absolute",
            top: "7px",
            left: "50%",
            transform: "translateX(-50%)",
            fontSize: "10px !important",
            lineHeight: "14px",
            textTransform: "none",
            color: selected ? "#7A7E92" : "#474A5B",
          }}
        >
          admin
        </Typography>
      )}
      <Typography
        sx={{
          margin: "0 auto",
          fontWeight: "bold",
          fontSize: "14px !important",
        }}
      >
        {title.toUpperCase()}
      </Typography>
    </Box>
  );
};

export default MobileMenu;
