const ticket = {
  type: "ticket",
  displayName: "Event Ticket",
  name: "",
  price: null,
  description: "",
  is_main_utility: false,
  custom_info_fields: [
    {
      name: "Date",
      value: null,
      type: "date",
    },
    {
      name: "Time",
      value: null,
      type: "time",
    },
    {
      name: "Location",
      value: null,
      type: "text",
    },
  ],
  request_info_fields: [],
};

const service = {
  type: "service",
  displayName: "Service",
  name: "",
  price: null,
  description: "",
  is_main_utility: false,
  custom_info_fields: [
    {
      name: "Type of Service",
      value: null,
      type: "text",
    },
  ],
  request_info_fields: [],
};

const product = {
  type: "product",
  displayName: "Product",
  name: "",
  price: null,
  description: "",
  is_main_utility: false,
  custom_info_fields: [
    {
      name: "Type of Product",
      value: null,
      type: "text",
    },
  ],
  request_info_fields: [],
};

const merch = {
  type: "merch",
  displayName: "Merch",
  name: "",
  price: null,
  description: "",
  is_main_utility: false,
  custom_info_fields: [
    {
      name: "Type of Merch",
      value: null,
      type: "text",
    },
  ],
  request_info_fields: [],
};

const digitalContent = {
  type: "digital_content",
  displayName: "Digital Content",
  name: "",
  price: null,
  description: "",
  is_main_utility: false,
  custom_info_fields: [],
  request_info_fields: [],
};

const discord = {
  type: "discord_access",
  displayName: "Discord Access",
  name: "",
  price: null,
  description: "",
  is_main_utility: false,
  custom_info_fields: [
    {
      name: "Channel Name",
      value: null,
      type: "text",
      canRemove: false,
    },
    {
      name: "Invite Link",
      value: null,
      type: "text",
      canRemove: false,
    },
  ],
  request_info_fields: [],
};

const custom = {
  type: "custom",
  displayName: "Custom",
  name: "",
  price: null,
  description: "",
  is_main_utility: false,
  custom_info_fields: [],
  request_info_fields: [],
};

const formInputs = {
  ticket,
  service,
  product,
  merch,
  digitalContent,
  discord,
  custom,
};

export default formInputs;
