import React from "react";
import TextField from "./TextField";
import NumberField from "./NumberField";
import Date from "./Date";
import Time from "./Time";

const GetInputByType = React.forwardRef((props, ref) => {
  const { type } = props;
  if (type === "text") {
    return <TextField {...props} ref={ref} />;
  } else if (type === "number") {
    return <NumberField {...props} ref={ref} />;
  } else if (type === "date") {
    return <Date {...props} ref={ref} />;
  } else if (type === "time") {
    return <Time {...props} ref={ref} />;
  } else {
    return <TextField {...props} ref={ref} />;
  }
});

export default GetInputByType;
