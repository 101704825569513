import React from "react";
import Auth from "../../auth";
import Button from "./Button";
import { useAccount, useDisconnect } from "wagmi";
import useHandleErrors from "../useHandleErrors";

const Index = ({ onNoExistingAccountError, title }) => {
  const { disconnect } = useDisconnect();
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [signInBusy, setSignInBusy] = React.useState(true);
  const { loginWithWallet } = Auth.useAuth();
  const { handleApiError, handleMetamaskError } = useHandleErrors();

  useAccount({
    onConnect() {
      loginWithWallet()
        .then(() => {
          setIsAuthenticated(true);
          setSignInBusy(false);
          window.location.reload();
        })
        .catch((error) => {
          if (error?.type === "metamask") {
            handleMetamaskError(error?.error);
            disconnect();
          } else {
            handleApiError(error);
            onNoExistingAccountError();
          }

          setSignInBusy(false);
        });
    },
  });

  return (
    <Button
      title={title}
      isAuthenticated={isAuthenticated}
      signInBusy={signInBusy}
    />
  );
};

export default Index;
