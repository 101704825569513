import * as React from "react";
import MaterialAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Avatar from "@mui/material/Avatar";

const Accordion = (props) => {
  const { id, title, logo, children, onRemove, customStyles, readonly, ...other } = props;
  return (
    <MaterialAccordion
      TransitionProps={{ unmountOnExit: true }}
      sx={{
        backgroundColor: (theme) => theme.palette.colors.darkGray,
      }}
      {...other}
    >
      <AccordionSummary
        sx={{
          height: { md: "65px", mobile: "55px" },
          padding: { md: "25px", mobile: "15px 20px" },
        }}
        expandIcon={<ExpandMoreIcon 
          sx={{ 
            color: customStyles
              ? customStyles.card_text_color
              : "#fff", 
            }} 
          />}
        id={id}
      >
        {logo && (
          <Box sx={{ mr: "20px" }}>
            <Avatar src={logo} alt={"avatar"} />
          </Box>
        )}
        <Typography
          sx={{
            display: { mobile: "flex", md: "none" },
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
          variant={"h7"}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            display: { mobile: "none", md: "flex" },
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
          variant={"h4"}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: { md: "0px 30px 15px 18px", mobile: "0px 23px 15px 13px" },
        }}
      >
        {children}
        {!readonly && (
          <Box
            sx={{
              mt: "20px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "flex-end",
            }}
            onClick={onRemove}
          >
            <DeleteForeverIcon
              sx={{ color: (theme) => theme.palette.colors.primary }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant={"h5"}>Remove Utility</Typography>
            </Box>
          </Box>
        )}
      </AccordionDetails>
    </MaterialAccordion>
  );
};

export default Accordion;
