import React from "react";
import UI from "../UI";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import { useBlockContext } from "../contextAPI";
import governorArtifact from "../../abis/contracts/qtech/governor/Governor.sol/QtechGovernor.json";
import moment from "moment";
import Alert from "@mui/material/Alert";
import { useUserContext } from "../userContext";
import useBlockchainState from "../hooks/useBlockchainState";
import voteWalletIcon from "../../static/voteWallet.png";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import customHooks from "../hooks";

const Option = ({ disabled, title, onClick, votes, totalStakedAmount }) => {
  const percentage = (votes / ethers.formatEther(totalStakedAmount)) * 100;

  return (
    <Box
      onClick={() => !disabled && onClick()}
      sx={{
        position: "relative",
        cursor: disabled ? "normal" : "pointer",
        border: "1px solid transparent",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          transform: "scale(1)",

          border: disabled ? "1px solid transparent" : "1px solid #944dff",
        },
        display: "flex",
        height: "48px",
        mb: { md: "11px", mobile: "9px" },
        backgroundColor: "rgba(51, 54, 69, 1)",
      }}
    >
      <Box
        sx={{
          transition: "all 0.5s ease-in-out",
          backgroundColor: `rgba(148, 77, 255, 1)`,
          width: `${percentage || 0}%`,
        }}
      ></Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant={"subtitle1"}
          sx={{
            position: "absolute",
            left: "17px",
            top: "50%",
            transform: "translateY(-50%)",
            ml: "0px",
            fontSize: "14px !important",
            fontFamily: "Quantico",
            fontWeight: "700",
            color: "#fff",
            textTransform: "uppercase",
          }}
        >
          {title}
        </Typography>

        {votes && (
          <Typography
            variant={"subtitle1"}
            sx={{
              position: "absolute",
              right: "17px",
              top: "50%",
              transform: `translateY(-50%) translateX(0)`,
              ml: "17px",
              fontWeight: "normal",
              fontSize: "14px !important",
              transition: "transform 0.4s ease-in-out",
              visibility: "visible",
            }}
          >
            {`${votes} votes`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const VotingEstimates = ({
  proposalState,
  DAO,
  proposal,
  setExecutionDateTime,
}) => {
  const provider = customHooks.useEthersProvider();
  const [voteEnd, setVoteEnd] = React.useState("0");
  const [voteStart, setVoteStart] = React.useState(null);
  const [timelockEta, setTimelockEta] = React.useState(null);
  const { isCalculatingBlockTimes, getFutureBlockDateTime } = useBlockContext();

  React.useEffect(() => {
    const fetchData = async () => {
      if (!isCalculatingBlockTimes && proposal && DAO) {
        const governor = new ethers.Contract(
          DAO?.governor_contract?.address,
          governorArtifact.abi,
          provider
        );

        const proposalEtaTimestampData = await governor.proposalEta(
          proposal?.proposal_hash_id
        );
        const proposalEta = Number(proposalEtaTimestampData).toString();

        setTimelockEta(moment(proposalEta * 1000).format("DD MMM YYYY HH:mm"));
        setExecutionDateTime(
          moment(proposalEta * 1000)
            .add(1, "minutes")
            .format("DD MMM YYYY HH:mm")
        );
        const voteStartBlockData = await governor.proposalSnapshot(
          proposal?.proposal_hash_id
        );
        const voteStartBlock = Number(voteStartBlockData).toString();
        const voteEndBlockData = await governor.proposalDeadline(
          proposal?.proposal_hash_id
        );
        const voteEndBlock = Number(voteEndBlockData).toString();

        const voteStartDateTime = await getFutureBlockDateTime(
          parseInt(voteStartBlock)
        );

        const voteEndDateTime = await getFutureBlockDateTime(
          parseInt(voteEndBlock)
        );

        setVoteStart(moment(voteStartDateTime).format("DD MMM YYYY HH:mm"));
        setVoteEnd(moment(voteEndDateTime).format("DD MMM YYYY HH:mm"));
      }
    };
    DAO && proposal && fetchData();
    // eslint-disable-next-line
  }, [isCalculatingBlockTimes, proposal, DAO]);

  return (
    <Box>
      <Typography variant={"subtitle1"}>
        {proposalState && proposalState.toLowerCase() === "pending" && (
          <Alert variant="outlined" severity="info" sx={{ color: "#4DB8FF" }}>
            {voteStart ? `Voting will start on ${voteStart}` : `Estimating...`}
          </Alert>
        )}
        {proposalState && proposalState.toLowerCase() === "active" && (
          <Alert variant="outlined" severity="info" sx={{ color: "#4DB8FF" }}>
            {voteStart ? `Voting will end on ${voteEnd}` : `Estimating...`}
          </Alert>
        )}
        {proposalState && proposalState.toLowerCase() === "queued" && (
          <Alert variant="outlined" severity="info" sx={{ color: "#4DB8FF" }}>
            <Box sx={{ display: "flex" }}>
              {voteStart
                ? `Vote will be ready to execute after ${timelockEta}`
                : `Estimating...`}
            </Box>
          </Alert>
        )}
      </Typography>
    </Box>
  );
};

const VotingPoll = ({
  DAO,
  proposal,
  onVote,
  onQueue,
  onExecute,
  hideQueue,
  hideExecute,
}) => {
  const { user: loggedInUser } = useUserContext();
  const { isConnected } = useAccount();
  const [executionDateTime, setExecutionDateTime] = React.useState(null);
  const [canUserVote, setCanUserVote] = React.useState(false);
  const { openConnectModal } = useConnectModal();
  const {
    proposalState,
    currentVotes,
    accountHasVoted,
    votingPower,
    totalStakedAmount,
  } = useBlockchainState(DAO, proposal);

  React.useEffect(() => {
    if (
      proposalState === "Succeeded" ||
      proposalState === "Pending" ||
      proposalState === "Defeated" ||
      accountHasVoted ||
      !isConnected ||
      DAO?.memberRole !== "ADMIN" ||
      DAO?.isMember === false ||
      !loggedInUser?.email_verified
    ) {
      setCanUserVote(false);
    } else {
      setCanUserVote(true);
    }
  }, [proposalState, accountHasVoted, isConnected, DAO, loggedInUser]);

  return (
    <Box
      sx={{
        p: { md: "18px 30px 12px 30px", mobile: "15px 15px 0px 15px" },
        backgroundColor: "rgba(27, 30, 47, 0.5)",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant={"h2"}
          sx={{
            fontSize: { md: "18px !important", mobile: "16px !important" },
            mb: "16px",
            maxWidth: "420px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <UI.OverflowTip>VOTING RESULTS</UI.OverflowTip>
          </Box>
        </Typography>
        <Box
          sx={{
            mt: "4px",
            ml: "10px",
            borderRadius: "100px",
            backgroundColor:
            proposalState === "Executed"
              ? "#50AF95"
              : proposalState === "Defeated"
              ? "#F8275C"
              : "#FFC144",
            width: "80px",
            p: "0px 9px",
            height: "25px",
            textAlign: "center",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              fontSize: "10px !important",
              lineHeight: "25px",
            }}
          >
            {proposalState === "Queued" ? "Pending" : proposalState}
          </Typography>
        </Box>
      </Box>
      {currentVotes && (
        <Box>
          <Option
            disabled={!canUserVote}
            onClick={() => onVote("yes")}
            accountHasVoted={accountHasVoted}
            totalStakedAmount={totalStakedAmount}
            votingPower={votingPower}
            title={"Yes"}
            votes={ethers.formatEther(currentVotes?.forVotes).toString()}
          />
          <Option
            disabled={!canUserVote}
            onClick={() => onVote("no")}
            accountHasVoted={accountHasVoted}
            totalStakedAmount={totalStakedAmount}
            votingPower={votingPower}
            title={"No"}
            votes={ethers.formatEther(currentVotes?.againstVotes).toString()}
          />
          <Option
            disabled={!canUserVote}
            onClick={() => onVote("abstain")}
            accountHasVoted={accountHasVoted}
            totalStakedAmount={totalStakedAmount}
            votingPower={votingPower}
            title={"Abstain"}
            votes={ethers.formatEther(currentVotes?.abstainVotes).toString()}
          />
        </Box>
      )}
      <Box
        sx={{
          display: { md: "flex", mobile: "row" },
          justifyContent: "space-between",
        }}
      >
        <VotingEstimates
          setExecutionDateTime={setExecutionDateTime}
          proposalState={proposalState}
          DAO={DAO}
          proposal={proposal}
        />
        {!accountHasVoted &&
          proposalState !== "Executed" &&
          proposalState !== "Defeated" && (
            <Typography
              variant={"subtitle1"}
              sx={{ fontSize: "14px !important" }}
            >
              <Typography
                variant={"subtitle1"}
                sx={{
                  fontSize: "14px !important",
                  color: "#7a7e92",
                  mt: { md: "0px", mobile: "10px" },
                }}
              >
                You have {votingPower}{" "}
                {votingPower.toString() === "1.0" ? "vote" : "votes"}
              </Typography>
            </Typography>
          )}
        {accountHasVoted &&
          proposalState !== "Executed" &&
          proposalState !== "Defeated" && (
            <Typography
              variant={"subtitle1"}
              sx={{
                fontSize: "14px !important",
                color: "#7a7e92",
                mt: { md: "0px", mobile: "10px" },
              }}
            >
              You have already voted
            </Typography>
          )}
        {proposalState === "Executed" && (
          <Typography
            variant={"subtitle1"}
            sx={{ fontSize: "14px !important", color: "#7a7e92" }}
          >
            {`Voting executed on ${moment(proposal?.datetime_completion).format(
              "DD MMM YYYY HH:mm"
            )}`}
          </Typography>
        )}
        {proposalState === "Defeated" && (
          <Typography
            variant={"subtitle1"}
            sx={{ fontSize: "14px !important", color: "#7a7e92" }}
          >
            {`Voting expired on ${moment(proposal?.datetime_completion).format(
              "DD MMM YYYY HH:mm"
            )}`}
          </Typography>
        )}
      </Box>
      {proposalState === "Succeeded" && !hideQueue && (
        <Box
          sx={{
            mt: "0px",
            display: "flex",
            justifyContent: "center",
            mb: "28px",
          }}
        >
          <UI.Button
            sx={{ width: "250px", mt: { md: "10px", mobile: "24px" } }}
            onClick={async () => onQueue()}
            disabled={!isConnected || DAO?.isMember === false}
            title={"Add to Queue"}
            type={"primary"}
            startIcon={
              <Box
                component={"img"}
                src={voteWalletIcon}
                sx={{ width: "43px", height: "19px", mr: "8px" }}
              />
            }
          />
        </Box>
      )}
      {proposalState === "Queued" && !hideExecute && (
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <UI.Button
              sx={{ width: "250px", mt: "15px" }}
              onClick={async () => onExecute()}
              disabled={
                !moment().isAfter(executionDateTime) ||
                !isConnected ||
                DAO?.isMember === false
              }
              title={"Execute"}
              type={"primary"}
              startIcon={
                <Box
                  component={"img"}
                  src={voteWalletIcon}
                  sx={{ width: "43px", height: "19px", mr: "8px" }}
                />
              }
            />
          </Box>
        </Box>
      )}
      <Typography
        variant={"subtitle1"}
        sx={{
          mt: { md: "10px", mobile: "24px" },
          mb: { md: "13px", mobile: "22px" },
        }}
      >
        {!isConnected && loggedInUser && (
          <Alert variant="outlined" severity="info" sx={{ color: "#4DB8FF" }}>
            {`Connect your wallet to vote`}
            <Box
              sx={{
                ml: "5px",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
                color: "#944DFF",
                fontWeight: "bold",
              }}
              onClick={() => openConnectModal()}
              component={"span"}
            >
              Connect Wallet
            </Box>
          </Alert>
        )}
      </Typography>
    </Box>
  );
};

export default VotingPoll;
