import Gateway from "../../../@components/gateway";

const useService = (daoId) => {
  const gateway = Gateway.useGateway();

  const vote = async (id, data) => {
    return gateway.post(`dao/${daoId}/proposal/${id}/submit_vote/`, data);
  };

  const updateVote = async (proposalId, voteId) => {
    return gateway.post(
      `dao/${daoId}/proposal/${proposalId}/vote/${voteId}/update_vote/`
    );
  };

  return {
    vote,
    updateVote,
  };
};

export default useService;
