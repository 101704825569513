import React from "react";
import LogInDialog from "./LogInDialog";
import NoExistingAccountDialog from "./NoExistingAccountDialog";
import PasswordResetDialog from "./ResetPasswordDialog";
import RegisterDialog from "./RegisterDialog";

const LogInDialogFlow = ({ trigger }) => {
  const [triggerLoginDialog, setTriggerLoginDialog] = React.useState(null);
  const [triggerRegisterDialog, setTriggerRegisterDialog] =
    React.useState(null);
  const [triggerPasswordResetDialog, setTriggerPasswordResetDialog] =
    React.useState(null);
  const [noExistingAccountDialog, setNoExistingAccountDialog] =
    React.useState(null);

  React.useEffect(() => {
    trigger && setTriggerLoginDialog(Math.random());
  }, [trigger]);

  return (
    <>
      <LogInDialog
        toggleOpen={triggerLoginDialog}
        showNoExistingAccountError={() => {
          setTriggerLoginDialog(Math.random());
          setNoExistingAccountDialog(Math.random());
        }}
        showCreateAccountDialog={() => {
          setTriggerLoginDialog(Math.random());
          setTriggerRegisterDialog(Math.random());
        }}
        showPasswordResetDialog={() => {
          setTriggerLoginDialog(Math.random());
          setTriggerPasswordResetDialog(Math.random());
        }}
      />
      <NoExistingAccountDialog toggleOpen={noExistingAccountDialog} />
      <PasswordResetDialog toggleOpen={triggerPasswordResetDialog} />
      <RegisterDialog
        toggleOpen={triggerRegisterDialog}
        showLogInDialog={() => {
          setTriggerRegisterDialog(Math.random());
          setTriggerLoginDialog(Math.random());
        }}
      />
    </>
  );
};

export default LogInDialogFlow;
