import React from "react";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import GradingIcon from "@mui/icons-material/Grading";
import { makeStyles } from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";
import PaidIcon from "@mui/icons-material/Paid";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  stepLabel: {
    fontFamily: "Quantico !important",
  },
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "#944DFF",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "#944DFF",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf045",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#575760" : "#575760",
  zIndex: 1,
  color: "#fff",
  width: 45, 
  height: 45,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    background: "#944DFF",
    boxShadow: "0px 0px 30px rgb(138 38 255 / 50%)",
  }),
  ...(ownerState.completed && {
    background: "#944DFF",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <InfoIcon />,
    2: <PaidIcon />,
    3: <GradingIcon />,
    4: <CheckIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const FormStepper = ({ steps, activeStep }) => {
  const classes = useStyles();
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            classes={{ label: classes.stepLabel }}
            StepIconComponent={ColorlibStepIcon}
          >
            <Typography
              variant={"h2"}
              sx={{ 
                fontSize: { md: "16px !important", mobile: "13px !important" },
                lineHeight: { md: "22px !important", mobile: "18px !important" },
                fontWeight: "bold" }}
            >
              {label}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default FormStepper;
