import React from "react";
import { useFieldArray, useForm, FormProvider } from "react-hook-form";
import { useFormData } from "../context";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../../@components/UI";
import { useAccount } from "wagmi";
import { useUserContext } from "../../../../../@components/userContext";
import AccordionItem from "./AccordionItem";
import backArrow from "../../../../../static/back-arrow.png";
import { makeStyles } from "@mui/styles";
import Select from "./Select";
import Form from "./Form";

const useStyles = makeStyles((theme) => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  backButton: {
    textAlign: "left",
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },
}));

const Index = ({ draft, nextStep, previouseStep, onSave }) => {
  const classes = useStyles();
  const { user: loggedInUser } = useUserContext();
  const { isConnected } = useAccount();
  const { data, setFormValues } = useFormData();

  const methods = useForm({
    defaultValues: {
      extra_utilities: data?.extra_utilities || [],
    },
    mode: "all",
  });

  const {
    fields: extraUtilities,
    append: addExtraUtility,
    remove: removeExtraUtility,
  } = useFieldArray({
    control: methods.control,
    name: "extra_utilities",
  });

  // Reset form with draft data.
  React.useEffect(() => {
    if (draft) {
      setFormValues(draft.data);
      methods.reset({
        extra_utilities: draft.data.extra_utilities,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft]);

  React.useEffect(() => {
    setFormValues({
      ...data,
      extra_utilities: extraUtilities,
    });
    // eslint-disable-next-line
  }, [extraUtilities]);

  const onSubmit = async (values, action) => {
    switch (action) {
      case "save_and_continue": {
        await onSave(values, false);
        nextStep();
        break;
      }
      case "save": {
        await onSave(values, true);
        break;
      }
      default: {
        await onSave(values, true);
        break;
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit((values) =>
          onSubmit(values, "save_and_continue")
        )}
      >
        <Box
          sx={{
            mt: { md: "15px", mobile: "20px" },
            p: { md: "40px 65px", mobile: "20px 10px" },
          }}
        >
          <Box
            onClick={() => previouseStep()}
            className={classes.backButtonOuter}
            sx={{
              display: "flex",
              cursor: "pointer",
              mb: { md: "50px", mobile: "30px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mr: "8px",
              }}
            >
              <img
                className={"backArrow"}
                style={{ transition: "0.4s ease-in" }}
                src={backArrow}
                alt={"back-arrow"}
                width={"15px"}
                height={"15px"}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box className={classes.backButton}>back to main feature</Box>
            </Box>
          </Box>
          <Box
            sx={{
              mb: "50px",
              display: "flex",
              textAlign: "left",
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "30px !important", mobile: "24px !important" },
              }}
              variant={"h1"}
            >
              STEP 3: Add Extras
            </Typography>
          </Box>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              {extraUtilities.map((item, utilityIndex) => (
                <Box sx={{ mb: "50px" }} key={utilityIndex}>
                  <Form
                    data={item}
                    index={utilityIndex}
                    onRemove={(index) => removeExtraUtility(index)}
                  />
                </Box>
              ))}
              <Box sx={{ mb: "15px" }}>
                <Select onSelect={(utility) => addExtraUtility(utility)} />
              </Box>
            </Grid>

            <Box
              sx={{
                width: "100%",
                mt: "40px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "210px" }}>
                  <UI.Button
                    disabled={
                      !!Object.keys(methods?.formState?.errors).length ||
                      !isConnected ||
                      !loggedInUser?.email_verified
                    }
                    sx={{
                      filter:
                        "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
                    }}
                    title={`Save & Continue`}
                    type={"primary"}
                  />
                  <UI.Button
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const allValues = methods.getValues();
                      await onSubmit(allValues, "save");
                    }}
                    disabled={!isConnected || !loggedInUser?.email_verified}
                    sx={{
                      mt: "10px",
                      filter:
                        "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
                    }}
                    title={"Save for later"}
                    type={"terciary"}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>
      </form>
    </FormProvider>
  );
};

Index.AccordionItem = AccordionItem;

export default Index;
