import React from "react";
import UI from "../../../../../@components/UI";
import AddField from "../AddField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { useFormData } from "../context";
import formInputs from "../formInputs";

const Select = ({ onSelect }) => {
  const [showSelectInput, setShowSelectInput] = React.useState(false);
  const { data } = useFormData();
  return (
    <>
      {!showSelectInput && (
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <AddField
            title={`Add ${
              data?.extra_utilities.length > 0 ? "another" : ""
            } Extra`}
            onClick={() => setShowSelectInput(true)}
          />
        </Box>
      )}
      {showSelectInput && (
        <UI.Select
          defaultOpen
          defaultValue={""}
          onClose={() => setShowSelectInput(false)}
          onChange={(e) => {
            setShowSelectInput(false);
            onSelect(e.target.value);
          }}
        >
          <MenuItem value={formInputs.digitalContent}>Digital Content</MenuItem>
          <MenuItem value={formInputs.discord}>Discord Access</MenuItem>
          <MenuItem value={formInputs.ticket}>Ticket</MenuItem>
          <MenuItem value={formInputs.product}>Product</MenuItem>
          <MenuItem value={formInputs.merch}>Merch</MenuItem>
          <MenuItem value={formInputs.service}>Service</MenuItem>
          <MenuItem value={formInputs.custom}>Custom</MenuItem>
        </UI.Select>
      )}
    </>
  );
};

export default Select;
