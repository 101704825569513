import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import UI from "../../../@components/UI";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MaterialDialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";

const DeleteDialog = ({ open, onClose, onConfirm }) => {
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          padding: { md: "30px 10px 42px 10px", mobile: "0px 0px" },
          width: "550px",
          margin: { mobile: "15px", md: "0px" },
        },
      }}
      open={open}
      onClose={onClose}
    >
      <CloseIcon
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 30,
          top: 30,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <DialogTitle sx={{ p: {md: "8px 24px", mobile: "28px 24px 10px" } }}>Delete Post</DialogTitle>
      <DialogContent sx={{ p: "0px 24px 5px 24px" }}>
        <Box sx={{ mt: "10px" }}>
          <DialogContentText>
            <Typography variant={"subtitle1"}>
              Are You sure you want to delete this post?
            </Typography>
          </DialogContentText>
        </Box>
        <Box sx={{ mt: "10px" }}></Box>
      </DialogContent>
      <Box
        sx={{
          mt: { md: "20px", mobile: "10px"},
          px: "15px",
          display: "flex",
          justifyContent: "center",
          mb: { md: "0px", mobile: "24px"}
        }}
      >
        <DialogActions>
          <UI.Button sx={{ width: "150px" }} title={"Delete"} type={"primary"} onClick={onConfirm} />
        </DialogActions>
      </Box>
    </MaterialDialog>
  );
};

const Action = ({ element: Component, onConfirm }) => {
  const [open, setOpen] = React.useState(null);

  return (
    <>
      <Box component={"span"} onClick={() => setOpen(!open)}>
        {Component}
      </Box>

      <DeleteDialog
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={() => {
          onConfirm();
          setOpen(false);
        }}
      />
    </>
  );
};

DeleteDialog.Action = Action;

export default DeleteDialog;
