import React from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useHistory } from "react-router-dom";
import Auth from "../../auth";

const UserMenu = ({ user, openMenu, onClose }) => {
  const { logout } = Auth.useAuth();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    openMenu && setAnchorEl(openMenu);
  }, [openMenu]);

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 18,
            width: 10,
            height: 10,
            bgcolor: "#0f1224",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        onClick={() =>
          history.push({
            pathname: "/profile",
            state: { scrollToTop: true },
          })
        }
      >
        <Avatar
          sx={{
            transition: "box-shadow 0.3s",
            boxShadow: user?.email_verified ? "unset" : "0 0 0 2px #d32f2f",
            "&:hover": {
              boxShadow: user?.email_verified
                ? "0 0 0 2px #944DFF"
                : "0 0 0 2px #d32f2f",
            },
          }}
          src={user?.avatar_url}
        />{" "}
        <Typography variant={"subtitle1"}>Profile</Typography>
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() =>
          history.push({
            pathname: "/profile/settings",
            state: { scrollToTop: true },
          })
        }
      >
        <ListItemIcon sx={{ mr: "10px" }}>
          <Settings sx={{ fontSize: "25px !important" }} fontSize="small" />
        </ListItemIcon>
        <Typography variant={"subtitle1"}>Settings</Typography>
      </MenuItem>
      <MenuItem onClick={() => logout()}>
        <ListItemIcon sx={{ mr: "10px" }}>
          <Logout sx={{ fontSize: "25px !important" }} fontSize="small" />
        </ListItemIcon>
        <Typography variant={"subtitle1"}>Logout</Typography>
      </MenuItem>
    </Menu>
  );
};

export default UserMenu;
