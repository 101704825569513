import React from "react";
import Box from "@mui/material/Box";
import UI from "../../../../../../@components/UI";
import RequestInfoDialog from "./RequestInfoDialog";
import PaymentDialog from "./PaymentDialog";

import paypalLogo from "../../../../../../static/paypal.png";
import amexLogo from "../../../../../../static/amex-logo.png";
import mastercardLogo from "../../../../../../static/mastercard-logo.png";
import maestroLogo from "../../../../../../static/maestro-logo.png";

import Tooltip from "@mui/material/Tooltip";
import { useUserContext } from "../../../../../../@components/userContext";
import visaLogo from "../../../../../../static/visa-logo.png";
import dinersLogo from "../../../../../../static/diners-logo.png";
import CustomHooks from "../../../../../../@components/hooks";
import { useUserRole } from "../../../../context";

const Button = (props) => {
  let query = CustomHooks.useQuery();
  const {
    title,
    collection,
    onSuccess,
    onRequestPassword,
    onError,
    onToggleEnablePurchase,
    isFreeMintNoWallet,
    ...other
  } = props;
  const { user: loggedInUser } = useUserContext();
  const { isBrandMember } = useUserRole();
  const [orderId, setOrderId] = React.useState(null);
  const [showRequestInfoDialog, setShowRequestInfoDialog] =
    React.useState(null);

  React.useEffect(() => {
    const success = query.get("success");
    const request_password = query.get("call_password");
    const orderId = query.get("order_id");
    //const canceled = query.get("canceled");

    if (success && request_password?.toLowerCase() === "false") {
      onSuccess();
    }

    if (request_password?.toLowerCase() === "true" && orderId) {
      onRequestPassword(orderId);
    }

    // if (canceled) {
    //   onCanceled();
    // }
    // eslint-disable-next-line
  }, [query]);

  return (
    <>
      {showRequestInfoDialog && (
        <RequestInfoDialog
          isFreeMintNoWallet={isFreeMintNoWallet}
          collection={collection}
          onClose={() => setShowRequestInfoDialog(false)}
          onConfirm={(orderId) => {
            setOrderId(orderId);
            setShowRequestInfoDialog(false);
          }}
        />
      )}
      {orderId && (
        <PaymentDialog
          isFreeMintNoWallet={isFreeMintNoWallet}
          collection={collection}
          onSuccess={() => {
            setOrderId(null);
            onSuccess();
          }}
          onRequestPassword={onRequestPassword}
          onError={() => {
            setOrderId(null);
            onError();
          }}
          orderId={orderId}
          onClose={() => setOrderId(null)}
        />
      )}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <UI.SplitButton
          onMenuItemClick={onToggleEnablePurchase}
          isPurchaseEnabled={collection.purchase_enabled}
          hideDropDownButton={!isBrandMember}
          disabled={
            !collection.purchase_enabled ||
            !loggedInUser?.email_verified ||
            collection?.schedule_status.toLowerCase() !== "active" ||
            collection?.nft_amount_minted >= collection?.nft_amount_limit
          }
          sx={{ height: "42px", width: "150px", mr: "15px" }}
          type={"primary"}
          title={title}
          onClick={() => {
            setShowRequestInfoDialog(true);
          }}
          {...other}
        />
        {collection?.paypal && (
          <Tooltip title={"Paypal"} arrow>
            <Box
              component={"img"}
              src={paypalLogo}
              sx={{
                width: "35px",
                height: "24px",

                mr: "5px",
                ml: "5px",
                "&:hover": {
                  transform: "scale(1.1)",
                },

                transition: "all 0.5s ease-in-out",
              }}
            />
          </Tooltip>
        )}
        {collection?.stripe && (
          <Box sx={{ display: { md: "flex", mobile: "none" } }}>
            <Tooltip title={"Visa"} arrow>
              <Box
                component={"img"}
                src={visaLogo}
                sx={{
                  width: "35px",
                  height: "24px",
                  mx: "5px",
                  transition: "all 0.5s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              />
            </Tooltip>
            <Tooltip title={"Diners Club"} arrow>
              <Box
                component={"img"}
                src={dinersLogo}
                sx={{
                  width: "35px",
                  height: "24px",
                  mx: "5px",
                  transition: "all 0.5s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              />
            </Tooltip>
            <Tooltip title={"American Express"} arrow>
              <Box
                component={"img"}
                src={amexLogo}
                sx={{
                  width: "35px",
                  height: "24px",
                  mx: "5px",
                  transition: "all 0.5s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              />
            </Tooltip>
            <Tooltip title={"Mastercard"} arrow>
              <Box
                component={"img"}
                src={mastercardLogo}
                sx={{
                  width: "35px",
                  height: "24px",
                  mx: "5px",
                  transition: "all 0.5s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              />
            </Tooltip>
            <Tooltip title={"Maestro"} arrow>
              <Box
                component={"img"}
                src={maestroLogo}
                sx={{
                  width: "35px",
                  height: "24px",
                  mx: "5px",
                  transition: "all 0.5s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              />
            </Tooltip>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Button;
