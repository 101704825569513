import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import linkedInLogo from "../../../../static/linkedin.png";
import discordLogo from "../../../../static/discord.png";
import twitterLogo from "../../../../static/twitter.png";
import telegramLogo from "../../../../static/telegram.png";
import instagramLogo from "../../../../static/instagram.png";
import facebookLogo from "../../../../static/facebook.png";
import useExternalURL from "../../../../@components/hooks/useExternalURL";

const Item = ({ logo, url }) => {
  const { getURL } = useExternalURL();
  return (
    <Box sx={{ display: "flex", justifyContent: "center", mx: "8px" }}>
      <Link target="_blank" href={getURL(url)} underline="hover">
        <Box
          component={"img"}
          src={logo}
          sx={{
            "&:hover": {
              transform: "scale(1.1)",
            },

            transition: "all 0.5s ease-in-out",
            cursor: "pointer",
            width: "35px",
            height: "35px",
          }}
        />
      </Link>
    </Box>
  );
};

const Index = ({ DAO }) => {
  return (
    <Container maxWidth={"md"} sx={{ textAlign: "center" }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {DAO?.linkedin && <Item logo={linkedInLogo} url={DAO?.linkedin} />}
        {DAO?.discord && <Item logo={discordLogo} url={DAO?.discord} />}
        {DAO?.twitter && <Item logo={twitterLogo} url={DAO?.twitter} />}
        {DAO?.telegram && <Item logo={telegramLogo} url={DAO?.telegram} />}
        {DAO?.instagram && <Item logo={instagramLogo} url={DAO?.instagram} />}
        {DAO?.facebook && <Item logo={facebookLogo} url={DAO?.facebook} />}
      </Box>
    </Container>
  );
};

export default Index;
