import Actions from "./Actions";
import Stripe from "./fiat/stripe";
import Paypal from "./fiat/paypal";
import Success from "./success";
import Failed from "./failed";

const Payment = {
  Stripe,
  Paypal,
  Actions,
  Success,
  Failed,
};

export default Payment;
