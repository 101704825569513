import React from "react";
import List from "./List";
import CustomHooks from "../../../@components/hooks";
import useService from "../../useService";
import { ethers } from "ethers";
import erc721Artifact from "../../../abis/contracts/modules/collection/Collection.sol/Collection.json";
import { useAccount } from "wagmi";
import useHandleErrors from "../../../@components/UI/useHandleErrors";
import UI from "../../../@components/UI";
import { useSnackbar } from "notistack";
import customHooks from "../../../@components/hooks";

const Index = ({ ownedNFTs }) => {
  const service = useService();
  const signer = customHooks.useEthersSigner();
  const { address: ownerAddress } = useAccount();
  const { handleMetamaskError } = useHandleErrors();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const transferTicket = async (ticket, recipientAddress) => {
    setLoading(true);

    try {
      const nft_contract = new ethers.Contract(
        ticket?.nft_address,
        erc721Artifact.abi,
        signer
      );

      await nft_contract.transferFrom(
        ownerAddress,
        recipientAddress,
        ticket.nft_id
      );
      setLoading(false);
    } catch (e) {
      handleMetamaskError(e);
      setLoading(false);
    }
  };

  const { mutate: generateQR } = CustomHooks.usePost(service.generateQR, {
    onSuccess: () => {
      enqueueSnackbar("QR Ticket was succesfully sent to your email", {
        variant: "success",
      });
    },
  });

  return (
    <>
      <UI.Busy.FullscreenIndicator show={loading} />
      <List
        items={ownedNFTs}
        busy={false}
        onGenerateQrTicket={(ticket) => generateQR(ticket)}
        onTransferTicket={(ticket, address) => transferTicket(ticket, address)}
      />
    </>
  );
};
Index.List = List;
export default Index;
