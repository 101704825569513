import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const Item = () => {
  return (
    <Grid item mobile={12} tablet={12} sm={12} md={6} lg={6}>
      <Paper
        sx={{
          backgroundColor: (theme) => theme.palette.colors.darkGray,
          mb: "10px",
        }}
      >
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            border: "1px solid transparent",
            padding: { md: "20px 35px", mobile: "20px 10px" },
            "&:hover": {
              border: `1px solid #944dff`,
              boxShadow: "#944dff 0px 0px 10px 0px",
            },
          }}
        >
          <Box sx={{ flex: 1, paddingRight: { md: "80px", mobile: "0px" } }}>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ mr: "20px" }}>
                <Skeleton variant={"circular"} width={"40px"} height={"40px"} />
              </Box>
              <Typography
                variant={"h4"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  variant={"rectangular"}
                  width={"180px"}
                  height={"30px"}
                />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

const SmallSkeletonList = () => {
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  return (
    <>
      {items.map((index) => (
        <Item key={index} />
      ))}
    </>
  );
};

export default SmallSkeletonList;
