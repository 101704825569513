import Gateway from "../@components/gateway";

const useService = (daoId) => {
  const gateway = Gateway.useGateway();

  const save = (data) => {
    return gateway.post(`dao/new/`, data);
  };

  const list = async () => {
    const response = await gateway.get("/daos/?page_size=100");
    return response.data.results;
  };

  const featured = async () => {
    const response = await gateway.get("/daos/featured/");
    return response.data;
  };

  const dao = async () => {
    const response = await gateway.get(`/dao/${daoId}/`);
    return response.data;
  };

  return {
    save,
    list,
    dao,
    featured,
  };
};

export default useService;
