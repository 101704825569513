import Gateway from "../../../../@components/gateway";

const useService = (daoId) => {
  const gateway = Gateway.useGateway();

  const save = (data) => {
    return gateway.post(`dao/${daoId}/info/edit_billing/`, data);
  };

  const dao = async () => {
    const response = await gateway.get(`/dao/${daoId}/`);
    return response.data;
  };

  return {
    save,
    dao,
  };
};

export default useService;
