import React from "react";
import Box from "@mui/material/Box";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// Styles must use direct files imports
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module

import { Navigation, Autoplay } from "swiper";

import "./styles.css";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import UI from "../../@components/UI";
import CustomHooks from "../../@components/hooks";
import useCollectionService from "../../collections/useService";
import { useHistory } from "react-router-dom";

const ImageItem = ({ item, onClick }) => {
  return (
    <Box
      onClick={() => onClick(item)}
      sx={{
        position: "relative",
        height: { md: "360px", xl: "400px", mobile: "360px" },
        width: "100%",
        maxHeight: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: "0px",
        background:
          "linear-gradient(180deg, rgb(8 10 25 / 0%) 54.17%, rgb(8 10 25 / 68%) 100%)",
      }}
    >
      <UI.ImageWithSkeleton
        sx={{
          inset: "0px",
          boxSizing: "border-box",
          padding: "0px",
          border: "none",
          margin: "auto",
          display: "block",
          width: "0px",
          height: "0px",
          minWidth: "100%",
          maxWidth: "100%",
          minHeight: "100%",
          maxHeight: "100%",
          objectFit: "cover",
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            transform: "scale(1.1)",
          },
        }}
        src={item.nft_image_url}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "absolute",
          bottom: "0px",
          height: "50%",
          width: "100%",
          background:
            "linear-gradient(180deg, rgb(8 10 25 / 0%) 54.17%, rgb(8 10 25 / 68%) 100%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            ml: "20px",
            position: "relative",
            width: "100%",
          }}
        >
          <Box
            sx={{
              justifyContent: "space-between",
              display: "flex",
              width: "100%",
              position: "absolute",
              bottom: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  mr: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Avatar src={item.dao?.avatar_url} alt={"avatar"} />
              </Box>
              <Typography
                variant={"h4"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  fontSize: "16px !important",
                  maxWidth: { md: "160px", xl: "200px", mobile: "150px" },
                }}
              >
                <UI.OverflowTip>{item?.dao?.name}</UI.OverflowTip>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", mr: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant={"h4"}
                  sx={{ fontSize: "16px !important", maxWidth: "60px" }}
                >
                  <UI.OverflowTip>
                    {`${item?.nft_amount_minted}/${item?.nft_amount_limit}`}{" "}
                  </UI.OverflowTip>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const VideoItem = ({ item, onClick }) => {
  return (
    <Box
      onClick={() => onClick(item)}
      sx={{
        position: "relative",
        height: { md: "360px", xl: "400px", mobile: "360px" },
        width: "100%",
        maxHeight: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: "0px",
        background:
          "linear-gradient(180deg, rgb(8 10 25 / 0%) 54.17%, rgb(8 10 25 / 68%) 100%)",
      }}
    >
      <Box
        sx={{
          inset: "0px",
          boxSizing: "border-box",
          padding: "0px",
          border: "none",
          margin: "auto",
          display: "block",
          width: "0px",
          height: "0px",
          minWidth: "100%",
          maxWidth: "100%",
          minHeight: "100%",
          maxHeight: "100%",
          objectFit: "cover",
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            transform: "scale(1.1)",
          },
        }}
      >
        <video
          playsInline
          width={"100%"}
          height={"auto"}
          autoPlay="autoplay"
          loop
          muted
        >
          <source src={item.nft_image_url} />
        </video>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "absolute",
          bottom: "0px",
          height: "50%",
          width: "100%",
          background:
            "linear-gradient(180deg, rgb(8 10 25 / 0%) 54.17%, rgb(8 10 25 / 68%) 100%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            ml: "20px",
            position: "relative",
            width: "100%",
          }}
        >
          <Box
            sx={{
              justifyContent: "space-between",
              display: "flex",
              width: "100%",
              position: "absolute",
              bottom: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  mr: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Avatar src={item.dao.avatar_url} alt={"avatar"} />
              </Box>
              <Typography
                variant={"h4"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  fontSize: "16px !important",
                  maxWidth: "200px",
                }}
              >
                <UI.OverflowTip> {item.dao.name}</UI.OverflowTip>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", mr: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  mr: "20px",
                }}
              >
                <Typography variant={"h4"}>
                  {`${item?.nft_amount_minted}/${item?.nft_amount_limit}`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SkeletonItem = () => {
  return (
    <Box
      sx={{
        position: "relative",
        height: "400px",
        width: "100%",
        maxHeight: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: "0px",
        background:
          "linear-gradient(rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%)",
      }}
    >
      <Skeleton
        variant={"rectangular"}
        sx={{
          inset: "0px",
          boxSizing: "border-box",
          padding: "0px",
          border: "none",
          margin: "auto",
          display: "block",
          width: "0px",
          height: "0px",
          minWidth: "100%",
          maxWidth: "100%",
          minHeight: "100%",
          maxHeight: "100%",
          objectFit: "cover",
        }}
      />
    </Box>
  );
};
const NewCollections = ({ onClick }) => {
  const collectionService = useCollectionService();
  const history = useHistory();

  const { data: collections } = CustomHooks.useFetch(
    "collections_featured",
    collectionService.featured
  );

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: { mobile: "column", md: "row" } }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mr: { md: "50px", mobile: "0px" },
            mb: { md: "0px", mobile: "30px" },
          }}
        >
          <Box sx={{ width: "300px", flexDirection: "column" }}>
            <Typography
              variant={"h4"}
              sx={{ fontSize: { md: "30px !important", mobile: "27px !important" }, whiteSpace: "pre-line" }}
            >
              {"New \n Collections"}
            </Typography>
            <Typography
              variant={"subtitle1"}
              sx={{ fontSize: "16px !important", mt: "10px", display: "none" }}
            >
              {"Maecenas vitae leo interdum, \n commodo ex sed."}
            </Typography>
            <UI.Button
              type={"primary"}
              size={"small"}
              onClick={() => {
                history.push({
                  pathname: "/collection/explore",
                  state: { scrollToTop: true },
                });
              }}
              sx={{
                mt: "40px",
                width: "150px",
                height: "42px",
                display: { md: "flex", mobile: "none" },
              }}
              title={"See More"}
            />
          </Box>
        </Box>
        <Swiper
          grabCursor={true}
          slidesPerView={3}
          spaceBetween={100}
          slidesPerGroup={1}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
            pauseOnMouseEnter: false,
          }}
          loop={true}
          loopFillGroupWithBlank={true}
          breakpoints={{
            100: {
              slidesPerView: 1.2,
              spaceBetween: 20,
            },
            1000: {
              slidesPerView: 1.2,
              spaceBetween: 20,
            },
            1100: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1150: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1400: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="desktopExplorePreview"
        >
          {collections?.length > 0
            ? collections.map((item) => (
                <SwiperSlide key={item.id}>
                  {item.nft_file_type.toLowerCase().startsWith("image") && (
                    <ImageItem item={item} onClick={(item) => onClick(item)} />
                  )}
                  {item.nft_file_type.startsWith("video") && (
                    <VideoItem
                      playsinline
                      onClick={(item) => onClick(item)}
                      item={item}
                    />
                  )}
                </SwiperSlide>
              ))
            : [0, 1, 2, 3].map((index) => (
                <SwiperSlide key={index}>
                  <SkeletonItem />
                </SwiperSlide>
              ))}
        </Swiper>
        <Box
          sx={{
            display: { md: "none", mobile: "flex" },
            width: "100%",
            justifyContent: "center",
          }}
        >
          <UI.Button
            type={"primary"}
            size={"small"}
            onClick={() => {
              history.push({
                pathname: "/collection/explore",
                state: { scrollToTop: true },
              });
            }}
            sx={{
              mt: "30px",
              width: "150px",
              height: "42px",
              display: { md: "none", mobile: "flex" },
            }}
            title={"See More"}
          />
        </Box>
      </Box>
    </>
  );
};

export default NewCollections;
