import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Edit from "./Edit";
import Grid from "@mui/material/Grid";
import Vote from "./vote";
import UI from "../../@components/UI";
import { useHistory, useLocation } from "react-router-dom";
import Link from "@mui/material/Link";
import CustomHooks from "../../@components/hooks";
import EmailRedirectVote from "./EmailRedirectVote";

const Index = ({ DAO }) => {
  const location = useLocation();
  const history = useHistory();
  let query = CustomHooks.useQuery();
  const [showVoteRedirectDialog, setShowVoteRedirectDialog] =
    React.useState(null);
  const [voteRedirectUrl, setVoteRedirectUrl] = React.useState(null);

  React.useEffect(() => {
    const is_email_redirect_vote = query.get("email_redirect_vote");
    const redirect_url = query.get("redirect_url");
    if (is_email_redirect_vote && redirect_url) {
      setShowVoteRedirectDialog(true);
      setVoteRedirectUrl(redirect_url);
    }
  }, [query]);

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        pb: "40px",
        background: "transparent",
        px: { md: "0px", mobile: "15px" },
      }}
    >
      {showVoteRedirectDialog && (
        <EmailRedirectVote
          onClose={() => {
            setShowVoteRedirectDialog(false);
            history.push(`${location.pathname}`);
          }}
          onConfirm={() => {
            setShowVoteRedirectDialog(false);
            history.push(`${location.pathname}/${voteRedirectUrl}`);
          }}
        />
      )}
      <Grid container>
        <Grid
          item
          mobile={12}
          md={12}
          sx={{
            mt: { mobile: "33px", md: "33px" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: { md: "745px", mobile: "100%" } }}>
            <Typography
              sx={{
                fontSize: "30px !important",
                textAlign: "center",
                lineHeight: "43px !important",
              }}
              variant={"h6"}
            >
              CO-CREATE THE QVRSE ECOSYSTEM.
            </Typography>
            <Typography
              sx={{
                fontSize: "24px !important",
                textAlign: "center",
                lineHeight: "33px !important",
                mt: { md: "6px", mobile: "20px" },
              }}
              variant={"h6"}
            >
              VOTE ON THE NEW BRANDS ENTERING THE PLATFORM
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: { mobile: "column", md: "row" },
                mt: { mobile: "40px", md: "45px" },
              }}
            >
              <UI.Button
                sx={{
                  width: "260px",
                  margin: { mobile: "auto", md: "0 15px" },
                }}
                type={"primary"}
                title={"GO TO QVRSE DAO VOTING"}
                onClick={() => {
                  history.push(`/qvrse/vote`);
                }}
              />
              <UI.Button
                sx={{
                  width: "180px",
                  margin: { mobile: "auto", md: "0 15px" },
                  mt: { mobile: "22px", md: "0px" },
                }}
                type={"secondary"}
                title={"join qvrse dao"}
                onClick={() => {
                  history.push(`/qvrse/membership/new`);
                }}
              />
            </Box>
            <Typography
              sx={{
                mt: { mobile: "70px", md: "100px" },
                fontSize: "24px !important",
                textAlign: "center",
                lineHeight: "33px !important",
              }}
              variant={"h6"}
            >
              YOU DRIVE THE QVRSE MOVEMENT
            </Typography>
            <Typography
              sx={{
                mt: "12px",
                fontSize: "18px !important",
                textAlign: "center",
                lineHeight: "23px !important",
                color: "rgba(255, 255, 255, 0.8)",
                fontFamily: "Open Sans",
              }}
              variant={"subtitle2"}
            >
              Imagine the biggest social media platforms asking for your
              participation. That’s what we are doing. Qvrse's digital community
              members actively shape an online organization - DAO-  that
              democratically votes on new brands entering the Qvrse platform.
              We're pioneering an innovative approach for customer onboarding.
            </Typography>

            <Box
              sx={{
                width: "100%",
                mt: { mobile: "70px", md: "100px" },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px !important",
                  textAlign: "center",
                  lineHeight: "33px !important",
                }}
                variant={"h6"}
              >
                latest proposals
              </Typography>
              <Box sx={{ mt: "24px", width: { md: "600px", mobile: "100%" } }}>
                <Vote
                  DAO={DAO}
                  filter={["Active", "Succeeded", "Queued"]}
                  limit={3}
                  gridItem={12}
                />
              </Box>
              <UI.Button
                sx={{
                  width: "260px",
                  mt: { mobile: "35px", md: "45px" },
                }}
                type={"primary"}
                title={"GO TO QVRSE DAO VOTING"}
                onClick={() => {
                  history.push({
                    pathname: `/qvrse/vote`,
                    state: { scrollToTop: true },
                  });
                }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          mobile={12}
          md={12}
          sx={{
            mt: { mobile: "73px", md: "105px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { mobile: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                background: "rgba(27, 30, 47, 0.5);",
                alignItems: "flex-start",
                px: "40px",
                mx: "15px",
                width: "353px",
                height: { mobile: "230px", md: "270px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mb: { mobile: "16px", md: "0px" },
              }}
            >
              <Typography
                variant={"h2"}
                sx={{ fontSize: "24px !important", mb: "10px" }}
              >
                VOTE
              </Typography>
              <Typography
                variant={"subtitle3"}
                sx={{
                  fontSize: "15px !important",
                  color: "rgba(255, 255, 255, 0.8)",
                  lineHeight: "21px",
                }}
              >
                Qvrse DAO champions a democratic system, empowering members to
                have a direct impact on brand admission within our ecosystem.
              </Typography>
            </Box>
            <Box
              sx={{
                background: "rgba(27, 30, 47, 0.5);",
                alignItems: "flex-start",
                px: "40px",
                mx: "15px",
                width: "353px",
                height: { mobile: "230px", md: "270px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mb: { mobile: "16px", md: "0px" },
              }}
            >
              <Typography
                variant={"h2"}
                sx={{ fontSize: "24px !important", mb: "10px" }}
              >
                belong
              </Typography>
              <Typography
                variant={"subtitle3"}
                sx={{
                  fontSize: "15px !important",
                  color: "rgba(255, 255, 255, 0.8)",
                  lineHeight: "21px",
                }}
              >
                Qvrse DAO connects dedicated brand communities with the broader
                Qvrse network, cultivating a vibrant and immersive social
                experience.
              </Typography>
            </Box>
            <Box
              sx={{
                background: "rgba(27, 30, 47, 0.5)",
                alignItems: "flex-start",
                px: "40px",
                mx: "15px",
                width: "353px",
                height: { mobile: "230px", md: "270px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mb: "0px",
              }}
            >
              <Typography
                variant={"h2"}
                sx={{ fontSize: "24px !important", mb: "10px" }}
              >
                ENJOY PERKS
              </Typography>
              <Typography
                variant={"subtitle3"}
                sx={{
                  fontSize: "15px !important",
                  color: "rgba(255, 255, 255, 0.8)",
                  lineHeight: "21px",
                }}
              >
                Collect reward for voting. As the ecosystem grows so will the 
                perks.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          mobile={12}
          md={12}
          sx={{
            mt: { mobile: "70px", md: "100px" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: { md: "850px", mobile: "100%" } }}>
            <Typography
              sx={{
                fontSize: "24px !important",
                textAlign: "center",
                lineHeight: "33px !important",
              }}
              variant={"h6"}
            >
              THE QVRSE DAO TOKEN (QVRS) = VOTING TICKET
            </Typography>
            <Typography
              sx={{
                mt: "12px",
                fontSize: "18px !important",
                textAlign: "center",
                lineHeight: "23px !important",
                color: "rgba(255, 255, 255, 0.8)",
              }}
              variant={"subtitle1"}
            >
              An online global community requires a secure method for voting,
              verifying votes, and safely transferring them to the designated
              location. The Qvrse DAO community created a token to facilitate a
              secure voting process. The Qvrse token is your ticket to voting
              and the prizes that follow.
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Link
                href={"https://qvrse.io/participate_in_presale.php"}
                target={"_blank"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "none",
                }}
              >
                <UI.Button
                  sx={{
                    width: "250px",
                    mt: { mobile: "35px", md: "45px" },
                  }}
                  onClick={() => {}}
                  type={"primary"}
                  title={"get your qvrs tokens"}
                />
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

Index.Edit = Edit;

export default Index;
