import Gateway from "../../../@components/gateway";

const useService = (daoId) => {
  const gateway = Gateway.useGateway();

  const save = (data) => {
    return gateway.postForm(`/dao/${daoId}/gated_content/new/`, data);
  };

  const pinPost = (id) => {
    return gateway.postForm(`/dao/${daoId}/gated_content/${id}/pin_post/`);
  };

  const list = async () => {
    const response = await gateway.get(`/dao/${daoId}/gated_content/`);
    return response.data.results;
  };

  const remove = (id) => {
    return gateway.post(`/dao/${daoId}/gated_content/${id}/delete/`);
  };

  return {
    save,
    pinPost,
    list,
    remove,
  };
};

export default useService;
