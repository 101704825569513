import React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UI from "../../../../@components/UI";
import CloseIcon from "@mui/icons-material/Close";

const Confirmation = ({ toggleOpen, onConfirm }) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);

  return (
    <Dialog
      fullWidth
      PaperProps={{
        sx: {
          position: "relative",
          width: "800px",
          p: { md: "50px", mobile: "30px 20px" },
          margin: { md: "32px", mobile: "10px" },
          backgroundColor: (theme) => theme.palette.colors.darkGray,
        },
      }}
      onClose={() => setOpen(false)}
      open={open}
    >
      <CloseIcon
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          right: 30,
          top: 30,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <Typography variant={"h2"}>Application submitted</Typography>
      <Box sx={{ mt: "40px" }}>
        <Typography variant={"subtitle1"}>
          Your application is now submitted for voting in the Qvrse DAO.
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: "50px" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <UI.Button
            type={"primary"}
            title={"Go to QVRSE Vote"}
            onClick={() => {
              onConfirm("/qvrse/vote");
            }}
            sx={{
              width: "185px",
              lineHeight: "18px",
              height: "50px",
              filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default Confirmation;
