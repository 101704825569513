import Gateway from "../../../../../@components/gateway";

const useService = (daoId, collectionId) => {
  const gateway = Gateway.useGateway();

  const allBuyers = (id) => {
    return gateway.get(`dao/${daoId}/collection/${id}/buyers/`);
  };

  const recieptToggle = (ticketId) => {
    return gateway.post(
      `dao/${daoId}/collection/${collectionId}/ticket/${ticketId}/receipt/toggle/`
    );
  };

  const nftToggle = (ticketId) => {
    return gateway.post(
      `dao/${daoId}/collection/${collectionId}/ticket/${ticketId}/nft_used/toggle/`
    );
  };

  return {
    allBuyers,
    recieptToggle,
    nftToggle,
  };
};

export default useService;
