import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import useCollectionService from "../useService";
import UI from "../../../../@components/UI";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useAccount } from "wagmi";
import Ticket from "./Ticket";
import Skeleton from "@mui/material/Skeleton";
import Admin from "./admin";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "../../../../static/info-icon.png";
import Alert from "@mui/material/Alert";
import Payment from "./payment";
import Utilities from "./Utilities";
import CustomHooks from "../../../../@components/hooks";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useUserRole } from "../../context";
import { useUserContext } from "../../../../@components/userContext";
import MaterialAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";

const Index = ({ DAO }) => {
  const { pathname } = useLocation();
  const { isBrandMember } = useUserRole();
  const history = useHistory();
  const { id } = useParams();
  const { address } = useAccount();
  const { enqueueSnackbar } = useSnackbar();
  const collectionService = useCollectionService(DAO?.id);
  const [mainUtility, setMainUtility] = React.useState(null);
  const [isFreeNFTAlreadyClaimed, setFreeNftAlreadyClaimed] =
    React.useState(false);
  const [openPaymentFlow, setOpenPaymentFlow] = React.useState(false);
  const { user: loggedInUser } = useUserContext();
  const { isConnected } = useAccount();
  const { data: collection, refetch } = CustomHooks.useFetch(
    "collection",
    () => collectionService.collection(id),
    {
      enabled: false,
      onSuccess: async (collection) => {
        setMainUtility(
          collection?.utilities.find((utility) => utility.is_main_utility)
        );
      },
    }
  );

  React.useEffect(() => {
    if (collection?.total_price_with_tax === "0.00") {
      //Free NFT
      //Check if address is in collection.buyers.buyer_address
      if (
        collection?.buyers?.find(
          (buyer) =>
            buyer.buyer_address.toLowerCase() === address?.toLowerCase()
        )
      ) {
        setFreeNftAlreadyClaimed(true);
      }
    }
  }, [collection, address]);

  React.useEffect(() => {
    DAO && refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DAO]);

  const { mutate: toggleVisiblity, isLoading: isChangingVisibility } =
    CustomHooks.usePost(collectionService.toggleVisibility, {
      onSuccess: async () => {
        enqueueSnackbar("Visibility was changed successfully", {
          variant: "success",
        });
        refetch();
      },
    });

  const { mutate: toggleEnablePurchasing } = CustomHooks.usePost(
    collectionService.toggleEnablePurchase,
    {
      onSuccess: async () => {
        enqueueSnackbar("Enable Purchase was changed successfully", {
          variant: "success",
        });
        refetch();
      },
    }
  );

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <UI.Busy.FullscreenIndicator show={isChangingVisibility}>
        <Typography variant={"subtitle2"}>Changing Visibility ...</Typography>
        <Typography variant={"subtitle2"}>
          Please do not close this window.
        </Typography>
      </UI.Busy.FullscreenIndicator>
      <Paper elevation={0} sx={{ width: "100%", background: "transparent" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { md: "0px", mobile: "10px" },
            pb: "60px",
            pt: { md: "20px", mobile: "20px" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <UI.BackButton
              fontSize={"14px !important"}
              title={"BACK TO COLLECTIONS"}
              onClick={() =>
                history.push({
                  pathname: `/brand/${DAO?.id}/collection`,
                  state: { scrollToTop: true },
                })
              }
              address={collection?.nft_address}
            />

            {DAO?.maintainer_address?.toLowerCase() ===
              address?.toLowerCase() && (
              <Box
                sx={{
                  display: { mobile: "block", md: "none" },
                }}
              >
                <Tooltip title={"Admin"} arrow>
                  <AdminPanelSettingsIcon
                    sx={{
                      fontSize: "50px",
                      color: "#944dff",
                      cursor: "pointer",
                      transition: "all 0.2s ease",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                    onClick={() =>
                      history.push({
                        pathname: `${pathname}/admin`,
                        state: {
                          scrollToTop: false,
                          daoId: DAO?.id,
                          collectionId: id,
                          collection: collection,
                        },
                      })
                    }
                    aria-label="admin"
                    aria-haspopup="true"
                  />
                </Tooltip>
              </Box>
            )}
          </Box>
          <Box sx={{ display: "flex" }}>
            {isBrandMember && (
              <Box sx={{ display: "flex", mr: "55px" }}>
                <UI.SwitchButton
                  leftText={"Private"}
                  rightText={"Public"}
                  isChecked={collection?.visible}
                  onChange={() =>
                    toggleVisiblity({
                      collectionId: collection?.id,
                      isVisible: !collection?.visible,
                    })
                  }
                />
              </Box>
            )}
            <Box sx={{ display: { md: "flex", mobile: "none" } }}>
              <Tooltip title={"Go to QSCHOOL"} arrow>
                <Box sx={{ mr: "14px" }}>
                  <Link
                    target="_blank"
                    href={"https://qschool.qvrse.io/first_steps.php"}
                    underline="hover"
                  >
                    <IconButton
                      sx={{
                        p: "0px",
                        transition: "all 0.2s ease",
                        "&:hover": {
                          transform: "scale(1.1)",
                        },
                      }}
                      onClick={() => {}}
                      aria-label="qschool"
                      aria-haspopup="true"
                    >
                      <img
                        alt={"qschool"}
                        src={InfoIcon}
                        width={"36px"}
                        height={"100%"}
                      />
                    </IconButton>
                  </Link>
                </Box>
              </Tooltip>
            </Box>
            {isBrandMember && (
              <Box
                sx={{
                  display: { mobile: "none", md: "block" },
                }}
              >
                <Tooltip title={"Admin"} arrow>
                  <AdminPanelSettingsIcon
                    sx={{
                      fontSize: "50px",
                      mt: "-5px",
                      color: "#944dff",
                      cursor: "pointer",
                      transition: "all 0.2s ease",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                    onClick={() =>
                      history.push({
                        pathname: `${pathname}/admin`,
                        state: {
                          scrollToTop: false,
                          daoId: DAO?.id,
                          collectionId: id,
                          collection: collection,
                        },
                      })
                    }
                    aria-label="admin"
                    aria-haspopup="true"
                  />
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            paddingLeft: { mobile: "10px", md: "0px" },
            paddingRight: { mobile: "10px", md: "0px" },
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={4} mobile={12}>
              <Box
                sx={{
                  display: "flex",
                  mb: "60px",
                  justifyContent: { mobile: "center", md: "unset" },
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Ticket
                    brandName={DAO?.name}
                    brandAvatar={DAO?.avatar_url}
                    name={collection?.name}
                    fileType={collection?.nft_file_type}
                    data={collection?.nft_image_url}
                    animationUrl={collection?.nft_animation_url || null}
                    mainUtility={mainUtility}
                  />
                  {/*{!isConnected && (*/}
                  {/*  <Box sx={{ mt: "15px", width: "300px" }}>*/}
                  {/*    <Alert*/}
                  {/*      variant="outlined"*/}
                  {/*      severity="info"*/}
                  {/*      sx={{ color: "#4DB8FF", p: "5px" }}*/}
                  {/*    >*/}
                  {/*      <Typography>*/}
                  {/*        Please connect your wallet to buy. If you don't have a*/}
                  {/*        wallet we prepared tutorials{" "}*/}
                  {/*        <Link*/}
                  {/*          target="_blank"*/}
                  {/*          sx={{ textDecorationColor: "#944DFF;" }}*/}
                  {/*          href="https://qschool.qvrse.io/first_steps.php"*/}
                  {/*        >*/}
                  {/*          here*/}
                  {/*        </Link>*/}
                  {/*      </Typography>*/}
                  {/*    </Alert>*/}
                  {/*  </Box>*/}
                  {/*)}*/}
                </Box>
              </Box>
            </Grid>
            <Grid item md={8} mobile={12}>
              <Box sx={{ ml: { md: "60px", mobile: "0px" } }}>
                <Box sx={{ mb: "14px" }}>
                  {DAO?.name ? (
                    <Typography
                      variant={"subtitle1"}
                      sx={{
                        fontSize: "16px !important",
                        color: "#7A7E92",
                        lineHeight: "13px !important",
                      }}
                    >
                      {DAO?.name}
                    </Typography>
                  ) : (
                    <Skeleton
                      sx={{ mb: "10px" }}
                      height={"15px"}
                      width={"300px"}
                      variant={"rectangular"}
                    />
                  )}
                </Box>
                <Box sx={{ mb: "30px" }}>
                  {collection?.name ? (
                    <Typography
                      variant={"h2"}
                      sx={{
                        lineHeight: {
                          md: "18px !important",
                          mobile: "30px !important",
                        },
                      }}
                    >
                      {`${collection?.name}`}
                    </Typography>
                  ) : (
                    <Skeleton
                      height={"30px"}
                      width={"300px"}
                      variant={"rectangular"}
                    />
                  )}
                </Box>
                {collection ? (
                  <Box sx={{ mb: "30px", display: "flex" }}>
                    <UI.UtilityTag utilityType={mainUtility?.type} />
                    <Box
                      sx={{
                        ml: "10px",
                        borderRadius: "100px",
                        backgroundColor: "#50AF95",
                        width: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        p: "4px 14px",
                        height: "23px",
                      }}
                    >
                      <Typography
                        variant={"h2"}
                        sx={{
                          fontSize: "10px !important",
                          lineHeight: "unset",
                        }}
                      >
                        {collection?.is_imported ? "Imported" : "Limited"}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Skeleton
                    sx={{ mb: "30px", display: "flex" }}
                    height={"30px"}
                    width={"300px"}
                    variant={"rectangular"}
                  />
                )}
                <Box sx={{ mb: "30px", wordBreak: "break-word" }}>
                  {collection?.description ? (
                    <UI.ReadMore fontSize={"18px"} maxTextLength={800}>
                      {collection?.description}
                    </UI.ReadMore>
                  ) : (
                    <Skeleton
                      height={"150px"}
                      width={"100%"}
                      variant={"rectangular"}
                    />
                  )}
                </Box>
                {collection ? (
                  <>
                    {!collection.is_imported && (
                      <Box
                        sx={{
                          mb: "15px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            variant={"subtitle1"}
                            sx={{
                              fontSize: "16px !important",
                              color: "#7A7E92",
                            }}
                          >
                            Price
                          </Typography>
                          <Typography
                            variant={"subtitle1"}
                            sx={{
                              ml: "4.5px",
                              fontSize: "10px !important",
                              color: "#7A7E92",
                              mt: "2.7px",
                            }}
                          >
                            with tax
                          </Typography>
                        </Box>

                        {collection?.total_price_with_tax > 0 && (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box
                              sx={{ width: "20px", height: "20px", ml: "10px" }}
                            >
                              <UI.PaymentIcon currency={collection?.currency} />
                            </Box>

                            <Typography
                              variant={"h1"}
                              sx={{
                                fontSize: "16px !important",
                                ml: "10px",
                                lineHeight: "unset !important",
                              }}
                            >
                              {`${collection?.total_price_with_tax} ${collection?.currency}`}
                            </Typography>
                          </Box>
                        )}
                        {collection?.total_price_with_tax === "0.00" && (
                          <Typography
                            variant={"h1"}
                            sx={{
                              fontSize: "16px !important",
                              ml: "10px",
                              lineHeight: "unset !important",
                            }}
                          >
                            FREE
                          </Typography>
                        )}
                      </Box>
                    )}
                  </>
                ) : (
                  <Skeleton
                    sx={{ mb: "10px" }}
                    height={"15px"}
                    width={"300px"}
                    variant={"rectangular"}
                  />
                )}
                {collection ? (
                  <>
                    {!collection.is_imported && (
                      <Box
                        sx={{
                          mb: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant={"subtitle1"}
                          sx={{
                            fontSize: "16px !important",
                            color: "#7A7E92",
                          }}
                        >
                          Availability
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant={"h1"}
                            sx={{
                              fontSize: "16px !important",
                              ml: "10px",
                              lineHeight: "unset !important",
                            }}
                          >
                            {`${collection?.nft_amount_minted}/${collection?.nft_amount_limit}`}
                          </Typography>
                          {collection?.nft_amount_limit -
                            collection?.nft_amount_minted <=
                          0 ? (
                            <Box
                              sx={{
                                ml: "12px",
                                borderRadius: "100px",
                                backgroundColor: "#F8285C",
                                width: "fit-content",
                                p: "4px 14px",
                              }}
                            >
                              <Typography
                                variant={"h2"}
                                sx={{
                                  fontSize: "10px !important",
                                  lineHeight: "unset",
                                }}
                              >
                                {"Sold Out"}
                              </Typography>
                            </Box>
                          ) : collection?.nft_amount_limit -
                              collection?.nft_amount_minted <=
                            5 ? (
                            <Box
                              sx={{
                                ml: "20px",
                                borderRadius: "100px",
                                backgroundColor: "#50AF95",
                                width: "fit-content",
                                p: "4px 14px",
                              }}
                            >
                              <Typography
                                variant={"h2"}
                                sx={{
                                  fontSize: "10px !important",
                                  lineHeight: "unset",
                                }}
                              >
                                {`Only ${
                                  collection?.nft_amount_limit -
                                  collection?.nft_amount_minted
                                } left`}
                              </Typography>
                            </Box>
                          ) : null}
                        </Box>
                      </Box>
                    )}
                  </>
                ) : (
                  <Skeleton
                    sx={{ mb: "10px" }}
                    height={"15px"}
                    width={"300px"}
                    variant={"rectangular"}
                  />
                )}
                {collection && DAO && (
                  <UI.SplitButton
                    onMenuItemClick={() =>
                      toggleEnablePurchasing({
                        collectionId: collection?.id,
                        isEnabled: !collection?.purchase_enabled,
                      })
                    }
                    isPurchaseEnabled={collection.purchase_enabled}
                    hideDropDownButton={!isBrandMember}
                    disabled={
                      isFreeNFTAlreadyClaimed ||
                      !collection.purchase_enabled ||
                      !loggedInUser?.email_verified ||
                      collection?.schedule_status.toLowerCase() !== "active" ||
                      collection?.nft_amount_minted >=
                        collection?.nft_amount_limit
                    }
                    sx={{ height: "42px", width: "150px", mr: "15px" }}
                    type={"primary"}
                    title={"BUY"}
                    onClick={() => {
                      setOpenPaymentFlow(true);
                    }}
                  />
                )}
                {collection && collection?.schedule_status !== "active" && (
                  <Box sx={{ mt: "15px" }}>
                    <Alert
                      variant="outlined"
                      severity="info"
                      sx={{ color: "#4DB8FF" }}
                    >
                      <Typography>
                        {`Heads up! Mark your calendar for ${moment(
                          collection?.scheduled_date_time
                        ).format("DD MMM YYYY HH:mm")} to buy
                        this unique NFT. See you then!`}
                      </Typography>
                    </Alert>
                  </Box>
                )}
                {collection?.total_price_with_tax === "0.00" &&
                  isConnected &&
                  loggedInUser && (
                    <Typography
                      variant={"subtitle1"}
                      sx={{
                        mt: "15px",
                        display: "block",
                      }}
                    >
                      <Alert
                        variant="outlined"
                        severity="info"
                        sx={{ color: "#4DB8FF" }}
                      >
                        {
                          "Free NFT is available only for one account per collection."
                        }
                      </Alert>
                    </Typography>
                  )}
              </Box>
            </Grid>
            {collection?.has_multiple_images && (
              <Grid
                item
                md={12}
                mobile={12}
                sx={{ mt: { mobile: "60px", md: "50px" } }}
              >
                <MaterialAccordion
                  TransitionProps={{ unmountOnExit: true }}
                  sx={{
                    backgroundColor: "rgba(27, 30, 47, 0.5)",
                  }}
                  expanded={true}
                >
                  <AccordionSummary
                    sx={{
                      height: "80px",
                      padding: { md: "18px 30px", mobile: "15px" },
                    }}
                    expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                  >
                    {" "}
                    <Typography variant={"h2"}>
                      Sneak Peek: NFTs That Could Be Yours
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: { md: "15px  35px", mobile: "15px  15px" },
                    }}
                  >
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      {collection?.collection_images.map((item, index) => (
                        <CardItem item={item} />
                      ))}
                    </Box>
                  </AccordionDetails>
                </MaterialAccordion>
              </Grid>
            )}
            <Grid
              item
              md={12}
              mobile={12}
              sx={{ mt: { mobile: "60px", md: "50px" } }}
            >
              <Box sx={{ mb: "30px" }}>
                <Typography variant={"h2"}>Perks</Typography>
              </Box>
              <Utilities collection={collection} />
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <UI.QvrsePayment
        loggedInQvrseUser={loggedInUser}
        onClose={() => {
          setOpenPaymentFlow(false);
          enqueueSnackbar(
            "You cancelled the payment. Try again by clicking the Buy button",
            { variant: "error" }
          );
        }}
        onSuccess={() => {
          setOpenPaymentFlow(false);
          enqueueSnackbar("Payment successful", { variant: "success" });
          history.push({
            pathname: `/brand/${DAO?.id}/collection/${id}/payment/success`,
            state: { scrollToTop: true },
          });
        }}
        open={openPaymentFlow}
        daoId={DAO?.id}
        collectionId={collection?.id}
      />
    </Box>
  );
};

const CardItem = ({ item }) => {
  return (
    <Box
      sx={{
        width: "150px",
        mr: { md: "30px", mobile: "20px" },
        mt: { md: "12px", mobile: "10px" },
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        transition: "all  0.3s ease-in-out",
        "&:hover": {
          transform: "scale(1.1)",
        },
        "&:hover .removeIcon": {
          display: "block",
          transition: "all 0.1s ease 0s",
        },
      }}
    >
      <Box
        sx={{
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "140px",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            height: "140px",
            overflow: "hidden",
            border: "2px solid transparent",
            transition: "all 0.2s ease-in-out",
            zIndex: 1,
            "&:hover": {
              boxShadow: "#944dff 0px 0px 15px 0px",
              border: "2px solid #944dff",
              transform: "scale(1.1)",
              transition: "all 0.2s ease-in-out",
            },
          }}
        >
          {item ? (
            <UI.ImageWithSkeleton
              sx={{
                position: "absolute",
                inset: 0,
                boxSizing: "border-box",
                padding: 0,
                border: "none",
                margin: "auto",
                display: "block",
                width: 0,
                height: 0,
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: "100%",
                maxHeight: "100%",
                objectFit: "cover",
              }}
              src={item.image_url}
              width={"100%"}
              alt={"slider-item"}
            />
          ) : (
            <Skeleton
              height={"300px"}
              width={"300px"}
              variant={"rectangular"}
            />
          )}
        </Box>
        <Box
          sx={{
            mt: { md: "14px", mobile: "8px" },
            justifyContent: "center",
            textAlign: "center",
            height: "50px",
            overflow: "hidden",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              variant={"h3"}
              sx={{
                margin: "0 auto",
                maxWidth: "300px",
                fontSize: { md: "14px !important", mobile: "14px !important" },
                whiteSpace: "wrap",
                lineHeight: "26px !important",
              }}
            >
              {item?.name.length > 15
                ? item?.name.slice(0, 15) + "... "
                : item?.name + " "}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Index.Admin = Admin;
Index.Payment = Payment;

export default Index;
