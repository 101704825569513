import React from "react";
import Box from "@mui/material/Box";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from "react-router-dom";
import Crypto from "./crypto";
import Fiat from "./fiat";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useUserContext } from "../../../../../@components/userContext";
import { useAccount } from "wagmi";
import FreeNFT from "./FreeNFT";

const Mobile = ({
  DAO,
  collection,
  collectionId,
  onSuccess,
  onRequestPassword,
  onToggleEnablePurchase,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        mt: "60px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {collection?.currency !== "EUR" && collection?.currency !== "USD" && (
          <Box sx={{ margin: "0 auto" }}>
            {collection?.total_price_with_tax !== "0.00" && (
              <Crypto.Button
                onToggleEnablePurchase={onToggleEnablePurchase}
                title={"Pay with Crypto"}
                collection={collection}
                collectionId={collectionId}
                DAO={DAO}
                onSuccess={onSuccess}
                onRequestPassword={onRequestPassword}
                startIcon={<AccountBalanceWalletIcon />}
              />
            )}
            <Box sx={{ width: "100%" }}>
              {collection?.total_price_with_tax === "0.00" && (
                <FreeNFT
                  collectionId={collectionId}
                  collection={collection}
                  DAO={DAO}
                  onSuccess={onSuccess}
                  onToggleEnablePurchase={onToggleEnablePurchase}
                  onRequestPassword={onRequestPassword}
                />
              )}
            </Box>
          </Box>
        )}
        {(collection?.stripe || collection?.paypal) && (
          <Box
            sx={{ display: "flex", mt: "20px" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Fiat.Button
              onToggleEnablePurchase={onToggleEnablePurchase}
              title={"Buy"}
              onRequestPassword={onRequestPassword}
              collection={collection}
              onSuccess={onSuccess}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const Desktop = ({
  DAO,
  collection,
  collectionId,
  onSuccess,
  onRequestPassword,
  onToggleEnablePurchase,
}) => {
  return (
    <Box sx={{ display: "flex" }}>
      {collection?.currency !== "EUR" && collection?.currency !== "USD" && (
        <Box sx={{ mr: "16px" }}>
          <Box sx={{ width: "270px" }}>
            {collection?.total_price_with_tax !== "0.00" && (
              <Crypto.Button
                onToggleEnablePurchase={onToggleEnablePurchase}
                title={"Pay with Crypto"}
                collection={collection}
                collectionId={collectionId}
                DAO={DAO}
                onSuccess={onSuccess}
                startIcon={<AccountBalanceWalletIcon />}
              />
            )}
          </Box>
          <Box sx={{ width: "100%" }}>
            {collection?.total_price_with_tax === "0.00" && (
              <FreeNFT
                collectionId={collectionId}
                collection={collection}
                DAO={DAO}
                onSuccess={onSuccess}
                onToggleEnablePurchase={onToggleEnablePurchase}
                onRequestPassword={onRequestPassword}
              />
            )}
          </Box>
        </Box>
      )}
      {(collection?.stripe || collection?.paypal) && (
        <Box
          sx={{ display: "flex", mt: "20px" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Fiat.Button
            onToggleEnablePurchase={onToggleEnablePurchase}
            title={"Buy"}
            collection={collection}
            onRequestPassword={onRequestPassword}
            onSuccess={onSuccess}
          />
        </Box>
      )}
    </Box>
  );
};

const Actions = (props) => {
  const { id } = useParams();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { user: loggedInUser } = useUserContext();
  const { isConnected } = useAccount();
  const { collection } = props;
  return (
    <PayPalScriptProvider
      options={{
        currency: collection?.currency || "EUR",
        "client-id":
          collection?.paypal_client_id ||
          "AR5-T_x1yhaiW1MhxuBvaJ79ft6ICI68GpNeEVc-M-nI2tRgTrPPNAEoz6Xy410fdbmJHIL-GOdRnW8x",
      }}
    >
      {isMobile ? (
        <Mobile
          collectionId={id}
          {...props}
          user={loggedInUser}
          isConnected={isConnected}
        />
      ) : (
        <Desktop
          collectionId={id}
          {...props}
          user={loggedInUser}
          isConnected={isConnected}
        />
      )}
    </PayPalScriptProvider>
  );
};

export default Actions;
