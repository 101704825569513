import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import UI from "../../../../@components/UI";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import { Controller, useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import { useFormDataContext } from "../context";

const TokenDialog = ({ open, onClose, onSave }) => {
  const { formData } = useFormDataContext();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      token_name: formData?.token_name,
      token_symbol: formData?.token_symbol,
      token_supply: formData?.token_supply,
      token_icon_url: formData?.token_icon_url,
    },
    mode: "all",
  });

  const onSubmit = async (values) => {
    onSave(values, false);
    onClose();
  };

  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          backgroundColor: "#1B1E2F !important",
          padding: { md: "40px 30px", mobile: "40px 10px" },
          width: "700px",
          margin: { mobile: "0px" },
        },
      }}
      open={open}
      onClose={(e) => {
        onClose();
      }}
    >
      <DialogContent sx={{ p: { mobile: "0px" } }}>
        <CloseIcon
          onClick={() => {
            onClose();
            reset();
          }}
          sx={{
            position: "absolute",
            right: 25,
            top: 10,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />

        <Grid container>
          <Grid item mobile={12} md={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography
                variant={"h6"}
                sx={{ fontSize: "30px", textAlign: "center" }}
              >
                Governance Token
              </Typography>

              <Box sx={{ mt: "30px", px: "10px" }}>
                <Typography
                  variant={"subtitle1"}
                  sx={{ fontSize: "15px", mb: "30px" }}
                >
                  This governance token will enable the brand to govern its
                  treasury, which includes the brand's cryptocurrency funds.
                </Typography>
                <Box sx={{ mt: "26px" }}>
                  <Typography
                    variant={"h5"}
                    sx={{ fontSize: "16px !important", mb: "5px" }}
                  >
                    Token name
                  </Typography>
                  <Controller
                    name={"token_name"}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <UI.TextField
                        {...field}
                        backgroundcolor={"#303346"}
                        fullWidth
                      />
                    )}
                  />
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors.token_name && (
                      <Alert
                        sx={{
                          color: (theme) => theme.palette.colors.darkRed,
                        }}
                        variant="outlined"
                        severity="error"
                      >
                        {errors.token_name?.type === "required" &&
                          "Token name is required"}
                      </Alert>
                    )}
                  </Typography>
                </Box>
                <Box sx={{ mt: "26px" }}>
                  <Typography
                    variant={"h5"}
                    sx={{ fontSize: "16px !important", mb: "5px" }}
                  >
                    Token symbol
                  </Typography>
                  <Controller
                    name={"token_symbol"}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <UI.TextField
                        {...field}
                        backgroundcolor={"#303346"}
                        fullWidth
                      />
                    )}
                  />
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors.token_symbol && (
                      <Alert
                        sx={{
                          color: (theme) => theme.palette.colors.darkRed,
                        }}
                        variant="outlined"
                        severity="error"
                      >
                        {errors.token_name?.type === "required" &&
                          "Token symbol is required"}
                      </Alert>
                    )}
                  </Typography>
                </Box>
                <Box sx={{ mt: "26px" }}>
                  <Typography
                    variant={"h5"}
                    sx={{ fontSize: "16px !important", mb: "5px" }}
                  >
                    Token supply
                  </Typography>
                  <Controller
                    name={"token_supply"}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <UI.TextField
                        disabled={true}
                        {...field}
                        backgroundcolor={"#303346"}
                        fullWidth
                      />
                    )}
                  />
                  <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                    {errors.token_supply && (
                      <Alert
                        sx={{
                          color: (theme) => theme.palette.colors.darkRed,
                        }}
                        variant="outlined"
                        severity="error"
                      >
                        {errors.token_name?.type === "required" &&
                          "Token supply is required"}
                      </Alert>
                    )}
                  </Typography>
                </Box>
                {formData?.token_icon_url && (
                  <Box sx={{ mt: "26px" }}>
                    <Controller
                      name={"token_icon_url"}
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <UI.Upload
                          defaultValue={formData?.token_icon_url}
                          {...field}
                          id={"avatar"}
                          title={"Upload your token icon"}
                          acceptFiles={"image/jpeg,image/gif,image/png"}
                        />
                      )}
                    />
                    <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                      {errors.token_icon_url && (
                        <Alert
                          sx={{
                            color: (theme) => theme.palette.colors.darkRed,
                          }}
                          variant="outlined"
                          severity="error"
                        >
                          {errors.token_name?.type === "required" &&
                            "Token icon is required"}
                        </Alert>
                      )}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  mt: "30px",
                  px: "15px",
                  display: "flex",
                  justifyContent: "center",
                  mb:"20px"
                }}
              >
                <UI.Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSubmit((values) => onSubmit(values));
                  }}
                  disabled={!!Object.keys(errors).length}
                  sx={{ width: "210px" }}
                  title={"Save"}
                  type={"primary"}
                />
              </Box>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
    </MaterialDialog>
  );
};

const Action = ({ action: Component, onSave }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Box
        component={"span"}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {Component}
      </Box>
      <TokenDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSave={onSave}
      />
    </Box>
  );
};

TokenDialog.Action = Action;

export default TokenDialog;
