import React from "react";
import useService from "./useService";
import CustomHooks from "../../../@components/hooks";
import SkeletonList from "../../../qvrse/membership/SkeletonList";
import Box from "@mui/material/Box";
import UI from "../../../@components/UI";
import { useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import truncateEthAddress from "truncate-eth-address";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MaterialAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Skeleton from "@mui/material/Skeleton";

const CardItem = ({ item, onClick }) => {
  return (
    <Box
      sx={{
        width: "150px",
        mr: { md: "30px", mobile: "20px" },
        mt: { md: "12px", mobile: "10px" },
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        transition: "all  0.3s ease-in-out",
        "&:hover": {
          transform: "scale(1.1)",
        },
        "&:hover .removeIcon": {
          display: "block",
          transition: "all 0.1s ease 0s",
        },
      }}
    >
      <Box
        sx={{
          cursor: "pointer",
        }}
        onClick={() => {
          onClick(item.collection_model.id);
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "140px",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            height: "140px",
            overflow: "hidden",
            border: "2px solid transparent",
            transition: "all 0.2s ease-in-out",
            zIndex: 1,
            "&:hover": {
              boxShadow: "#944dff 0px 0px 15px 0px",
              border: "2px solid #944dff",
              transform: "scale(1.1)",
              transition: "all 0.2s ease-in-out",
            },
          }}
        >
          {item ? (
            item?.collection_model?.nft_file_type?.startsWith("video") ? (
              <video
                playsInline
                width="100%"
                height={"auto"}
                autoplay="autoplay"
                loop
                muted
              >
                <source src={item.collection_model.nft_image_url} />
              </video>
            ) : (
              <UI.ImageWithSkeleton
                sx={{
                  position: "absolute",
                  inset: 0,
                  boxSizing: "border-box",
                  padding: 0,
                  border: "none",
                  margin: "auto",
                  display: "block",
                  width: 0,
                  height: 0,
                  minWidth: "100%",
                  maxWidth: "100%",
                  minHeight: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
                src={item.collection_model.nft_image_url}
                width={"100%"}
                alt={"slider-item"}
              />
            )
          ) : (
            <Skeleton
              height={"300px"}
              width={"300px"}
              variant={"rectangular"}
            />
          )}
        </Box>
        <Box
          sx={{
            mt: { md: "14px", mobile: "8px" },
            justifyContent: "center",
            textAlign: "center",
            height: "50px",
            overflow: "hidden",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              variant={"h3"}
              sx={{
                margin: "0 auto",
                maxWidth: "300px",
                fontSize: { md: "14px !important", mobile: "14px !important" },
                whiteSpace: "wrap",
                lineHeight: "26px !important",
              }}
            >
              {item?.collection_model?.name.length > 15
                ? item?.collection_model?.name.slice(0, 15) + "... "
                : item?.collection_model?.name + " "}
            </Typography>
            <Typography
              variant={"h3"}
              sx={{
                fontSize: { md: "14px !important", mobile: "14px !important" },
                lineHeight: "26px !important",
              }}
            >{`#${item?.nft_id}`}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const CardList = ({ nfts, DAO }) => {
  const history = useHistory();
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {nfts.map((item, index) => (
        <CardItem
          item={item}
          index={index}
          onClick={(collectionId) => {
            history.push({
              pathname: `/brand/${DAO.id}/collection/${collectionId}`,
              state: { scrollToTop: true },
            });
          }}
        />
      ))}
    </Box>
  );
};

const Item = ({ item, DAO }) => {
  return (
    <Box
      sx={{
        mb: "15px",
      }}
    >
      <MaterialAccordion
        TransitionProps={{ unmountOnExit: true }}
        sx={{
          backgroundColor: "rgba(27, 30, 47, 0.5)",
        }}
      >
        <AccordionSummary
          sx={{
            height: "80px",
            padding: { md: "18px 30px", mobile: "15px" },
          }}
          expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
          id={item.id}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ paddingRight: { md: "80px", mobile: "0px" } }}>
              <Box
                sx={{ display: "flex" }}
                onClick={(e) => e.stopPropagation()}
              >
                <Box sx={{ mr: "20px" }}>
                  <Avatar src={item?.avatar_url} alt={"avatar"} />
                </Box>
                {item?.address && (
                  <Typography
                    variant={"h4"}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <UI.CopyToClipboard
                      sx={{ fontSize: "16px !important" }}
                      label={truncateEthAddress(item?.address || "")}
                      value={item?.address || ""}
                    />
                  </Typography>
                )}
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  mr: "20px",
                }}
              >
                <Typography
                  variant={"h4"}
                  sx={{
                    fontSize: {
                      mobile: "14px !important",
                      md: "16px !important",
                    },
                  }}
                >
                  {`${item?.owned_nfts.length} NFT ${
                    item?.owned_nfts.length === 1 ? "CARD" : "CARDS"
                  }`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: { md: "15px  35px", mobile: "15px  15px" },
          }}
        >
          <CardList nfts={item?.owned_nfts} DAO={DAO} />
        </AccordionDetails>
      </MaterialAccordion>
    </Box>
  );
};

const List = ({ DAO }) => {
  const service = useService(DAO?.id);

  const {
    data: items,
    isLoading,
    refetch,
  } = CustomHooks.useFetch("community_members", service.list, {
    enabled: false,
  });

  React.useEffect(() => {
    DAO && refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DAO]);

  return (
    <Box>
      {isLoading && <SkeletonList />}
      {!isLoading &&
        items &&
        items.length > 0 &&
        items.map((item) => <Item key={item.id} item={item} DAO={DAO} />)}
      {!isLoading && items && items.length === 0 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <UI.NoResults text={"No Community Members Found :("} />
        </Box>
      )}
    </Box>
  );
};

export default List;
