import { ethers } from "ethers";
import governorArtifact from "../../../../abis/contracts/modules/governor/Governor.sol/DaoGovernor.json";
import timelockArtifact from "../../../../abis/contracts/modules/timelock/Timelock.sol/Timelock.json";
import customHooks from "../../../../@components/hooks";

const updateQuorumFunctionEncoded = (governor, newQuorumNumerator) => {
  return governor.interface.encodeFunctionData(
    "updateQuorumNumerator(uint256 newQuorumNumerator)",
    [newQuorumNumerator]
  );
};

const setVotingDelayFunctionEncoded = (governor, newVotingDelay) => {
  return governor.interface.encodeFunctionData(
    "setVotingDelay(uint256 newVotingDelay)",
    [newVotingDelay]
  );
};

const setVotingPeriodFunctionEncoded = (governor, newVotingPeriod) => {
  return governor.interface.encodeFunctionData(
    "setVotingPeriod(uint256 newVotingPeriod)",
    [newVotingPeriod]
  );
};

const setProposalThresholdFunctionEncoded = (
  governor,
  newProposalThreshold
) => {
  return governor.interface.encodeFunctionData(
    "setProposalThreshold(uint256 newProposalThreshold)",
    [ethers.parseEther(newProposalThreshold)]
  );
};

const updateDelayFunctionEncoded = (timelock, newDelay) => {
  return timelock.interface.encodeFunctionData(
    "updateDelay(uint256 newDelay)",
    [newDelay]
  );
};

export const useProposal = (DAO) => {
  const provider = customHooks.useEthersProvider();

  const getProposalData = (form, dirtyFields) => {
    const governor = new ethers.Contract(
      DAO?.governor_contract?.address,
      governorArtifact.abi,
      provider
    );

    const timelock = new ethers.Contract(
      DAO?.timelock_contract?.address,
      timelockArtifact.abi,
      provider
    );

    let calldatas = [];
    let targets = [];
    let events = [];
    let values = [];
    if (dirtyFields?.quorum) {
      targets.push(DAO?.governor_contract?.address);
      calldatas.push(updateQuorumFunctionEncoded(governor, form.quorum));
      events.push({
        sourceContract: "governor",
        event: "QuorumNumeratorUpdated",
      });
      values.push(0);
    }
    if (dirtyFields?.voting_delay) {
      targets.push(DAO?.governor_contract?.address);
      calldatas.push(
        setVotingDelayFunctionEncoded(governor, form.voting_delay)
      );
      events.push({
        sourceContract: "governor",
        event: "VotingDelaySet",
      });
      values.push(0);
    }
    if (dirtyFields?.voting_period) {
      targets.push(DAO?.governor_contract?.address);
      calldatas.push(
        setVotingPeriodFunctionEncoded(governor, form.voting_period)
      );
      events.push({
        sourceContract: "governor",
        event: "VotingPeriodSet",
      });
      values.push(0);
    }
    if (dirtyFields?.voting_period) {
      targets.push(DAO?.governor_contract?.address);
      calldatas.push(
        setVotingPeriodFunctionEncoded(governor, form.voting_period)
      );
      events.push({
        sourceContract: "governor",
        event: "VotingPeriodSet",
      });
      values.push(0);
    }

    if (dirtyFields?.proposal_treshold) {
      targets.push(DAO?.governor_contract?.address);
      calldatas.push(
        setProposalThresholdFunctionEncoded(governor, form.proposal_treshold)
      );
      events.push({
        sourceContract: "governor",
        event: "ProposalThresholdSet",
      });
      values.push(0);
    }

    if (dirtyFields?.timelock_delay) {
      targets.push(DAO?.timelock_contract?.address);
      calldatas.push(updateDelayFunctionEncoded(timelock, form.timelock_delay));
      events.push({
        sourceContract: "timelock",
        event: "MinDelayChange",
      });
      values.push(0);
    }
    return {
      targets,
      calldatas,
      events,
      values,
    };
  };

  return {
    getProposalData,
  };
};
