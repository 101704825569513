import React from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import facebook from "../../static/facebook.png";
import twitter from "../../static/twitter.png";
import discord from "../../static/discord.png";
import instagram from "../../static/instagram.png";
import { makeStyles } from "@mui/styles";
import backgroundImg from "../../static/background.png";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: "cover",
  },
  footerTitle: {
    color: theme.palette.text.primary,
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "22px",
    lineHeight: "28px",
    textTransform: "uppercase",
  },
  footerLink: {
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    opacity: "1",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "25px",
    "&:hover": {
      color: "#944DFF",
      transition: "color 0.5s",
    },
  },
  subscribeDescription: {
    marginTop: "20px",
    color: theme.palette.text.secondary,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "30px",
  },
  notchedOutline: {
    borderColor: "transparent !important",
  },
  verticalAlign: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        mt: "0px",
        width: "100%",
        display: "block",
      }}
    >
      <Box>
        <Box
          sx={{
            p: { md: "0px 100px", mobile: "0px" },
            paddingBottom: "0px",
            paddingTop: "0px",
          }}
        >
          <Box
            sx={{
              marginTop: "0px",
              p: { md: "40px 0px", mobile: "0px 0px 20px" },
              display: { mobile: "none", md: "flex" },
              flexDirection: { mobile: "column", md: "row" },
              justifyContent: { mobile: "center", md: "space-between" },
              alignItems: "center",
            }}
          >
            <Typography
              variant={"subtitle1"}
              sx={{
                fontSize: "14px !important",
                display: "flex",
                alignItems: "center",
                mb: { mobile: "10px", md: "0px" },
                fontWeight: "500",
              }}
            >
              {`© 2022 - 2023 HAZEL LABS d. o. o | version ${process.env.REACT_APP_VERSION}`}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: { mobile: "20px", md: "0px" },
              }}
            >
              <Link
                to={{
                  pathname: "https://files.qvrse.io/Terms_and_Conditions.pdf",
                }}
                target="_blank"
                className={classes.footerLink}
              >
                Terms & Conditions
              </Link>

              <Box sx={{ ml: "40px", mr: "75px" }}>
                <Link
                  to={{ pathname: "https://files.qvrse.io/Privacy_Policy.pdf" }}
                  target="_blank"
                  className={classes.footerLink}
                >
                  Privacy policy
                </Link>
              </Box>
            </Box>
            <Box sx={{ display: "flex", mb: { mobile: "10px", md: "0px" } }}>
              <Box className={classes.verticalAlign}>
                <Link
                  to={{ pathname: "https://discord.gg/ts3HUyspEN" }}
                  target="_blank"
                  className={classes.footerLink}
                >
                  <img
                    width={"30px"}
                    height={"30px"}
                    src={discord}
                    alt={"discord"}
                  />
                </Link>
              </Box>
              <Box className={classes.verticalAlign} sx={{ ml: "10px" }}>
                <Link
                  to={{ pathname: "https://www.instagram.com/qvrse.io/" }}
                  target="_blank"
                  className={classes.footerLink}
                >
                  <img
                    width={"30px"}
                    height={"30px"}
                    src={instagram}
                    alt={"instagram"}
                  />
                </Link>
              </Box>
              <Box className={classes.verticalAlign} sx={{ ml: "10px" }}>
                <Link
                  to={{ pathname: "https://twitter.com/QvrseDAO" }}
                  target="_blank"
                  className={classes.footerLink}
                >
                  <img
                    width={"30px"}
                    height={"30px"}
                    src={twitter}
                    alt={"twitter"}
                  />
                </Link>
              </Box>
              <Box className={classes.verticalAlign} sx={{ ml: "10px" }}>
                <Link
                  to={{ pathname: "https://www.facebook.com/qvrse.io" }}
                  target="_blank"
                  className={classes.footerLink}
                >
                  <img
                    width={"30px"}
                    height={"30px"}
                    src={facebook}
                    alt={"facebook"}
                  />
                </Link>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: "0px",
              p: { md: "40px 0px", mobile: "20px 0px" },
              display: { mobile: "flex", md: "none" },
              flexDirection: { mobile: "column", md: "row" },
              justifyContent: { mobile: "center", md: "space-between" },
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: { mobile: "10px", md: "0px" },
              }}
            >
              <Link
                to={{
                  pathname: "https://files.qvrse.io/Terms_and_Conditions.pdf",
                }}
                target="_blank"
                className={classes.footerLink}
              >
                Terms & Conditions
              </Link>

              <Box sx={{ ml: "40px" }}>
                <Link
                  to={{ pathname: "https://files.qvrse.io/Privacy_Policy.pdf" }}
                  target="_blank"
                  className={classes.footerLink}
                >
                  Privacy policy
                </Link>
              </Box>
            </Box>
            <Typography
              variant={"subtitle1"}
              sx={{
                fontSize: "14px !important",
                display: "flex",
                alignItems: "center",
                mb: { mobile: "10px", md: "0px" },
                fontWeight: "500",
              }}
            >
              {`© 2022 - 2023 HAZEL LABS d. o. o. | ${process.env.REACT_APP_VERSION}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
