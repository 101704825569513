import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import UI from "../@components/UI";
import Grid from "@mui/material/Grid";
import SmallSkeletonList from "./SmallSkeletonList";
import useDaoService from "../daos/useService";
import CustomHooks from "../@components/hooks";
import PeopleIcon from "@mui/icons-material/People";
import collectionIcon from "../static/collection_icon.png";
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";

const Item = ({ item }) => {
  const history = useHistory();
  return (
    <Grid item mobile={12} tablet={6} sm={6} md={6} lg={6}>
      <Paper
        sx={{
          backgroundColor: "rgba(27, 30, 47, 0.5);",
        }}
      >
        <Box
          onClick={() =>
            history.push({
              pathname: item?.id === 1 ? `/qvrse` : `/brand/${item?.id}`,
              state: { scrollToTop: true },
            })
          }
          sx={{
            cursor: "pointer",
            border: "1px solid transparent",
            borderRadius: "0px",
            "&:hover": {
              boxShadow: "#944dff 0px 0px 10px 0px",
              border: "1px solid #944DFF",
            },
            display: "flex",
            justifyContent: "space-between",
            padding: { md: "17px 20px", mobile: "20px 10px" },
          }}
        >
          <Box sx={{ paddingRight: { md: "80px", mobile: "0px" } }}>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ mr: "20px" }}>
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    overflow: "hidden",
                    borderRadius: "50%",
                  }}
                >
                  <Box
                    component={"img"}
                    src={item?.avatar_url}
                    alt={"avatar"}
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      textIndent: "10000px",
                    }}
                  />
                </Box>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant={"h4"}
                  sx={{
                    fontSize: "18px !important",
                    lineHeight: "25px !important",

                    maxWidth: { mobile: "285px", md: "500px" },
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item?.name}
                </Typography>
              </Box>
            </Box>
          </Box>

          {item?.number_of_community_members && item?.number_of_collections && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "110px",
              }}
            >
              <Tooltip title={"Members"} arrow>
                <PeopleIcon />
              </Tooltip>
              <Typography
                variant={"h4"}
                sx={{ fontSize: "16px !important", ml: "10px" }}
              >
                {`${item?.number_of_community_members}`}
              </Typography>
              <Tooltip title={"Collections"} arrow>
                <Box
                  component={"img"}
                  src={collectionIcon}
                  sx={{
                    ml: "15px",
                    width: "24px",
                    height: "20px",
                    filter: "brightness(10)",
                  }}
                />
              </Tooltip>

              <Typography
                variant={"h4"}
                sx={{ fontSize: "16px !important", ml: "10px" }}
              >
                {`${item?.number_of_collections}`}
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Grid>
  );
};

const SmallList = ({ limit = { from: 0, to: -1 } }) => {
  const daoService = useDaoService();

  const { data: brands, isLoading: brandsLoading } = CustomHooks.useFetch(
    "brands",
    daoService.list
  );

  return (
    <Grid container spacing={2}>
      {brandsLoading && <SmallSkeletonList />}
      {!brandsLoading &&
        brands &&
        brands.length > 0 &&
        brands.map((item) => <Item item={item} />)}
      {!brandsLoading && brands && brands.length === 0 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <UI.NoResults text={"No collections found :("} />
        </Box>
      )}
    </Grid>
  );
};

export default SmallList;
