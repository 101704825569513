import Withdraw from "./withdraw";
import Settings from "./settings";
import Collection from "./collection";
import AddMember from "./addMember";

const Components = {
  Withdraw,
  Settings,
  Collection,
  AddMember,
};

export default Components;
