import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Controller, useFieldArray } from "react-hook-form";
import UI from "../../../../@components/UI";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles(() => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Poll = ({ control, errors, setValue, reset }) => {
  const classes = useStyles();

  const { fields, append, remove, replace } = useFieldArray({
    control: control,
    name: `content.options`,
  });

  React.useEffect(() => {
    // Default values
    setValue("content", {
      type: "poll",
      title: "",
      options: [],
      multiple_select: false,
      datetime_expire: new Date(Date.now() + 24 * 60 * 60 * 1000), // 24 hours from now
    });

    replace([
      { id: 1, title: "" },
      { id: 2, title: "" },
    ]);
  }, [setValue, replace]);

  return (
    <Box>
      <Box className={classes.inputOuter}>
        <Box sx={{ display: "flex", mb: "15px" }}>
          <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>TITLE</Typography>
        </Box>
        <Controller
          name={"content.title"}
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <UI.TextField placeholder={"Title"} {...field} />
          )}
        />
        {errors.content?.title?.type === "required" && (
          <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
            <Alert
              sx={{ color: (theme) => theme.palette.colors.darkRed }}
              variant="outlined"
              severity="error"
            >
              Title is required
            </Alert>
          </Typography>
        )}
      </Box>
      <Box className={classes.inputOuter}  sx={{ mb: "10px" }}>
        <Box sx={{ display: "flex", mb: "10px" }}>
          <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>OPTIONS</Typography>
        </Box>
        {fields.map((item, index) => {
          return (
            <Box sx={{ display: "flex", mb: "12px" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <Controller
                  name={`content.options.${index}.title`}
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <UI.TextField
                      placeholder={`Option ${index + 1}`}
                      {...field}
                    />
                  )}
                />
                {Object.keys(errors).length > 0 &&
                  errors.content?.options &&
                  errors.content.options[index]?.title?.type === "required" && (
                    <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                      <Alert
                        sx={{ color: (theme) => theme.palette.colors.darkRed }}
                        variant="outlined"
                        severity="error"
                      >
                        Option is required
                      </Alert>
                    </Typography>
                  )}
              </Box>
              {index > 1 && (
                <Box
                  onClick={() => remove(index)}
                  sx={{
                    cursor: "pointer",
                    ml: "8px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <DeleteForeverIcon
                    sx={{
                      color: (theme) => theme.palette.colors.darkRed,
                      opacity: "0.8",
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
          );
        })}
        <Box
          onClick={() => {
            fields.length < 10 && append({ id: fields.length + 1, title: "" });
          }}
          variant="raised"
          component="span"
          sx={{
            cursor: fields.length < 10 ? "pointer" : "cursor",
            padding: { md: "15px", mobile: "0px" },

            border: "3px dotted rgba(27, 30, 47, 1)",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "55px",
            "&:hover": {
              border:
                fields.length < 10
                  ? "3px dotted #944DFF"
                  : "3px dotted rgba(27, 30, 47, 1)",
            },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "flex-start" }}>
            <AddIcon
              sx={{
                color: fields.length < 10 ? "#944DFF" : "#944dff8f",
                fontSize: "2.5rem",
              }}
            />
            <Box
              sx={{
                ml: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "left",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px !important",
                  lineHeight: "18px",
                  color:
                    fields.length < 10
                      ? "rgba(255, 255, 255, 1)"
                      : "rgba(255, 255, 255, 0.5)",
                }}
                variant={"h2"}
              >
                Add Option
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.inputOuter}>
        <Box sx={{ display: "flex", mb: "10px" }}>
          <Controller
            name={"content.multiple_select"}
            control={control}
            render={({ field }) => (
              <UI.Checkbox
                {...field}
                nomargin={"true"}
                label={
                  <Box component={"span"} sx={{ fontWeight: "700", textTransform: "uppercase" }}>
                    Allow users to select multiple answers
                  </Box>
                }
              />
            )}
          />
        </Box>
      </Box>
      <Box className={classes.inputOuter}>
        <Box sx={{ display: "flex", mb: "0px" }}>
          <Typography variant={"h2"} sx={{ fontSize: "18px !important" }}>Poll duration</Typography>
        </Box>
        <Controller
          name={"content.datetime_expire"}
          control={control}
          render={({ field }) => <UI.DateTime {...field} />}
        />
      </Box>
    </Box>
  );
};

export default Poll;
