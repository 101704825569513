import React from "react";
import Box from "@mui/material/Box";
import UI from "../../../../@components/UI";
import { useAccount } from "wagmi";
import { useProposal } from "./utils";
import { ethers } from "ethers";
import governorArtifact from "../../../../abis/contracts/modules/governor/Governor.sol/DaoGovernor.json";
import useHandleErrors from "../../../../@components/UI/useHandleErrors";
import { useMenu } from "../../context";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import useService from "../useService";
import { uuid } from "uuidv4";
import voteWalletIcon from "../../../../static/voteWallet.png";
import customHooks from "../../../../@components/hooks";

const Index = ({
  DAO,
  isUserValid,
  walletAddress,
  emailAddress,
  amountTokens,
  userProfileImage,
  errors,
}) => {
  const { isConnected } = useAccount();
  const signer = customHooks.useEthersSigner();
  const history = useHistory();
  const voteService = useService(DAO?.id);
  const { getProposalData } = useProposal(DAO);
  const { updateMenu } = useMenu();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const { handleApiError, handleMetamaskError } = useHandleErrors();

  const addProposal = async () => {
    const { targets, values, calldatas } = getProposalData(
      walletAddress,
      amountTokens
    );

    try {
      setIsLoading(true);

      const proposalData = {
        targets: targets,
        values: values,
        calldatas: calldatas,
        description: `Mint governance tokens to address: ${walletAddress}. ID: ${uuid()}`,
      };

      const governor = new ethers.Contract(
        DAO?.governor_contract?.address,
        governorArtifact.abi,
        signer
      );

      const proposeTx = await governor.propose(
        proposalData.targets,
        proposalData.values,
        proposalData.calldatas,
        proposalData.description
      );

      const proposeReceipt = await proposeTx.wait(1);

      const proposalId = proposeReceipt.logs[0].args.proposalId.toString();

      // Save proposal to BE.
      voteService
        .save({
          proposal_type: "addMember",
          proposal_hash_id: proposalId,
          calldatas: [],
          signatures: [],
          targets: [],
          start_block: Number(
            proposeReceipt.logs[0].args.startBlock
          ).toString(),
          end_block: Number(proposeReceipt.logs[0].args.endBlock).toString(),
          status: "Active",
          progress: 0,
          data: {
            proposal_data: proposalData,
            walletAddress,
            amountTokens,
            emailAddress,
            userProfileImage,
            daoId: DAO.id,
          },
        })
        .then((response) => {
          enqueueSnackbar("Proposal succesfully sent.", { variant: "success" });
          updateMenu();
          setIsLoading(false);
          history.push({
            pathname: `/brand/${DAO.id}/vote/${response?.data?.id}/addMember`,
            state: { scrollToTop: true },
          });
        })
        .catch((error) => {
          handleApiError(error);
          setIsLoading(false);
        });
    } catch (error) {
      handleMetamaskError(error);
      setIsLoading(false);
    }
  };
  return (
    <Box
      sx={{ width: "210px" }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <UI.Busy.FullscreenIndicator show={isLoading} />
      <UI.Button
        disabled={
          !isConnected || !isUserValid || Object.keys(errors).length > 0
        }
        onClick={addProposal}
        title={"Add Member"}
        type={"primary"}
        startIcon={
          <Box
            component={"img"}
            src={voteWalletIcon}
            sx={{ width: "43px", height: "19px", mr: "8px" }}
          />
        }
      />
    </Box>
  );
};

export default Index;
