import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import UI from "../../@components/UI";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useAccount } from "wagmi";
import useService from "./useService";
import { useSnackbar } from "notistack";
import Alert from "@mui/material/Alert";
import { ethers } from "ethers";
import useHandleErrors from "../../@components/UI/useHandleErrors";
import erc20TokenArtifact from "../../abis/contracts/modules/token/ERC20Token.sol/ERC20Token.json";
import stakingArtifact from "../../abis/contracts/qtech/staking/Staking.sol/QtechStaking.json";
import tokenArtifact from "../../abis/contracts/token/QvrseToken.sol/QvrseToken.json";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Link from "@mui/material/Link";
import customHooks from "../../@components/hooks";

const useStyles = makeStyles(() => ({
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },
  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Create = ({ DAO }) => {
  const { enqueueSnackbar } = useSnackbar();
  const service = useService(DAO?.id);
  const history = useHistory();
  const classes = useStyles();
  const { address, isConnected } = useAccount();
  const provider = customHooks.useEthersProvider();
  const signer = customHooks.useEthersSigner();
  const [tokenBalance, setTokenBalance] = React.useState(0);
  const { handleApiError, handleMetamaskError } = useHandleErrors();
  const [isLoading, setIsLoading] = React.useState(false);
  const [canStake, setCanStake] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const token = new ethers.Contract(
        DAO?.dao_token?.token_address,
        tokenArtifact.abi,
        provider
      );

      const tokenBalance = await token.balanceOf(address);
      setTokenBalance(ethers.formatEther(tokenBalance).toString());
    };
    provider && address && DAO && fetchData();
    //eslint-disable-next-line
  }, [provider, address, DAO]);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      amount: "",
      duration_days: 30,
      user_address: address || null,
    },
  });

  const amountValue = useWatch({ control, name: "amount" });

  React.useEffect(() => {
    if (parseFloat(amountValue) > tokenBalance) {
      setCanStake(false);
    } else {
      setCanStake(true);
    }
  }, [amountValue, tokenBalance]);

  const onSubmit = async (form) => {
    setIsLoading(true);

    try {
      const token = new ethers.Contract(
        DAO.token_contract.address,
        erc20TokenArtifact.abi,
        signer
      );

      const staking = new ethers.Contract(
        DAO.staking_contract.address,
        stakingArtifact.abi,
        signer
      );

      const allowanceTx = await token.increaseAllowance(
        staking.target,
        ethers.parseEther(form.amount)
      );
      await allowanceTx.wait(1);

      const stakeTx = await staking.stakeTokens(
        ethers.parseEther(form.amount),
        0
      );
      const stakeReceipt = await stakeTx.wait(1);

      const event = stakeReceipt.logs.find(
        (data) => data.eventName === "SimpleStakeEvent"
      );

      const stakeIdBigInt = event && event.args["userStake"].userStakeId;
      const stakeId = Number(stakeIdBigInt).toString();
      const data = { ...form, stake_id: stakeId };

      await service
        .save(data)
        .then(() => {
          enqueueSnackbar("Succesfully added to members", {
            variant: "success",
          });
          setIsLoading(false);
          history.push({
            pathname: `/qvrse/membership`,
            state: { scrollToTop: true },
          });
        })
        .catch((error) => {
          handleApiError(error);
        });
    } catch (error) {
      handleMetamaskError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{ width: "100%", background: "rgba(27, 30, 47, 0.5)" }}
    >
      <UI.Busy.FullscreenIndicator show={isLoading} />
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", mobile: "column" },
          justifyContent: { md: "space-between", mobile: "center" },
          px: { md: "70px", mobile: "0px" },
          pb: "20px",
          pt: { md: "55px", mobile: "30px" },
        }}
      >
        <UI.BackButton
          fontSize={"14px !important"}
          title={"BACK TO MEMBERSHIP"}
          onClick={() =>
            history.push({
              pathname: `/qvrse/membership`,
              state: { scrollToTop: true },
            })
          }
        />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: "100%",
            px: { md: "80px", mobile: "10px" },
            pb: "70px",
          }}
        >
          <Typography
            variant={"h1"}
            sx={{
              fontSize: "30px !important",
              lineHeight: { md: "20px !important", mobile: "1.5 !important" },
              mb: "25px",
              mt: "10px",
            }}
          >
            BECOME THE QVRSE DAO MEMBER
          </Typography>
          <Typography variant={"subtitle1"}>
            Once you acquire the Qvrse tokens, you simply need to lock a minimum
            of 1 token to unlock membership privileges and gain the right to
            vote. Think of this process as claiming your membership ticket.
            However, the amount of tokens you lock determines your voting power
            within the Qvrse DAO.
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item mobile={12} md={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px", mt: "30px" }}>
                  <Typography
                    variant={"h2"}
                    sx={{ fontSize: "18px !important" }}
                  >
                    Amount
                  </Typography>
                  <UI.Tooltip>
                    Enter the number of tokens you want to lock.
                  </UI.Tooltip>
                </Box>

                <Controller
                  name={"amount"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.TextField {...field} placeholder={"Amount"} />
                  )}
                />
                <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                  {errors.amount?.type === "required" && (
                    <Alert
                      sx={{ color: (theme) => theme.palette.colors.darkRed }}
                      variant="outlined"
                      severity="error"
                    >
                      Amount is required
                    </Alert>
                  )}
                </Typography>
                {DAO && (
                  <Typography variant={"subtitle1"} sx={{ mt: "5px" }}>
                    <Alert
                      variant="outlined"
                      severity="info"
                      sx={{ color: "#4DB8FF" }}
                    >
                      {canStake === false
                        ? `You currently don’t have the required amount of tokens available.`
                        : `You have ${tokenBalance} ${DAO?.dao_token?.token_symbol} tokens`}
                    </Alert>
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              width: "100%",
              mt: "45px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: "210px" }}>
                <UI.Button
                  disabled={
                    !canStake ||
                    !isConnected ||
                    !(tokenBalance > 0) ||
                    parseFloat(amountValue || 0) === 0
                  }
                  title={"join"}
                  type={"primary"}
                  startIcon={<AccountBalanceWalletIcon />}
                />
              </Box>
            </Box>
          </Box>
          <Typography
            variant={"subtitle1"}
            sx={{
              mt: "45px",
            }}
          >
            If you wish to become a member of the Qvrse DAO but haven't acquired
            the Qvrse tokens yet, click the button below.
          </Typography>
        </Box>
      </form>
      <Box
        sx={{
          width: "100%",
          pb: "75px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Link
          href={"https://qvrse.io/participate_in_presale.php"}
          target={"_blank"}
          sx={{
            display: "flex",
            justifyContent: "center",
            textDecoration: "none",
          }}
        >
          <Box sx={{ width: "250px" }}>
            <UI.Button
              onClick={() => {}}
              title={"GET YOUR QVRSE TOKENS"}
              type={"primary"}
            />
          </Box>
        </Link>
      </Box>
    </Paper>
  );
};

export default Create;
