import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module

import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../index";
import Skeleton from "@mui/material/Skeleton";
import maticLogo from "../../../static/matic-logo.png";

const Item = ({
  name,
  price,
  image,
  availableTickets,
  ticketLimit,
  daoId,
  collectionId,
}) => {
  const history = useHistory();
  return (
    <Box
      sx={{
        width: "300px",
        "&:hover": {
          "box-shadow": "0px 0px 5px 2px #944dff",
        },
        transition: "box-shadow 0.5s ease-in-out",
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.colors.darkBlue,
          border: "1px solid #944DFF",
          filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5))",
          borderBottom: "none",
          padding: "20px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {name ? (
            <Typography
              variant={"h2"}
              sx={{
                fontSize: "1rem !important",
                maxWidth: "185.4px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <UI.OverflowTip>{`${name}`}</UI.OverflowTip>
            </Typography>
          ) : (
            <Skeleton height={"30px"} width={"130px"} variant={"rectangular"} />
          )}
          <Typography
            variant={"h2"}
            sx={{
              fontSize: "1rem !important",
              maxWidth: "60px",
              display: { lg: "block", md: "none", mobile: "none" },
            }}
          >
            <UI.OverflowTip>{`${availableTickets} / ${ticketLimit}`}</UI.OverflowTip>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          height: { lg: "300px", md: "300px" },
          width: { lg: "300px", md: "100%" },
          overflow: "hidden",
          border: "1px solid #944DFF",
          filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5))",
        }}
      >
        {image ? (
          <img src={image} alt={"ticket"} height={"100%"} width={"100%"} />
        ) : (
          <Skeleton height={"100%"} width={"100%"} variant={"rectangular"} />
        )}
      </Box>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.colors.darkBlue,
          border: "1px solid #944DFF",
          filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5))",
          borderTop: "none",
          padding: "20px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {name ? (
            <Box sx={{ display: "flex" }}>
              <Typography
                variant={"subtitle1"}
                sx={{
                  fontSize: "1rem !important",
                  lineHeight: "33px",
                  mr: "10px",
                }}
              >
                Price
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    mr: "5px",
                    height: "25px",
                    width: "15px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <img src={maticLogo} alt={"maticLogo"} width={"100%"} />
                </Box>
              </Box>
              <Typography
                variant={"h2"}
                sx={{ maxWidth: "80px", fontSize: "1rem !important" }}
              >
                <UI.OverflowTip>{`${
                  price == null || price === "" ? 0 : price.toString()
                }`}</UI.OverflowTip>
              </Typography>
            </Box>
          ) : (
            <Skeleton height={"30px"} width={"100px"} variant={"rectangular"} />
          )}
          <Box sx={{ width: { lg: "100px", md: "70px" } }}>
            <UI.Button
              sx={{ fontSize: "14px", height: "30px" }}
              onClick={() =>
                history.push({
                  pathname: `/brand/${daoId}/collection/${collectionId}`,
                  state: { scrollToTop: true },
                })
              }
              type={"primary"}
              title={"View"}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default function App({ items }) {
  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        slidesPerGroup={3}
        loop={true}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {items?.map((item) => (
          <SwiperSlide>
            <Item
              key={item.id}
              daoId={item.dao_id}
              collectionId={item.id}
              name={item.name}
              price={item.total_price_with_tax || 0}
              image={item.nft_image_url}
              availableTickets={item.nft_amount_minted}
              ticketLimit={item.nft_amount_limit}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
