import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import formInputs from "../formInputs";
import { useFormData } from "../context";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "./styles.css";
// Styles must use direct files imports
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module

const useStyles = makeStyles(() => ({
  categoryItem: {
    width: "170px",
    height: ({ isMobile }) => {
      return isMobile ? "110px" : "170px";
    },
    display: "flex",
    alignItems: "center",
    background: "rgba(27, 30, 47, 0.7)",
    marginLeft: ({ isMobile }) => {
      return isMobile ? "5px" : "17px";
    },
    marginRight: ({ isMobile }) => {
      return isMobile ? "5px" : "17px";
    },
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    boxShadow: ({ selected }) => {
      return selected ? "#944dff 0px 0px 10px 0px" : "none";
    },
    border: ({ selected }) => {
      return selected ? "2px solid #944DFF" : "2px solid transparent";
    },
    "&:hover": {
      transform: ({ isMobile }) => {
        return isMobile ? "scale(1)" : "scale(1.1)";
      },
      boxShadow: "#944dff 0px 0px 10px 0px",
      border: "2px solid #944DFF",
    },
  },
}));

const Item = ({ selected, index, onSelect, title }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const classes = useStyles({ selected, isMobile });
  return (
    <Box className={classes.categoryItem} onClick={() => onSelect(index)}>
      <Typography sx={{ margin: "0 auto", fontWeight: "bold" }}>
        {title.toUpperCase()}
      </Typography>
    </Box>
  );
};

const Desktop = ({ selected, setSelected, onSelect }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Item
        selected={selected === 0}
        onSelect={() => {
          setSelected(0);
          onSelect(formInputs.digitalContent);
        }}
        index={0}
        title={"Digital Content"}
      />
      <Item
        selected={selected === 1}
        onSelect={() => {
          setSelected(1);
          onSelect(formInputs.product);
        }}
        index={1}
        title={"Product"}
      />
      <Item
        selected={selected === 2}
        onSelect={() => {
          setSelected(2);
          onSelect(formInputs.service);
        }}
        index={2}
        title={"Service"}
      />
      <Item
        selected={selected === 3}
        onSelect={() => {
          setSelected(3);
          onSelect(formInputs.ticket);
        }}
        index={3}
        title={"Event Ticket"}
      />
    </Box>
  );
};

const Mobile = ({ selected, setSelected, onSelect }) => {
  return (
    <Swiper
      spaceBetween={5}
      grabCursor={true}
      slidesPerView={3}
      className="collection-category-swiper"
    >
      <SwiperSlide>
        <Item
          selected={selected === 0}
          onSelect={() => {
            setSelected(0);
            onSelect(formInputs.digitalContent);
          }}
          index={0}
          title={"Digital Content"}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Item
          selected={selected === 1}
          onSelect={() => {
            setSelected(1);
            onSelect(formInputs.product);
          }}
          index={1}
          title={"Product"}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Item
          selected={selected === 2}
          onSelect={() => {
            setSelected(2);
            onSelect(formInputs.service);
          }}
          index={2}
          title={"Service"}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Item
          selected={selected === 3}
          onSelect={() => {
            setSelected(3);
            onSelect(formInputs.ticket);
          }}
          index={3}
          title={"Event Ticket"}
        />
      </SwiperSlide>
    </Swiper>
  );
};

const Select = ({ draft, onSelect }) => {
  const [selected, setSelected] = React.useState(0);
  const { data } = useFormData();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  React.useEffect(() => {
    if (data && draft) {
      let formData = {};
      if (draft !== null) {
        formData = draft.data;
      } else {
        formData = data;
      }
      const {
        main_utility: { type },
      } = formData;

      if (type === "digital_content") {
        setSelected(0);
      } else if (type === "product") {
        setSelected(1);
      } else if (type === "service") {
        setSelected(2);
      } else if (type === "ticket") {
        setSelected(3);
      }
    }
  }, [data, draft]);
  return (
    <Box sx={{ width: "100%" }}>
      {!isMobile ? (
        <Desktop
          selected={selected}
          onSelect={onSelect}
          setSelected={setSelected}
        />
      ) : (
        <Mobile
          selected={selected}
          onSelect={onSelect}
          setSelected={setSelected}
        />
      )}
    </Box>
  );
};

export default Select;
