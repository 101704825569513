import React, { useEffect } from "react";
import { getProposalState } from "./utils";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import governorArtifact from "../../../../abis/contracts/modules/governor/GovernorERC20.sol/DaoGovernorERC20.json";
import stakingArtifact from "../../../../abis/contracts/modules/staking/Staking.sol/Staking.json";
import customHooks from "../../../../@components/hooks";

const useBlockchainState = (DAO, proposal) => {
  const provider = customHooks.useEthersProvider();
  const [isFetchingBlockchainState, setIsFetchingBlockchainState] =
    React.useState(false);
  const [currentVotes, setCurrentVotes] = React.useState(null);
  const [proposalState, setProposalState] = React.useState(null);
  const [accountHasVoted, setAccountHasVoted] = React.useState(null);
  const [votingPower, setVotingPower] = React.useState(0);
  const [totalStakedAmount, setTotalStakedAmount] = React.useState(0);

  const { address, isConnected } = useAccount();

  const getStakingData = async () => {
    const staking = new ethers.Contract(
      DAO?.staking_contract?.address,
      stakingArtifact.abi,
      provider
    );
    const stakedAmount = await staking.getTotalStakedAmount();
    setTotalStakedAmount(stakedAmount.toString());
  };

  const getGovernorData = async (proposal) => {
    if (proposal) {
      const governor = new ethers.Contract(
        DAO?.governor_contract?.address,
        governorArtifact.abi,
        provider
      );
      if (isConnected) {
        const hasVoted = await governor.hasVoted(
          proposal.proposal_hash_id,
          address
        );
        const votingPower = await governor.getVotes(
          address,
          proposal.start_block
        );
        setAccountHasVoted(hasVoted);
        setVotingPower(ethers.formatEther(votingPower));
      }

      const proposalVotes = await governor.proposalVotes(
        proposal.proposal_hash_id
      );

      const proposalState = await governor.state(proposal.proposal_hash_id);

      setProposalState(await getProposalState(proposalState));
      setCurrentVotes(proposalVotes);
    }
  };

  useEffect(() => {
    if (
      currentVotes &&
      proposalState &&
      accountHasVoted &&
      votingPower &&
      totalStakedAmount
    ) {
      setIsFetchingBlockchainState(false);
    }
  }, [
    currentVotes,
    proposalState,
    accountHasVoted,
    votingPower,
    totalStakedAmount,
  ]);

  React.useEffect(() => {
    const fetchData = async () => {
      setIsFetchingBlockchainState(true);
      if (DAO && proposal) {
        await getGovernorData(proposal);
        await getStakingData(proposal);
      }
    };
    DAO && proposal && fetchData();
    // eslint-disable-next-line
  }, [proposal, DAO]);

  return {
    currentVotes,
    proposalState,
    accountHasVoted,
    votingPower,
    totalStakedAmount,
    isFetchingBlockchainState,
  };
};

export default useBlockchainState;
