import React from "react";
import UI from "../../../../../../@components/UI";
import useService from "../../../useService";
import { ethers } from "ethers";
import eventArtifact from "../../../../../../abis/contracts/modules/event/Event.sol/Event.json";
import collectionMultiArtifact from "../../../../../../abis/contracts/modules/collectionNativeMultipleImages/CollectionNativeMultipleImages.sol/CollectionNativeMultipleImages.json";
import { useSnackbar } from "notistack";
import { useAccount } from "wagmi";
import useHandleErrors from "../../../../../../@components/UI/useHandleErrors";
import RequestInfoDialog from "../RequestInfoDialog";
import SuccessDialog from "./SuccessDialog";
import { useUserContext } from "../../../../../../@components/userContext";
import { useUserRole } from "../../../../context";
import customHooks from "../../../../../../@components/hooks";
const Button = (props) => {
  const {
    title,
    DAO,
    collection,
    collectionId,
    onSuccess,
    onToggleEnablePurchase,
    ...other
  } = props;
  const { user: loggedInUser } = useUserContext();
  const { isConnected } = useAccount();
  const { isBrandMember } = useUserRole();
  const signer = customHooks.useEthersSigner();
  const { enqueueSnackbar } = useSnackbar();
  const { handleMetamaskError } = useHandleErrors();
  const [showSuccessDialog, setShowSuccessDialog] = React.useState(false);
  const [showRequestInfoDialog, setShowRequestInfoDialog] =
    React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const collectionService = useService(DAO?.id);

  const [requestInfoFields, setRequestInfoFields] = React.useState([]);
  const [isUserAlreadyBuyer, setIsUserAlreadyBuyer] = React.useState(false);

  React.useEffect(() => {
    if (collection?.total_price_with_tax === "0.00") {
      const isUserAlreadyBuyer = collection?.buyers.some(
        (buyer) => buyer.buyer_address === loggedInUser?.address
      );
      setIsUserAlreadyBuyer(isUserAlreadyBuyer);
    }
  }, [collection, loggedInUser]);

  React.useEffect(() => {
    if (collection?.utilities.length === 0) return;

    const fields = collection?.utilities
      .flatMap((utility) => utility.request_info_fields)
      .filter((field) => field !== undefined);
    setRequestInfoFields(fields);
  }, [collection]);

  const saveBuyer = async (ticketId, additionalInformation) => {
    await collectionService
      .buyTicket(collectionId, {
        ticket_id: ticketId,
        additional_info: {
          ...additionalInformation,
        },
      })
      .then(() => {
        enqueueSnackbar("The NFT was successfully purchased", {
          variant: "success",
        });
        setShowSuccessDialog(true);
        onSuccess();
      })
      .catch((error) => {
        enqueueSnackbar(error?.message, { variant: "error" });
      });
  };

  const buyTicket = async (additionalInformation) => {
    if (collection.has_multiple_images) {
      // if the collection has multiple images, we need to call buyMultiTicket
      await buyMultiTicket(additionalInformation);
    } else {
      // otherwise, we call buyStandardTicket
      await buyStandardTicket(additionalInformation);
    }
  };

  const buyMultiTicket = async (additionalInformation) => {
    setShowRequestInfoDialog(false);
    setIsLoading(true);

    try {
      const collectionContract = new ethers.Contract(
        collection.nft_address,
        collectionMultiArtifact.abi,
        signer
      );

      const priceToBuy = ethers.parseUnits(
        String(collection?.total_price_with_tax),
        collection?.payment_token?.decimals
      );

      let buyTicketTxResponse;

      // get random image url from collection.collection_images array
      const randomImageUrl =
        collection.collection_images[
          Math.floor(Math.random() * collection.collection_images.length)
        ];

      buyTicketTxResponse = await collectionContract.buyTicket(
        randomImageUrl.image_url,
        {
          value: priceToBuy,
        }
      );

      const buyTicketReceipt = await buyTicketTxResponse.wait(1);

      const ticketMintEvent = buyTicketReceipt.logs.find(
        (event) => event?.eventName === "TicketMint"
      );

      const ticketId = Number(ticketMintEvent.args[1]);

      saveBuyer(ticketId, additionalInformation);
      setIsLoading(false);
    } catch (error) {
      handleMetamaskError(error);
      setIsLoading(false);
    }
  };

  const buyStandardTicket = async (additionalInformation) => {
    setShowRequestInfoDialog(false);
    setIsLoading(true);
    try {
      const collectionContract = new ethers.Contract(
        collection.nft_address,
        eventArtifact.abi,
        signer
      );

      const priceToBuy = ethers.parseUnits(
        String(collection?.total_price_with_tax),
        collection?.payment_token?.decimals
      );

      let buyTicketTxResponse;

      buyTicketTxResponse = await collectionContract.buyTicket({
        value: priceToBuy,
      });

      const buyTicketReceipt = await buyTicketTxResponse.wait(1);

      const ticketMintEvent = buyTicketReceipt.logs.find(
        (event) => event?.eventName === "TicketMint"
      );

      const ticketId = Number(ticketMintEvent.args[1]);

      saveBuyer(ticketId, additionalInformation);
      setIsLoading(false);
    } catch (error) {
      handleMetamaskError(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <UI.Busy.FullscreenIndicator show={isLoading} />
      {showRequestInfoDialog && (
        <RequestInfoDialog
          onClose={() => setShowRequestInfoDialog(false)}
          onConfirm={(additionalInformation) =>
            buyTicket(additionalInformation)
          }
          fields={requestInfoFields}
        />
      )}
      {showSuccessDialog && (
        <SuccessDialog onClose={() => setShowSuccessDialog(false)} />
      )}
      <UI.SplitButton
        onMenuItemClick={onToggleEnablePurchase}
        isPurchaseEnabled={collection.purchase_enabled}
        hideDropDownButton={!isBrandMember}
        disabled={
          !collection.purchase_enabled ||
          isUserAlreadyBuyer ||
          !isConnected ||
          !loggedInUser?.email_verified ||
          collection?.schedule_status.toLowerCase() !== "active" ||
          collection?.nft_amount_minted >= collection?.nft_amount_limit
        }
        sx={{ height: "42px", mr: "15px", width: "180px" }}
        type={"primary"}
        title={title}
        onClick={() =>
          requestInfoFields.length > 0
            ? setShowRequestInfoDialog(true)
            : buyTicket()
        }
        {...other}
      />
    </>
  );
};

export default Button;
