import React, { useState } from "react";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";

const FlipCardContainer = styled(Card)`
  width: 290px;
  height: 445px;
  perspective: 1000px;
  background: transparent;
`;

const FlipCardInner = styled("div", {
  shouldForwardProp: (prop) => prop !== "isFlipped",
})(({ isFlipped }) => ({
  position: "relative",
  width: "100%",
  height: "auto",
  textAlign: "center",
  transition: "transform 0.3s",
  transformStyle: "preserve-3d",
  transform: isFlipped ? "rotateY(180deg)" : "none",
}));

const FlipCardSide = styled("div")`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
`;

const FlipCardFront = styled(FlipCardSide)``;

const FlipCardBack = styled(FlipCardSide)`
  transform: rotateY(180deg);
`;

const FlipCard = ({ frontComponent, backComponent }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <FlipCardContainer
      onClick={handleClick}
      sx={{
        transition: "all 0.2s ease-in-out",
        border: "2px solid transparent",
        "&:hover": {
          border: "2px solid #944dff",
          boxShadow: "#944dff 0px 0px 10px 0px",
          transition: "all 0.2s ease-in-out",
        },
      }}
    >
      <FlipCardInner isFlipped={isFlipped}>
        <FlipCardFront>{frontComponent}</FlipCardFront>
        <FlipCardBack>{backComponent}</FlipCardBack>
      </FlipCardInner>
    </FlipCardContainer>
  );
};

export default FlipCard;
