import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../@components/UI";
import paypalLogo from "../../../../static/paypal.png";
import stripeLogo from "../../../../static/stripe.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import MaterialAccordion from "@mui/material/Accordion";
import Utility from "../../proposal/collection/extraUtilities";
import PaypalDialog from "./PaypalDialog";
import StripeDialog from "./StripeDialog";
import { useUserRole } from "../../context";
import CustomHooks from "../../../../@components/hooks";

const Index = ({ DAO, refetch }) => {
  const { isBrandMember } = useUserRole();
  CustomHooks.useProtectedRoute(DAO, isBrandMember);
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: { md: "70px", mobile: "10px" },
          pb: "20px",
          pt: { md: "30px", mobile: "30px" },
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            variant={"h1"}
            sx={{ fontSize: "30px !important", lineHeight: "20px !important" }}
          >
            PAYMENT SETTINGS
          </Typography>
        </Box>
      </Box>
      <Box sx={{ padding: { md: "10px 70px", mobile: "0px 10px" } }}>
        <Typography variant={"subtitle1"} sx={{ mb: "50px" }}>
          Here you can edit or add more info about your brand.
        </Typography>
        <MaterialAccordion
          TransitionProps={{ unmountOnExit: true }}
          sx={{
            backgroundColor: "rgba(27, 30, 47, 0.5)",
          }}
        >
          <AccordionSummary
            sx={{
              height: "80px",
              padding: { md: "18px 30px", mobile: "15px" },
            }}
            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{ display: "flex" }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Box
                    component={"img"}
                    src={paypalLogo}
                    sx={{ width: "45px" }}
                  />
                  <Typography variant={"h4"} sx={{ ml: "15px" }}>
                    Paypal
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Box
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    display: { md: "flex", mobile: "none" },
                    flexDirection: "column",
                    justifyContent: "center",
                    mr: "20px",
                  }}
                >
                  <PaypalDialog.Action
                    element={
                      <UI.Button type={"secondary"} title={"Change settings"} />
                    }
                    DAO={DAO}
                    onSuccess={() => refetch()}
                  />
                </Box>
                <Box
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    display: { md: "none", mobile: "flex" },
                    flexDirection: "column",
                    justifyContent: "center",
                    mr: "20px",
                  }}
                >
                  <PaypalDialog.Action
                    element={
                      <UI.Button type={"secondary"} title={"Change"} />
                    }
                    DAO={DAO}
                    onSuccess={() => refetch()}
                  />
                </Box>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: { md: "15px  35px", mobile: "15px  15px" },
            }}
          >
            <Utility.AccordionItem
              title={"Client ID"}
              value={DAO?.fiat_payment_info?.paypal?.public_key}
            />
            <Utility.AccordionItem
              title={"Secret Key"}
              value={DAO?.fiat_payment_info?.paypal?.secret_key}
            />
            <Utility.AccordionItem
              title={"Web hook"}
              value={DAO?.fiat_payment_info?.paypal?.web_hook}
            />
          </AccordionDetails>
        </MaterialAccordion>
        <MaterialAccordion
          TransitionProps={{ unmountOnExit: true }}
          sx={{
            mt: "15px",
            backgroundColor: "rgba(27, 30, 47, 0.5)",
          }}
        >
          <AccordionSummary
            sx={{
              height: "80px",
              padding: { md: "18px 30px", mobile: "15px" },
            }}
            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{ display: "flex" }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Box
                    component={"img"}
                    src={stripeLogo}
                    sx={{ width: "45px" }}
                  />
                  <Typography variant={"h4"} sx={{ ml: "15px" }}>
                    Stripe
                  </Typography>
                </Box>
              </Box>
              <Box
                onClick={(e) => e.stopPropagation()}
                sx={{ display: "flex" }}
              >
                <Box
                  sx={{
                    display: { md: "flex", mobile: "none" },
                    flexDirection: "column",
                    justifyContent: "center",
                    mr: "20px",
                  }}
                >
                  <StripeDialog.Action
                    element={
                      <UI.Button type={"secondary"} title={"Change settings"} />
                    }
                    DAO={DAO}
                    onSuccess={() => refetch()}
                  />
                </Box>
                <Box
                  sx={{
                    display: { md: "none", mobile: "flex" },
                    flexDirection: "column",
                    justifyContent: "center",
                    mr: "20px",
                  }}
                >
                  <StripeDialog.Action
                    element={
                      <UI.Button type={"secondary"} title={"Change"} />
                    }
                    DAO={DAO}
                    onSuccess={() => refetch()}
                  />
                </Box>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: { md: "15px  35px", mobile: "15px  15px" },
            }}
          >
            <Utility.AccordionItem
              title={"Public Key"}
              value={DAO?.fiat_payment_info?.stripe?.public_key}
            />
            <Utility.AccordionItem
              title={"Secret Key"}
              value={DAO?.fiat_payment_info?.stripe?.secret_key}
            />
          </AccordionDetails>
        </MaterialAccordion>
      </Box>
    </Box>
  );
};

export default Index;
