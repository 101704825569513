import Checkbox from "./Checkbox";
import Date from "./Date";
import DateTime from "./DateTime";
import NumberField from "./NumberField";
import PasswordField from "./PasswordField";
import Select from "./Select";
import TextField from "./TextField";
import Time from "./Time";
import GetInputByType from "./GetInputByType";

const Inputs = {
  Checkbox,
  Date,
  DateTime,
  NumberField,
  PasswordField,
  Select,
  TextField,
  Time,
  GetInputByType,
};

export default Inputs;
