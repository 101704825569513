import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Draft from "./draft";
import Grid from "@mui/material/Grid";
import Brands from "./brands";
import { useHistory } from "react-router-dom";
import UI from "../../@components/UI";

const Index = () => {
  const history = useHistory();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pb: "20px",
          pt: { md: "70px", mobile: "30px" },
        }}
      >
        <Grid container>
          <Grid item mobile={12} md={12}>
            <Typography variant={"h1"} sx={{ fontSize: "30px !important" }}>
              Created Brands
            </Typography>

            <Box sx={{ padding: { md: "30px 0px", mobile: "30px 0px" } }}>
              <Brands />
            </Box>
          </Grid>
          <Grid
            item
            mobile={12}
            md={12}
            sx={{
              mt: { mobile: "60px", md: "60px" },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant={"h1"} sx={{ fontSize: "24px !important" }}>
                Brand Drafts
              </Typography>
              <Box
                sx={{
                  width: "240px",
                  height: "42px",
                  display: { md: "flex", mobile: "none" },
                }}
              >
                <UI.Button
                  type={"primary"}
                  title={"Apply your brand"}
                  onClick={() =>
                    history.push({
                      pathname: `/brand/apply`,
                      state: { scrollToTop: true },
                    })
                  }
                />
              </Box>
            </Box>

            <Box
              sx={{
                padding: { md: "10px 0px", mobile: "10px 0px" },
              }}
            >
              <Draft.BrandList
                draftType={"applyForDao"}
                onClick={(item) =>
                  history.push({
                    pathname: `/brand/apply/draft/${item?.id}`,
                    state: {
                      scrollToTop: true,
                      draft: item.data,
                      draft_id: item?.id,
                    },
                  })
                }
              />
            </Box>
            <Box
              sx={{
                width: "240px",
                height: "42px",
                mt: "40px",
                mx: "auto",
                display: { md: "none", mobile: "flex" },
              }}
            >
              <UI.Button
                type={"primary"}
                title={"Apply your brand"}
                onClick={() =>
                  history.push({
                    pathname: `/brand/apply`,
                    state: { scrollToTop: true },
                  })
                }
              />
            </Box>
          </Grid>
          <Grid
            item
            mobile={12}
            md={12}
            sx={{
              mt: { mobile: "60px", md: "60px" },
            }}
          >
            <Typography variant={"h1"} sx={{ fontSize: "24px !important" }}>
              Collection Drafts
            </Typography>

            <Box sx={{ padding: { md: "10px 0px", mobile: "10px 0px" } }}>
              <Draft.CollectionList
                draftType={"collection"}
                onClick={(item, index) =>
                  history.push({
                    pathname:
                      item.draft_type === "collection_import"
                        ? `/brand/${item?.data?.dao_id}/collection/import/draft/${item?.id}`
                        : `/brand/${item?.data?.dao_id}/collection/new/draft/${item?.id}`,
                    state: {
                      scrollToTop: true,
                      draft: item.data,
                      draft_id: item?.id,
                      draft_display_index: index,
                    },
                  })
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Index;
