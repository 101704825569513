import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Typography from "@mui/material/Typography";
import React from "react";

const QvrseTooltip = ({ children }) => {
  return (
    <Tooltip
      sx={{
        width: "18px",
        cursor: "pointer",
        ml: "5px",
        color: (theme) => theme.palette.colors.primary,
      }}
      title={
        <Typography variant={"subtitle1"} sx={{ fontSize: "14px !important" }}>
          {children}
        </Typography>
      }
      placement="top"
      arrow
    >
      <HelpOutlineIcon />
    </Tooltip>
  );
};

export default QvrseTooltip;
